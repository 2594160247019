/*
 * Written by Alexander Agudelo < alex@kemu.io >, 2022
 * Date: 30/Mar/2022
 * Last Modified: 28/04/2022, 3:48:43 pm
 * Modified By: Alexander Agudelo
 * Description:  Loads the Speedy Vision library. Make sure you build the library
 * manually without the 'mangle' option. Otherwise it might throw errors.
 * 
 * ------
 * Copyright (C) 2022 Kemu - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */

type WindowWithSpeedyLib = Window & {
  Speedy?: {
		Settings: {
			logging: 'default' | 'none';
		}
	};
}

let loading = false;
const loadSpeedyVision = (): void => {
  const w = window as WindowWithSpeedyLib;
		let script: HTMLScriptElement;
		if (!w.Speedy && !loading) {
      loading = true;
			script = document.createElement('script');
      script.id = 'speedy';
			script.src = `${process.env.PUBLIC_URL}/libs/speedy-vision.min.js`;
			script.async = true;
			script.onload = () => {
				if (w.Speedy) {
					w.Speedy.Settings.logging = 'none';
				}
			};

			document.body.appendChild(script);
		}
};

export default loadSpeedyVision;
