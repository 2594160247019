import React, { createContext, useCallback, useMemo, useRef, useState } from 'react';
import algoliasearch from 'algoliasearch';
import { MultipleQueriesQuery, Hit, SearchOptions, SearchResponse } from '@algolia/client-search';
import { SearchIndex } from 'algoliasearch/lite.js';

type Props = {
  children: React.ReactNode;
  apiKey: string;
  appId: string;
};


export type ResultStats = {
  /** total number of results */
  totalHits: number;
  /** hits per page */
  hitsPerPage: number;
  currentPage: number;
  totalPages: number;
  durationMs: number;
}

export type QueryResult<T> = {
  hits: Hit<T>[];
  stats: ResultStats;
}

type LastQueryConfig = {
  query: string;
  indexName: string;
  options?: SearchOptions;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GlobalSearchContextType<T=any> = {
  /** List of results */
  results: QueryResult<T> | null;

  /** clears out the current list of results, while leaving the cache intact */
  clearResults: () => void;

  /** 
   * Resets the search context, search results and cached items.
   * New searches won't be possible until a new context is set 
   **/
  clearSearchContext: () => Promise<void>;

  /**
   * Removes the cached items and performs a fresh search using the last query parameters.
   */
  refreshCache: () => Promise<void>;

  /** 
   * Use it in conjunction with `getSearchContext` to figure out the
   * context of the current results.
   * @param name a name for the given context.
   * @param indexName the default name of the index used for searching.
   * @param options the default options used for searching.
   **/
  setSearchContext: (name: string, indexName: string, options: SearchOptions) => void;
  /**
   * @returns the name of the current search context.
   */
  getSearchContext: () => string;
  /** A map of indexName and results */
  multiIndexResults: Record<string, QueryResult<T>> | null;
  /** last query used to fetch data */
  multiIndexSearch: (queries: MultipleQueriesQuery[]) => void;
  /**
   * Performs a search in the given index.
   * @param query the query to search for.
   * @param indexName overrides the default index name defined by `setSearchContext`.
   * @param options the options to use for the search. This will override the default options set by `setSearchContext`.
   */
  search: (query: string, indexName?: string, options?: SearchOptions) => void;
  /**
   * Searches using the previous settings set by `search`
   */
  goToPage: (page: number) => void;
}


export const SearchContext = createContext<GlobalSearchContextType>({
  results: null,
  multiIndexResults: null,
  getSearchContext: () => '',
  setSearchContext: () => void 0,
  clearResults: () => void 0,
  clearSearchContext: () => Promise.resolve(),
  multiIndexSearch: () => ({}),
  search: () => ({}),
  goToPage: () => ({}),
  refreshCache: () => Promise.resolve(),
});


function GlobalSearchContextProvider<T> (props: Props): React.JSX.Element {
  const [hits, setHits] = useState<QueryResult<T> | null>(null);
  const [multiIndexResults, setMultiIndexResults] = useState<Record<string, QueryResult<T>> | null>(null);
  const lastIndex = useRef<SearchIndex | null>(null);
  const [currentSearchContext, setCurrentSearchContext] = useState<string>('');
  const queryParameters = useRef<LastQueryConfig | null>(null);

  const searchClient = useMemo(() => algoliasearch(
    props.appId,
    props.apiKey,
  ), [props.appId, props.apiKey]);

  const multiIndexSearch = useCallback(async (queries: MultipleQueriesQuery[]) => {
    const search = await searchClient.multipleQueries<T>(queries);
    const resultsMap: Record<string, QueryResult<T>> = {};
    search.results.forEach((r) => {
      const result = r as SearchResponse<T>;
      if (result.index) {
        resultsMap[result.index] = {
          hits: result.hits,
          stats: {
            durationMs: result.processingTimeMS,
            totalHits: result.nbHits,
            hitsPerPage: result.hitsPerPage,
            currentPage: result.page,
            totalPages: result.nbPages,
          }
        };
      }
    });

    setMultiIndexResults(resultsMap);
    setHits(null);
  }, [searchClient]);


  /**
   * Performs the actual search using the settings in `queryParameters.current`
   */
  const searchWithQueryParams = useCallback(async () => {
    if (queryParameters.current) {
      let index: SearchIndex;

      if (lastIndex.current?.indexName !== queryParameters.current.indexName) {
        const tmpIndex = searchClient.initIndex(queryParameters.current.indexName);
        lastIndex.current = tmpIndex;
        index = tmpIndex;
      } else {
        index = lastIndex.current;
      }

      const searchOptions = queryParameters.current.options /* || defaultSearchOptions.current */;
      const res = await index.search<T>(queryParameters.current.query, searchOptions);
      setMultiIndexResults(null);
      setHits({
        hits: res.hits,
        stats: {
          durationMs: res.processingTimeMS,
          totalHits: res.nbHits,
          hitsPerPage: res.hitsPerPage,
          currentPage: res.page,
          totalPages: res.nbPages,
        }
      });
    }
  }, [searchClient]);

  /**
   * Removes the cached items and performs a fresh search using the last query parameters.
   */
  const refreshCache = useCallback(async () => {
    if (queryParameters.current) {
      await searchClient.clearCache();
      lastIndex.current = searchClient.initIndex(queryParameters.current.indexName);
      searchWithQueryParams();
    }
  }, [searchWithQueryParams, searchClient]);

  /**
   * Searches the given index with the given query.
   * It stores the settings parameters to be used later by `goToPage`.
   */
  const search = useCallback(async (query: string, indexName?: string, options?: SearchOptions) => {
    // Keep track of the search parameters for later
    queryParameters.current = {
      query,
      indexName: indexName ?? queryParameters.current?.indexName ?? '',
      options: {
        ...queryParameters.current?.options,
        ...options,
      }
    };

    searchWithQueryParams();
  }, [searchWithQueryParams]);


  /**
   * Go to a specific page using
   * the last query and search options set by `search`.
   */
  const goToPage = useCallback(async (page: number) => {
    if (queryParameters.current) {
      queryParameters.current.options = {
        ...queryParameters.current.options,
        page,
      };
      searchWithQueryParams();
    }
  }, [searchWithQueryParams]);

  /**
   * Clears out the hits results.
   */
  const clearResults = useCallback(() => {
    setHits(null);
  }, []);

  const clearSearchContext = useCallback(async () => {
    queryParameters.current = null;
    setHits(null);
    setCurrentSearchContext('');
    await searchClient.clearCache();
  }, [searchClient]);


  const getSearchContext = useCallback(() => {
    return currentSearchContext;
  }, [currentSearchContext]);

  const setSearchContext = useCallback((name: string, indexName: string, searchOptions: SearchOptions) => {
    queryParameters.current = {
      query: '',
      indexName,
      options: searchOptions,
    };

    setHits(null);
    setCurrentSearchContext(name);
  }, []);

  return (
    <SearchContext.Provider value={
      {
        results: hits,
        multiIndexResults: multiIndexResults,
        multiIndexSearch,
        search,
        clearResults,
        getSearchContext,
        setSearchContext,
        clearSearchContext,
        goToPage,
        refreshCache,
      }
    }>
      {props.children}
    </SearchContext.Provider>
  );
}

export default GlobalSearchContextProvider;
