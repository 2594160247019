import React, { useCallback, useMemo, useState } from 'react';
import { CheckboxConfig } from '@kemu-io/kemu-core/types';
import variablesManager from '@kemu-io/kemu-core/common/managers/variablesManager.js';
import classNames from 'classnames';
import tinycolor from 'tinycolor2';
import { DataType } from '@kemu-io/hs-types';
import FieldLabel from '../FieldLabel/FieldLabel';
import styles from './CheckboxField.module.css';
import FormGroup from '@components/form-control/formGroup/formGroup';
import KemuSwitch from '@components/form-control/kemuSwitch/KemuSwitch';


type Props = CheckboxConfig & {
  label: string;
  variableName: string;
  recipeId: string;
  thingRecipeId: string;
  widgetId: string;
  color?: string;
  disabled?: boolean;
  isBrightColor?: boolean;
  isSingleElement: boolean;
}

const CheckboxField = (props: Props) => {
  const { variableName, recipeId, thingRecipeId, widgetId, disabled, label, isSingleElement, color, isBrightColor } = props;
  const currentVarValue = variablesManager.getThingVariableValue(recipeId, thingRecipeId, variableName, {
    ownerWidgetId: widgetId,
  });

  const [selected, setSelected] = useState<boolean>(Boolean(currentVarValue));

  const checkColor = useMemo(() => {
    if (!color) { return undefined; }
    if (isBrightColor) {
      return tinycolor(color).complement().toString();
    }

    return tinycolor(color).lighten(10).toString();
  }, [color, isBrightColor]);

  const handleSwitchChange = useCallback((checked: boolean) => {
    variablesManager.setThingVariable(recipeId, thingRecipeId, widgetId, variableName, {
      type: DataType.Boolean,
      value: checked,
    });

    setSelected(checked);
  }, [recipeId, thingRecipeId, variableName, widgetId]);

  return (
    <FormGroup noMarginBottom className={classNames(styles.Group, {
      [styles.SingleElement]: isSingleElement && !label,
      [styles.Pb5]: isSingleElement && label
    })}>
      <FieldLabel color={props.color} label={label} />
      <KemuSwitch
        disabled={disabled}
        checked={selected}
        onChange={handleSwitchChange}
        size="default"
        style={{
          ...(selected? { backgroundColor: checkColor }:{})
        }}
      />
    </FormGroup>
  );
};

export default CheckboxField;
