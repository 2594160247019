
import React, { useRef } from 'react';
import Icon, { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import ClockWidgetProcessor, {
	ClockWidgetState,
	getDefaultState,
	InterruptPortName
} from '@kemu-io/kemu-core/widgets/clock/index.js';
import classNames from 'classnames';
import { WidgetPortContext } from '@kemu-io/kemu-core/types';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import { GetPortsInformationFunction, GateUI, GateUIProps } from '../index';
import GateIcon from '../../gateIcon/gateIcon';


import { PortLocation } from '../../../types/canvas_t';
import NumericInput from '../../WidgetsComponents/NumericInput/NumericInput';
import { addIntervalInterrupt, cancelInterrupt } from '../../../app/recipe/utils';
import styles from './clock.module.css';
import { ReactComponent as ElapsedGateIcon } from './icon.svg';

type Props = GateUIProps;


const mergeStates = (state: ClockWidgetState) => {
	const fixedState: ClockWidgetState = {
		...getDefaultState(),
		...state
	};

	return fixedState;
};

const MIN_VALUE_MS = 10;

const fixDecimals = (value: number) => Number(value.toFixed(2));
const getMinValue = (mode: 'ms' | 'sec') => mode === 'ms' ? MIN_VALUE_MS : fixDecimals(MIN_VALUE_MS / 1000);
const getFixedInterval = (interval: number, mode: 'ms' | 'sec') => mode === 'ms' ? interval : fixDecimals(interval/1000);

const ClockWidget = (props: Props): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<ClockWidgetState>(props.recipeId, props.thingRecipeId, props.info.id);
	const fixedState = mergeStates(state);
	const inputRef = useRef<HTMLInputElement>(null);
	const minValue = getMinValue(fixedState.intervalMode);
	const currentValue = getFixedInterval(fixedState.interval, fixedState.intervalMode);
	const isRunningClass = { isRunning: fixedState.enabled };

	const inputChangedCb = (newValue: number) => {
		const fixed = fixedState.intervalMode === 'ms' ? newValue : fixDecimals(newValue*1000);
		setState({
			...state,
			interval: fixed,
		}, true);
	};

	// Creates an interrupt and saves the id in the widget state
	const initTimer = () => {
		// Create new interrupt
		const interruptId = addIntervalInterrupt(
			props.info.id,
			props.thingRecipeId,
			props.recipeId,
			InterruptPortName,
			fixedState.interval
		);

		setState({
			...fixedState,
			$$interruptId: interruptId,
			enabled: true
		});
	};

	// Enable/disable
	const handleToggleState = () => {
		if (fixedState.enabled && fixedState.$$interruptId) {
			// Abort interrupts
			cancelInterrupt(fixedState.$$interruptId);
			setState({
				...fixedState,
				$$interruptId: undefined,
				enabled: false
			});

		} else if (!fixedState.enabled) {
			initTimer();
		}
	};

	const onToggleMode = () => {
		if (!fixedState.enabled) {
			// This is required so that the input changes immediately if focused.
			if (inputRef.current) {
				inputRef.current.blur();
			}

			const nextMode = fixedState.intervalMode === 'ms' ? 'sec' : 'ms';
			setState({
				...fixedState,
				intervalMode: nextMode,
			});
		}
	};

	return (
		<div className={classNames(styles.GateBody, isRunningClass)}>
			<div className={styles.InputContainer}>
				<span
					onClick={handleToggleState}
					className={classNames(styles.PlayButton, isRunningClass)}
				>
					{fixedState.enabled ? (
						<PauseOutlined />
					) : (
						<CaretRightOutlined />
					)}
				</span>

				<NumericInput
					ref={inputRef}
					min={minValue}
					value={currentValue}
					onChange={inputChangedCb}
					// Prevent changes while running
					disabled={fixedState.enabled}
				/>
				<span
					onClick={onToggleMode}
					className={classNames(styles.Suffix, isRunningClass)}
				>
					{fixedState.intervalMode}
				</span>
			</div>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={ElapsedGateIcon} />}/>
	);
};


const getPortsInformation: GetPortsInformationFunction = (state, widgetInfo) => {
	const portContext: WidgetPortContext = {
		recipePoolId: widgetInfo.recipePoolId,
		recipeType: widgetInfo.recipeType,
		thingRecipeId: widgetInfo.thingRecipeId,
		widgetId: widgetInfo.id,
	};
	const outputNames = ClockWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = ClockWidgetProcessor.getInputNames(state, portContext);

	const inputPositions: Record<string, PortLocation> = {
		'start': [0, 0.3, -1, 0],
		'stop': [0, 0.7, -1, 0],
	};

	return {
		inputs: inputNames.map(input => ({
			name: input.name,
			type: input.type,
			position: inputPositions[input.name]
		})),

		outputs: [{
			name: outputNames[0].name,
			type: outputNames[0].type,
			position: 'Right'
		}]
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: ClockWidget,
	hasTitle: true,
	getWidgetTitle: (intl) => intl.formatMessage({ defaultMessage: 'Clock', id: 'LogicMaker.Gates.Clock.Title' }),
} as GateUI;
