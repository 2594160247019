/*
 * Written by Alexander Agudelo < alex.agudelo@kitsunei.com >, 2020
 * Date: 06/Dec/2020
 * Last Modified: 19/08/2024, 9:22:06 pm
 * Modified By: Alexander Agudelo
 * Description:  State of gates might happen outside the store (Kemu-core), this hook
 * uses Kemu's event listener subscriptions to notify the component the state has changed
 * 
 * ------
 * Copyright (C) 2020 Kitsunei - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */

import { useCallback, useEffect, useState } from 'react';
import KemuCore from '@kemu-io/kemu-core';
import { GateInvocationEvent } from '@kemu-io/kemu-core/types';
import { Data } from '@kemu-io/hs-types';

export type ParentEventResponse = {
	/** might not be available for 'input' type gates */
	sourceGate: {
		id: string;
		port: string;
	};

	targetPort: string;
	data: Data
}

/**
 * Forces rendering when the given gate has been invoked by a parent. 
 * NOTE: This only works for non-input type gates.
 * @param recipePoolId the id of the recipe in the pool
 * @param blockId the id of the block in the recipe
 * @param widgetId the id of the block in the recipe (recipePoolId)
 */
function useGateParentEventDetector (recipePoolId: string, blockId: string, widgetId: string): ParentEventResponse | null {

	const [state, setState] = useState<ParentEventResponse | null>(null);

	const handleGateInvoked = useCallback(async (evt: GateInvocationEvent) => {
		if (evt.recipeId === recipePoolId && evt.gateId === widgetId && evt.blockId === blockId) {
			if (evt.sourceGate && evt.sourcePort) {
				setState({
					data: evt.data as Data,
					sourceGate: {
						id: evt.sourceGate,
						port: evt.sourcePort
					},
					targetPort: evt.targetPort
				});
			}
		}
	}, [recipePoolId, widgetId, blockId]);


	useEffect(() => {
		KemuCore.onGateInvoked(handleGateInvoked, blockId, widgetId);

		return () => {
			KemuCore.offGateInvoked(handleGateInvoked, blockId, widgetId);
		};
	}, [recipePoolId, blockId, widgetId, handleGateInvoked]);

	return state;
}

export default useGateParentEventDetector;
