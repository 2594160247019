import React from 'react';
import {
  ValidFieldConfig,
} from '@kemu-io/kemu-core/types';

type CommonProps = {
  currentConfig: ValidFieldConfig | null;
  open: boolean;
  fieldIndex: number;
  onCloseModal: (fieldIndex: number, newConfig: ValidFieldConfig | null) => void;
}


const FieldWrapper = (props: CommonProps & {
  component: React.FunctionComponent<Omit<CommonProps, 'currentConfig' | 'fieldIndex'> & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currentConfig: any;
    fieldIndex: number;
  }>;
}) => {
  if (props.currentConfig === null) { return null; }
  // TODO: Improve this, this causes the modal to disappear without the closing animation.
  // Done this way to prevent loading multiple modals at once.
  if (!props.open) { return null; }

  const Child = props.component;

  return <Child
    currentConfig={props.currentConfig}
    open={props.open && props.fieldIndex > -1}
    fieldIndex={props.fieldIndex}
    onCloseModal={props.onCloseModal}
  />;
};

export default FieldWrapper;
