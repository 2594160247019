import globals from '../../common/globals';
import getFreshDeskWidget from './freshDeskWidget';

type WindowWithFreshChat = {
	fcWidget?: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		init: (settings: {token: string, host: string, config?: any}) => void;
		setExternalId: (id: string) => void;
		close: () => void;
		hide: () => void;
		show: () => void;
		isOpen: () => boolean;
		on: (evtName: string, callback: (args: unknown) => void) => void;
		off: (evtName: string, callback: (args: unknown) => void) => void;
		user: {
			setProperties: (info: Record<string, unknown>) => void;
		};
	};

	FreshChatEl?: HTMLElement | null;
};

type UserInfo = {
	id: string;
	firstName: string;
	lastName: string;
	email?: string;
	plan?: string;
};

export type FreshChatInstance = {
	initialized: boolean;
	close: () => void;
	hide: () => void;
	show: () => void;
	isOpen: () => boolean;
	setUserInfo: (userInfo: UserInfo) => void;
	setUserProperty: (props: Record<string, string | number>) => void;
};


const w = window as WindowWithFreshChat;
const reportNotInitialized = () => console.error('Widget not yet initialized');
let isHidden = false;

const freshChatInstance: FreshChatInstance = {
	close: reportNotInitialized,
	hide: () => isHidden = true,
	show: () => isHidden = false,
	setUserInfo: reportNotInitialized,
	setUserProperty: reportNotInitialized,
	isOpen: () => false,
	initialized: false
};

const setUserInfo = (info: UserInfo) => {
	if (!w.fcWidget) { return reportNotInitialized(); }
	w.fcWidget.setExternalId(info.id);
	w.fcWidget.user.setProperties({ ...info });
};

const setUserProperty = (props: Record<string, string | number>) => {
	if (!w.fcWidget) { return reportNotInitialized(); }
	w.fcWidget.user.setProperties({ ...props });
};

const closeWidget = () => {
	if (!w.fcWidget) { return reportNotInitialized(); }
	w.fcWidget.close();
};

const hideWidget = () => {
	isHidden = true;
	if (!w.fcWidget) { return reportNotInitialized(); }
	w.fcWidget.hide();
};

const showWidget = () => {
	isHidden = false;
	if (!w.fcWidget) { return reportNotInitialized(); }
	w.fcWidget.show();
};

const isOpen = (): boolean => {
	if (!w.fcWidget) { return false; }
	return w.fcWidget.isOpen();
};

const initWidget = () => {
	if (w.fcWidget) {
		w.fcWidget.init({
			token: globals.FRESHCHAT_TOKEN,
			host: globals.FRESHCHAT_HOST,
			config: {
				hideFAQ: false,
				actions: {
					tab_faq: 'Articles',
					tab_chat: 'Chat'
				}
			}
		});

		if (!freshChatInstance.initialized) {
			freshChatInstance.initialized = true;
			freshChatInstance.setUserInfo = setUserInfo;
			freshChatInstance.setUserProperty = setUserProperty;
			freshChatInstance.close = closeWidget;
			freshChatInstance.hide = hideWidget;
			freshChatInstance.show = showWidget;
			freshChatInstance.isOpen = isOpen;

			if (isHidden) { hideWidget(); }

			// Setup an event listener to hide the helpdesk widget when this one opens up
			w.fcWidget.on('widget:opened', () => {
				const deskWidget = getFreshDeskWidget();
				if (deskWidget.initialized) { deskWidget.hide(); }
			});
		}
	}
};


let checkCounter = 0;
const waitForInstanceToBeCreated = () => {
	if (w.fcWidget) {
		initWidget();
	} else {
		// Only retry for 30 seconds
		if (checkCounter++ > 300) { return; }
		setTimeout(waitForInstanceToBeCreated, 100);
	}
};

/**
 * Initializes an instance of the FreshChat widget.
 * NOTE: not using hooks to allow multiple components to share the same instance.
 * @returns a partial object that will eventually contain the correct methods to 
 * update the user properties. You should check for the `initialized` property to know
 * if the functions can be accessed or not.
 */
const initFreshChatWidget = (): FreshChatInstance => {
	w.FreshChatEl = document.getElementById('Freshchat-js-sdk');

	if (!w.FreshChatEl) {
		const script = document.createElement('script');

		script.src = 'https://wchat.au.freshchat.com/js/widget.js';
		script.async = true;
		script.id = 'Freshchat-js-sdk';
		script.onload = () => {
			waitForInstanceToBeCreated();
		};

		w.FreshChatEl = script;
		document.body.appendChild(script);
	} else {
		initWidget();
	}

	return freshChatInstance;
};

const getFreshChatWidget = (): FreshChatInstance => {
	return freshChatInstance;
};

export default getFreshChatWidget;
export {
	initFreshChatWidget
};
