import React, { useContext, useEffect } from 'react';
import { WidgetGroupSetting } from '@kemu-io/kemu-core/types';
import GroupWithHiddenFields from '../GroupWithHiddenFields/GroupWithHiddenFields';
import GroupWithVisibleFields from '../GroupWithVisibleFields/GroupWithVisibleFields';
import WidgetIcon from '../WidgetIcon/WidgetIcon';
import styles from './WidgetBodySelector.module.css';
import { LogicMapperContext } from '@common/context/LogicMapperContext/LogicMapperContext';
import useRepaintWidgetPorts from '@hooks/useRepaintWidgetPorts';

type Props = {
  settings?: WidgetGroupSetting[];
  recipeId: string;
  thingRecipeId: string;
  widgetId: string;
  color?: string;
  disabled?: boolean;
  icon?: string;
  name: string;
  totalInputs: number;
  totalOutputs: number;
  onOpenCustomSettings?: () => void;
}

const WidgetBodySelector = (props: Props) => {
  const {
    settings, recipeId, thingRecipeId, color,
    disabled, icon, name, onOpenCustomSettings,
    widgetId,
  } = props;

  const { jsPlumb } = useContext(LogicMapperContext);
  const repaintWidgetPorts = useRepaintWidgetPorts(
    jsPlumb,
		recipeId,
		thingRecipeId,
		widgetId
	);

  const visibleFields = (settings || []).filter((setting) => setting.customSettingField === false);
	const hiddenFields = (settings || []).filter((setting) => setting.customSettingField === true);
  const totalVisible = visibleFields.length;
  const totalHidden = hiddenFields.length;

	const hasVisibleFields = visibleFields.length > 0;
	const hasHiddenFields = hiddenFields.length > 0;

  useEffect(() => {
    repaintWidgetPorts();
  }, [totalHidden, totalVisible, repaintWidgetPorts]);

  if (hasVisibleFields) {
    return (
      <GroupWithVisibleFields
        onRepaintWidgetPorts={repaintWidgetPorts}
        name={name}
        recipeId={recipeId}
        thingRecipeId={thingRecipeId}
        widgetId={widgetId}
        color={color}
        disabled={disabled}
        settings={settings}
        maxPorts={Math.max(props.totalInputs, props.totalOutputs)}
        hasHiddenFields={hasHiddenFields}
        onOpenCustomSettings={onOpenCustomSettings}
      />
    );
  }

  if (hasHiddenFields) {
    return (
      <GroupWithHiddenFields
        name={name}
        recipeId={recipeId}
        thingRecipeId={thingRecipeId}
        color={color}
        icon={icon}
        disabled={disabled}
        settings={settings}
        onOpenCustomSettings={onOpenCustomSettings}
      />
    );
  }

  return (
    <div className={styles.GateBody}>
      <WidgetIcon icon={icon} />
    </div>
  );
};

export default WidgetBodySelector;
