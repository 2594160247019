import { BrowserJsPlumbInstance, ready as onJsPlumbReady, newInstance }  from '@jsplumb/community';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ABORT_DRAGGING_CLASS } from '../constants';
import { getDefaultSettings } from '../jsPlumb/settings';
import globals from '@common/globals';

type UsePlumbResult = {
  destroy: () => void;
  initialize: (node: HTMLDivElement) => void;
  jspInstance: BrowserJsPlumbInstance | null;
  containerEl: HTMLDivElement | null;
}

type UsePlumbParameters = {
  /** The class to use for connections */
  connectionClass: string;
}

const useJSPlumb = (params: UsePlumbParameters): UsePlumbResult => {
  const [jspInstance, setJsPlumbInstance] = useState<BrowserJsPlumbInstance | null>(null);
  const jsPlumbReadyRef = useRef<boolean>(false);
  const jspRef = useRef<BrowserJsPlumbInstance | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const destroy = useCallback(() => {
    if (jspRef.current) {
      jspRef.current.reset();
      jspRef.current.destroy();
      containerRef.current = null;
      jspRef.current = null;
      setJsPlumbInstance(null);
    }
  }, []);

  const initialize = useCallback((containerEl: HTMLDivElement) => {
    if (containerEl) {
      const basicSettings = getDefaultSettings({ connectionClass: params.connectionClass });
      basicSettings.container = containerEl;
      containerRef.current = containerEl;
      jspRef.current = newInstance(basicSettings);
      // Prevent labels from initiating dragging
      jspRef.current.addDragFilter(`.port .label`, true);
      // Prevent dragging from any child of a parent with the class 'no-child-drag'
      jspRef.current.addDragFilter((evt: MouseEvent) => {
        if (evt.composedPath) {
          return evt.composedPath().find(el => {
            const element = el as Element;
            if (element.classList) {
              return (
                element.classList.contains('no-child-drag') ||
                element.classList.contains(ABORT_DRAGGING_CLASS)
              );
            }
          });
        }
      }, true);

      setJsPlumbInstance(jspRef.current);
      if (globals.STAGE === 'local' || globals.STAGE === 'development') {
        (window as any).jsPlumbInstance = jspRef.current;
      }
    } else {
      destroy();
    }
  }, [destroy, params.connectionClass]);

  const handleJsPlumbReady = useCallback(() => {
    jsPlumbReadyRef.current = true;
  }, []);

  useEffect(() => {
    if (!jspInstance) {
      onJsPlumbReady(handleJsPlumbReady);
    }
  }, [handleJsPlumbReady, jspInstance]);

  return {
    destroy,
    jspInstance,
    initialize,
    containerEl: containerRef.current
  };
};

export default useJSPlumb;
