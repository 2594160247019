/*
 * Written by Alexander Agudelo < alex.agudelo@kitsunei.com >, 2020
 * Date: 23/Nov/2020
 * Last Modified: 19/08/2023, 3:24:02 pm
 * Modified By: Alexander Agudelo
 * Description:  Gates might some times have custom icons (png) or default ant-design icons.
 * This module allows the rendering of ether type seamlessly.
 * 
 * ------
 * Copyright (C) 2020 Kitsunei - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */

import './gateIcon.css';
import React, { CSSProperties } from 'react';
import classNames from 'classnames';

interface Props {
	/** custom image url */
	image?: string;
	/** native icon name */
	icon?: React.JSX.Element;
	className?: string;
	style?: CSSProperties;
}

const GateIcon = (props: Props): React.JSX.Element => {
	const IconEl = props.icon;
	return (
		<div className={classNames('logic-gate-icon', 'gate-icon', props.className)} style={props.style}>
			{props.image ? (
				<img src={props.image} className="gate-image"/>
			) : (
				IconEl
			)}
		</div>
	);
};

export default GateIcon;
