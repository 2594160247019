import React from 'react';
import StyledButton from '../form-control/styledButton/styledButton';

interface Props {
	disabled?: boolean;
	loading?: boolean;
	onClick?: ()=> void;
	color?: 'light' | 'primary' | 'secondary' | 'danger' | 'warning';
	className?: string;
	sectionTitle: React.ReactNode;
	buttonTitle: React.ReactNode;
	'data-kemu-meta'?: string;
}

const SectionHeaderWithButton = (props: Props): React.JSX.Element => {
	const kemuMeta = props['data-kemu-meta'] ? `${props['data-kemu-meta']}-action-btn` : undefined;
	return (
		<div data-kemu-meta={props['data-kemu-meta'] || 'section-header'} style={{ display: 'contents' }}>
			<h1>{props.sectionTitle}</h1>
			<StyledButton
				data-kemu-meta={kemuMeta}
				disabled={props.loading}
				loading={props.loading}
				onClick={props.onClick}
				color={props.color}
				className={props.className}
				title={props.buttonTitle}
			/>
		</div>
	);
};

export default SectionHeaderWithButton;
