import './canvasGate.css';
import React, { useRef, CSSProperties, useEffect, useCallback, useState, useMemo } from 'react';
import { BrowserJsPlumbInstance } from '@jsplumb/community';
import {
	CustomWidgetState,
	WidgetGroupState,
	WidgetState,
	WidgetType,
	HubServiceState,
} from '@kemu-io/kemu-core/types';
import { useContextMenu } from 'react-contexify';
import { useIntl } from 'react-intl';
import { SettingFilled } from '@ant-design/icons';
import { motion, AnimatePresence } from 'framer-motion';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Connection } from '@jsplumb/community/types/core';
import { SourceInfo } from '@kemu-io/kemu-core/common/eventHistory';
import { findWidgetInRecipe } from '@kemu-io/kemu-core/common/recipeCache';
import { createWidgetPortIdentifier } from '@kemu-io/kemu-core/common/utils';
import { useDebouncedCallback } from 'use-debounce';
import { getGateBody, PortDescription, WidgetPortsSummary, WidgetPortWithId } from '../gates/index';
import GateSettingsCloseBtn from '../gateSettingsCloseBtn/gateSettingsCloseBtn';
import { buildPortClasses, repaintPorts, updateCustomPortClasses } from './utils';
import WidgetLabels, { LabelsInstanceRef } from './WidgetLabels/WidgetLabels';
import { MENU_GATE, WidgetMenuProps } from '@src/features/LogicMapper/contextMenu/contextMenu';
import { generateDomId } from '@common/utils';
import { PortLocation } from '@src/types/canvas_t';
import Logger from '@common/logger';
import useReactiveWidgetState from '@common/hooks/useReactiveWidgetState';
import useAlwaysLinked from '@common/hooks/useAlwaysLinked';
import { getEndpointSetting } from '@common/jsPlumb/settings';
import { CANVAS_WIDGET_CLASS, CUSTOM_HUB_SERVICE_UI_CLASS, CUSTOM_WIDGET_PORT_COLOR_FLAG, CUSTOM_WIDGET_PORT_COLOR_PREFIX, DISABLED_WIDGET_CLASS, OFFLINE_SERVICE_CLASS, ORIGINAL_EVENT_CLASS, WIDGET_INVOKED_CLASS, WIDGET_PORT_RADIUS } from '@common/constants';
import { KemuConnectionData, StatelessRecipeWidget } from '@src/types/core_t';
import { getCategoryFromType } from '@common/widgetCategories';
import { selectCurrentRecipeType } from '@src/features/Workspace/workspaceSlice';
import useWidgetInvokeMonitor from '@hooks/useWidgetInvokeMonitor';
import { calculateWidgetColors } from '@components/gates/common';
import useHubServiceInfo from '@hooks/useHubServiceInfo';

const logger = Logger('canvasGate');
interface Props{
	thingRecipeId: string;
	thingDbId: string;
	thingVersion: string;
	recipeId: string;
	gateId: string;
	hidden: boolean;
	plumbInstance: BrowserJsPlumbInstance;
	/**
	 * Used to notify parent ports for this widget have been painted
	 * @returns true if connections have been painted.
	 */
	// onPortsPainted: (widgetId: string) => boolean;
}

type PortWithoutType = Omit<PortDescription, 'type'>;

const settingsDialogAnimation = {
	visible: {
		opacity: 1,
		scale: 1,
		transition: {
			duration: 0.1
		},
	},

	hide: {
		opacity: 0,
		scale: 0,
		transition: {
			duration: 0.1
		},
	},

	hidden: {
		opacity: 0,
		scale: 0.9,
	}
};

/** Builds valid port settings depending on the type */
const getEndpointConfig = (isTarget: boolean, id: string, customPosition: PortLocation) => {
	// top (LR), left (TB), (dx, dy) <== direction of curve
	const portSettings = getEndpointSetting(isTarget, customPosition, WIDGET_PORT_RADIUS);
	portSettings.portId = id;
	portSettings.parameters = {
		isTarget,
	};

	portSettings.uuid = id;
	return portSettings;
};


/**
 * Adds ports to the given gate element
 */
const buildPorts = (
	gateId: string,
	plumbInstance: BrowserJsPlumbInstance,
	inputPorts: PortWithoutType[],
	outputPorts: PortWithoutType[],
	gateEl: HTMLElement
) => {

	const hasPorts = inputPorts.length || outputPorts.length;
	if (!hasPorts) {
		// Without adding ports, the element is not draggable, 
		// here we make sure it is by making it a managed element
		plumbInstance.manage(gateEl);
		return;
	}

	plumbInstance.batch(() => {
		inputPorts.map((port, index) => {
			// const portId = getUniqueWidgetPortId(index, 'input', gateId, port.name);
			const portId = createWidgetPortIdentifier(gateId, 'input', port.name);
			if (gateEl) {
				const targetPortSettings = getEndpointConfig(true, portId, port.position);
				plumbInstance.addEndpoint(gateEl, { ...targetPortSettings });
			}
		});

		outputPorts.map((port, index) => {
			// const portId = getUniqueWidgetPortId(index, 'output', gateId, port.name);
			const portId = createWidgetPortIdentifier(gateId, 'output', port.name);
			if (gateEl) {
				const sourcePortSettings = getEndpointConfig(false, portId, port.position);
				plumbInstance.addEndpoint(gateEl, { ...sourcePortSettings });
			}
		});
	});
};

const buildWidgetPortsInfo = (portType: 'input' | 'output', widgetId: string, ports: PortDescription[]): WidgetPortWithId[] => {
	return ports.map((port, index) => {
		// const portId = getUniqueWidgetPortId(index, portType, widgetId, port.name);
		const portId = createWidgetPortIdentifier(widgetId, portType, port.name);
		return {
			id: portId,
			name: port.name,
			type: port.type,
			shape: port.jsonShape,
		};
	});
};

const checkIfSourceIsInnerWidget = (recipeId: string, thingRecipeId: string, sourceWidgetId: string, currentWidgetGroupId: string) => {
	const sourceWidgetInfo = findWidgetInRecipe(recipeId, thingRecipeId, sourceWidgetId);
	const isChildWidget = sourceWidgetInfo?.groupId && sourceWidgetInfo?.groupId === currentWidgetGroupId;
	return isChildWidget;
};

const CanvasGateElement = (props: Props & { gateInfo: StatelessRecipeWidget }): React.JSX.Element => {
	const intl = useIntl();
	const [showSettingsBox, setShowSettingsBox] = useState(false);
	const gateElRef = useRef<HTMLDivElement>(null);
	const wasHiddenRef = useRef<boolean>(props.hidden);
	const widgetLabelsRef = useRef<LabelsInstanceRef>(null);
	// const [gateEl, setGateEl] = useState<HTMLDivElement | null>(null);
	const { gateInfo, gateId, thingRecipeId, recipeId, plumbInstance, /* onPortsPainted, */ hidden } = props;
	const currentRecipeType = useSelector(selectCurrentRecipeType);
	const gateUI = getGateBody(gateInfo.type);
	if (!gateUI) { throw Error(`Unknown gate [${gateInfo.type}]`); }

	const { type } = gateInfo;
	const hasCustomSettings = !!gateUI.CustomSettingsDialog;

	// TODO: By default the useGateState should ignore private properties to prevent re-rendering
	// when widget processors use state flags internally (Eg. pixelfy widget)
	const [gateState] = useReactiveWidgetState<CustomWidgetState>(props.recipeId, thingRecipeId, props.gateId);
	const canvasDomId = generateDomId(props.recipeId, thingRecipeId, props.gateId);
	const getHubServiceInfo = useHubServiceInfo();

	const hubService = useMemo(() => {
		if (type === WidgetType.hubService) {
			const hubState = gateState as WidgetState<HubServiceState>;
			if (hubState.service) {
				return getHubServiceInfo(hubState.service.name, hubState.service.version);
			}
		}

		return null;
	}, [getHubServiceInfo, gateState, type]);

	const serviceOffline = type === WidgetType.hubService && !hubService;

	const handleWidgetInvoked = useCallback((wasInvoked: boolean, eventSource?: SourceInfo, aborted?: boolean) => {
		const connections = plumbInstance.getConnections({ target: gateElRef.current }) as Connection[];
		for (const conn of connections) {
			// Cancel animations
			if (aborted) {
				conn.removeClass(WIDGET_INVOKED_CLASS);
				continue;
			}

			const connData: KemuConnectionData | undefined = conn.getData();
			if (connData && eventSource?.widgetId) {

				// For groups, the source widget will be one of the inner widgets
				// in which case, this initial conditional doesn't work.
				if (connData?.sourceWidgetId === eventSource?.widgetId
					// if the source is a widget that belongs to a group, we need to find out if the group
					// is a child of connection source.
					|| checkIfSourceIsInnerWidget(recipeId, thingRecipeId, eventSource.widgetId, gateId)
				) {
					if (wasInvoked) {
						conn.addClass(WIDGET_INVOKED_CLASS);
					} else {
						conn.removeClass(WIDGET_INVOKED_CLASS);
					}
				}
			}
		}
	}, [plumbInstance, thingRecipeId, recipeId, gateId]);

	useWidgetInvokeMonitor(props.thingRecipeId, props.gateId, 200, handleWidgetInvoked);

	// Some gates are of type group, which means they render virtual ports
	// const visibleGroup = useSelector(selectVisibleGroup);
	// const hasVirtualPorts = gateInfo.type === 'widgetGroup';
	// const isInsideGroup = visibleGroup?.groupId === (gateState as CustomWidgetState<WidgetGroupState>).groupId && hasVirtualPorts;

	// Get actual presentation information from the gate itself
	const gatePorts = gateUI.getPortsInformation!(gateState, {
		id: gateInfo.id,
		thingRecipeId: thingRecipeId,
		thingDbId: props.thingDbId,
		thingVersion: props.thingVersion,
		recipeId: recipeId,
		recipePoolId: recipeId,
		recipeType: currentRecipeType,
	}, intl);

	const repaintConnections = useAlwaysLinked(
		props.recipeId,
		props.thingRecipeId,
		props.gateId,
		gatePorts.inputs.length,
		gatePorts.outputs.length,
		plumbInstance
	);

	// Keep track of the ports position and id in string format to make sure we don't trigger
	// the useEffect that builds the ports between renders
	// TODO: We should be able to replace this with `widgetPortsSummary`
	const portsStr = JSON.stringify({
		inputPorts: gatePorts.inputs.map(input => ({ position: input.position, name: input.name })),
		outputPorts: gatePorts.outputs.map(output => ({ position: output.position, name: output.name })),
	});

	// The color of the hub service is the color of the group
	const hubServiceColor = hubService?.color || (gateState as HubServiceState)?.service?.color;

	// Keeps track of the ports shapes and types
	const widgetPortsSummary: WidgetPortsSummary = {
		inputPorts: buildWidgetPortsInfo('input', gateInfo.id, gatePorts.inputs),
		outputPorts: buildWidgetPortsInfo('output', gateInfo.id, gatePorts.outputs),
		color: type === WidgetType.widgetGroup
			? (gateState as WidgetState<WidgetGroupState>)?.color
			: hubServiceColor,
	};

	// Use a string representation of the summary to easily detect changes
	const portsSummaryStr = JSON.stringify(widgetPortsSummary);
	// const renderedPortsRef = useRef<string>(portsSummaryStr);

	const handleRepaintPorts = useCallback(() => {
		repaintPorts(plumbInstance, gateElRef.current);
	}, [plumbInstance]);

	/** 
	 * Allows widget canvas components to refresh their port classes.
	 **/
	const rebuildPortClasses = useCallback((summary: WidgetPortsSummary) => {
		buildPortClasses({
			canvasDomId,
			plumbInstance,
			serviceOffline,
			summary,
			widgetDisabled: !!gateInfo.disabled,
			widgetType: gateInfo.type,
		});
		updateCustomPortClasses();
	}, [
		canvasDomId, plumbInstance, serviceOffline,
		gateInfo.disabled, gateInfo.type,
	]);


	// Debounced function to draw the ports and connections only once
	const drawPointsAndConnections = useDebouncedCallback(() => {
		if (gateElRef.current && !hidden) {
			const { inputPorts, outputPorts } = JSON.parse(portsStr) as {inputPorts: PortWithoutType[], outputPorts: PortWithoutType[]};
			// const changed = renderedPortsRef.current !== portsSummaryStr;
			logger.log(`Building ports for gate ${type}`);

			// Draw endpoints
			buildPorts(gateId, plumbInstance, inputPorts, outputPorts, gateElRef.current);
			// Add classes to endpoints
			rebuildPortClasses(widgetPortsSummary);
			// Notify the parent that the ports for this widget have been painted,
			// the parent will draw connections AFTER all the ports have been painted
			// const connectionsDrawn = onPortsPainted(gateId);
			// const forceRepaint = !hidden && wasHiddenRef.current;
			// if (renderedPortsRef.current && changed && !connectionsDrawn) {
			repaintConnections(/* forceRepaint */);
			widgetLabelsRef.current?.repaintLabels();
			// }
		}

		// Keep track of the hidden status so we can force connection repainting for 
		// services when they are shown again
		// wasHiddenRef.current = hidden;
	}, 5);

	// Schedules the drawing of the ports, their cases and connections
	useEffect(() => {
		const gateElId = gateElRef.current?.id;
		drawPointsAndConnections();
		return () => {
			drawPointsAndConnections.cancel();
			if (gateElId) {
				logger.log(`Removing all endpoints of ${type} (${gateId})`);
				plumbInstance.removeAllEndpoints(gateElId);
			}
		};
	}, [
		plumbInstance, gateId,
		drawPointsAndConnections, type,
		// NOTE: we track `hidden` to force a repaint of the connections
		portsStr, hidden
	]);


	const gatePos: CSSProperties = {
		left: gateInfo.canvas.position.x,
		top: gateInfo.canvas.position.y,
		...((gateInfo.type === WidgetType.widgetGroup && (gateState as WidgetGroupState)?.color) ? {
			boxShadow: `0px 0px 7px 1px ${(gateState as WidgetGroupState)?.color}`,
		} : {}),
		...((gateInfo.type === WidgetType.hubService && (gateState as HubServiceState)?.service?.color) ? {
			boxShadow: `0px 0px 7px 1px ${(gateState as HubServiceState).service?.color}`
		} : {})
	};

	// Shows the context menu
	const { show: showMenu } = useContextMenu({ id: MENU_GATE });
	const handleItemClick = (event: React.MouseEvent<HTMLElement>) => {
		// @ts-expect-error not sure why tagName isn't recognized
		if (event.target.tagName !== 'INPUT') {
			event.preventDefault();
			showMenu(event, {
				props: {
					disabled: gateInfo.disabled,
					boundaryClass: CANVAS_WIDGET_CLASS,
					recipeId,
					gateId,
					blockId: thingRecipeId,
					gateType: gateInfo.type,
					returnOriginalEvent: gateInfo.returnOriginalEvent
				} as WidgetMenuProps
			});
		}
	};

	const onShowCustomSettings = useCallback(() => {
		plumbInstance.getEndpoints(gateElRef.current).forEach(ep => ep.addClass('hidden'));
		setShowSettingsBox(true);
	}, [plumbInstance]);

	const closeDialog = useCallback(() => {
		setShowSettingsBox(false);
		plumbInstance.getEndpoints(gateElRef.current).forEach(ep => ep.removeClass('hidden'));
	}, [plumbInstance]);


	const togglePortsClass = useCallback((className: string, add: boolean) => {
		const ports = plumbInstance.getEndpoints(canvasDomId);
		ports.forEach(port => {
			if (add) {
				port.addClass(className);
			} else {
				port.removeClass(className);
			}
		});
	}, [canvasDomId, plumbInstance]);


	// Monitors changes to the enable/disable property
	useEffect(() => {
		if (gateElRef.current) {
			togglePortsClass(DISABLED_WIDGET_CLASS, !!gateInfo.disabled);
		}
	}, [gateInfo.disabled, togglePortsClass]);

	// Add the custom color class to ports every time the port info changes
	useEffect(() => {
		if (portsSummaryStr) {
			const ports = plumbInstance.getEndpoints(gateElRef.current);
			// Prevent re-rendering if the ports are not ready
			if (ports.length) {
				const info = JSON.parse(portsSummaryStr) as WidgetPortsSummary;
				rebuildPortClasses(info);
			}
		}
	}, [portsSummaryStr, rebuildPortClasses, plumbInstance]);


	const gateHeaderText = (gateUI.getWidgetTitle && gateUI.getWidgetTitle(intl)) || gateInfo.type;

	return (
		<>
			<div ref={gateElRef}
				data-kemu-type={gateInfo.type}
				className={classNames(
					CANVAS_WIDGET_CLASS,
					`ctgry-${getCategoryFromType(gateInfo.type)}`,

					gateUI.getWrapperClass && gateUI.getWrapperClass(),
					{
						'original-event': gateInfo.returnOriginalEvent,
						'widget-disabled': gateInfo.disabled,
						'settings-visible': showSettingsBox,
						[CUSTOM_HUB_SERVICE_UI_CLASS]: !!hubService?.widgetUI,
					},
				)}
				style={gatePos}
				id={canvasDomId}
				onContextMenu={handleItemClick}
			>

				{gateUI.CustomSettingsDialog && gateElRef.current && (
					<div className={
						classNames('gate-settings-wrapper', 'custom-size', { 'visible': showSettingsBox })
					} style={{ position: 'absolute', height: '100%', 'width': '100%' }}>
						<AnimatePresence mode='wait'>
							{showSettingsBox && (
								<motion.div
									className="animator"
									animate="visible"
									initial="hidden"
									variants={settingsDialogAnimation}
									exit="hide"
								>
									<gateUI.CustomSettingsDialog
										repaintPorts={handleRepaintPorts}
										container={gateElRef.current}
										onClose={closeDialog}
										gateInfo={gateInfo}
										recipeId={props.recipeId}
										recipeType={currentRecipeType}
										blockId={props.thingRecipeId}
									>
										<GateSettingsCloseBtn onClose={closeDialog}/>
									</gateUI.CustomSettingsDialog>
								</motion.div>
							)}
						</AnimatePresence>
					</div>
				)}

				<div className="cg-container">
					{gateUI.hasTitle && (
						<div className={`cg-header ${hasCustomSettings ? `custom-settings ${showSettingsBox ? 'visible' : ''}` : ''}`}>
							<div className="cg-label noselect">
								<span>{gateHeaderText}</span>
							</div>
							<span className="settings-btn" onClick={onShowCustomSettings}>
								<SettingFilled />
							</span>
						</div>
					)}
					<div className="cg-body">
						<gateUI.Element
							info={gateInfo}
							hidden={hidden}
							recipeType={currentRecipeType}
							thingRecipeId={props.thingRecipeId}
							thingDbId={props.thingRecipeId}
							thingVersion={props.thingVersion}
							recipeId={props.recipeId}
							repaint={handleRepaintPorts}
							customSettingsVisible={showSettingsBox}
							rebuildPortClasses={rebuildPortClasses}
							openCustomSettings={onShowCustomSettings}
						/>
					</div>

					<WidgetLabels
						inputs={gatePorts.inputs}
						outputs={gatePorts.outputs}
						hidden={!!(gateUI.hideLabelsOnSettingsOpen && showSettingsBox) || hidden}
						ref={widgetLabelsRef}
					/>
				</div>
			</div>
		</>
	);
};

export default CanvasGateElement;
