import React, { createContext } from 'react';

type LaunchpadContextType = {
  /** whether the launchpad is visible */
  visible: boolean;
  /** allows child components to signal the launchpad not to close */
  setPreventClose: (preventClose: boolean) => void;
  hideLaunchpad: (hide: boolean) => void,
  setCustomFooter: (footer: React.ReactElement | null) => void;
}

export const WidgetLaunchpadContext = createContext<LaunchpadContextType>({
  visible: false,
  setPreventClose: () => void 0,
  hideLaunchpad: () => void 0,
  setCustomFooter: () => void 0,
});

const WidgetLaunchpadContextProvider = WidgetLaunchpadContext.Provider;

export default WidgetLaunchpadContextProvider;

export type { LaunchpadContextType };

