import { PublicationType } from '@kemu-io/kemu-types';

const getMyRecipesRoute = (selected?: string): string => `?marketplace=true&tab=recipe${selected ? `&select=${selected}` : ''}`;
const getMyTutorialsRoute = (): string => `?marketplace=true&tab=tutorial`;
const getThingsRoute = (): string => `?marketplace=true&tab=thing`;
const getMarketplaceRoute = (): string => `?marketplace=true`;

// Base route for publications (public recipes, things, widgets, etc)
const getMarketplacePublicRoute = (): string => `?marketplace=true&tab=public`;
// Publication details page
const getPublicationDetailsRoute = (type: PublicationType, id: string): string => `${getMarketplacePublicRoute()}/${type}/${id}`;
// @ts-expect-error creating a generic route
const getGenericPublicationDetailsRoute = (): string => `${getPublicationDetailsRoute(':entityType', ':id')}`;
// Publication list page
const getPublicationListRoute = (type: PublicationType): string => `${getMarketplacePublicRoute()}/${type}`;

export default {
	getMyRecipesRoute,
	getMyTutorialsRoute,
	getPublicationDetailsRoute,
	getMarketplaceRoute,
	getPublicationListRoute,
	getGenericPublicationDetailsRoute,
	getThingsRoute,
	getMarketplacePublicRoute,
};

