import React, { createContext } from 'react';
import { BrowserJsPlumbInstance } from '@jsplumb/community';


export type LogicMapperContextInstance = {
  jsPlumb: BrowserJsPlumbInstance | null;
}

export const LogicMapperContext = createContext<LogicMapperContextInstance>({
  jsPlumb: null
});


interface Props {
  children: React.ReactNode;
}

const LogicMapperContextProvider = LogicMapperContext.Provider;

export default LogicMapperContextProvider;
