import { PayloadAction, Draft, createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import widgetBundleManager from '@kemu-io/kemu-core/widgetBundle';
import kemuCore from '@kemu-io/kemu-core';
import variablesManager from '@kemu-io/kemu-core/common/managers/variablesManager.js';
import {
	ValidFieldConfig,
	WidgetGroupState,
	HubServiceState,
	Position,
	WidgetBundleState,
	CustomWidgetState,
	RecipeWidget,
	WidgetType
} from '@kemu-io/kemu-core/types';
import { generateUniqueStorageUnitKey } from '@kemu-io/kemu-core/common/utils';
import { RemoveInvokeError } from '@kemu-io/hs/ri';
import { LogicMapperState, SelectedWidgetInfo } from '../logicMapperSlice';
import { addToKemuClipboard, getFromKemuClipboard } from './clipboardUtils';
import { defineGroupFieldVariables } from './widgetGroupHelpers';
import {
	getWidgetsInThing,
	removeWidget,
	getCustomWidgetInnerWidgets,
	addWidgetsFromStringMap,
	cloneStorageUnit,
} from '@src/app/recipe/utils';
import { RootState } from '@src/app/store';
import { safeJsonClone, widgetMapToStatelessMap } from '@common/utils';
import { WidgetsMap, StatelessWidgetsMap } from '@src/types/core_t';
import ephemeralStorage from '@src/app/ephemeralStorage';
import connectionManager from '@src/app/kemuHub/connectionManager';
import { showGlobalNotification } from '@src/features/interface/interfaceSlice';
import { getGlobalIntl } from '@src/assets/i18n/globalIntl';

type CopySelectedWidgetsAction = {
	/** id of the recipe in the pool */
	recipeId: string;
	/** id of the Thing in the recipe */
	thingId: string;
	groupId?: string;
};

/**
 * Copies all the widgets in the current selection to the clipboard
 */
const copySelectedWidgetsReducer = (state: Draft<LogicMapperState>, action: PayloadAction<CopySelectedWidgetsAction>): void => {

	const widgetsMap = getWidgetsInThing(action.payload.recipeId, action.payload.thingId);
	let widgetsToCloneMap: WidgetsMap = {};

	const selectedWidgetsIds: string[] = [];
	state.selectedWidgets.forEach((widgetInfo) => {
		const widgetInThing = widgetsMap[widgetInfo.widgetId];
		if (widgetInThing) {
			// Make a copy of the widget without their state's private properties
			const safeWidgetCopy = safeJsonClone<RecipeWidget>(widgetInThing);
			// Check if a bundle is selected, if so, make sure we restore 
			// bundle's processors private data to point to their cache location.
			if (safeWidgetCopy.type === WidgetType.widgetBundle) {
				const bundleState: WidgetBundleState = widgetInThing.state as CustomWidgetState<WidgetBundleState>;
				safeWidgetCopy.state = {
					...safeWidgetCopy.state,
					...(bundleState.$$cacheInfo ? { $$cacheInfo: { ...bundleState.$$cacheInfo } } : {}),
					...(bundleState.collectionInfo ? { collectionInfo: { ...bundleState.collectionInfo } } : {}),
				} as CustomWidgetState<WidgetBundleState>;
			}

			widgetsToCloneMap[widgetInThing.id] = safeWidgetCopy;
			selectedWidgetsIds.push(widgetInThing.id);
		}
	});


	// find group type widgets in the selection and add to the list any inner widgets.
	Object.values(widgetsToCloneMap).forEach((widget) => {
		if (widget.type === WidgetType.widgetGroup) {
			const childrenMap = getCustomWidgetInnerWidgets(
				widget.id,
				action.payload.thingId,
				action.payload.recipeId,
				// We need to send the list of selected widgets so that outer children
				// are not removed from the list of children.
				selectedWidgetsIds
			);

			widgetsToCloneMap = {
				...widgetsToCloneMap,
				...childrenMap
			};

			// Identify custom widgets with fields and extract the current value from the thing
			// and replace the field's default value.
			const widgetGroupState = widget.state as CustomWidgetState<WidgetGroupState>;
			const fields = [...widgetGroupState.settings || []];
			for (const field of fields) {
				if (field.variableName) {
					const matchingVar = variablesManager.getThingVariableValue(
						action.payload.recipeId,
						action.payload.thingId,
						field.variableName,
						{ ownerWidgetId: widget.id }
					);

					if (matchingVar) {
						// NOTE: TextConfig is just a placeholder for a generic field
						// with a `defaultValue` property.
						const fieldConfig = field.config as ValidFieldConfig;
						const valueType = typeof matchingVar;
						// Future proofing: other processes (script widgets) could potentially set invalid
						// values for a field's variable, so we need to make sure we only set valid types.
						const isArrayAndValid = Array.isArray(matchingVar) && matchingVar.every((item) => typeof item === 'string' || typeof item === 'number');
						if (valueType === 'string' || valueType === 'number' || isArrayAndValid) {
							fieldConfig.defaultValue = matchingVar as string | number | boolean | string[] | undefined;
						}
					}
				}
			}

			// Update the original reference with the updated fields
			widgetsToCloneMap[widget.id].state = {
				...widget.state,
			};
		}
	});

	// IMPORTANT: Remove children references that are not part of the selection
	// this may happen if the selected widget is linked to a root level widget
	// that is not part of the selection.
	for (const widgetId in widgetsToCloneMap) {
		const widget = widgetsToCloneMap[widgetId];
		widget.children = (widget.children ||[]).filter((child) => {
			return !!widgetsToCloneMap[child.childId];
		});
	}

	// Remove the groupId from any widget at the root level
	if (action.payload.groupId) {
		Object.values(widgetsToCloneMap).forEach(widget => {
			if (widget.groupId === action.payload.groupId) {
				widget.groupId = undefined;
			}
		});
	}

	addToKemuClipboard(widgetsToCloneMap);
};

/**
 * Keeps track of all selected widgets 
 */
const setSelectedWidgetsReducer = (state: Draft<LogicMapperState>, action: PayloadAction<SelectedWidgetInfo[]>): void => {
	state.selectedWidgets = action.payload;
};

/**
 * Checks the clipboard for a valid kemu-structure string and adds the list of widgets to the current thing.
 */
export const pasteFromClipboardAction = createAsyncThunk('/clipboard/pasteFromClipboardAction', async (
	payload: {
		recipeId: string,
		thingId: string,
		mouseLocation?: Position,
		/** 
		 * A stringified map of widgets to be added. If not provided, it attempts to read 
		 * and decode text from the actual system clipboard.
		 */
		mapData?: string,
	},
	thunkApi
): Promise<StatelessWidgetsMap> => {
	const clipboardData = payload.mapData || await getFromKemuClipboard();
	if (clipboardData) {
		const { logicMapper, workspace } = thunkApi.getState() as RootState;
		const mousePosition = payload.mouseLocation || logicMapper.lastMouseClick;
		const zoom = logicMapper.canvasZoom;

		const groupId = workspace.folderPath[workspace.folderPath.length - 1]?.groupId;
		const t = getGlobalIntl();

		// mousePosition.x = mousePosition.x / zoom;
		// mousePosition.y = mousePosition.y / zoom;
		// console.log(`Pasting from clipboard at ${mousePosition.x}, ${mousePosition.y}, zoom: ${zoom}`);

		const { addedWidgets, widgetsIdMap, originalParsedData } = await addWidgetsFromStringMap(
			clipboardData, payload.recipeId, payload.thingId, mousePosition, zoom, groupId
		);

		const addedWidgetsList = Object.values(addedWidgets);
		for (const widget of addedWidgetsList) {
			// For widget bundles, if in a temp location, create
			// a new location for them.
			if (widget.type === WidgetType.widgetBundle) {
				const bundleState = widget.state as CustomWidgetState<WidgetBundleState>;
				if (bundleState.$$cacheInfo || bundleState.collectionInfo) {
					const cacheId = bundleState.$$cacheInfo?.widgetThingId || bundleState.collectionInfo?.widgetId || widget.id;
					const cachedVersion = bundleState.$$cacheInfo?.version || bundleState.collectionInfo?.version || 1;

					// Check if there is already a cached (collection installed) version of the widget
					const cachedBundle = widgetBundleManager.getCachedWidget(
						cacheId,
						cachedVersion
					);

					/**
					 * Find out if the source widget was a temp or installed bundle.
					 * Temp bundles are widget bundles that are not part of the user collection yet. For example,
					 * when a user creates a bundle from scratch.
					 */
					const isTempBundle = !cachedBundle;

					// We only need to generate new tmp locations for tmp bundles because
					// those dropped from the Widgets Launchpad (coming from a collection)
					// point to the permanent location of the widget in the cache. It is only
					// if a collection widget is modified (or created from scratch) that a /tmp location is created.
					if (isTempBundle) {
						// Find the id of the widget that was copied
						const sourceWidgetId = Object.keys(widgetsIdMap).find((id) => widgetsIdMap[id] === widget.id);
						if (sourceWidgetId) {
							// We need to capture the previous cache location, otherwise we won't be able to duplicate its contents
							const prevWidget = originalParsedData[sourceWidgetId];
							const prevWidgetState = prevWidget.state as CustomWidgetState<WidgetBundleState>;

							// Extra check, but at this point, we already identified this is a temp bundle, so it should
							// ALWAYS have a cache info.
							if (prevWidgetState.$$cacheInfo) {
								const oldWidgetId = prevWidgetState.$$cacheInfo.widgetThingId;
								const oldVersion = prevWidgetState.$$cacheInfo.version;
								const newWidgetId = widget.id;
								const newVersion = 1;
								const { processor } = await widgetBundleManager.duplicateWidgetTempLocation(
									oldWidgetId, oldVersion, newWidgetId, newVersion
								);

								// IMPORTANT: point to the new version of the cloned widget and set a processor
								// otherwise the bundle will try to load its processor the memory cache.
								// NOTE: there is no need to update the `widgetThingId` because it should
								// already point to the new id since `duplicateWidgetTempLocation` regenerates all ids.
								bundleState.$$cacheInfo = {
									...bundleState.$$cacheInfo,
									version: newVersion,
									widgetThingId: newWidgetId,
								};

								bundleState.$$processor = processor;

								// bundleState.$$cacheInfo.version = newVersion;

								// The `addWidgetsFromStringMap` function regenerates ids, including storage units. This causes
								// a bug in widget bundles since they point to a storage unit that has never existed.
								// Here we make a copy of the real storage unit using the newly generated id.
								if (prevWidgetState.storageUnitId) {
									// Create a brand new key
									const newKeyId = generateUniqueStorageUnitKey(payload.recipeId);
									// Attempt to get the previous data in case the original widget has already been removed
									const defaultData = ephemeralStorage.get(prevWidgetState.storageUnitId);
									// This may still fail if the data in the clipboard is from a previous session.
									// here we catch the error to allow the widget to be rendered.
									try {
										cloneStorageUnit(
											payload.recipeId,
											payload.thingId,
											prevWidgetState.storageUnitId,
											newKeyId,
											defaultData,
										);

										// Point to the new storage unit
										bundleState.storageUnitId = newKeyId;
									} catch (e) {
										console.error('Error cloning storage unit', e);
										bundleState.storageUnitId = undefined;
									}
								}

								/* const { zipHandler } = await widgetBundleManager.recreateBundleFromStorage(
									newWidgetId,
									newVersion,
									true
								); */

								// Now we must initialize the widget so that it loads its processor
								await kemuCore.initializeWidget(payload.recipeId, payload.thingId, newWidgetId, {
									/** prevents replacing the current state with the default one stored in the zip file (state.json) */
									keepCurrentState: true
								});
							}
						}
					} else {
						// We still need to initialize the widget so that it loads its processor
						await kemuCore.initializeWidget(payload.recipeId, payload.thingId, widget.id, {
							/** prevents replacing the current state with the default one stored in the zip file (state.json) */
							keepCurrentState: true
						});
					}
				}
			}

			// Subscribe to services
			if (widget.type === WidgetType.hubService) {
				const hubServiceState = widget.state as HubServiceState;
				if (hubServiceState.service?.eventEmitter) {
					const hc = connectionManager.getConnector();
					hc.subscribeToServiceEvents({
						targetService: {
							serviceName: hubServiceState.service.name,
							version: hubServiceState.service.version
						},
						listener: {
							recipeId: payload.recipeId,
						}
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					}).catch((e: any) => {
						console.error('Error subscribing to service events for new widget', e);
					});
				}

				try {
					await kemuCore.initializeWidget(payload.recipeId, payload.thingId, widget.id);
				} catch (e) {
					// Allow unimplemented init functions to be ignored
					if (e?.errCode !== RemoveInvokeError.FunctionNotFound) {
						// Backwards compatibility with older versions of remoteInvoker without custom errors
						const functionNotDefined = !!(typeof e === 'string' && e.match('^Function ".*" not found.$'));
						// Remove the widget if the initialization fails
						if (!functionNotDefined) {
							delete addedWidgets[widget.id];
							await removeWidget(payload.recipeId, payload.thingId, widget.id);

							thunkApi.dispatch(showGlobalNotification({
								title: t('Errors.ServiceInitError'),
								message: typeof e === 'string' ? e : e.error || e.message,
								type: 'error',
							}));
						}
					}
				}
			}

			// For widget groups, we need to loop though its settings fields
			// and re-define the variables (if non-exist)
			defineGroupFieldVariables(payload.recipeId, payload.thingId, widget);
		}

		const statelessMap = widgetMapToStatelessMap(addedWidgets);

		return statelessMap;
	}

	return {};
});


export const pasteFromClipboardReducer = ((builder: ActionReducerMapBuilder<LogicMapperState>): void => {
	builder.addCase(pasteFromClipboardAction.fulfilled, (state, action: PayloadAction<StatelessWidgetsMap>) => {
		state.gates = {
			...state.gates,
			...action.payload
		};

		// Also force re-rendering
		state.renderCounter = state.renderCounter + 1;
	});

	builder.addCase(pasteFromClipboardAction.rejected, (state, action) => {
		console.error('Failed to paste from clipboard: ', action.error);
	});
});

export default {
	copySelectedWidgetsReducer,
	setSelectedWidgetsReducer,
};
