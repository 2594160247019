import React, { useCallback, useEffect, useRef, useState } from 'react';
import Icon from '@ant-design/icons';
import ImageCropWidgetProcessor, {
	ImageCropWidgetState,
	getDefaultState,
} from '@kemu-io/kemu-core/widgets/imageCrop/index.js';

import { CustomWidgetState, WidgetPortContext } from '@kemu-io/kemu-core/types';
import classNames from 'classnames';
import { Component as ReactCrop } from 'react-image-crop';
import useTranslation from '../../../common/hooks/useTranslation';
import KemuSwitch from '../../form-control/kemuSwitch/KemuSwitch';
import { PortLocation } from '../../../types/canvas_t';
import GateIcon from '../../gateIcon/gateIcon';
import {
	GateCustomSettingsProps,
	GetPortsInformationFunction,
	GateUI,
	PortDescription,
	GateUIProps,
} from '../index.ts';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import { SETTINGS_CONTAINER_CLASS } from '../../../common/constants';
import ReactiveCanvas from '../helpers/ReactiveCanvas/ReactiveCanvas';
import styles from './imageCrop.module.css';
import { ReactComponent as ImageCropIcon } from './icon.svg';
import 'react-image-crop/dist/ReactCrop.css';
import { removeWidgetPortConnections } from '@src/app/recipe/utils';

// NOTE: This belongs to `react-image-crop`, however their type exports
// are not working properly (missing extension)
export interface Crop {
	x: number;
	y: number;
	width: number;
	height: number;
	unit: 'px' | '%';
}

const WIDGET_WRAPPER_CLASS = `image-crop-widget-wrapper`;
const DefaultCanvasWidth = 300;
const DefaultCanvasHeight = 300;

const ImageCropWidget = (props: GateUIProps): React.JSX.Element => {
	const [state] = useReactiveWidgetState<ImageCropWidgetState>(props.recipeId, props.thingRecipeId, props.info.id);

	return (
		<div className={classNames(styles.GateBody, {
			[styles.LargeGateBody]: state.showInputPorts,
			[styles.DisabledWidget]: props.info.disabled
		})}>
			<Icon component={ImageCropIcon}/>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={ImageCropIcon} />}/>
	);
};

const GateCustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
	const { recipeId, blockId, gateInfo, recipeType } = props;
	const [state, setState] = useReactiveWidgetState<ImageCropWidgetState>(recipeId, blockId, gateInfo.id);
	const canvasRef = useRef<HTMLCanvasElement | null>(null);
	const [lastImageSize, setLastImageSize] = useState<{width: number, height: number} | null>(null);

	const fixedState = {
		...getDefaultState(),
		...state
	};

	const croppedStateWidth = fixedState.cropWidth || 1;
	const croppedStateHeight = fixedState.cropHeight || 1;
	const croppedStateX = fixedState.cropX || 0;
	const croppedStateY = fixedState.cropY || 0;

	const getCompensatedDimensions = useCallback((
		x: number, y: number, w: number, h: number,
		imageSize: { width: number, height: number },
		cropToCanvas: boolean
	) => {
		if (imageSize.width <= 0 || imageSize.height <= 0) {
			return {
				x: 0,
				y: 0,
				width: 0,
				height: 0,
			};
		}

		const canvasElHeight = canvasRef.current?.clientHeight || 1;
		const canvasElWidth = canvasRef.current?.clientWidth || 1;

		const cropX = (x * (cropToCanvas ? imageSize.width : canvasElWidth));
		const cropY = (y * (cropToCanvas ? imageSize.height : canvasElHeight));
		const cropWidth = (w * (cropToCanvas ? imageSize.width : canvasElWidth));
		const cropHeight = (h * (cropToCanvas ? imageSize.height : canvasElHeight));

		return {
			x: cropX / (cropToCanvas ? canvasElWidth : imageSize.width),
			y: cropY / (cropToCanvas ? canvasElHeight : imageSize.height),
			width: cropWidth / (cropToCanvas ? canvasElWidth : imageSize.width),
			height: cropHeight / (cropToCanvas ? canvasElHeight : imageSize.height),
		};
	}, []);


	const [drawingCrop, setDrawingCrop] = useState<Crop>({
		unit: 'px',
		width: 0,
		height: 0,
		x: 0,
		y: 0,
	});


	const t = useTranslation('LogicMaker.Gates.ImageCrop.Settings');


	const handleImageSizeChange = useCallback((width: number, height: number) => {
		setLastImageSize({
			width,
			height,
		});
	}, []);

	const handleCrop = useCallback((crop: Crop) => {
		// console.log('crop', crop);
		setDrawingCrop(crop);
		setState((current) => {

			if (!crop.width || !crop.height) {
				return {
					...current,
					cropX: 0,
					cropY: 0,
					cropWidth: 0,
					cropHeight: 0,
				};
			}

			const lastImageWidth = current.$$memCanvas?.width || 1;
			const lastImageHeight = current.$$memCanvas?.height || 1;
			const scaledDimensions = getCompensatedDimensions(crop.x, crop.y, crop.width, crop.height, {
				width: lastImageWidth,
				height: lastImageHeight,
			}, true);

			// console.log(`From width: ${crop.width} to ${scaledDimensions.width}`);
			return {
				...current,
				cropX: scaledDimensions.x,
				cropY: scaledDimensions.y,
				cropWidth: scaledDimensions.width,
				cropHeight: scaledDimensions.height,
			};
		});
	}, [setState, getCompensatedDimensions]);

	const handleSwitchChange = useCallback((checked: boolean) => {
		setState((current) => {
			// Disconnect all inputs if the switch is off
			if (!checked) {
				const inputNames = ImageCropWidgetProcessor.getInputNames(current, {
					recipePoolId: recipeId,
					recipeType: recipeType,
					thingRecipeId: blockId,
					widgetId: gateInfo.id,
				});

				const nonImageInputs = inputNames.filter(input => input.name !== 'image');
				for (const input of nonImageInputs) {
					removeWidgetPortConnections(
						recipeId,
						blockId,
						gateInfo.id,
						input.name,
					);
				}
			}

			return {
				...current,
				showInputPorts: checked
			};
		});
	}, [setState, recipeId, blockId, gateInfo.id, recipeType]);

	useEffect(() => {
		if (lastImageSize) {
			setDrawingCrop((current) => {
				// If not initialized with state
				if (!current.x && !current.y && !current.width && !current.height) {
					if (croppedStateWidth <= 1 || croppedStateHeight <= 1) {
						return current;
					}

					const savedCropToCanvas = getCompensatedDimensions(
						croppedStateX,
						croppedStateY,
						croppedStateWidth,
						croppedStateHeight,
						lastImageSize,
						false,
					);

					// console.log(`From width2: ${croppedStateWidth} to ${savedCropToCanvas.width}`);

					return {
						unit: 'px',
						width: savedCropToCanvas.width,
						height: savedCropToCanvas.height,
						x: savedCropToCanvas.x,
						y: savedCropToCanvas.y,
					};
				}

				return current;
			});
		} /* else if (fixedState.$$memCanvasContext && canvasRef.current && fixedState.$$memCanvas) {
			// const imgData = fixedState.$$memCanvasContext.getImageData(0, 0, fixedState.$$memCanvas.width, fixedState.$$memCanvas.height);
			const displayContext = canvasRef.current.getContext('2d');
			if (displayContext) {
				displayContext.drawImage(fixedState.$$memCanvas, 0, 0, fixedState.$$memCanvas.width, fixedState.$$memCanvas.height);
			}
		} */
	}, [
		lastImageSize, /* fixedState.$$memCanvas, */
		getCompensatedDimensions, /* fixedState.$$memCanvasContext, */
		croppedStateX, croppedStateY, croppedStateWidth, croppedStateHeight
	]);

	return (
		<div
			className={classNames(styles.SettingsContainer, SETTINGS_CONTAINER_CLASS, {
				[styles.ManualMode]: !fixedState.showInputPorts,
			})}
		>
			{props.children}

			<div className={styles.SwitchContainer}>
				<label>{t('Label', 'Show input ports')}</label>
				<KemuSwitch
					size="small"
					checked={fixedState.showInputPorts}
					onChange={handleSwitchChange}
				/>
			</div>

			{/* Manual Canvas */}
			{!fixedState.showInputPorts && (
				<>
					<div className={styles.CroppingContainer}>
						{!fixedState.$$memCanvas && (
							<div className={styles.NoImage}>Waiting for image</div>
						)}

						{fixedState.$$memCanvas && (
							<ReactCrop
								onChange={handleCrop}
								crop={drawingCrop}
							>
								<ReactiveCanvas
									inputPortName='image'
									recipeId={recipeId}
									thingId={blockId}
									widgetId={gateInfo.id}
									allowImageData={true}
									canvasHeight={fixedState.$$memCanvas.height}
									canvasWidth={fixedState.$$memCanvas.width}
									canvasRef={canvasRef}
									onImageSizeChange={handleImageSizeChange}
									canvasStyle={{
										width: DefaultCanvasWidth,
										height: 'auto',
										// maxHeight: DefaultCanvasHeight,
									}}
								/>
							</ReactCrop>
						)}
					</div>
				</>
			)}
		</div>
	);
};

const getPortsInformation: GetPortsInformationFunction = (state: CustomWidgetState<ImageCropWidgetState>, widgetInfo) => {
	const portContext: WidgetPortContext = {
		recipePoolId: widgetInfo.recipePoolId,
		recipeType: widgetInfo.recipeType,
		thingRecipeId: widgetInfo.thingRecipeId,
		widgetId: widgetInfo.id,
	};
	const outputNames = ImageCropWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = ImageCropWidgetProcessor.getInputNames(state, portContext);

	const inputPositions: Record<string, PortLocation> = {
		'image': [0, 0.15, -1, 0],
		'x': [0, 0.32, -1, 0],
		'y': [0, 0.50, -1, 0],
		'width': [0, 0.67, -1, 0],
		'height': [0, 0.85, -1, 0],
	};

	let inputs: PortDescription[] = [];
	if (inputNames.length === 1) {
		inputs.push({
			...inputNames[0],
			position: 'Left'
		} as PortDescription);
	} else {
		inputs = inputNames.map(input => {
			return {
				...input,
				position: inputPositions[input.name],
			};
		});
	}

	return {
		outputs: [{
			position: 'Right',
			name: outputNames[0].name,
			type: outputNames[0].type,
		}],

		inputs,
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: ImageCropWidget,
	CustomSettingsDialog: GateCustomSettings,
	getWrapperClass: () => WIDGET_WRAPPER_CLASS,
	hasTitle: true,
	getGatesBarTitle: (intl) => intl.formatMessage({ id: 'LogicMaker.Gates.ImageCrop.Title', defaultMessage: 'Crop Image' }),
	getWidgetTitle: (intl) => intl.formatMessage({ id: 'LogicMaker.Gates.ImageCrop.WidgetHeader', defaultMessage: 'Crop' }),
} as GateUI;
