import { Recipe, RecipeStatus } from '@kemu-io/kemu-core/types';
import { DEFAULT_THING_ID, DEFAULT_THING_VERSION } from '@kemu-io/kemu-core/common/constants';
import { ThingCategory, ThingType } from '@kemu-io/kemu-types';

/**
 * Returns a default recipe based on the organizational settings
 */
const getDefaultRecipe = async (): Promise<Recipe> => {
	// TODO: Get this from the DB
	const recipe: Recipe = {
		id: '',
		name: 'Untitled recipe',
		blocks: {
			[DEFAULT_THING_ID]: {
				id: DEFAULT_THING_ID,
				gates: {},
				category: ThingCategory.Virtual,
				children: [],
				inputPorts: [],
				outputPorts: [],
				name: 'default',
				recipeId: DEFAULT_THING_ID,
				state: {},
				type: ThingType.Web,
				version: DEFAULT_THING_VERSION,
				canvas: {
					logicMapper: {
						position: { x: 0, y: 0 },
						zoom: 1,
					},
					position: { x: 0, y: 0 },
				}
			}
		},
		canvas: {
			workspace: {
				position: { x: 0, y: 0 },
			}
		},

		// ==== Just for compliance ====
		author: {
			firstName: 'Unknown',
			id: '0',
			lastName: 'unknown',
		},

		credits: 0,
		dataSources: [],
		startedAt: 0,
		lastInvokedAt: 0,
		lastProcessedAt: 0,
		status: RecipeStatus.RUNNING,
		// v1.0 => Alpha
		// v1.3 => Support for dynamic ports
		// v1.4 => Recipe widgets metadata
		// v1.5 => Support for cloud recipes
		// v1.6 => Ports no longer include index, id of child widgets is prefixed to the source and target ports
		protocolVersion: '1.6'
	};

	return recipe;
};

export {
	getDefaultRecipe,
};
