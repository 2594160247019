import React, { ReactElement, useState } from 'react';
import { Row, Col, Spin, Alert } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthCard from '../AuthCard/AuthCard';
import styles from './accessCode.module.css';
import useQueryString from '@hooks/useQueryString';
import { AsyncRequestStatus, AsyncState } from '@src/types/core_t';
import globals from '@common/globals';
import routes from '@common/routes/index';
import StyledInput from '@components/form-control/styledInput/styledInput';
import StyledButton from '@components/form-control/styledButton/styledButton';
import FormGroup from '@components/form-control/formGroup/formGroup';

// Hard-coding this as it will only be used once during launching
const verifyCode = async (code: string): Promise<void> => {
	const response = await axios.post(`${globals.ACCESS_CODE_API}/verify/code/${code}`);
	return response.data;
};

interface Props {
	onCodeValid: (code: string)=> void;
}

function AccessCode (props: Props): ReactElement {
	const [asyncState, setAsyncState] = useState<AsyncState>({ status: AsyncRequestStatus.idle });
	const queryString = useQueryString();
	const defaultCode = queryString.get('code');
	const [code, setCode] = useState(defaultCode || '');

	const onSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		if (!code.trim()) { return; }
    try {
			setAsyncState({ status: AsyncRequestStatus.loading });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const verifiedCode = await verifyCode(code);
			setAsyncState({ status: AsyncRequestStatus.completed });
			props.onCodeValid && props.onCodeValid(code);
    } catch (e) {
			const error = e.response?.data || e.message || e;
			setAsyncState({
				status: AsyncRequestStatus.error,
				error: typeof error === 'string' ? { message: error } : error
			});
		}
	};


	return (
		<AuthCard title="Access Code" >
			<Spin indicator={<LoadingOutlined className={styles.LoadingIcon}/>} spinning={asyncState.status === AsyncRequestStatus.loading}>

				{asyncState.status === AsyncRequestStatus.error && (
					<Alert closable={true} message={asyncState.error?.message} type="error" style={{ marginBottom: 10 }}/>
				)}

				<p>Access to Kemu is Invitation Only. If you have an access code, enter it below:</p>
				<form method="POST" onSubmit={onSubmit}>
					<Row >
						<Col span={24}>
							<FormGroup>
								<StyledInput onChange={e => setCode(e.currentTarget.value)} value={code} placeholder="Enter access code"/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<FormGroup className={styles.Centered}>
								<Link to={routes.auth.getSignInRoute()} className={styles.TryAgain}>
									Back to Login
								</Link>

								<StyledButton className={styles.Button} title="Verify" type="submit" disabled={!code.trim().length}/>
							</FormGroup>
						</Col>
					</Row>
				</form>
			</Spin>
		</AuthCard>
	);
}

export default AccessCode;
