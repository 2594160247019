// import { DataType } from '@kemu-io/kemu-core/types';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const KLProtocolHeadersSize = {
  protocolPrefix: 4,
  protocolVersion: 1,
  jsonLength: 4,
  binaryLength: 4,
  fromServiceId: 4,
  toServiceId: 4,
  sentAt: 8,
};

/**
 * Size of all IPC headers
 */
export const KLHeaderSize = Object.values(KLProtocolHeadersSize).reduce((acc, curr) => acc + curr, 0);


/** Represents a message in flight */
export type KLProtocolHeader<DT extends Buffer | ArrayBuffer> = {
  protocolVersion: number;
  /** number of bytes used by the JSON portion of the message */
  jsonLength: number;
  /** number of bytes used by the binary data (if any) */
  binaryLength: number;
  /** a numeric identifier for the source service */
  fromServiceId : number;
  toServiceId: number;
  /** The timestamp of the message when it was sent (LE). */
  sentAt: Date;
  /** 
   * All the packages received that form the message body. This excludes the
   * protocol headers.
   */
  packages: DT[];
  /** 
   * The package that described the header. Use it, along with the `packages` to build
   * the original transmission.
   */
  headerPackage: DT;
}

export type UKLProtocolHeader = KLProtocolHeader<ArrayBuffer>;

export type ParentWidgetEvent = {
  /** the name of the port in the target widget the event is intended for */
  targetPortName: string;
  parent?: {
    /** the id of the parent widget */
    id: string;
    /** the name of the port in the parent widget */
    sourcePortName?: string;
  };
  /** the current state of the widget */
  currentState?: Record<string, any>;
}

export type WidgetInvokeEvent = {
  /** the name of the port in the current widget that is producing the event */
  sourcePortName: string;
  /** the widget's state after executing an event */
  newState?: Record<string, any>;
}

/**
 * The minimum info that must be present in the JSON portion of a Kemu Link message
 */
export type BaseJsonInfo = {
  messageId: string;
  replyMessageId?: string;
  sentAt: number;
  dataType: /* DataType */any;
  data?: any;
  binaryData?: Buffer;
  /** used when the service is replying to a message? */
  // serviceSessionId?: number;
  eventInfo: ParentWidgetEvent | WidgetInvokeEvent;
}

export type SupportedBinaryType = 'Buffer' | 'ArrayBuffer' | 'Uint8ClampedArray' | 'Uint8Array' | 'Int8Array';

export type BinaryPortion = {
  /** position of the buffer where the portion starts */
  index: number;
  /** length of the binary portion */
  length: number;
  binaryType: SupportedBinaryType;
}

export type UserMessage = {
  json: Record<string, any>;
}

export type KLMessage = UserMessage & {
  /** 
   * map of property path to binary data portion. 
   * This is automatically created when any data in the given json
   * contains a binary data buffer. Eg:
   * ```json
   * {
   *  "images[0].src": {
   *   "index": 0,
   *   "length": 100,
   *   "binaryType": "ArrayBuffer"
   *  },
   * "images[1].src": {
   *  "index": 100,
   *  "length": 200,
   *  "binaryType": "Buffer"
   * }
   * ```
   **/
  jsonBinaryMap?: Record<string, BinaryPortion>;
}



// export type KLMessage<B extends Buffer | ArrayBuffer = ArrayBuffer> = {
//   json?: Record<string, any>;
//   binaryData?: B;
// }

/** Universal Kemu Link Message */
// export type UKLMessage = {
//   json?: Record<string, any>;
//   binaryData?: ArrayBuffer;
// }

export type DecodedKLMessage<T extends Buffer | ArrayBuffer> = {
  json: Record<string, any>;
  // binaryData?: T;
  /** the full message including headers */
  rawMessage: T;
}

// export type DecodedKLMessage<Data = any, WidgetState = Record<string, unknown>> = {
//   /** the state of the widget after a message has been processed */
//   newState?: WidgetState;
//   data?: Data;
//   json: BaseJsonInfo;
// }

export type KLMessageInFlight<T extends Buffer | ArrayBuffer> = {
  firstPackageAt: number;
  header?: KLProtocolHeader<T> & {
    totalBytesReceived: number;
    totalBytesExpected: number;
    /**
     * The remaining data that does not form part of the message header + data.
     * If present, it indicates there were more than 1 messages in the buffer.
     */
    remaining: T | null;
  };
  /**
   * Contains the first initial bytes of a message with the valid prefix
   * but not enough bytes to form a full header.
   */
  partialHeaderData?: T;
}

/** Universal Kemu Link MessageInFlight */

// export type UKLMessageInFlight = UKLProtocolHeader & {
//   firstPackageAt: number;
//   totalBytesReceived: number;
//   totalBytesExpected: number;
//   /**
//    * The remaining data that does not form part of the message header + data.
//    * If present, it indicates there were more than 1 messages in the buffer.
//    */
//   remaining: Buffer | null;
// }

export type KLConnection = {
  socket: WebSocket;
  activeMessage: KLMessageInFlight<ArrayBuffer> | null;
}

export type TransmissionInfo<B extends Buffer | ArrayBuffer = Buffer> = {
  sourceServiceId: number;
  targetServiceId: number;
  rawMessage: B;
}
