import React, { useCallback, useMemo, useState } from 'react';
import { SliderConfig } from '@kemu-io/kemu-core/types';
import variablesManager from '@kemu-io/kemu-core/common/managers/variablesManager.js';
import { Slider } from 'antd';
import tinycolor from 'tinycolor2';
import { DataType } from '@kemu-io/hs-types';
import FieldLabel from '../FieldLabel/FieldLabel';
import styles from './SliderField.module.css';
import { ABORT_CHILD_DRAGGING } from '@common/constants';
import FormGroup from '@components/form-control/formGroup/formGroup';

type Props = SliderConfig & {
  label: string;
  variableName: string;
  recipeId: string;
  thingRecipeId: string;
  widgetId: string;
  color?: string;
  disabled?: boolean;
}

const SliderField = (props: Props) => {
  const { variableName, recipeId, thingRecipeId, widgetId, color, disabled } = props;
  const currentVarValue = variablesManager.getThingVariableValue(recipeId, thingRecipeId, variableName, {
    ownerWidgetId: widgetId,
  });

  const [sliderValue, setSliderValue] = useState<number>(currentVarValue as number);

  const colors = useMemo(() => {
    if (!color) { return undefined; }
    const trackColor = tinycolor(color).darken(20).toString();
    const brightness = tinycolor(color).getBrightness();
    const isBrightColor = brightness > 200;

    return {
      trackColor,
      brightness,
      isBrightColor,
    };
  }, [color]);

  const onValueChange = useCallback((value: number) => {
    setSliderValue(() => {
      variablesManager.setThingVariable(recipeId, thingRecipeId, widgetId, variableName, {
        type: DataType.Number,
        value
      });

      return value;
    });
  }, [recipeId, thingRecipeId, variableName, widgetId]);

  return (
    <div className={styles.Wrapper}>
      <FormGroup column={true} noMarginBottom>
        <FieldLabel color={props.color} label={props.label} noBottomMargin />
        <div className={`${styles.SliderField} ${ABORT_CHILD_DRAGGING}`}>
          <Slider
            disabled={disabled}
            handleStyle={{
              borderColor: colors?.trackColor
            }}
            trackStyle={{
              backgroundColor: colors?.trackColor
            }}
            step={props.step}
            value={sliderValue}
            onChange={onValueChange}
            min={props.min}
            max={props.max}
          />
        </div>
      </FormGroup>
    </div>
  );
};

export default SliderField;
