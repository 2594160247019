import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { WidgetCollectionState } from '@src/app/reducers/widget/widgetSlice';
import * as widgetApi from '@src/api/widget/widgetApi';
import { AsyncRequestStatus } from '@src/types/core_t';
import { getGlobalIntl } from '@src/assets/i18n/globalIntl';

/**
 * Deletes the given widget in the database.
 * It also removes the widget from the local cache.
*/
export const shareWidgetAction = createAsyncThunk('/widgets/shareWidgetAction', async (
	args: { widgetDbId: string, enable: boolean },
): Promise<{ widgetId: string, publicId?: string }> => {

	const response = await widgetApi.toggleLinkSharing(args.widgetDbId, args.enable);
	// Store the widget from the local cache
	return {
		widgetId: args.widgetDbId,
		publicId: response.publicId
	};
});


export const shareWidgetReducer = ((builder: ActionReducerMapBuilder<WidgetCollectionState>): void => {

	builder.addCase(shareWidgetAction.pending, (state) => {
		// const t = getGlobalIntl();
		state.asyncIndicator = {
			title: '',
			asyncStatus: {
				status: AsyncRequestStatus.loading,
				error: undefined,
			}
		};
	});

	builder.addCase(shareWidgetAction.rejected, (state, action) => {
		const t = getGlobalIntl();
		state.asyncIndicator = {
			title: t('Widget.AsyncIndicator.LinkShare.Failure', 'Error: '),
			asyncStatus: {
				status: AsyncRequestStatus.error,
				error: action.error,
			}
		};
	});

	builder.addCase(shareWidgetAction.fulfilled, (state, action) => {
		// const t = getGlobalIntl();
		state.asyncIndicator = {
			title: '',
			asyncStatus: {
				status: AsyncRequestStatus.completed,
				error: undefined,
			}
		};

		state.customWidgets = state.customWidgets.map(widget => {
			const targetWidget = widget.dbId === action.payload.widgetId;
			return {
				...widget,
				publicShareId: targetWidget ? action.payload.publicId : widget.publicShareId
			};
		});
	});
});

