import { getOrigin } from '../utils';
import { getDirectActionRoute } from './common';

const origin = getOrigin();
const getPublicWidgetImportUrl = (id?: string): string => `${origin}${getDirectActionRoute()}/wi/${id !== undefined ? id : ':id?'}`;
const getImportWidgetDirectRoute = (id?: string): string => `${getDirectActionRoute()}/wi/${id !== undefined ? id : ':id?'}`;

export default {
	getPublicWidgetImportUrl,
	getImportWidgetDirectRoute
};
