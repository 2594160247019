import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	GroupWidgetPort,
	WidgetGroupSetting,
	WidgetGroupState
} from '@kemu-io/kemu-core/types';
import { Tabs } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
// import variablesManager from '@kemu-io/kemu-core/common/managers/variablesManager.js';
import { DataType } from '@kemu-io/hs-types';
import {
	addCustomWidgetAction,
	editCustomWidgetAction,
	selectShowCreateWidgetModal,
	showCreateWidgetModalAction
} from '../logicMapperSlice.ts';
import { WidgetGroupStateWithId } from '../reducers/customWidgetReducer';
import { selectedBlock, selectVisibleGroup } from '../../Workspace/workspaceSlice';
import styles from './customWidgetModal.module.css';
import WidgetSettingsTab from './WidgetSettingsTab/WidgetSettingsTab';
import DetailsTab from './DetailsTab/DetailsTab';
import useTranslation from '@common/hooks/useTranslation';
import StandardModal from '@components/modal/standardModal';

const defaultInput: GroupWidgetPort = { index: 0, label: 'input1', name: 'input1', type: [DataType.Anything] };
const defaultOutput: GroupWidgetPort = { index: 0, label: 'output1', name: 'output1', type: [DataType.Anything] };
const defaultColor = '#4e3eff';

const CustomWidgetModal = (): React.JSX.Element => {
	const dispatch = useDispatch();
	const showModal = useSelector(selectShowCreateWidgetModal);
	const existingWidgetInfo = (showModal as Partial<WidgetGroupStateWithId>) || null;
	const currentBlockInfo = useSelector(selectedBlock);
	const groupPath = useSelector(selectVisibleGroup);
	const t = useTranslation('CustomWidgetModal');
	const [inputs, setInputs] = useState<GroupWidgetPort[]>(existingWidgetInfo?.inputs || [defaultInput]);
	const [outputs, setOutputs] = useState<GroupWidgetPort[]>(existingWidgetInfo?.outputs || [defaultOutput]);
	const [color, setColor] = useState<string | undefined>(existingWidgetInfo?.color);
	const [icon, setIcon] = useState<string | undefined>(existingWidgetInfo?.icon);
	const [widgetName, setWidgetName] = useState<string>(existingWidgetInfo?.name || '');
	const [widgetDescription, setWidgetDescription] = useState<string>(existingWidgetInfo?.description || '');
	const missingName = widgetName.trim() === '';
	const [selectedTab, setSelectedTab] = useState<string>('details');
	const isSaving = !!showModal;
	const currentWidgetId = existingWidgetInfo?.widgetId;
	const currentWidgetProtocol  = existingWidgetInfo?.protocolVersion;
	const [settingsFields, setSettingsFields] = useState<WidgetGroupSetting[]>(existingWidgetInfo?.settings || []);
	const hasEmptyVariableNames = Boolean(settingsFields?.find((s) => s.variableName.trim() === ''));

	// Prevents saving widget with variables that have the same name as other widgets
	const fieldsVarNames = settingsFields.map((item) => item.variableName);
  // Find filed names that appear more than once
  const hasRepeatedVariables = fieldsVarNames.some((name, index) => fieldsVarNames.indexOf(name) !== index);

	// const hasRepeatedVariables = useMemo(() => {
	// 	if (!currentBlockInfo?.recipePoolId) { return false; }
	// 	const variables = variablesManager.getVariableNames(
  //     currentBlockInfo.recipePoolId,
  //     currentBlockInfo.recipeId
  //   );

	// 	const repeatedVariables = settingsFields.find((s) => {
	// 		// Find any other widget other than this using the same var name
	// 		const matchingVar = variables.find((v) =>
	// 			v.name === s.variableName
	// 			&& v.ownerWidgetId !== currentWidgetId
	// 		);
	// 		return Boolean(matchingVar);
	// 	});

	// 	return Boolean(repeatedVariables);
	// }, [settingsFields, currentBlockInfo?.recipeId, currentBlockInfo?.recipePoolId, currentWidgetId]);

	const handleCancel = useCallback(() => {
		dispatch(showCreateWidgetModalAction(false));
	}, [dispatch]);

	const handleCreate = useCallback(() => {
		if (widgetName && currentBlockInfo) {
			const name = widgetName;
			const description = widgetDescription || '';

			const baseInformation = {
				description,
				name,
				inputs,
				outputs,
				icon,
				color,
				settings: settingsFields,
				recipeId: currentBlockInfo.recipePoolId,
				thingId: currentBlockInfo.recipeId,
				protocolVersion: currentWidgetProtocol,
				...(groupPath ? { parentGroupId: groupPath.groupId } : undefined)
			};

			if (isSaving && currentWidgetId) {
				// Editing existing widget
				dispatch(editCustomWidgetAction({
					...baseInformation,
					widgetId: currentWidgetId,
				}));
			} else {
				dispatch(addCustomWidgetAction({
					...baseInformation,
					...(groupPath ? { parentGroupId: groupPath.groupId } : undefined)
				}));
			}

			// Hide the modal
			dispatch(showCreateWidgetModalAction(false));
		}
	}, [
		dispatch, color, icon, currentBlockInfo,
		groupPath, inputs, outputs, isSaving,
		currentWidgetId, widgetName, widgetDescription,
		settingsFields, currentWidgetProtocol,
	]);

	const handleTabChange = useCallback((key: string) => {
		setSelectedTab(key);
	}, []);

	// Clear list upon exit
	useEffect(() => {
		const modalInfo = (showModal as Partial<WidgetGroupState>);
		if (modalInfo) {
			setInputs(modalInfo.inputs || [defaultInput]);
			setOutputs(modalInfo.outputs || [defaultOutput]);
			setIcon(modalInfo.icon);
			setColor(modalInfo.color || defaultColor);
			setWidgetName(modalInfo.name || '');
			setSettingsFields(modalInfo.settings || []);
			setWidgetDescription(modalInfo.description || '');
		}
	}, [showModal]);

	// Show details on new widget creation 
	useEffect(() => {
		if (showModal && !currentWidgetId) {
			setSelectedTab('details');
		}
	}, [showModal, isSaving, currentWidgetId]);

	return (
		<StandardModal
			hideCloseIcon
			bodyClassName={styles.ModalBody}
			// width={750}
			className={styles.ModalRoot}
			width={selectedTab === 'details' ? 650 : 800}
			visible={!!showModal}
			title={t('Title', 'Create Custom Widget')}
			cancelBtnLabel={t('CancelBtn', 'Cancel')}
			disableOkButton={missingName || hasEmptyVariableNames || hasRepeatedVariables}
			okBtnLabel={
				isSaving ? t('SaveBtn', 'Save') : t('CreateBtn', 'Create')
			}
			onCancel={handleCancel}
			onOk={handleCreate}
			closeOnMaskClick={false}
			dataKemuMeta="custom-widget-modal"
		>
			<Tabs
				activeKey={selectedTab}
				type='card'
				defaultActiveKey='details'
				onChange={handleTabChange}
				className={styles.TabsContainer}
			>
				<Tabs.TabPane tab={t('Tab.Details', 'Details')} key="details">
					<DetailsTab
						inputs={inputs}
						outputs={outputs}
						color={color}
						icon={icon}
						onColorChange={setColor}
						name={widgetName}
						onNameChange={setWidgetName}
						description={widgetDescription}
						onDescriptionChange={setWidgetDescription}
						onIconChange={setIcon}
						onInputsChange={setInputs}
						onOutputsChange={setOutputs}
						existingWidgetInfo={existingWidgetInfo}
						widgetId={currentWidgetId}
					/>
				</Tabs.TabPane>

				<Tabs.TabPane
					tab={
						<div>
							<SettingOutlined />
							<span>{t('Tab.Settings', 'Settings')}</span>
						</div>
					}
					key="settings"
				>
					<WidgetSettingsTab
							currentThing={currentBlockInfo}
							widgetSettingsFields={settingsFields}
							onFieldsUpdated={setSettingsFields}
							widgetId={currentWidgetId}
							widgetName={widgetName}
						/>
				</Tabs.TabPane>
			</Tabs>
		</StandardModal>
	);
};

export default CustomWidgetModal;
