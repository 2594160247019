import { PayloadAction, Draft } from '@reduxjs/toolkit';
import { TextConfig, WidgetGroupSetting, WidgetGroupState, Position, GateChild, GroupWidgetPort } from '@kemu-io/kemu-core/types';
import variablesManager from '@kemu-io/kemu-core/common/managers/variablesManager.js';
import { createWidgetPortIdentifier, encodePortName } from '@kemu-io/kemu-core/common/utils';
import { LogicMapperState } from '../logicMapperSlice';
import showCreateWidgetBundleModal from './showCreateWidgetBundleModal';
import { getDataTypeFromWidgetFieldType } from './common';
import * as recipeUtils from '@src/app/recipe/utils';
import { jsonCloneObject } from '@common/utils';
import { StatelessRecipeWidget } from '@src/types/core_t';

export type WidgetGroupStateWithId = (WidgetGroupState & { widgetId?: string });

export type MinActionInfo = {
	/** id of the thing in the recipe */
	thingId: string;
	/** id of the recipe in the pool */
	recipeId: string;
	/** name of the custom widget */
}

export type AddCustomWidgetProps = MinActionInfo & {
	name: string;
	/** description of the custom widget*/
	description: string;
	inputs: GroupWidgetPort[];
	outputs: GroupWidgetPort[];
	/** 
	 * The default location in the canvas to position the custom widget.
	 * If not provided, the last mouse click location will be used instead.
	 * */
	position?: Position;
	parentGroupId?: string;
	color?: string;
	icon?: string;
	settings: WidgetGroupSetting[];
	protocolVersion?: string;
}

const createWidgetFieldVariables = (
	settings: WidgetGroupSetting[],
	recipeId: string,
	thingId: string,
	widgetId: string,
): void => {
	for (const field of settings) {
		const varType = getDataTypeFromWidgetFieldType(field.type);
		const fieldConfig = field.config as TextConfig;
		variablesManager.defineThingVariable(
			recipeId,
			thingId,
			widgetId,
			field.variableName,
			varType,
			{
				...(fieldConfig.defaultValue !== undefined ? { defaultValue: fieldConfig.defaultValue } : {}),
				isCustomFieldVar: true,
				storeValueInRecipe: field.private,
			}
		);
	}
};

export const addCustomWidgetReducer = (
	state: Draft<LogicMapperState>,
	action: PayloadAction<AddCustomWidgetProps>
): void => {

	const defaultState: Partial<WidgetGroupState> = {
		name: action.payload.name,
		description: action.payload.description,
		inputs: action.payload.inputs,
		outputs: action.payload.outputs,
		settings: action.payload.settings,
		icon: action.payload.icon,
		color: action.payload.color,
		// Always use protocol v2 for new widget
		protocolVersion: '2',
	};

	const addedWidget = recipeUtils.addCustomWidgetToThing(
		action.payload.recipeId,
		action.payload.thingId,
		action.payload.position || {
			x: state.lastMouseClick.x,
			y: state.lastMouseClick.y
		},
		defaultState as WidgetGroupState,
		action.payload.parentGroupId,
	);

	// Create custom field variables if any
	const fields = action.payload.settings || [];
	createWidgetFieldVariables(fields, action.payload.recipeId, action.payload.thingId, addedWidget.id);

	state.gates[addedWidget.id] = jsonCloneObject<StatelessRecipeWidget>(addedWidget);
};


export const editCustomWidgetReducer = (
	state: Draft<LogicMapperState>,
	action: PayloadAction<AddCustomWidgetProps & { widgetId: string }>
): void => {
	// const canvasInfo = recipeUtils.getCanvasInfo(action.payload.recipeId);
	const currentState = recipeUtils.getWidgetState<WidgetGroupState>(action.payload.widgetId, action.payload.thingId, action.payload.recipeId);
	const thingWidgets = recipeUtils.getWidgetsInThing(action.payload.recipeId, action.payload.thingId);

	// Unsubscribe from previous variables
	const previousFields = currentState.settings || [];
	const newFields = action.payload.settings || [];
	for (const field of previousFields) {
		// Check if the old field variable was removed
		const fieldKept = newFields.find(f => f.variableName === field.variableName);
		if (!fieldKept) {
			variablesManager.removeVariableDefinition(
				action.payload.recipeId,
				action.payload.thingId,
				action.payload.widgetId,
				field.variableName,
			);
		}
	}

	// Find missing children from the previous inputs
	const missingInputPort = currentState.inputs.filter((input) => {
		// Find a matching input in the new inputs
		const found = action.payload.inputs.find((newInput) => {
			return newInput.name === input.name;
		});

		return !found;
	});

	const missingOutputPort = currentState.outputs.filter((output) => {
		// Find a matching input in the new inputs
		const found = action.payload.outputs.find((newOutput) => {
			return newOutput.name === output.name;
		});

		return !found;
	});

	// TODO: Consider calling `removeWidgetPortConnections` directly to avoid all the code below
	// which was written to handle the old format of the widget ports.

	/**
	 * Removes a child from the given widget source
	 * @param sourceWidgetId the id of the widget that contains the child to be removed.
	 * @param childToRemove the child to be removed.
	 */
	const removeChildAndUpdateRedux = (sourceWidgetId: string, childToRemove: GateChild) => {
		const updatedChildrenList = recipeUtils.removeChildGate(
			action.payload.recipeId,
			action.payload.thingId,
			sourceWidgetId,
			childToRemove.childId,
			childToRemove.sourcePort,
			childToRemove.targetPort,
		);

		// Update redux state with the new children list of the mo
		if (state.gates[sourceWidgetId]) {
			state.gates[sourceWidgetId].children = JSON.parse(JSON.stringify(updatedChildrenList));
		}
	};

	// Disconnect inner children linked to the removed inputs
	const thisWidget = state.gates[action.payload.widgetId];
	const thisWidgetChildren = thisWidget.children || [];

	const parents = Object.keys(thingWidgets).filter((wId) => thingWidgets[wId].children.find(child => child.childId === thisWidget.id));

	for (const outputPort of missingOutputPort) {

		const innerPortId = encodePortName('inner', 'output', outputPort.name);
		const outerPortId = encodePortName('outer', 'output', outputPort.name);

		// Ports on the right (act as outputs)
		const v2InnerInputPort = createWidgetPortIdentifier(action.payload.widgetId, 'innerInput', innerPortId);
		// Standard input port
		const v2OuterOutputPort = createWidgetPortIdentifier(action.payload.widgetId, 'output', outerPortId);

		// Disconnect inner parents linked to the removed outputs
		for (const parentId of parents) {
			const parent = thingWidgets[parentId];
			const parentChildren = parent.children || [];

			const childWithV2Format = parentChildren.find((child) => child.targetPort === v2InnerInputPort);

			// Remove myself from the inner parent's children list
			if (childWithV2Format) {
				removeChildAndUpdateRedux(parentId, childWithV2Format);
			} else {
				// Old format
				if (parentChildren[outputPort.index]) {
					removeChildAndUpdateRedux(parentId, parentChildren[outputPort.index]);
				}
			}
		}

		// Also remove any of my direct children (outer children) linked to the removed output
		for (const myChild of thisWidgetChildren) {
			if (myChild.sourcePort === v2OuterOutputPort) {
				removeChildAndUpdateRedux(action.payload.widgetId, myChild);
			}
		}
	}


	for (const inputPort of missingInputPort) {
		const children = thisWidget.children || [];

		// Create a list of potential names including support for v2 format as well as the inner ports naming
		const innerPortId = encodePortName('inner', 'input', inputPort.name);
		const outerPortId = encodePortName('outer', 'input', inputPort.name);

		// Ports on the left (act as inputs)
		const v2InnerOutputPort = createWidgetPortIdentifier(action.payload.widgetId, 'innerOutput', innerPortId);
		// Standard input port
		const v2OuterInputPort = createWidgetPortIdentifier(action.payload.widgetId, 'input', outerPortId);

		const childWithV2Format = children.find((child) => child.sourcePort === v2InnerOutputPort);

		// Remove any widget attached to the inner port as a child
		if (childWithV2Format) {
			removeChildAndUpdateRedux(action.payload.widgetId, childWithV2Format);
		} else {
			if (children[inputPort.index]) {
				removeChildAndUpdateRedux(action.payload.widgetId, children[inputPort.index]);
			}
		}

		// Also search any widget that has the outer input port as a target (outer parent)
		const mutableWidgets = Object.values(thingWidgets);
		for (const mutableWidget of mutableWidgets) {
			for (const outerChild of mutableWidget.children || []) {
				if (outerChild.targetPort === v2OuterInputPort) {
					console.log(`Widget ${mutableWidget.id} has a child connected to the removed port (${inputPort.name}), removing child reference.`);
					removeChildAndUpdateRedux(mutableWidget.id, outerChild);
				}
			}
		}
	}


	recipeUtils.editCustomWidgetState(
		action.payload.recipeId,
		action.payload.thingId,
		action.payload.widgetId,
		{
			description: action.payload.description,
			inputs: action.payload.inputs,
			outputs: action.payload.outputs,
			name: action.payload.name,
			type: 'custom',
			color: action.payload.color,
			icon: action.payload.icon,
			// canvasPosition: canvasInfo.workspace.position,
			canvasPosition: currentState.canvasPosition,
			// restore the linked connection info
			collectionInfo: currentState.collectionInfo,
			settings: action.payload.settings,
			protocolVersion: action.payload.protocolVersion,
		}
	);

	// Re-subscribe to new variables
	const fields = action.payload.settings || [];
	createWidgetFieldVariables(fields, action.payload.recipeId, action.payload.thingId, action.payload.widgetId);

	state.gates[action.payload.widgetId] = {
		...state.gates[action.payload.widgetId],
	};
};


export const setShowCreateWidgetModalReducer = (state: Draft<LogicMapperState>, action: PayloadAction<MinActionInfo & { widgetId: string } | boolean>): void => {
	let widgetInfo: WidgetGroupStateWithId | boolean = action.payload === true ? (action.payload as boolean) : false;
	// If a widget id was provided, we are in editing mode. Read state from recipe
	// before showing the modal
	if (action.payload && action.payload !== true) {
		const currentState = recipeUtils.getWidgetState<WidgetGroupState>(
			action.payload.widgetId,
			action.payload.thingId,
			action.payload.recipeId,
		);

		const stateCopy = jsonCloneObject<WidgetGroupState>(currentState);
		widgetInfo = {
			...stateCopy,
			widgetId: action.payload.widgetId,
		};
	}

	state.showCreateWidgetModal = widgetInfo;
};


/**
 * Adds a new widget and its
 * @param state 
 * @param action 
 */
// export const addWidgetFromTemplateReducer = async (state: Draft<LogicMapperState>, action: PayloadAction<{
// 	widget: WidgetCollectionItem,
// 	recipeId: string,
// 	thingId: string,
// 	currentGroupId?: string,
// 	position?: Position,
// }>): Promise<void> => {
// 	// FIXME: this should be a thunk
// 	const gatesList = await recipeUtils.addCustomWidgetFromTemplate(
// 		action.payload.widget,
// 		action.payload.recipeId,
// 		action.payload.thingId,
// 		action.payload.position,
// 		action.payload.currentGroupId
// 	);

// 	const stateLessGates: StatelessWidgetsMap = {};
// 	Object.values(gatesList).map(gate => {
// 		// eslint-disable-next-line @typescript-eslint/no-unused-vars
// 		const { state, ...stateLess } = gate;
// 		stateLessGates[gate.id] = stateLess as StatelessRecipeWidget;
// 	});

// 	state.gates = safeJsonClone(stateLessGates);
// };



export default {
	addCustomWidgetReducer,
	editCustomWidgetReducer,
	setShowCreateWidgetModalReducer,
	showCreateWidgetBundleModal,
	// addWidgetFromTemplateReducer
};
