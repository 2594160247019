import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { Recipe , TransferProgressEvent } from '@kemu-io/kemu-core/types';
import { storageDiskToMap } from '@kemu-io/kemu-core/common/utils';
import { RecipeType } from '@kemu-io/kemu-types';
import { NavigateFunction } from 'react-router-dom';
import { InterfaceState, setRecipeDownloadProgress } from '../interfaceSlice';
import { clearInstallationProgress, setRecipeType } from '../../Workspace/workspaceSlice';
import * as recipeApi from '@src/api/recipe/recipeApi';
import { openRecipeAction } from '@src/app/reducers/workspace/openRecipeReducer';
import routes from '@src/common/routes/index';
import { AsyncRequestStatus } from '@src/types/core_t';
import { UseAlertInstance } from '@components/alert/useAlert';

/**
 * Downloads the full contents of a recipe and its storage
 * triggering notification events.
 */
export const downloadRecipeAction = createAsyncThunk<void, {recipeId: string, navigate: NavigateFunction, alert: UseAlertInstance }>(
  '/Interface/downloadRecipe', async (options, thunkAPI) => {
    try {
      // Get Signed URLs
      const recipeInfo = await recipeApi.getRecipeById(options.recipeId);
      // 1.3 support for dynamic ports and changes the way port references are stored
      if (recipeInfo?.protocolVersion && Number(recipeInfo.protocolVersion) < 1.3) {
        options.alert.error({
          title: 'Recipe No Compatible',
          content: 'Kemu has introduced significant updates since this recipe was saved and it is no longer compatible.',
          okText: 'Close'
        });
        return;
      }


      const calculatePercentage = (index: number, evt: TransferProgressEvent) => {
        const info = {
          [!index ? 'content' : 'storage']: evt.percentage,
        };

        if (!recipeInfo.storage) {
          info.storage = 100;
        }

        thunkAPI.dispatch(setRecipeDownloadProgress(info));
      };


      // Download contents
      const downloads: Promise<Recipe | ArrayBuffer>[] = [
        recipeApi.downloadRecipeFile<Recipe>(recipeInfo.contents, 'json', calculatePercentage.bind(null, 0))
      ];

      if (recipeInfo.storage) {
        downloads.push(
          recipeApi.downloadRecipeFile<ArrayBuffer>(recipeInfo.storage, 'arraybuffer', calculatePercentage.bind(null, 1))
        );
      }

      // Wait for all downloads to complete
      const results = await Promise.all(downloads);
      const recipe: Recipe = <Recipe> results[0];
      // It is possible for recipe contents to lack an id. It happens when the recipe is first saved
      // because the id is given by the server but the 'content' section of the recipe, which is the actual
      // recipe data that resides in S3, is created by the client, and during saving time, not given an id.
      // Here we make sure the recipe contents always have an id. 
      // Update 20/Feb/2021: In fact, we should never trust the id inside the content's file, since it could
      // even point to a marketplace recipe this recipe was copied from.
      recipe.id = options.recipeId;

      // Make sure the name displayed comes from the db entity (very important for cases when the recipe
      // was cloned, then the name in the contents will be the old recipe's name);
      recipe.name = recipeInfo.name;

      const recipeCombinedDisk = <ArrayBuffer> results[1];
      // Parse the recipe contents
      // const recipe: Recipe = JSON.parse(recipeJSON);
      // If storage is available, split it into storage units.
      if (recipeCombinedDisk) {
        // TODO: Checksum?
        recipe.storage = storageDiskToMap(recipeCombinedDisk);
      }

      // Clear any previous download indicator
      thunkAPI.dispatch(clearInstallationProgress('all'));

      // Clear the bundle path that might be part of the recipe (older recipes, 
      // bundle is as of 01/Nov/2021 actively removed during saving), 
      // to force using a version pointed by either the cache or the dev blocks.
      const recipeThingsIds = Object.keys(recipe.blocks);

      recipeThingsIds.forEach(blockKey => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        delete (recipe.blocks[blockKey] as any).bundle;
      });

      // Recipes might have been created with older version of Things
      // here we find the latest installed version and replace the details of
      // the thing in the recipe.
      // for (const thingId of recipeThingsIds) {
      //   const thing = recipe.blocks[thingId];
      //   const compatibleVersion = await getCompatibleVersionInstalled(thing.id, thing.version);
      //   thing.version = compatibleVersion;
      // }

      // Check blocks in dev mode from this recipe
      // overrideDevThings(recipe.blocks);

      // eww, "as unknown as Record"? ugly as...
      // const installationResults = await downloadThingBundlesInParallel(recipe.blocks as unknown as Record<string, LimitedThingInfo>, (blockRecipeId, download) => {
      //   if (!download.errorMsg) {
      //     thunkAPI.dispatch(setInstallationProgress({
      //       id: recipe.blocks[blockRecipeId].id,
      //       block: recipe.blocks[blockRecipeId] as unknown as LimitedThingInfo,
      //       progress: download.progress!,
      //       version: recipe.blocks[blockRecipeId].version,
      //     }));
      //   } else if (download.errorMsg) {
      //     thunkAPI.dispatch(setInstallationProgress({
      //       id: recipe.blocks[blockRecipeId].id,
      //       block: recipe.blocks[blockRecipeId] as unknown as LimitedThingInfo,
      //       errorMsg: download.errorMsg,
      //       version: recipe.blocks[blockRecipeId].version,
      //     }));
      //   }
      // });

      const successfullyInstalled = [];
      // installationResults.forEach(result => {
      //   if (result.status === 'fulfilled') {
      //     // Clear out the progress of those that downloaded OK
      //     thunkAPI.dispatch(clearInstallationProgress({
      //       id: result.value.id,
      //       version: result.value.version,
      //     }));
      //     successfullyInstalled.push(recipe.blocks[result.value.id]);
      //   }
      // });



      // Make sure ALL blocks were successfully downloaded
      // if (successfullyInstalled.length === Object.keys(recipe.blocks).length) {

        // if (recipeInfo.linkedTutorial) {
        //   thunkAPI.dispatch(setActiveTutorialInfo({
        //     id: recipeInfo.linkedTutorial,
        //     isDraft: false,
        //     notificationShown: false,
        //     step: 0,
        //   }));
        // } else {
        //   // Clear any reference of a previous tutorial
        //   thunkAPI.dispatch(setActiveTutorialInfo(null));
        // }

        // Registers the recipe with Kemu Core and initialize it.
        await thunkAPI.dispatch(
          openRecipeAction({
            recipe: recipe,
            isDefault: false,
            dbInfo: {
              id: recipeInfo.id,
              authorId: recipeInfo.author.id,
              version: recipeInfo.version,
              recipeType: recipeInfo.cloud ? RecipeType.Cloud : RecipeType.Browser,
            }
          })
        );

        // Navigate back to app depending on the type of recipe
        if (recipeInfo.cloud) {
          thunkAPI.dispatch(setRecipeType({
            type: RecipeType.Cloud,
            cloudInfo: recipeInfo.cloud,
          }));

          options.navigate(routes.recipe.getCloudRecipeRoute(recipeInfo.id));
        } else {
          thunkAPI.dispatch(setRecipeType({
            type: RecipeType.Browser,
            cloudInfo: null
          }));

          options.navigate(routes.recipe.getBrowserRecipeRoute(recipeInfo.id));
        }
      // } else {
      //   // console.log('Cannot open recipe, some blocks are not currently installed');
      //   throw new Error(`Some blocks are not currently installed`);
      // }

    } catch (e) {
      console.error('Error downloading recipe: ', e);
      throw e;
    }
  }
);

export const downloadRecipeActionReducer = ((builder: ActionReducerMapBuilder<InterfaceState>): void => {
  builder.addCase(downloadRecipeAction.pending, (state) => {
    state.recipeDownloadDetails = {
      ...state.recipeDownloadDetails,
      contentProgress: 0,
      storageProgress: 0,
      status: AsyncRequestStatus.loading
    };
  });

  builder.addCase(downloadRecipeAction.fulfilled, (state) => {
    state.recipeDownloadDetails = {
      ...state.recipeDownloadDetails,
      status: AsyncRequestStatus.completed,
      error: undefined
    };
  });

  builder.addCase(downloadRecipeAction.rejected, (state, action) => {
    state.recipeDownloadDetails = {
      ...state.recipeDownloadDetails,
      status: AsyncRequestStatus.error,
      error: action.error
    };
  });
});
