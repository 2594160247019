import React, { useMemo } from 'react';
import classNames from 'classnames';
import styles from './FieldLabel.module.css';
import { calculateWidgetColors } from '@components/gates/common';

type Props = {
  color?: string;
  label?: string;
  noBottomMargin?: boolean;
}

const FieldLabel = (props: Props) => {
  const { color, label } = props;
  const colors = color ? calculateWidgetColors(color) : undefined;

  if (!label?.length) { return null; }

  return (
    <label className={classNames(styles.Label, {
      [styles.DarkLabel]: colors?.isBrightColor,
      [styles.NoMb]: props.noBottomMargin,
    })}>
      {props.label}
    </label>
  );
};

export default FieldLabel;
