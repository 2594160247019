import axios from 'axios';
import { API } from '@aws-amplify/api';
import {
	CloneRecipeResponse,
	SaveRecipeRequestBody,
	SaveRecipeResponse,
	UpdateRecipeResponse,
	RecipeBaseEntity,
	RecipeDbEntity,
	RecipeLimitedPublicInfo,
	SharedWithUser,
	SaveRecipeRequestInfo,
} from '@kemu-io/kemu-types';
import { TransferProgressEvent } from '@kemu-io/kemu-core/types';
import { RecipeResponse } from '@src/types/core_t';
import globals from '@common/globals';


const API_NAME = globals.RECIPE_API_NAME;
// Forces to return the entire Axios response object instead of only response.data
const returnAxiosResponse = { response: true };

// type BaseRecipeWithoutVersion = Omit<RecipeBaseEntity, 'version'>;

/**
 * Gets the recipe data from the database
 * @param id the id of the recipe in the database
 */
const getRecipeById = async (id: string): Promise<RecipeDbEntity> => {
	const recipe = await API.get(API_NAME, `/recipe/${id}`, returnAxiosResponse);
	return recipe.data;
};

// export interface SaveRecipeResponse {
// 	recipeId: string;
// 	uploadUrl?: string;
// }


/**
 * Store a new recipe info in the DB. 
 * IMPORTANT: This is just part of the process, you need to upload the contents and storage files
 * after the recipe info has been added to the DB.
 * @param recipeData the raw recipe to upload
 * @param userId
 * @param storageChecksum the sha1 of the storage drive or 0 if none exists
 * @returns a pre-signed url to upload the actual recipe contents.
 */
const saveRecipe = async (recipeData: SaveRecipeRequestInfo, contentLength: number, storageSize: number, storageChecksum: string): Promise<SaveRecipeResponse> => {
	const requestBody: SaveRecipeRequestBody = {
		recipe: {
			...recipeData,
			contentLength,
		},
		storageSize,
		storageChecksum
	};

	const response = await API.post(API_NAME, `/recipe`, { ...returnAxiosResponse, body: requestBody });
	return response.data as SaveRecipeResponse;
};


/**
 * Updates an existing recipe
 * @param recipeInfo 
 * @param recipeId the id of the recipe to be updated.
 * @param storageChecksum the sha1 of the storage drive or 0 if none exists
 * @returns the new id of the recipe in the database
 */
const updateRecipe = async (recipeInfo: SaveRecipeRequestInfo, recipeId: string, contentLength: number, storageSize: number, storageChecksum: string): Promise<UpdateRecipeResponse> => {
	const requestBody: SaveRecipeRequestBody = {
		recipe: {
			...recipeInfo,
			contentLength,
		},
		storageSize,
		storageChecksum
	};

	const response = await API.put(API_NAME, `/recipe/${recipeId}`, { ...returnAxiosResponse, body: requestBody });
	return response.data as UpdateRecipeResponse;
};


const deleteRecipe = async (recipeId: string): Promise<void> => {
	await API.del(API_NAME, `/recipe/${recipeId}`, returnAxiosResponse);
};

/**
 * Returns a list of recipes from the current user
 */
const getAllRecipes = async (): Promise<RecipeResponse[]> => {
	const recipes = await API.get(API_NAME, `/recipes`, returnAxiosResponse);
	return recipes.data;
};


/**
 * Downloads a file with progress. 
 * @param presignedUrl a pre-signed S3 url
 * @param onProgressCb a method to call with the progress events of the download
 * @returns the recipe in JSON format
 */
const downloadRecipeFile = async <T>(
	presignedUrl: string,
	responseType: 'json' | 'arraybuffer',
	onProgressCb?: (evt: TransferProgressEvent) => void
): Promise<T> => {
	const results = await axios.get(`${presignedUrl}`, {
		responseType,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onDownloadProgress: (evt: any) => {
			if (onProgressCb) {
				const percentage = Math.round((evt.loaded / evt.total) * 100);
				onProgressCb({ ...evt, percentage });
			}
		}
	});

	return results.data;
};


/**
 * Uploads a recipe file to S3
 * @param presignedUrl a pre-signed URL for the upload
 * @param onProgressCb a method to call with the progress events of the upload
 */
const uploadRecipeFile = async (
	presignedUrl: string,
	data: ArrayBuffer,
	onProgressCb?: (evt: TransferProgressEvent) => void
): Promise<void> => {
	const results = await axios.put(`${presignedUrl}`, data, {
		// NOTE: this MUST match the content type set by the service when creating the pre-signed url
		headers: { 'Content-Type': 'application/octet-stream' },
		onUploadProgress: (evt) => {
			if (onProgressCb) {
				const total = evt.total || 1;
				const percentage = Math.round((evt.loaded / total) * 100);
				onProgressCb({
					loaded: evt.loaded,
					percentage,
					total,
				});
			}
		}
	});

	return results.data;
};

// TODO: how to reply if the server fails because user doesn't exist
const addSharedUser = async (recipeId: string, userEmail: string | undefined, accessMode: string | undefined, notificationMessage?: string,  addIfNoneExists=false): Promise<SharedWithUser[]> => {
	const response = await API.post(API_NAME, `/recipe/${recipeId}/addUser`, {
		...returnAxiosResponse,
		body: {
			userEmail,
			accessMode,
			notificationMessage ,
			addIfNoneExists
		}
	});

	return response.data;
};

const removeSharedUser = async (recipeId: string, userEmail: string ): Promise<SharedWithUser[]> => {
	const response = await API.post(API_NAME, `/recipe/${recipeId}/removeUser`, {
		...returnAxiosResponse,
		body: {
			userEmail,
		}
	});

	const remainingList = response.data;
	return remainingList;
};

/**
 * Enables/Disables link sharing.
 * @param recipeId 
 * @param enable 
 */
const toggleLinkShare = async (recipeId: string, enable: boolean): Promise<string> => {
	const response = await API.post(API_NAME, `/recipe/${recipeId}/linkShare`, {
		...returnAxiosResponse,
		body: {
			linkSharing: enable
		}
	});

	return response.data?.publicSharedId;
};

type UpdatableDetails = Omit<RecipeBaseEntity, 'id' | 'storage' | 'linkedTutorial'>;
const patchRecipe = async (recipeId: string, details: Partial<UpdatableDetails>): Promise<RecipeDbEntity> => {
	const response = await API.patch(API_NAME, `/recipe/${recipeId}`, { ...returnAxiosResponse, body: details });
	return response.data as RecipeDbEntity;
};

/**
 * Creates a link between the given recipe and tutorial
 */
const linkTutorial = async (recipeId: string, tutorialId: string): Promise<RecipeDbEntity> => {
	const response = await API.post(API_NAME, `/recipe/${recipeId}/link/${tutorialId}`, returnAxiosResponse);
	return response.data as RecipeDbEntity;
};

/**
 * Removes an existing link between a recipe and a tutorial 
 */
const unlinkTutorial = async (recipeId: string, tutorialId: string): Promise<RecipeDbEntity> => {
	const response = await API.del(API_NAME, `/recipe/${recipeId}/link/${tutorialId}`, returnAxiosResponse);
	return response.data as RecipeDbEntity;
};

/**
 * Creates a copy of the given recipe
 * @param recipeId 
 */
const cloneRecipe = async (recipeId: string, name?: string): Promise<RecipeDbEntity> => {
	const request: Record<string, unknown> = { ...returnAxiosResponse };
	if (name) {
		request.body = { name };
	}
	const response = await API.post(API_NAME, `/recipe/${recipeId}/clone`, request);
	return response.data as RecipeDbEntity;
};

const getPublicRecipeInfo = async (publicId: string): Promise<RecipeLimitedPublicInfo> => {
	const recipe = await API.get(API_NAME, `/recipe/public/${publicId}`, returnAxiosResponse);
	return recipe.data as RecipeLimitedPublicInfo;
};

const importPublicRecipe = async (publicId: string, name?: string): Promise<CloneRecipeResponse> => {
	const request: Record<string, unknown> = { ...returnAxiosResponse };
	if (name) {
		request.body = { name };
	}
	const response = await API.post(API_NAME, `/recipe/${publicId}/import`, request);
	return response.data as CloneRecipeResponse;
};

export {
	getRecipeById,
	getAllRecipes,
	saveRecipe,
	updateRecipe,
	deleteRecipe,
	downloadRecipeFile,
	uploadRecipeFile,
	addSharedUser,
	toggleLinkShare,
	removeSharedUser,
	patchRecipe,
	linkTutorial,
	unlinkTutorial,
	cloneRecipe,
	importPublicRecipe,
	getPublicRecipeInfo
};
