import { API } from '@aws-amplify/api';
import {
	CloneWidgetResponse,
	CustomWidgetDbEntity,
	DeleteWidgetResponse,
	GetPublicWidgetInfoResponse,
	GetSingleWidgetResponse,
	GetUserWidgetsResponse,
	SaveAsWidgetRequest,
	SaveAsWidgetResponse,
	SaveCustomWidgetRequest,
	SaveCustomWidgetResponse,
	ShareWidgetResponse,
	UpdateWidgetRequest,
	UpdateWidgetResponse
} from '@kemu-io/kemu-types';
import { TransferProgressEvent } from '@kemu-io/kemu-core/types';
import axios from 'axios';
import globals from '../../common/globals';

const API_NAME = globals.WIDGET_API_NAME;

// Forces to return the entire Axios response object instead of only response.data
const returnAxiosResponse = { response: true };

/**
 * Creates a new widget and associate it to the current user profile.
 * It returns an object with an 'uploadUrl' property that must be used to upload the
 * actual contents to storage.
 * @param widgetInfo 
 */
const saveNewWidget = async (widgetInfo: SaveCustomWidgetRequest): Promise<SaveCustomWidgetResponse> => {
	// POST /widget
	const response = await API.post(API_NAME, `/widget`, { ...returnAxiosResponse, body: widgetInfo });
	const data = response.data as SaveCustomWidgetResponse;
	return data;
};

/**
 * Returns a list of widgets for the current user with the 'contents' property
 * as a pre-signed url to download the actual contents. It is HIGHLY recommended
 * to download this contents and store them locally as soon as possible.
 * @returns 
 */
const getUserWidgets = async (): Promise<CustomWidgetDbEntity[]> => {
	const response = await API.get(API_NAME, `/widget`, returnAxiosResponse);
	const data = response.data as GetUserWidgetsResponse;
	return data.widgets;
};

/**
 * Downloads the widget's contents or .zip bundle file.
 * @param id the id of the widget in the database
 * @param version Optional. The version of the widget to download. If not provided, the latest version will be downloaded.
 * @returns the contents of the widget as a Uint8Array
 */
const downloadWidgetContents = async (
	id: string,
	version?: string | number,
	onProgressCb?: (evt: TransferProgressEvent) => void,
): Promise<Uint8Array> => {
	// - GET /widget/download/{widgetId}/v/{version}
	// - GET /widget/download/{widgetId}
	const url = version ? `/widget/download/${id}/v/${version}` : `/widget/download/${id}`;

	// We avoid redirects because there is no reliable way to change the request
	// headers before the redirect happens, which is required to remove the Authorization header.
	// as S3 doesn't support Authorization header in pre-signed urls.
	// https://stackoverflow.com/questions/35400943/how-to-remove-authorization-header-in-a-http-302-response

	// The first request needs to be authenticated using the user's token
	const { data: preSignedUrl } = await API.get(API_NAME, `${url}?noRedirect=true`, {
		...returnAxiosResponse,
	});

	// The second request needs to be anonymous since it is a pre-signed url.
	if (typeof preSignedUrl !== 'string') {
		throw new Error(`Invalid pre-signed url: ${preSignedUrl}`);
	}


	const response = await axios({
		method: 'GET',
		url: preSignedUrl,
		responseType: 'arraybuffer',
		headers: {},
		maxRedirects: 1,
		onDownloadProgress: ((evt) => {
			if (onProgressCb) {
				const total = evt.total || 1;
				const percentage = Math.round((evt.loaded / total) * 100);
				onProgressCb({
					loaded: evt.loaded,
					total: total,
					percentage
				});
			}
		})
	});

	const data = response.data as Uint8Array;
	return data;
};

/**
 * Gets in formation about a single widget. The 'contents' property will contain
 * a pre-signed url to download the actual contents. 
 * IMPORTANT: Don't download the widget contents, instead use the `importPublicWidget` api call 
 * to import the widget into the user's collection.
 * 
 * @param publicId the id of a publicly shared widget.
 */
const getWidgetInfoByPublicId = async (publicId: string): Promise<GetPublicWidgetInfoResponse> => {
	const response = await API.get(API_NAME, `/widget/public/${publicId}`, returnAxiosResponse);
	const data = response.data as GetPublicWidgetInfoResponse;
	return data;
};

/**
 * Gets in formation about a single widget. The 'contents' property will contain
 * a pre-signed url to download the actual contents. 
 * IMPORTANT: Don't download the widget contents, instead use the `importPublicWidget` api call 
 * to import the widget into the user's collection.
 * 
 * @param publicId the id of a publicly shared widget.
 */
const getWidgetInfo = async (widgetId: string): Promise<CustomWidgetDbEntity> => {
	const response = await API.get(API_NAME, `/widget/${widgetId}`, returnAxiosResponse);
	const data = response.data as GetSingleWidgetResponse;
	return data.widget;
};

/**
 * Updates an existing widget
 */
const updateWidget = async (widgetId: string, widgetInfo: UpdateWidgetRequest): Promise<UpdateWidgetResponse> => {
	const response = await API.put(API_NAME, `/widget/${widgetId}`, { ...returnAxiosResponse, body: widgetInfo });
	const data = response.data as UpdateWidgetResponse;
	return data;
};

/**
 * Deletes an existing widget
 */
const deleteWidget = async (widgetId: string): Promise<DeleteWidgetResponse> => {
	const response = await API.del(API_NAME, `/widget/${widgetId}`, returnAxiosResponse);
	const data = response.data as DeleteWidgetResponse;
	return data;
};

/**
 * Enables/Disables link sharing
 */
const toggleLinkSharing = async (widgetId: string, enable: boolean): Promise<ShareWidgetResponse> => {
	// POST /widget/{widgetId}/linkShare/{action: 'enable' | 'disable'}
	const response = await API.post(API_NAME, `/widget/${widgetId}/linkShare/${enable ? 'enable' : 'disable'}`, returnAxiosResponse);
	const data = response.data as ShareWidgetResponse;
	return data;
};

/**
 * Creates a copy of the given widget and changes the name.
 * @param widgetId the id of widget entity to be duplicated. This is used so that any link tutorial is preserved
 * @param widgetInfo extra information about the info used to replace the current metadata in the cloned entity.
 */
const saveWidgetAs = async (widgetId: string, widgetInfo: SaveAsWidgetRequest): Promise<SaveAsWidgetResponse> => {
	// POST /widget/{widgetId}/saveAs
	const response = await API.post(API_NAME, `/widget/${widgetId}/saveAs`, { ...returnAxiosResponse, body: { ...widgetInfo } });
	const data = response.data as SaveAsWidgetResponse;
	return data;
};

/**
 * Adds a public widget to the user account
 */
const importPublicWidget = async (publicId: string): Promise<CustomWidgetDbEntity> => {
	// POST /widget/{widgetId}/saveAs
	const response = await API.post(API_NAME, `/widget/${publicId}/import`, returnAxiosResponse);
	const data = response.data as CloneWidgetResponse;
	return data.widget;
};

export {
	getUserWidgets,
	getWidgetInfoByPublicId,
	updateWidget,
	saveNewWidget,
	saveWidgetAs,
	importPublicWidget,
	deleteWidget,
	toggleLinkSharing,
	downloadWidgetContents,
	getWidgetInfo,
};
