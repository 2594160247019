import React, { forwardRef } from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { FilterItem } from '@kemu-io/kemu-core/widgets/imageFilter/index.js';
import styles from './FilterRow.module.css';

type Props = {
  filter: FilterItem;
  handleRemoveFilter: (id: string) => void;
  handleValueMouseDown: (e: React.MouseEvent<HTMLDivElement>) => void;
  handleDoubleClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  editingValue: FilterItem | null;
  setEditingValue: (value: FilterItem) => void;
  clearEditingValue: () => void;
  setState: React.Dispatch<React.SetStateAction<{ filters: FilterItem[] }>>;
}

const FilterRow = (props: Props) => {
  const {
    filter, editingValue,
    handleDoubleClick, setState, clearEditingValue, setEditingValue, handleValueMouseDown, handleRemoveFilter
  } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id: filter.id });

  const handleRowClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if ((e.target as HTMLElement)?.tagName !== 'INPUT') {
      clearEditingValue();
    }
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className={styles.FilterItem}
      onClick={handleRowClick}
      ref={setNodeRef}
      style={style}
    >
      <div className={styles.Dragger} ref={setActivatorNodeRef} {...listeners} {...attributes}>::</div>
      <div className={styles.FilterName}>{filter.name}</div>

      {editingValue?.id === filter.id ? (
        <>
        (<span>
          <input
            autoFocus
            className={styles.FilterValueInput}
            value={editingValue?.value}
            onChange={(e) => setEditingValue({ ...filter, value: Number(e.target.value) })}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setState((s) => {
                  return {
                    ...s,
                    filters: s.filters.map((f) => f.id === filter.id ? { ...f, value: Number(editingValue?.value) } : f),
                  };
                });
                clearEditingValue();
              } else if (e.key === 'Escape') {
                clearEditingValue();
              }
            }}
          />
        </span>)
        </>
      ) : (
        <div
          className={styles.FilterValue}
          data-filter-id={filter.id}
          onMouseDown={handleValueMouseDown}
          onDoubleClick={handleDoubleClick}
        >
          (<span>{filter.value}{filter.name === 'blur' ? 'px' : ''}</span>)
        </div>
      )}

      <div className={styles.RemoveBtn}>
        <Button size='small' shape="circle" icon={<CloseOutlined />} onClick={() => handleRemoveFilter(filter.id)}/>
      </div>
    </div>
  );
};

export default FilterRow;
