import React from 'react';
import { Badge } from 'antd';
import { PublicationState } from '@kemu-io/kemu-types';
import styles from './CardRibbon.module.css';

interface Props {
	text?: React.ReactNode;
	children?: React.ReactNode;
	color?: string;
	state?: PublicationState;
	className?: string;
}

const CardRibbon = (props: Props): React.JSX.Element => {
	return (
		<Badge.Ribbon
			color={props.color}
			text={props.text}
			className={`${props.text ? `${styles.Ribbon} ${props.state ? props.state : ''}` : styles.NoRibbon} ${props.className || ''}`}
		>
			{props.children}
		</Badge.Ribbon>
	);
};

export default CardRibbon;
