
import React from 'react';
import Icon from '@ant-design/icons';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { IntlShape } from 'react-intl';
import { Data, DataType } from '@kemu-io/hs-types';
import { selectCurrentRecipe, selectedBlock } from '../../../features/Workspace/workspaceSlice';
import { GetPortsInformationFunction, GateUI, GateUIProps } from '../index';
import GateIcon from '../../gateIcon/gateIcon';
import { invokeNextGate } from '../../../app/recipe/utils';
import { ReactComponent as ButtonWidgetIcon } from './button.svg';
import style from './play.module.css';
interface Props extends GateUIProps {
	value?: string;
}


const ButtonWidget = (props: Props): React.JSX.Element => {
	const currentRecipe = useSelector(selectCurrentRecipe);
	const currentBlock = useSelector(selectedBlock);

	const onExecute = async () => {
		if (currentRecipe.poolId && currentBlock && !props.info.disabled) {
			const event: Data = {
				timestamp: Date.now(),
				type: DataType.Boolean,
				value: true
			};
			// Normally don't do this (assume you know the name of the port), but this gate is unlikely to change
			await invokeNextGate(
				currentRecipe.poolId,
				currentBlock.recipeId,
				props.thingDbId,
				props.thingVersion,
				props.info.id,
				'out',
				event,
				event
			);
		}
	};

	return (
		<div className="play-gate is-narrow">
			<div className={style.ButtonContainer}>
				<Button
					disabled={props.info.disabled}
					onClick={onExecute}
					type="link"
					block
					icon={<Icon component={ButtonWidgetIcon} style={{ fontSize: 32 }}/>}
					className={style.Button}
				/>
			</div>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={ButtonWidgetIcon} />}/>
	);
};



const getPortsInformation: GetPortsInformationFunction = () => {
	// Play/button widgets have no processor, they are only used by the web interface, thus have no processor
	return {
		outputs: [{
			position: 'Right',
			name: 'out',
			type: DataType.Boolean,
		}],

		inputs: [],
	};
};

const getTitle = (intl: IntlShape) => intl.formatMessage({ defaultMessage: 'Button', id: 'LogicMaker.Widgets.Button.Title' });

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: ButtonWidget,
	hasTitle: true,
	getWidgetTitle: getTitle,
	getGatesBarTitle: getTitle,
} as GateUI;
