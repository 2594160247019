/*
 * Written by Alexander Agudelo < alex@kemu.io >, 2020
 * Date: 21/Dec/2020
 * Last Modified: 26/06/2024, 8:10:19 pm
 * Modified By: Alexander Agudelo
 * Description:  A communication bridge between block Canvas instances and the Properties Panel
 * 
 * ------
 * Copyright (C) 2020 Kemu - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */
import { EventBridgeData, EventBridgeHandler } from '../../types/core_t';

interface InstanceMap {
	[blockRecipeId: string]: {
		canvasInstanceListener?: EventBridgeHandler;
		propsPanelInstanceListener?: EventBridgeHandler;
	}
}

const instancesMap: InstanceMap = {};
// Stores references to instances maps by block db id
const instanceTypePointer: Record<string, string[]> = {};

/**
 * Sends data to a canvas instance
 * @param blockId is the id of the block in the recipe
 * @param evt any block event
 **/
const emitToCanvas = async (blockId: string, evt: EventBridgeData): Promise<unknown> => {
	if (!instancesMap[blockId]) { return null; }
	const instanceFun = instancesMap[blockId].canvasInstanceListener;
	if (instanceFun) {
		return instanceFun(evt);
	}
};

/**
 * Sends the given event to ALL instances of a block identified by their database id
 * in 'parallel'.
 * @param blockDbId the id of the block in the database
 * @param evt the event to emit
 */
const emitToAllCanvas = async (blockDbId: string, blockVersion: string, evt: EventBridgeData): Promise<void> => {
	if (!instanceTypePointer[`${blockDbId}_${blockVersion}`]) { return; }
	const promises = instanceTypePointer[`${blockDbId}_${blockVersion}`].map(blockRecipeId => emitToCanvas(blockRecipeId, evt));
	await Promise.all(promises);
};

const emitToPropsPanel = async (blockId: string, evt: EventBridgeData): Promise<unknown> => {
	if (!instancesMap[blockId]) { return null; }
	const instanceFun = instancesMap[blockId].propsPanelInstanceListener;
	if (instanceFun) {
		return instanceFun(evt);
	}
};

const _deleteIfEmpty = (blockId: string) => {
	if (instancesMap[blockId]) {
		if (!instancesMap[blockId].canvasInstanceListener && !instancesMap[blockId].propsPanelInstanceListener) {
			delete instancesMap[blockId];
		}
	}
};

/**
 * Registers an event listener to pipe data to canvas instances.
 * NOTE: the 'purpose' of this method is conceptual only. In reality this method does not
 * interact with (or know of) actual UI instances.
 * @param blockDbId the id of the block in the database
 * @param blockVersion the version number of the block (needed because multiple versions of a block share the same dbId)
 * @param blockId the id of the block in the recipe (block.recipeId)
 * @param cb a function to invoke when the canvas has produced data
 */
const setCanvasInstanceListener = (blockDbId: string, blockVersion: string, blockId: string, cb: EventBridgeHandler | null): void => {
	if (cb === null) {
		if (instancesMap[blockId]) {
			delete instancesMap[blockId].canvasInstanceListener;
			instanceTypePointer[`${blockDbId}_${blockVersion}`] = (instanceTypePointer[`${blockDbId}_${blockVersion}`] || []).filter(recipeId => recipeId !== blockId);
		}
	} else {
		instancesMap[blockId] = {
			...instancesMap[blockId],
			canvasInstanceListener: cb
		};

		// Store pointer from dbId to recipeId 
		instanceTypePointer[`${blockDbId}_${blockVersion}`] = [
			...(instanceTypePointer[`${blockDbId}_${blockVersion}`] ? instanceTypePointer[`${blockDbId}_${blockVersion}`] : []),
			blockId
		];
	}

	_deleteIfEmpty(blockId);
};

const setPropsPanelListener = (blockId: string, cb: EventBridgeHandler | null): void => {
	if (cb === null) {
		if (instancesMap[blockId]) {
			delete instancesMap[blockId].propsPanelInstanceListener;
		}
	} else {
		instancesMap[blockId] = {
			...instancesMap[blockId],
			propsPanelInstanceListener: cb
		};
	}

	_deleteIfEmpty(blockId);
};

export default {
	emitToCanvas,
	emitToAllCanvas,
	setCanvasInstanceListener,
	setPropsPanelListener,
	emitToPropsPanel
};
