
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Ace } from 'ace-builds';
import AceEditor from 'react-ace';
import Icon, {
	SaveFilled,
	PlayCircleFilled,
	PauseCircleFilled,
	BugFilled,
} from '@ant-design/icons';
import ScriptWidgetProcessor, {
	COMPILE_COMMAND,
	setLogger
} from '@kemu-io/kemu-core/widgets/script/index.js';
import {
	ScriptWidgetState,
	ConsoleLine,
} from '@kemu-io/kemu-core/widgets/script/types.js';
import { DEFAULT_MAIN_PAGE_ID, getDefaultState } from '@kemu-io/kemu-core/widgets/script/common.js';
import { CustomWidgetState, WidgetPortContext } from '@kemu-io/kemu-core/types';
import { Button, Tooltip, message } from 'antd';
import { format } from 'date-fns';
import { WebsocketCommandStatus } from '@kemu-io/kemu-types';
import { Data, DataType } from '@kemu-io/hs-types';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import {
	GateCustomSettingsProps,
	GetPortsInformationFunction,
	GateUI,
	GateUIProps
} from '../index.ts';
import { customAceEditorCompleter, setCompletionContext } from './autoComplete';
import { ReactComponent as ScriptWidgetIcon } from './icon.svg';
import { ReactComponent as ConsoleIcon } from './console.svg';
import { ReactComponent as ClearConsoleIcon } from './clearConsole.svg';
import styles from './script.module.css';
import StandardModal from '@components/modal/standardModal';
import FormGroup from '@components/form-control/formGroup/formGroup';
import GateIcon from '@components/gateIcon/gateIcon';
import { selectLastInstallDependencyCommand } from '@src/app/reducers/websocket/websocketSlice';
import { SITE_LAYOUT_RIGHT_SECTION_CLASS } from '@common/constants';
import { selectCurrentRecipe, selectedBlock } from '@src/features/Workspace/workspaceSlice';
import { invokeWidgetProcessor } from '@src/app/recipe/utils';
import { PortLocation } from '@src/types/canvas_t';
import { copyPlainText } from '@src/features/LogicMapper/reducers/clipboardUtils';
import useTranslation from '@hooks/useTranslation';

// IMPORTANT: Never uncomment this, it causes hundreds of js files to be added to the build and slows down the service worker install process
// leaving it here for reference only.
// import 'ace-builds/webpack-resolver';

import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';
import globals from '@common/globals.ts';
// import DependenciesModal from './DependenciesModal';

type Props = GateUIProps

if (globals.STAGE === 'development') {
	setLogger(console);
}

const ScriptWidget = (props: Props): React.JSX.Element => {
	const [state] = useReactiveWidgetState<ScriptWidgetState>(props.recipeId, props.thingRecipeId, props.info.id);

	const fixedState: ScriptWidgetState = {
		...getDefaultState(),
		...state,
	};

	return (
		<div className={classNames(styles.GateBody, {
			[styles.WidgetEnabled]: !props.info.disabled
		})}>
			{!!fixedState.$$error && (
				<div className={styles.BugIconContainer}>
					<Tooltip title={fixedState.$$error.message}>
						<BugFilled className={styles.BugIcon}/>
					</Tooltip>
				</div>
			)}
			<Icon component={ScriptWidgetIcon} className={styles.Icon} />
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={ScriptWidgetIcon} />}/>
	);
};

const handleCopyLine = (evt: React.MouseEvent<HTMLSpanElement, MouseEvent>, line: ConsoleLine, onCopied?: () => void) => {
	if (evt.currentTarget) {
		copyPlainText(typeof line.text === 'string' ? line.text : JSON.stringify(line.text));
		evt.currentTarget.classList.remove(styles.ClickedConsoleLineText);
		void evt.currentTarget.offsetWidth;
		evt.currentTarget.classList.add(styles.ClickedConsoleLineText);
		onCopied && onCopied();
	}
};

// https://github.com/ajaxorg/ace/wiki/Default-Keyboard-Shortcuts
const editorCommands = [
	{  name: 'blockoutdent', exec: 'blockoutdent', bindKey: { win: 'Ctrl-[', mac: 'Command-[' }, },
	{  name: 'blockindent', exec: 'blockindent', bindKey: { win: 'Ctrl-]', mac: 'Command-]' }, },

	{  name: 'copylinesup', exec: 'copylinesup', bindKey: { win: 'Alt-Shift-Up', mac: 'Alt-Shift-Up' }, },
	{  name: 'copylinesdown', exec: 'copylinesdown', bindKey: { win: 'Alt-Shift-Down', mac: 'Alt-Shift-Down' }, },

	{  name: 'movelinesup', exec: 'movelinesup', bindKey: { win: 'Alt-Up', mac: 'Alt-Up' }, },
	{  name: 'movelinesdown', exec: 'movelinesdown', bindKey: { win: 'Alt-Down', mac: 'Alt-Down' }, },

	{  name: 'addCursorAbove', exec: 'addCursorAbove', bindKey: { win: 'Alt-Ctrl-Up', mac: 'Alt-Command-Up' }, },
	{  name: 'addCursorBelow', exec: 'addCursorBelow', bindKey: { win: 'Alt-Ctrl-Down', mac: 'Alt-Command-Down' }, },

	{  name: 'selectMoreAfter', exec: 'selectMoreAfter', bindKey: { win: 'Ctrl-D', mac: 'Command-D' }, },
	{  name: 'findAll', exec: 'findAll', bindKey: { win: 'Shift-Ctrl-L', mac: 'Shift-Command-L' }, },

];

/**
 * Some of the state properties of the script reside in the $$private section,
 * but changes to these properties are normally ignored by the useReactiveWidgetState hook.
 */
const statesDiffer = (prevState: ScriptWidgetState, newState: ScriptWidgetState): boolean => {
	const prevCode = prevState.pages[DEFAULT_MAIN_PAGE_ID].code;
	const currCode = newState.pages[DEFAULT_MAIN_PAGE_ID].code;

	const consoleLinesChanged = prevState.$$consoleLines?.length !== newState.$$consoleLines?.length;
	const consoleVisibleChanged = prevState.consoleVisible !== newState.consoleVisible;
	const pauseExecutionChanged = prevState.pauseExecution !== newState.pauseExecution;

	return prevCode !== currCode
		|| (consoleLinesChanged && newState.consoleVisible)	// <== no point in re-rendering if the console is not showing
		|| consoleVisibleChanged
		|| pauseExecutionChanged;
};


const GateCustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
	const { repaintPorts, onClose } = props;
	const currentRecipe = useSelector(selectCurrentRecipe);
	const currentBlock = useSelector(selectedBlock);
	const installDependenciesCommand = useSelector(selectLastInstallDependencyCommand);
	const lastEscKeyPressedAt = useRef<number>(0);
	const [dependenciesModalVisible, setDependenciesModalVisible] = useState(false);
	const [state, setState] = useReactiveWidgetState<ScriptWidgetState>(props.recipeId, props.blockId, props.gateInfo.id, statesDiffer);
	const fixedState = useMemo(() => ({
		...getDefaultState(),
		...state
	}), [state]);

	const installDependenciesError = installDependenciesCommand?.status === WebsocketCommandStatus.Error;
	const mainPage = fixedState.pages[DEFAULT_MAIN_PAGE_ID];
	const [code, setCode] = useState(mainPage.code);
	const modalContainer = useMemo(() => document.querySelector<HTMLElement>(`.${SITE_LAYOUT_RIGHT_SECTION_CLASS}`), []);
	const [modalVisible, setModalVisible] = useState(true);
	const t = useTranslation('LogicMapper.Gates.Script.Settings');
	const isInstallingDependencies = installDependenciesCommand?.status === WebsocketCommandStatus.Pending;
	// const isInstallingDependencies = true;

	const handleSettingsClosed = useCallback(() => {
		setModalVisible(false);
		onClose();
	}, [onClose]);


	const handleAceLoaded = useCallback((editor: Ace.Editor) => {
		if (editor.completers) {
			// Remove default completers
			if (currentBlock) {
				const exists = editor.completers.find(c => c === customAceEditorCompleter);
				setCompletionContext({
					recipeId: currentBlock?.recipePoolId,
					thingRecipeId: currentBlock?.recipeId,
					widgetId: props.gateInfo.id,
				});

				if (!exists) {
					editor.completers?.push(customAceEditorCompleter);
				}
			}
			// editor.completers = [customAceEditorCompleter];
		}
	}, [currentBlock, props.gateInfo.id]);

	const handleAceChange = (value: string) => {
		setCode(value);
	};

	const onSaveScript = useCallback(async (withCode?: string) => {
		// Issue a re-compile command to the script widget.
		// We do that via a 'custom-port'
		if (currentRecipe.poolId && currentBlock && !props.gateInfo.disabled) {
			const commandEvent: Data = {
				timestamp: Date.now(),
				type: DataType.Anything,
				value: withCode || code
			};

			await invokeWidgetProcessor(
				props.gateInfo.id,
				currentBlock.recipeId,
				currentRecipe.poolId,
				COMPILE_COMMAND,
				commandEvent
			);

			repaintPorts();
			message.success(t('CodeSaved', 'Code Successfully Saved'));
		}
	}, [repaintPorts, currentRecipe, currentBlock, code, props.gateInfo.disabled, props.gateInfo.id, t]);

	const handleSaveShortcut = useCallback((editor: Ace.Editor) => {
		// Allow saving via shortcut even if the code is disabled
		setState({
			...fixedState,
			pauseExecution: false
		}, false);

		// When this method is invoked, 'code' still refers to the 
		// code when the ace editor was first mounted. Here we need
		// save using the latest version
		onSaveScript(editor.session.getValue());
	}, [onSaveScript, fixedState, setState]);

	const toggleWidgetDisabled = () => {
		setState({
			...fixedState,
			pauseExecution: !fixedState.pauseExecution
		});
	};



	const toggleConsoleVisibility = () => {
		setState({
			...fixedState,
			consoleVisible: !fixedState.consoleVisible
		});
	};

	const handleClearConsole = () => {
		setState({
			...fixedState,
			$$consoleLines: []
		});
	};

	const notifyCopied = useCallback(() => {
		const msg = t('CopiedToClipboardNotification', 'Link copied');
		message.success({
			duration: 1,
			content: msg,
		});
	}, [t]);

	const showDependenciesModal = () => {
		setDependenciesModalVisible(true);
	};

	const hideDependenciesModal = useCallback(() => {
		setDependenciesModalVisible(false);
	}, []);

	// Allows closing the code editor by pressing ESC twice
	useEffect(() => {
		const handleEscKey = (evt: KeyboardEvent) => {
			if (evt.key !== 'Escape') { return; }
			const now = Date.now();
			const elapsed = now - lastEscKeyPressedAt.current;
			lastEscKeyPressedAt.current = now;
			if (elapsed < 500) {
				handleSettingsClosed();
			}
		};

		document.addEventListener('keydown', handleEscKey);
		return () => {
			document.removeEventListener('keydown', handleEscKey);
		};

	}, [handleSettingsClosed]);

	return (
			// This widget does not follow the same color scheme as other widgets
			// <div className={classNames(styles.SettingsContainer)}>
			// 	{props.children}
		<>
			{/* Decommissioned on 19/Aug/2023 */}
			{/* {currentRecipe.type === RecipeType.Cloud && currentRecipe.entityInfo && (
				<DependenciesModal
					visible={dependenciesModalVisible}
					recipeDbId={currentRecipe.entityInfo.dbId}
					onClose={hideDependenciesModal}
					error={installDependenciesCommand?.error}
				/>
			)} */}

			<StandardModal
				destroyOnClose={true}
				dataKemuMeta="image-warp-custom-settings"
				onCancel={handleSettingsClosed}
				closeOnMaskClick={true}
				centered={false}
				bodyClassName={styles.SettingsModalBody}
				contentWrapperClassName={styles.ModalContent}
				modalWrapperClassName={styles.ScriptWrapper}
				rootClassName={styles.ModalRoot}
				bodyStyle={{
					padding: '0px',
					height: '100%',
				}}
				className={classNames(styles.Modal, { [styles.ConsoleVisible]: fixedState.consoleVisible })}
				visible={modalVisible}
				getContainer={() => modalContainer}
				noFooter
			>
				<div className={classNames(styles.EditorContainer)}>
					<div className={styles.EditorBar}>
					<FormGroup row={true}>
						<Tooltip placement="top" title={t('SaveBtn', 'Save script')}>
							<Button
								disabled={code === mainPage.code || fixedState.pauseExecution}
								onClick={() => onSaveScript()}
								className={classNames(styles.Button)}
								type="text"
								icon={<SaveFilled />}
								size={'large'}
							/>
						</Tooltip>

						<Tooltip placement="top" title={t(
								fixedState.pauseExecution ? 'Paused' : 'Resumed',
								fixedState.pauseExecution ? 'Code execution is paused' : 'Pause code execution'
							)}>
							<Button
								onClick={toggleWidgetDisabled}
								className={classNames(styles.Button, styles.PlayPause, { paused: fixedState.pauseExecution })}
								type="text"
								icon={ fixedState.pauseExecution ? <PlayCircleFilled /> : <PauseCircleFilled />}
								size={'large'}
							/>
						</Tooltip>

						<Tooltip placement="top" title={t(
								fixedState.consoleVisible ? 'HideConsole' : 'Hide debug console',
								fixedState.consoleVisible ? 'ShowConsole' : 'Show debug console'
							)}>
							<Button
								onClick={toggleConsoleVisibility}
								className={classNames(styles.Button, { visible: fixedState.consoleVisible })}
								type="text"
								icon={<Icon component={ConsoleIcon} />}
								size={'large'}
							/>
						</Tooltip>

						{/* {currentRecipe.type === RecipeType.Cloud && (
							<Tooltip placement="top" title={
								installDependenciesError
								? t('DependenciesBtnError', 'Error installing dependencies')
								: (
									isInstallingDependencies
										? t('DependenciesBtnLoading', 'Installing dependencies')
										: t('DependenciesBtn', 'Install dependencies')
								)
							}>
								<div className={styles.InstallDepBtnWrapper}>
									{installDependenciesError && (
										<BugFilled className={styles.DepErrorIcon}/>
									)}

									{isInstallingDependencies && (
										<SyncOutlined spin className={styles.InstallingDepIcon}/>
									)}

									<Button
										onClick={showDependenciesModal}
										type="text"
										icon={<Icon component={DependenciesIcon} />}
										size={'large'}
										disabled={isInstallingDependencies}
										// loading={isInstallingDependencies}
									/>
								</div>
							</Tooltip>
						)} */}
					</FormGroup>
					</div>
					<AceEditor
						annotations={fixedState.$$error ? [{
							text: fixedState.$$error.message,
							type: 'error',
							row: fixedState.$$error?.line || 0,
							column: fixedState.$$error?.column || 0
						}] : []}
						className={styles.Editor}
						width=""
						height=""
						placeholder="Enter your code here..."
						mode="javascript"
						theme="monokai"
						name={props.gateInfo.id}
						onLoad={handleAceLoaded}
						onChange={handleAceChange}
						fontSize={12}
						showPrintMargin={true}
						showGutter={true}
						highlightActiveLine={true}
						value={code}
						commands={[
							...editorCommands,
							{
								name: 'save',
								exec: handleSaveShortcut,
								bindKey: { win: 'Ctrl-S', mac: 'Cmd-S' },
							},
						]}
						enableBasicAutocompletion={true}
						enableLiveAutocompletion={true}
						// enableSnippets={true}

						setOptions={{
							// enableBasicAutocompletion: [customAceEditorCompleter],
							enableBasicAutocompletion: true,
							enableLiveAutocompletion: true,
							enableSnippets: true,
							showLineNumbers: true,
							tabSize: 2,
						}}
					/>
				</div>

				{fixedState.consoleVisible && (
					<div className={styles.ConsoleContainer}>
						<div className={styles.ConsoleIconsContainer}>
							<Tooltip placement="top" title={t('ClearConsoleButton', 'Clear console')}>
								<Button
									onClick={handleClearConsole}
									className={classNames('clear-console-button')}
									type="text"
									icon={<Icon component={ClearConsoleIcon} />}
									size={'large'}
								/>
						</Tooltip>
						</div>
						<div className={styles.ConsoleLines}>
							{fixedState.$$consoleLines.map((line, i) => {
								return (
									<div className={classNames(styles.ConsoleLine, line.type)} key={i}>
										<span className="timestamp">{format(line.timestamp, 'HH:mm:ss.SSS')}: </span>
										<span
											onClick={ (evt) => handleCopyLine(evt, line, notifyCopied)}
											className={styles.ConsoleLineText}
										>
											{typeof line.text === 'string' ? line.text : JSON.stringify(line.text)}
										</span>
									</div>
								);
							})}
						</div>
					</div>
				)}
			</StandardModal>
		</>
	);
};

const getPortsInformation: GetPortsInformationFunction = (state: CustomWidgetState<ScriptWidgetState>, widgetInfo) => {
	const portContext: WidgetPortContext = {
		recipePoolId: widgetInfo.recipePoolId,
		recipeType: widgetInfo.recipeType,
		thingRecipeId: widgetInfo.thingRecipeId,
		widgetId: widgetInfo.id,
	};
	const outputNames = ScriptWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = ScriptWidgetProcessor.getInputNames(state, portContext);

	const portSize = 0.02;

	const getPositionFromIndex = (total: number, index: number, isInput: boolean): PortLocation => {
		const topPadding = total <= 12 ? 0.15 : 0.05;
		const inputFraction = ((1 - (topPadding + portSize)) / total);
		let space = (inputFraction * index) + topPadding;
		const totalPorts = total + (state.useTriggerPort ? 1 : 0);
		if (totalPorts === 1) { space = 0.5; }
		if (totalPorts === 2) { space = 0.30 + (0.4 * index); }
		if (totalPorts === 3) { space = 0.25 + (0.25 * index); }
		if (totalPorts === 4) { space = 0.27 + (0.17 * index); }
		if (totalPorts === 5) { space = 0.20 + (0.15 * index); }
		if (totalPorts === 6) { space = 0.20 + (0.13 * index); }

		return [isInput ? 0 : 1, space, isInput ? -1 : 1, 0];
	};

	return {
		outputs: outputNames.map((output, i) => {
			return  {
				name: output.name,
				type: output.type,
				position: getPositionFromIndex(outputNames.length, i, false),
				...(output.jsonShape ? { jsonShape: output.jsonShape } : undefined),
				...(output.label ? { label: output.label } : undefined),
			};
		}),

		inputs: inputNames.map((input, i) => {
			return  {
				name: input.name,
				type: input.type,
				position: getPositionFromIndex(inputNames.length, i, true),
				...(input.jsonShape ? { jsonShape: input.jsonShape } : undefined),
				...(input.label ? { label: input.label } : undefined),
			};
		})
	};
};

export default {
	getPortsInformation,
	getWrapperClass: () => styles.CanvasIconWrapper,
	BarIcon: GateBarIcon,
	Element: ScriptWidget,
	CustomSettingsDialog: GateCustomSettings,
	hasTitle: true,
	getWidgetTitle: (intl) => intl.formatMessage({ id: 'LogicMapper.Gates.Script.Title', defaultMessage: 'Script' }),
} as GateUI;
