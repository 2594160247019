import React, { useEffect } from 'react';
import classNames from 'classnames';
import {
  CheckboxConfig,
  DropDownConfig,
  MultiSelectConfig,
  NumberConfig,
  SliderConfig,
  TextConfig,
  WidgetGroupSetting,
} from '@kemu-io/kemu-core/types';
import NumericField from '../DynamicFields/NumericField/NumericField';
import TextField from '../DynamicFields/TextField/TextField';
import SliderField from '../DynamicFields/SliderField/SliderField';
import CheckboxField from '../DynamicFields/CheckboxField/CheckboxField';
import DropdownField from '../DynamicFields/DropdownField/DropdownField';
import MultiSelectField from '../DynamicFields/MultiSelectField/MultiSelectField';
import styles from './GroupFieldsRenderer.module.css';
import { ABORT_OPEN_WIDGET_CLICK } from '@common/constants';

type Props = {
  fields: WidgetGroupSetting[];
  thingRecipeId: string;
  recipeId: string;
  widgetId: string;
  color?: string;
  isBrightColor?: boolean;
  disabled?: boolean;
  multiPort: boolean;
  isInnerSetting: boolean;
  repaintWidgetPorts: () => void;
}

const GroupFieldsRenderer = (props: Props) => {
  const {
    fields, recipeId, thingRecipeId,
    color, disabled, multiPort, repaintWidgetPorts,
    isBrightColor, isInnerSetting, widgetId
  } = props;

  const isSingleElement = fields.length === 1 && !multiPort && !isInnerSetting;

  return (
    <>
      {fields.map((setting, index) => (
        <div
          key={`field-${index}-${setting.type}`}
          className={classNames(styles.FieldItem, ABORT_OPEN_WIDGET_CLICK)}
        >
          {setting.type === 'number' && (
            <NumericField
              label={setting.label}
              recipeId={recipeId}
              widgetId={widgetId}
              color={color}
              thingRecipeId={thingRecipeId}
              isSingleElement={isSingleElement}
              variableName={setting.variableName}
              {...setting.config as NumberConfig }
            />
          )}

          {setting.type === 'text' && (
            <TextField
              isHiddenField={props.isInnerSetting}
              onRepaintWidgetPorts={repaintWidgetPorts}
              label={setting.label}
              recipeId={recipeId}
              thingRecipeId={thingRecipeId}
              widgetId={widgetId}
              variableName={setting.variableName}
              isSingleElement={isSingleElement}
              color={color}
              {...setting.config as TextConfig }
            />
          )}

          {setting.type === 'slider' && (
            <SliderField
              label={setting.label}
              disabled={disabled}
              recipeId={recipeId}
              thingRecipeId={thingRecipeId}
              widgetId={widgetId}
              variableName={setting.variableName}
              color={color}
              {...setting.config as SliderConfig }
            />
          )}

          {setting.type === 'dropdown' && (
            <DropdownField
              label={setting.label}
              disabled={disabled}
              recipeId={recipeId}
              thingRecipeId={thingRecipeId}
              widgetId={widgetId}
              variableName={setting.variableName}
              isSingleElement={isSingleElement}
              color={color}
              {...setting.config as DropDownConfig }
            />
          )}

          {setting.type === 'multiselect' && (
            <MultiSelectField
              label={setting.label}
              disabled={disabled}
              recipeId={recipeId}
              thingRecipeId={thingRecipeId}
              widgetId={widgetId}
              variableName={setting.variableName}
              isSingleElement={isSingleElement}
              color={color}
              {...setting.config as MultiSelectConfig }
            />
          )}

          {setting.type === 'checkbox' && (
            <CheckboxField
              label={setting.label}
              disabled={disabled}
              recipeId={recipeId}
              thingRecipeId={thingRecipeId}
              widgetId={widgetId}
              variableName={setting.variableName}
              isSingleElement={isSingleElement}
              color={color}
              isBrightColor={isBrightColor}
              {...setting.config as CheckboxConfig }
            />
          )}
        </div>
      ))}
    </>
  );
};

export default GroupFieldsRenderer;
