import { createIntl, createIntlCache, IntlShape, RawIntlProvider } from 'react-intl';

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

type AllowedValues = string | number | boolean | Date | null | undefined;
export type GlobalTranslateFunction = (id: string, defaultMessage?: string, values?: Record<string, AllowedValues>) => string;

let intl: IntlShape;

const createGlobalIntlInstance = (locale: string, messages: Record<string, string>) => {
  intl = createIntl({
    locale,
    messages,
  }, cache);
};

/**
 * @returns a function that can be used
 * to format messages in non-react parts of the application.
 */
const getGlobalIntl = (): GlobalTranslateFunction => {
  if (!intl) {
    throw new Error('Intl instance not created');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (id: string, defaultValue?: string, values?: Record<string, any>) => {
    return intl.formatMessage({ id, defaultMessage: defaultValue }, values);
  };
};

export { createGlobalIntlInstance, getGlobalIntl };
