import React, { useEffect } from 'react';

type Props = {
  /** the svg contents */
  contents: string;
  /** any extra class names to be added to the element */
  className?: string;
  /** the width of the svg */
  width?: number;
  /** the height of the svg */
  height?: number;
  targetElement?: HTMLElement | null;
  fill?: string;
}

/**
 * Creates a new SVG element from the given contents and injects it into
 * the target element.
 */
const InjectSvg = (props: Props) => {
  const { contents, className, width, height, targetElement, fill } = props;

  useEffect(() => {
    if (!targetElement)  { return; }

    const svg = new DOMParser().parseFromString(contents, 'image/svg+xml').documentElement;
    svg.setAttribute('width', width ? width.toString() : '100%');
    svg.setAttribute('height', height ? height.toString() : '100%');
    className && svg.setAttribute('class', className || '');
    fill && svg.setAttribute('fill', fill);

    targetElement.appendChild(svg);

    return () => {
      targetElement?.removeChild(svg);
    };
  }, [contents, className, width, height, targetElement, fill]);

  return null;
};

export default InjectSvg;
