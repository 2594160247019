import React, { forwardRef, useCallback, useEffect, useImperativeHandle } from 'react';
import WidgetGroupProcessor from '@kemu-io/kemu-core/widgets/widgetGroup/index.js';
import { BrowserJsPlumbInstance } from '@jsplumb/community';
import { Position, WidgetGroupState , GroupWidgetPort } from '@kemu-io/kemu-core/types';
import { decodePortName } from '@kemu-io/kemu-core/common/utils';
import { RecipeType } from '@kemu-io/kemu-types';
import useReactiveWidgetState from '../../common/hooks/useReactiveWidgetState';
import { PlumbDragEvent } from '../../types/core_t';
import { decodeDomId } from '../../common/utils';
import useAlwaysLinked from '../../common/hooks/useAlwaysLinked';
import VirtualPort from './virtualPort';

interface Props {
	blockId: string;
	recipeId: string;
	recipeType: RecipeType;
	/** id of the current group */
	groupId: string;
	plumbInstance: BrowserJsPlumbInstance;
}

export type VirtualPortsRef = {
	paintGroupConnections: () => void;
}

const buildStateAfterMove = (groupId: string, currentState: WidgetGroupState, evt: PlumbDragEvent): WidgetGroupState | null => {
	const widget = decodeDomId(evt.el.id);
	if (widget && widget.uid && widget.gateId === groupId) {
		const portName = widget.uid;

		const newPosition: Position = {
			x: evt.pos.left,
			y: evt.pos.top
		};

		const decodedPortName = decodePortName(portName).name;

		const newState: WidgetGroupState = {
			...currentState,
			outputs: currentState.outputs.map((output) => output.name === decodedPortName ? { ...output, position: newPosition } : output),
			inputs: currentState.inputs.map((input) => input.name === decodedPortName ? { ...input, position: newPosition } : input),
		};

		return newState;
	}

	return null;
};


const VirtualPortManager = forwardRef<VirtualPortsRef, Props>((props, virtualPortsRef): React.JSX.Element | null => {
	const { plumbInstance } = props;
	const [widgetState, setWidgetState] = useReactiveWidgetState<WidgetGroupState>(props.recipeId, props.blockId, props.groupId);

	const widgetInputs = WidgetGroupProcessor.getInputNames(widgetState, {
		recipePoolId: props.recipeId,
		recipeType: props.recipeType,
		thingRecipeId: props.blockId,
		widgetId: props.groupId
	});

	const widgetOutputs = WidgetGroupProcessor.getOutputNames(widgetState, {
		recipePoolId: props.recipeId,
		recipeType: props.recipeType,
		thingRecipeId: props.blockId,
		widgetId: props.groupId,
	});

	const filteredOutputs = widgetOutputs.filter(port => port.isInner);
	const filteredInputs = widgetInputs.filter(port => port.isInner);

	const repaintConnections = useAlwaysLinked(
		props.recipeId,
		props.blockId,
		props.groupId,
		filteredInputs.length,
		filteredOutputs.length,
		plumbInstance
	);

	useImperativeHandle(virtualPortsRef, () => {
		return {
			paintGroupConnections: () => {
				repaintConnections(/* true, props.groupId */);
			}
		};
	});



	const updatePortPosition = useCallback((port: GroupWidgetPort, newPosition: Position): void => {
		setWidgetState(currentState => {
			const portName = decodePortName(port.name).name;
			const newState: WidgetGroupState = {
				...currentState,
				outputs: currentState.outputs.map((input) => input.name === portName ? { ...input, position: newPosition } : input),
				inputs: currentState.inputs.map((output) => output.name === portName ? { ...output, position: newPosition } : output),
			};
			return newState;
		});

	}, [setWidgetState]);


	useEffect(() => {
		const handleEventMove = (evt: PlumbDragEvent) => {
			setWidgetState(currentState => {
				const updatedState = buildStateAfterMove(props.groupId, currentState, evt);
				return updatedState || currentState;
			});
		};

		plumbInstance.bind('drag:stop', handleEventMove);
		return () => {
			plumbInstance.unbind('drag:stop', handleEventMove);
		};
	}, [plumbInstance, props.groupId, setWidgetState/* , repaintConnections */]);


	return (
		<>
			{/* These become the inputs (source ports) inside the group */}
			{filteredOutputs.map((outputPort, i) => (
				<VirtualPort
					updatePortPosition={updatePortPosition}
					key={`${props.groupId}_${outputPort.name}_${i}`}
					recipeId={props.recipeId}
					blockId={props.blockId}
					gateId={props.groupId}
					port={outputPort}
					portLocation={'Right'}
					plumbInstance={props.plumbInstance}
					isInner={!!outputPort.isInner}
				/>
			))}

			{/* These will become the outputs (targets) inside of the group */}
			{filteredInputs.map((inputPort, i) => (
				<VirtualPort
					updatePortPosition={updatePortPosition}
					key={`${props.groupId}_${inputPort.name}_${i}`}
					recipeId={props.recipeId}
					blockId={props.blockId}
					gateId={props.groupId}
					port={inputPort}
					portLocation={'Left'}
					plumbInstance={props.plumbInstance}
					isInner={!!inputPort.isInner}
				/>
			))}
		</>
	);
});

export default VirtualPortManager;
