import React from 'react';
import { Hit } from '@algolia/client-search';
import { Col, Row } from 'antd';

export type ResultsGridProps<T> = /* HitsProvided<T> & */ {
  /**
   * Will get invoked when a new set of results is ready.
   * Use it to filter your results before rendering them.
   */
  onResults?: (hits: Hit<T>[]) => Hit<T>[];
  /** A component used to render an individual hit */
  hitRenderer: React.ComponentType<Hit<T>>;
  hits: Hit<T>[];
}

/**
 * A component that renders Algolia hits using the given custom component.
 * Usage:
 * ```ts
 * const ResultsGrid = connectHits<ResultsGridProps<MyCustomHitType>, MyCustomHitType>(AlgoliaResultsGrid);
 * 
 * const MyResultsContainerView = () => {
 *  const filterResults = useCallback((hits: Hit<MyCustomHitType>[]) => {
 *    return hits.filter(hit => hit.state === 'active');
 *  }, []);
 * 
 *  
 *  return (
 *    <ResultsGrid onResults={filterResults} customComponent={CustomResultItem} />
 *  )
 * }
 * ```
 */
function AlgoliaResultsGrid <T> (props: ResultsGridProps<T>): React.JSX.Element {
  const filteredHits = props.onResults ? props.onResults(props.hits) : props.hits;
  const CustomComponent = props.hitRenderer;

  return (
    <Row gutter={{ xs: 0, sm: 12, md: 16, lg: 16, xl: 24 }}>
      {filteredHits.map((hit) => (
        <Col key={hit.objectID} xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
          <CustomComponent {...hit}/>
        </Col>
      ))}
    </Row>
  );
}

/**
 * Higher order component that build a component to render Algolia hits using the given custom component.
 * Usage:
 * ```ts
 * const ResultsGrid = withAlgoliaResultsGrid<MyCustomHitType>();
 * 
 * const MyResultsContainerView = () => {
 *  const filterResults = useCallback((hits: Hit<MyCustomHitType>[]) => {
 *    return hits.filter(hit => hit.state === 'active');
 *  }, []);
 * 
 *  
 *  return (
 *    <ResultsGrid onResults={filterResults} customComponent={CustomResultItem} />
 *  )
 * }
 * ```
 */
// export function withAlgoliaResultsGrid<T>(): ConnectedComponentClass<ResultsGridProps<T>, HitsProvided<T>> {
// export function withAlgoliaResultsGrid<T>(): React.ComponentType<T> {
//   // const resultsGridComponent = connectHits<ResultsGridProps<T>, T>(AlgoliaResultsGrid);
//   // return resultsGridComponent;
//   // const component = (props: T) => <AlgoliaResultsGrid {...props} />;
//   // return component;
//   // return AlgoliaResultsGrid;
// }


export default AlgoliaResultsGrid;
