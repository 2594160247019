import { Tour, TourEvent, TourEventInfo } from 'helphero';
import { useCallback, useEffect, useState } from 'react';
import helpHero from '../helpHero/helpHero';

export type HelpHeroTourState = 'idle' | 'tour_started' | 'tour_advanced' | 'tour_completed' | 'tour_cancelled' | 'tour_interrupted' | 'error';

export type TourState = {
  /** indicates whether a tour is active or not */
  active: boolean;
  /** the latest tour state */
  tourState: HelpHeroTourState;
  /** information about the last tour */
  info: Tour | null;
}

const useHelpHeroTourState = (): TourState => {
  const helpHeroInstance = helpHero.getInstance();
	const [tourState, setTourState] = useState<TourState>({
    active: false, info: null, tourState: 'idle',
  });

  const handleTourEvent = useCallback((ev: TourEvent, info: TourEventInfo) => {
    setTourState({
      active: ev.kind === 'tour_started' || ev.kind === 'tour_advanced',
      info: info.tour || null,
      tourState: ev.kind,
    });
	}, []);


  useEffect(() => {
		if (helpHeroInstance) {
			helpHeroInstance.on('tour_started', handleTourEvent);
			helpHeroInstance.on('tour_completed', handleTourEvent);
			helpHeroInstance.on('tour_cancelled', handleTourEvent);
			helpHeroInstance.on('tour_interrupted', handleTourEvent);
		}

		return () => {
			if (helpHeroInstance) {
				helpHeroInstance.off('tour_started', handleTourEvent);
				helpHeroInstance.off('tour_completed', handleTourEvent);
				helpHeroInstance.off('tour_cancelled', handleTourEvent);
				helpHeroInstance.off('tour_interrupted', handleTourEvent);
			}
		};
	}, [helpHeroInstance, handleTourEvent]);

  return tourState;
};

export default useHelpHeroTourState;
