import React from 'react';

interface Props {
	selectedStep: number;
	children?: React.JSX.Element[] | JSX.Element;
	className?: string;
}

const SelectedStep = (props: Props): React.JSX.Element => {
	const children = Array.isArray(props.children) ? props.children : [props.children];
	const selectedChild = (props.selectedStep < children.length && children[props.selectedStep]) || null;
	return (
		<div className={props.className || ''}>
			{selectedChild}
		</div>
	);
};

export default SelectedStep;
