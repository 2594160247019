import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Kemu } from '../kemu/kemu';
import { UserSessionContext } from '@common/context/UserSessionContext/UserSessionContext';
import getFreshChatWidget from '@src/app/recipe/freshChatWidget';
import getFreshDeskWidget from '@src/app/recipe/freshDeskWidget';
import routes from '@common/routes';

/**
 * Renders different routes based on the user's authentication state.
 */
const AuthStateRouter = () => {
  const session = useContext(UserSessionContext);
  const freshChat = getFreshChatWidget();
	const freshDesk = getFreshDeskWidget();
  // const organizationType = session.user?.attributes['custom:organization'];
	// const userId = session.user?.attributes['sub'];


  useEffect(() => {
    // console.log(`AuthStateRouter useEffect: ${session.state}`);
    if (session.state !== 'LoggedIn') {
      freshChat.hide();
      freshDesk.hideLauncher();
    }

  }, [freshDesk, freshChat, session.state]);


  if (session.state === 'LoggedIn') {
    return (
      <Kemu />
    );
  }

  if (session.state === 'NotLoggedIn') {
    return (
      <Navigate to={routes.auth.getSignInRoute()} />
    );
  }

  // Processing?
  return null;
};

export default AuthStateRouter;
