
import React, { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { WidgetPortContext } from '@kemu-io/kemu-core/types';
import { Slider } from 'antd';
import Icon from '@ant-design/icons';
import { useIntl, FormattedMessage } from 'react-intl';
import SliderWidgetProcessor, {
	SliderGateState,
	getDefaultState
} from '@kemu-io/kemu-core/widgets/slider/index.js';
import classNames from 'classnames';
import { Data, DataType } from '@kemu-io/hs-types';
import { invokeNextGate } from '../../../app/recipe/utils';
import GateIcon from '../../gateIcon/gateIcon';
import { GateCustomSettingsProps, GetPortsInformationFunction, GateUI, GateUIProps } from '../index';
import { selectCurrentRecipe, selectedBlock } from '../../../features/Workspace/workspaceSlice';
import { ReactComponent as SliderGateIcon } from '../../../assets/img/gates/slider-gate.svg';
import GateSettingsCloseBtn from '../../gateSettingsCloseBtn/gateSettingsCloseBtn';
import styles from './slider.module.css';
import useNumericInput from '@hooks/useNumericInput';
import { ABORT_CHILD_DRAGGING, SETTINGS_CONTAINER_CLASS } from '@common/constants';
import useReactiveWidgetState from '@hooks/useReactiveWidgetState';

type Props = GateUIProps;

const parseState = (state: SliderGateState) => {
	// NOTE: the actual 'default' limit is defined at the processor level (kemu-core/src/gates/if/index.ts),
	// here we just make sure we show the same.
	let min = state.min;
	let max = state.max;
	let current = state.currentValue;

	if (isNaN(min)) { min = 0; }
	if (isNaN(max)) { max = 180; }
	if (isNaN(current)) { current = 90; }

	const fixedState: SliderGateState = {
		currentValue: 90,
		min: Number(min),
		max: Number(max),
	};

	return fixedState;
};

const SliderGate = (props: Props): React.JSX.Element => {
	const currentRecipe = useSelector(selectCurrentRecipe);
	const currentBlock = useSelector(selectedBlock);
	const [state, setState] = useReactiveWidgetState<SliderGateState>(props.recipeId, props.thingRecipeId, props.info.id);
	const fixedState = {
		...getDefaultState(),
		...state
	};

	const outputNames = SliderWidgetProcessor.getOutputNames(state, {
		recipePoolId: props.recipeId,
		recipeType: props.recipeType,
		thingRecipeId: props.thingRecipeId,
		widgetId: props.info.id,
	});

	const onExecute = async (value: number) => {
		if (currentRecipe.poolId && currentBlock && !props.info.disabled) {
			const event: Data = {
				timestamp: Date.now(),
				type: DataType.Number,
				value: Number(value)
			};

			await invokeNextGate(
				currentRecipe.poolId,
				currentBlock.recipeId,
				props.thingDbId,
				props.thingVersion,
				props.info.id,
				outputNames[0].name,
				event,
				event
			);
		}
	};

	const updateState = (value: number) => {
		setState({
			...fixedState,
			currentValue: Number(value),
		});
	};

	const isFloat = (n: number) => Number(n) === n && n % 1 !== 0;
	const step = (isFloat(fixedState.max) || isFloat(fixedState.min)) ? 0.1 : 1;

	return (
		<div className={`${styles.GateBody}`}>
			<div className={`${styles.Slider} ${ABORT_CHILD_DRAGGING}`}>
				<Slider
					step={step}
					defaultValue={fixedState.currentValue}
					onChange={onExecute}
					onChangeComplete={updateState}
					min={fixedState.min}
					max={fixedState.max}
				/>
			</div>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={SliderGateIcon} />}/>
	);
};


const GateCustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
	const intl = useIntl();
	const [state, setState] = useReactiveWidgetState<SliderGateState>(props.recipeId, props.blockId, props.gateInfo.id);
	// const fixedState = parseState(state);
	const fixedState = {
		...getDefaultState(),
		...state
	};

	const minTooltip = intl.formatMessage({ id: 'LogicMaker.Gates.Slider.Tooltip.Min', defaultMessage: 'Minimum Value' });
	const maxTooltip = intl.formatMessage({ id: 'LogicMaker.Gates.Slider.Tooltip.Max', defaultMessage: 'Maximum Value' });
	const minPlaceholder = intl.formatMessage({ id: 'LogicMaker.Gates.Slider.Placeholder.Min', defaultMessage: 'min' });
	const maxPlaceholder = intl.formatMessage({ id: 'LogicMaker.Gates.Slider.Placeholder.Max', defaultMessage: 'max' });
	const numbersOnly = useNumericInput();

	const minInputRef = useRef<HTMLInputElement>(null);
	const maxInputRef = useRef<HTMLInputElement>(null);
	const { onClose } = props;
	const { currentValue } = fixedState;



	const onBeforeClose = useCallback(() => {
		if (minInputRef.current && maxInputRef.current) {
			setState(
				parseState({
					currentValue,
					min: minInputRef.current.value as unknown as number,
					max: maxInputRef.current.value as unknown as number
				})
			);
		}
		onClose();
	}, [setState, onClose, currentValue]);


	return (
		<div className={classNames(styles.SettingsContainer, SETTINGS_CONTAINER_CLASS)}>
			<GateSettingsCloseBtn onClose={onBeforeClose} />
			<label>
				<FormattedMessage id="LogicMaker.Gates.Slider.SettingsLabel" defaultMessage="Limits" />
			</label>
			<div className={`gate-input ${styles.Input}`}>
				<input ref={minInputRef} type="text" title={minTooltip} placeholder={minPlaceholder} onInput={numbersOnly} defaultValue={fixedState.min}  data-type="min"/>
			</div>

			<div className={`gate-input ${styles.Input}`}>
				<input ref={maxInputRef} type="text" title={maxTooltip} placeholder={maxPlaceholder} onInput={numbersOnly} defaultValue={fixedState.max}  data-type="max"/>
			</div>
		</div>
	);
};

const getPortsInformation: GetPortsInformationFunction = (state, widgetInfo) => {
	const portContext: WidgetPortContext = {
		recipePoolId: widgetInfo.recipePoolId,
		recipeType: widgetInfo.recipeType,
		thingRecipeId: widgetInfo.thingRecipeId,
		widgetId: widgetInfo.id,
	};
	const outputNames = SliderWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = SliderWidgetProcessor.getInputNames(state, portContext);

	return {
		inputs: [{
			name: inputNames[0].name,
			type: inputNames[0].type,
			position: 'Left'
		}],

		outputs: [{
			name: outputNames[0].name,
			type: outputNames[0].type,
			position: 'Right'
		}]
	};
};


export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: SliderGate,
	hasTitle: true,
	settingsCustomSize: true,
	CustomSettingsDialog: GateCustomSettings,
	getWidgetTitle: (intl) => intl.formatMessage({ defaultMessage: 'Slider', id: 'LogicMaker.Gates.Slider.Title' }),
} as GateUI;
