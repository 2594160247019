/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, CSSProperties, ChangeEvent, useCallback, MouseEventHandler } from 'react';
import { Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getValidIconNames } from '../../../../components/dynamicIcon/dynamicIcon';
import { WIDGET_DEFAULT_ICON_NAME } from '../../../../common/constants';
import StyledInput from '../../../../components/form-control/styledInput/styledInput';
import useTranslation from '../../../../common/hooks/useTranslation';
import styles from './iconSelector.module.css';

interface Props {
	icon?: string;
	backgroundColor?: string;
	onIconSelected?: (icon: string) => void;
}

const iconList = getValidIconNames();

const IconSelector = (props: Props): React.JSX.Element => {
	const { onIconSelected } = props;
	const currentIcon = props.icon;
	const styledBody: CSSProperties = props.backgroundColor ? { backgroundColor: props.backgroundColor } : {};
	const [filter, setFilter] = useState('');
	const [filteredList, setFilteredList] = useState<string[]>(iconList);
	const t = useTranslation('IconSelector.SearchInput');

	const onFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
		const text = event.currentTarget.value;
		setFilter(text);
		setFilteredList(iconList.filter(name => name.includes(text)));
	};

	const handleSelectedIcon: MouseEventHandler<HTMLDivElement> = useCallback((event) => {
		const name = event.currentTarget.getAttribute('data-name') || '';
		if (name !== '') {
			onIconSelected && onIconSelected(name);
		}
	}, [onIconSelected]);

	return (
		<Popover
			placement="rightTop"
			mouseLeaveDelay={0.1}
			trigger="click"
			overlayClassName={styles.PopupOverlay}
			content={
				<div>
					<div className={styles.SearchBox}>
						<StyledInput
							placeholder={t('Placeholder', 'Filter icons')}
							className={styles.SearchInput}
							value={filter}
							onChange={onFilterChange}
						/>
						<span>{filteredList.length} {t('Icons', 'icons')}</span>
					</div>
					<div className={styles.IconsGrid}>
						{filteredList.map((iconName) => {
							return (
								<div key={iconName} data-name={iconName} className={styles.GridIcon} onClick={handleSelectedIcon} >
									<FontAwesomeIcon icon={['fas', iconName as any]} />
								</div>
							);
						})}
					</div>
				</div>
			}
		>
			<div className={styles.PreviewContainer} style={styledBody}>
				{currentIcon !== undefined ? (
					<FontAwesomeIcon icon={['fas', currentIcon as any]} />
				) : (
					<FontAwesomeIcon icon={['fas', WIDGET_DEFAULT_ICON_NAME]} />
				)}
			</div>
		</Popover>
	);
};

export default IconSelector;
