import { PublishEntityRequestBody, PublicationEntity } from '@kemu-io/kemu-types';
import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import * as marketplaceApi from '../../../../api/marketplace/marketplaceApi';
import { AsyncRequestStatus } from '../../../../types/core_t';
import { MarketplaceState } from '../marketplaceSlice';

export const publishRecipe = createAsyncThunk('/marketplace/recipe/publish', async (
	args: { recipeId: string, details: PublishEntityRequestBody, existingPublicationId?: string }
): Promise<PublicationEntity> => {
	let response: PublicationEntity;
	if (!args.existingPublicationId) {
		response = await marketplaceApi.publishRecipe(args.recipeId, args.details);
	} else {
		response = await marketplaceApi.publishRecipeVersion(args.existingPublicationId, args.details);
	}

	return response;
});


export const publishRecipeReducer = ((builder: ActionReducerMapBuilder<MarketplaceState>/*, thunk: any*/): void => {
	builder.addCase(publishRecipe.pending, (state) => {
		state.publishingRecipe.asyncState.status = AsyncRequestStatus.loading;
		state.publishingRecipe.asyncState.error = undefined;
	});

	builder.addCase(publishRecipe.rejected, (state, action) => {
		state.publishingRecipe.asyncState.status = AsyncRequestStatus.error;
		state.publishingRecipe.asyncState.error = action.error;
	});

	builder.addCase(publishRecipe.fulfilled, (state, action: PayloadAction<PublicationEntity>) => {
		state.publishingRecipe.asyncState.status = AsyncRequestStatus.completed;
		state.publishingRecipe.publishedEntity = action.payload;
	});
});
