/** Forces a component to re-render */
import { useCallback, useState } from 'react';

const useForceUpdate = (): ()=> void => {
	const [, force] = useState({});

	const reload = useCallback(() => {
		force({});
	}, []);

	return reload;
};

export default useForceUpdate;
