
import React, { useEffect } from 'react';
import Icon from '@ant-design/icons';
import ObjectWidgetProcessor, {
	JsonParseWidgetState,
} from '@kemu-io/kemu-core/widgets/jsonParse/index.js';

import { CustomWidgetState, WidgetPortContext } from '@kemu-io/kemu-core/types';
import classNames from 'classnames';
import GateIcon from '../../gateIcon/gateIcon';
import {
	GetPortsInformationFunction,
	GateUI,
	GateUIProps,
} from '../index.ts';
import { PortLocation } from '../../../types/canvas_t';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import styles from './jsonParse.module.css';
import { ReactComponent as JsonParseIcon } from './icon.svg';


const JsonParseWidget = (props: GateUIProps): React.JSX.Element => {
	const [state] = useReactiveWidgetState<JsonParseWidgetState>(props.recipeId, props.thingRecipeId, props.info.id);
	const { repaint } = props;

	// Repaint widget when output type changes
	useEffect(() => {
		repaint();
	}, [state, repaint]);

	return (
		<div className={classNames(styles.GateBody, {
			[styles.WidgetDisabled]: props.info.disabled,
		})}>
			<Icon component={JsonParseIcon}/>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={JsonParseIcon} />}/>
	);
};


const getPortsInformation: GetPortsInformationFunction = (state: CustomWidgetState<JsonParseWidgetState>, widgetInfo) => {
	const portContext: WidgetPortContext = {
		recipePoolId: widgetInfo.recipePoolId,
		recipeType: widgetInfo.recipeType,
		thingRecipeId: widgetInfo.thingRecipeId,
		widgetId: widgetInfo.id,
	};
	const outputNames = ObjectWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = ObjectWidgetProcessor.getInputNames(state, portContext);

	const positions: Record<string, PortLocation> = {
		'object': [1, 0.3, 1, 0],
		'error': [1, 0.7, 1, 0]
	};

	return {
		inputs: [{
			position: 'Left',
			name: inputNames[0].name,
			type: inputNames[0].type,
		}],

		outputs: outputNames.map(output => ({
			name: output.name,
			type: output.type,
			position: positions[output.name],
			label: output.label
		})),
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: JsonParseWidget,
	hasTitle: false,
	getGatesBarTitle: (intl) => intl.formatMessage({ id: 'LogicMaker.Gates.JsonParse.Title', defaultMessage: 'Text To Object' }),
} as GateUI;
