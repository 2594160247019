const text = /*javascript*/`

const targetColor = { r: 255, g: 255, b: 255 };
const boxes = Kemu.image.getBoundingBox(
  imageData, 
  targetColor,
  {
    /** allowed distance from target color */
    threshold: 10,
    /** stop after finding 5 boxes */
    maxBoxes: 5,
    /** minimum size in pixels of a box */
    minWidth: 5,
    minHeight: 5,
  }
);`;

export default () => text.trim();
