import { GetPublicationDetailsResponse, PublicationType } from '@kemu-io/kemu-types';
import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import * as marketplaceApi from '../../../../api/marketplace/marketplaceApi';
import { AsyncRequestStatus } from '../../../../types/core_t';
import { MarketplaceState } from '../marketplaceSlice';

export const fetchPublicationDetails = createAsyncThunk(
	'/marketplace/publicationDetails', async (action: {
		entityType: PublicationType, publicationId: string
}): Promise<GetPublicationDetailsResponse> => {
	const details = await marketplaceApi.getPublicationDetails(action.entityType, action.publicationId);
	return details;
});

export const getPublicationDetailsReducer = ((builder: ActionReducerMapBuilder<MarketplaceState>/*, thunk: any*/): void => {
	builder.addCase(fetchPublicationDetails.pending, (state) => {
		state.publicationDetails.asyncState.status = AsyncRequestStatus.loading;
		state.publicationDetails.asyncState.error = undefined;
	});

	builder.addCase(fetchPublicationDetails.fulfilled, (state, action: PayloadAction<GetPublicationDetailsResponse>) => {
		state.publicationDetails.asyncState.status = AsyncRequestStatus.completed;
		state.publicationDetails.details = action.payload;
	});

	builder.addCase(fetchPublicationDetails.rejected, (state, action) => {
		state.publicationDetails.asyncState.status = AsyncRequestStatus.error;
		state.publicationDetails.asyncState.error = action.error;
	});
});
