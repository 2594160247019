import React from 'react';
import { Result } from 'antd';
import { Link } from 'react-router-dom';
import routes from '../../common/routes/index';
import Robot from './robot.png';
import styles from './PageNotFound.module.css';

const CustomIcon = () => {
	return (
		<img src={Robot} className={styles.Image} />
	);
};

const PageNotFound = (): React.JSX.Element => {
	return (
		<div className={styles.Container}>
			<Result
				icon={<CustomIcon />}
				title="Page Not Found"
				extra={<Link to={routes.getBaseRoute()}>Return home </Link>}
			/>,
		</div>
	);
};


export default PageNotFound;
