type Session = {
  token: string | null;
  expirationTime: number;
}

const session: Session = {
  token: null,
  expirationTime: 0,
};

const updateSessionToken = (token: string, expiration: number): void => {
  session.token = token;
  session.expirationTime = expiration;
};

const getCurrentSessionToken = (): string | null => session.token;
const getCurrentSessionExpirationTime = (): number => session.expirationTime;
const getCurrentSession = (): Session => ({ ...session } as Session);
const clearSession = (): void => {
  session.token = null;
  session.expirationTime = 0;
};

const hasTokenExpired = (): boolean => {
  const currentTime = Date.now();
  const inTwoSeconds = currentTime + 2000;
  return inTwoSeconds > (session.expirationTime * 1000);
};

export default {
  updateSessionToken,
  getCurrentSessionToken,
  getCurrentSessionExpirationTime,
  getCurrentSession,
  clearSession,
  hasTokenExpired,
};
