import classNames from 'classnames';
import React, { ReactElement } from 'react';
import styles from './cardWithBorder.module.css';

interface Props {
	borderWidth?: number;
	title?: string | ReactElement;
	children: React.JSX.Element[] | JSX.Element;
	className?: string;
	bodyClassName?: string;
	type?: 'success' | 'warning' | 'danger'
}

const CardWithBorder = (props: Props): React.JSX.Element => {
	return (
		<div className={`${styles.Card} ${props.type || ''} ${props.className ? props.className : ''}`} style={{ borderTopWidth: props.borderWidth !== undefined ? props.borderWidth : undefined }}>
			{props.title && (
				<div className={styles.CardHeader}>
					<h4>{props.title}</h4>
				</div>
			)}

			<div className={classNames(styles.CardBody, props.bodyClassName)}>
				{props.children}
			</div>
		</div>
	);
};

export default CardWithBorder;
