import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createBrowserRecipeAction } from '@src/app/reducers/workspace/createBrowserRecipeReducer';

/**
 * Decided what recipe to load (default or download) based on the url.
 * @param props 
 * @returns 
 */
const RecipeRouter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const baseRecipeCreatedRef = useRef(false);

  useEffect(() => {
    if (!baseRecipeCreatedRef.current) {
      baseRecipeCreatedRef.current = true;  // prevents creating the default recipe more than once
      dispatch(createBrowserRecipeAction({
        navigate
      }));
    }
  }, [dispatch, navigate]);

  return null;
};

export default RecipeRouter;
