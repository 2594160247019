import React, { memo, useEffect, useState } from 'react';


type Props = {
  /** url to download the svg from */
  src: string;
  className?: string;
  width?: number;
  height?: number;
}

/**
 * Renders the given SVG as an image. This is useful
 * when you don't know the viewport of the widget and want to make
 * sure the SVG will be rendered correctly. Eg. Widget bundles
 * @param props 
 * @returns 
 */
const SvgAsImage = (props: Props) => {
  const { src, className, width, height } = props;
  const [svgData, setSvgData] = useState<string | null>(null);

  useEffect(() => {
    let abort = false;
    const loadSvg = async () => {
      const response = await fetch(src);
      const text = await response.text();
      if (abort) { return; }

      const base64 = btoa(decodeURIComponent(encodeURIComponent(text)));
      setSvgData(base64);
    };

    loadSvg();

    return () => {
      abort = true;
      setSvgData(null);
    };
  }, [src]);

  if (!svgData) { return null; }
  return (
    <img
      className={className}
      width={width}
      height={height}
      style={{
        objectFit: 'contain',
      }}
      src={`data:image/svg+xml;base64,${svgData}`}
      alt="svg"
    />
  );
};



export default memo(SvgAsImage);
