import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MultiSelectConfig } from '@kemu-io/kemu-core/types';
import variablesManager from '@kemu-io/kemu-core/common/managers/variablesManager.js';
import { Dropdown, Menu, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { DataType } from '@kemu-io/hs-types';
import FieldLabel from '../FieldLabel/FieldLabel';
import styles from './MultiSelectField.module.css';
import FormGroup from '@components/form-control/formGroup/formGroup';

type Props = MultiSelectConfig & {
  label: string;
  variableName: string;
  recipeId: string;
  thingRecipeId: string;
  widgetId: string;
  color?: string;
  disabled?: boolean;
  isSingleElement: boolean;
}

type MenuInfo = {
  key: string;
}

const MultiSelectField = (props: Props) => {
  const { variableName, recipeId, thingRecipeId, widgetId, disabled, isSingleElement, label } = props;
  const currentVarValue = variablesManager.getThingVariableValue(recipeId, thingRecipeId, variableName, {
    ownerWidgetId: widgetId,
  });

  const [selectedOptions, setSelectedOptions] = useState<string[]>(currentVarValue as [] || []);


  const handleMenuClick = useCallback((event: MenuInfo) => {
    console.log(event);
    if (event.key) {
      setSelectedOptions((current) => {
        const option = String(event.key);
        let final: string[];
        if (current.includes(option)) {
          final = current.filter((c) => c !== option);
        } else {
          final = [...current, option];
        }

        variablesManager.setThingVariable(recipeId, thingRecipeId, widgetId, variableName, {
          type: DataType.Array,
          value: final,
        });

        return final;
      });
    }
  }, [recipeId, thingRecipeId, variableName, widgetId]);

  const menu = useMemo(() => (
		<Menu
      onClick={handleMenuClick}
      className={styles.Menu}
      selectedKeys={selectedOptions}
    >
			{props.options.map((option) => (
				<Menu.Item
          key={`${option}`}
          // isSelected={selectedOptions.includes(option)}
          className={classNames(styles.DropdownItem, {
            [styles.NotSelected]: !selectedOptions.includes(option),
          })}
          style={{
            backgroundColor: selectedOptions.includes(option) ? props.color : undefined,
            color: selectedOptions.includes(option) ? 'white' : undefined,
          }}
        >
					{option}
				</Menu.Item>
			))}
		</Menu>
	), [props.options, selectedOptions, handleMenuClick, props.color]);


  // Load the current variable value on mount
  useEffect(() => {
    let abort = false;
    const load = async () => {
      const currentVarValue = variablesManager.getThingVariableValue(recipeId, thingRecipeId, variableName, {
        ownerWidgetId: widgetId,
      });

      if (abort) {  return; }
      setSelectedOptions(currentVarValue ? currentVarValue as string[] : props.defaultValue);
    };

    load();

    return () => {
      abort = true;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedLabel = selectedOptions.join(', ');
  return (
    <FormGroup column={true} noMarginBottom>
      <FieldLabel color={props.color} label={label} />
      <Dropdown
        overlay={menu}
        placement="bottomLeft"
        trigger={['click']}
        disabled={disabled}
        className={classNames({
          [styles.SingleDropDown]: isSingleElement && !label,
        })}
      >
        <div className={styles.DropDownTrigger}>
          <Tooltip title={selectedLabel} mouseEnterDelay={0.6}>
            <span className={styles.SelectedOption} >
              {selectedLabel}
            </span>
          </Tooltip>
          <DownOutlined
            className={styles.DropIcon}
          />
        </div>
      </Dropdown>
    </FormGroup>
  );
};

export default MultiSelectField;
