import { useCallback } from 'react';
import { CustomWidgetState, GateState } from '@kemu-io/kemu-core/types';
import KemuCore from '@kemu-io/kemu-core';
import useWidgetState from './useWidgetState';

export type GetStateFunction<T> = (currentState: CustomWidgetState<T>) => T | Promise<T>;
export type NewStateOrFunction<T> = (T) | GetStateFunction<T>;

type UseSetWidgetStateInstance<T> = (
  /**
   * @param newState The current state of the widget.
   * @param triggerEvents If true, the widget will trigger events to notify the changes.
   */
  newState: NewStateOrFunction<T>, triggerEvents?: boolean
) => void;


/**
 * A hook to set the state of the widget directly to the recipe.
 * This is intended to be use by highly performant components as it does not perform
 * any type of cleaning of private properties.
 * 
 * WARNING: this method sets a shallow copy of the given state object to the widget's state object,
 * mutating nested properties of the object WILL modify the state of the widget.
 * 
 * @param recipePoolId the id of the recipe in the pool.
 * @param thingId the id of the thing in the recipe (blockRecipeId).
 * @param widgetId the id of the widget in the thing.
 * @returns a memorized function to set the state of the widget.
 */
const useSetWidgetState = <T=GateState>(recipePoolId: string, thingId: string, widgetId: string): UseSetWidgetStateInstance<T> => {
  const { getState } = useWidgetState(recipePoolId, thingId, widgetId);

  const setState = useCallback(async (newState: NewStateOrFunction<T>, triggerEvents=true) => {
    if (typeof newState === 'function') {
      const currentState = getState();
      const updatedState = await (newState as GetStateFunction<T>)(currentState as CustomWidgetState<T>);
      // Ignore the update if the state is the same (shallow comparison)
      if (currentState !== updatedState) {
        KemuCore.setWidgetState(recipePoolId, thingId, widgetId, { ...updatedState } as GateState, triggerEvents);
      }
    } else {
      KemuCore.setWidgetState(recipePoolId, thingId, widgetId, { ...newState } as GateState, triggerEvents);
    }
  }, [recipePoolId, thingId, widgetId, getState]);

  return setState;
};


export default useSetWidgetState;
