
import React, { useCallback } from 'react';
import Icon from '@ant-design/icons';
import FirstEventWidgetProcessor, { FirstEventWidgetState, getDefaultState } from '@kemu-io/kemu-core/widgets/firstEvent/index.js';
import { FormattedMessage } from 'react-intl';
import { CustomWidgetState, WidgetPortContext } from '@kemu-io/kemu-core/types';
import classNames from 'classnames';
import GateIcon from '../../gateIcon/gateIcon';
import {
	GateCustomSettingsProps,
	GetPortsInformationFunction,
	GateUI,
	GateUIProps
} from '../index.ts';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import useTranslation from '../../../common/hooks/useTranslation';
import { PortLocation } from '../../../types/canvas_t';
import NumericInput from '../../WidgetsComponents/NumericInput/NumericInput';
import KemuSwitch from '../../form-control/kemuSwitch/KemuSwitch';
import { SETTINGS_CONTAINER_CLASS } from '../../../common/constants';
import styles from './firstEvent.module.css';
import { ReactComponent as FirstEventWidgetIcon } from './firstEvent.svg';
import { removeWidgetPortConnections } from '@src/app/recipe/utils';

type Props = GateUIProps


const mergeStates = (state: FirstEventWidgetState) => {
	const fixedState: FirstEventWidgetState = {
		...getDefaultState(),
		...state
	};

	return fixedState;
};

const FirstEventWidget = (props: Props): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<FirstEventWidgetState>(props.recipeId, props.thingRecipeId, props.info.id);
	const fixedState = mergeStates(state);
	const t = useTranslation();

	const onNumberChange = (value: number) => {
		setState({
			...fixedState,
			delayMs: value
		}, true);
	};

	// 54 = Default height
	// -1 = default height is calculated based on a minimum input number of 1
	// 10 = extra pixels per added port
	const bodyHeight = 54 + ((fixedState.inputs - 1) * 10);
	return (
		<div className={`${styles.GateBody}`}>

			<div className={styles.InnerBody} style={{ height: bodyHeight }}>
				<span className={styles.Title}>{t('LogicMaker.Gates.FirstEvent.Pause', 'Pause')}</span>
				<div className={`gate-input`}>
					<NumericInput
						placeholder={t('LogicMaker.Gates.FirstEvent.Placeholder', 'ms')}
						min={0}
						value={state.delayMs}
						onChange={onNumberChange}
					/>
				</div>
			</div>

		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={FirstEventWidgetIcon} />}/>
	);
};

const GateCustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
	const { recipeId, blockId, gateInfo, recipeType } = props;
	const [state, setState] = useReactiveWidgetState<FirstEventWidgetState>(recipeId, blockId, gateInfo.id);
	const fixedState = mergeStates(state);

	const handleInputChange = useCallback((value: number) => {
		setState((current) => {
			const portRemoved = current.inputs > value;
			if (portRemoved) {
				// Get the current inputs
				const inputNames = FirstEventWidgetProcessor.getInputNames(current, {
					recipePoolId: recipeId,
					recipeType: recipeType,
					thingRecipeId: blockId,
					widgetId: gateInfo.id,
				});

				// Ignore fixed ports
				const dynamicInputs = inputNames.filter((input) => input.name.startsWith('input'));

				// Get the last input port
				const inputToRemove = dynamicInputs[dynamicInputs.length - 1];
				// Disconnect anything from the last input port
				if (inputToRemove) {
					removeWidgetPortConnections(
						recipeId,
						blockId,
						gateInfo.id,
						inputToRemove.name,
					);
				}

				if (current.matchingOutputs) {
					// get the current outputs
					const outputNames = FirstEventWidgetProcessor.getOutputNames(current, {
						recipePoolId: recipeId,
						recipeType: recipeType,
						thingRecipeId: blockId,
						widgetId: gateInfo.id,
					});

					const dynamicOutputs = outputNames.filter((output) => output.name.startsWith('output'));
					const outputToRemove = dynamicOutputs[dynamicOutputs.length - 1];
					removeWidgetPortConnections(
						recipeId,
						blockId,
						gateInfo.id,
						outputToRemove.name,
					);
				}
			}


			return {
				...current,
				inputs: value
			};
		}, true);
	}, [setState, blockId, gateInfo.id, recipeId, recipeType]);

	const handleSwitchChange = useCallback((checked: boolean) => {
		setState((current) => {
			// get the current outputs
			const outputNames = FirstEventWidgetProcessor.getOutputNames(current, {
				recipePoolId: recipeId,
				recipeType: recipeType,
				thingRecipeId: blockId,
				widgetId: gateInfo.id,
			});

			// Any change on this switch will cause the outputs to change, except for the 'aborted'
			// port. So here we disconnect EVERYTHING.
			outputNames.forEach((output) => {
				if (output.name !== 'aborted') {
					removeWidgetPortConnections(
						recipeId,
						blockId,
						gateInfo.id,
						output.name,
					);
				}
			});

			return {
				...current,
				matchingOutputs: checked
			};
		});
	}, [setState, blockId, gateInfo.id, recipeId, recipeType]);

	return (
		<div className={classNames(styles.SettingsContainer, SETTINGS_CONTAINER_CLASS)}>
			{props.children}
			<div className={styles.InputContainer}>
				<label>
					<FormattedMessage id="LogicMaker.Gates.Json.CustomSettingsTitle" defaultMessage="Total outputs" />
				</label>
				<NumericInput
					min={2}
					max={20}
					value={fixedState.inputs}
					onChange={handleInputChange}
				/>
			</div>

			<div className={styles.SwitchContainer}>
				<label>Match outputs</label>
				<KemuSwitch size="small" checked={fixedState.matchingOutputs} onChange={handleSwitchChange} />
			</div>
		</div>
	);
};

const getPortsInformation: GetPortsInformationFunction = (state: CustomWidgetState<FirstEventWidgetState>, widgetInfo) => {
	const portContext: WidgetPortContext = {
		recipePoolId: widgetInfo.recipePoolId,
		recipeType: widgetInfo.recipeType,
		thingRecipeId: widgetInfo.thingRecipeId,
		widgetId: widgetInfo.id,
	};
	const outputNames = FirstEventWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = FirstEventWidgetProcessor.getInputNames(state, portContext);

	// const positionByOutputName: Record<string, PortLocation> = {
	// 	'output': [1, 0.3, 1, 0],
	// 	'aborted': [1, 0.7, 1, 0]
	// };

	const inputFraction = ((1 - 0.08) / inputNames.length);

	const getPositionFromIndex = (index: number, isInput: boolean): PortLocation => {
		let space = (inputFraction * (index + 1));
		if (state.inputs === 1) { space = 0.4 + (0.33 * index); }
		if (state.inputs === 2) { space = 0.24 + (0.26 * index); }
		if (state.inputs === 3) { space = 0.2 + (0.2 * index); }
		if (state.inputs === 4) { space = 0.15 + (0.17 * index); }
		if (state.inputs === 5) { space = 0.14 + (0.14 * index); }

		return [isInput ? 0 : 1, space, isInput ? -1 : 1, 0];
	};

	return {
		outputs: outputNames.map((output, i) => ({
			position: state.matchingOutputs ? getPositionFromIndex(i, false) : [1, i === 0 ? 0.33 : 0.66, 1, 0],
			type: output.type,
			name: output.name
		})),

		inputs: inputNames.map((input, i) => {

			return  {
				name: input.name,
				type: input.type,
				// position: [0, space, -1, 0]
				position: getPositionFromIndex(i, true)
			};
		})
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: FirstEventWidget,
	CustomSettingsDialog: GateCustomSettings,
	hasTitle: true,
	getWidgetTitle: (intl) => intl.formatMessage({ id: 'LogicMaker.Gates.FirstEvent.Title', defaultMessage: 'First Event' }),
} as GateUI;
