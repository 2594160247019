import React, { ReactElement } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import styles from './LoadingEnvironmentScreen.module.css';
import RoundedModal from '@components/roundedModal/roundedModal';

type Props = {
  title: ReactElement | string;
}

const LoadingEnvironmentScreen = (props: Props) => {
  return (
    <RoundedModal
      title={props.title}
      visible={true}
      closable={false}
      noFooter={true}
    >
      <div className={styles.Content}>
        <Spin spinning={true} indicator={<LoadingOutlined />} />
      </div>
    </RoundedModal>
  );
};

export default LoadingEnvironmentScreen;
