import React, { useState } from 'react';
import { Row, Col, Spin, Alert } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { LoadingOutlined } from '@ant-design/icons';
import { Auth } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import StyledInput from '../../../components/form-control/styledInput/styledInput';
import StyledButton from '../../../components/form-control/styledButton/styledButton';
import FormGroup from '../../../components/form-control/formGroup/formGroup';
import AuthCard from '../AuthCard/AuthCard';
import routes from '../../../common/routes/index';
import styles from './passwordReset.module.css';

interface Props {
	email: string;
}

interface Message{
	type: 'success' | 'error',
	text: string;
}

const PasswordReset = (props: Props): React.JSX.Element => {
	const intl = useIntl();
	const navigate = useNavigate();
	const [message, setMessage] = useState<Message | null>(null);
	const [processing, setProcessing] = useState(false);
	const [formElements, setFormElements] = useState({
		code: '',
		password: '',
		repeatPassword: '',
	});

	const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
		const elName = event.currentTarget.getAttribute('name') || undefined;
		const value = event.currentTarget.value;
		if (elName !== undefined && value !== undefined) {
			setFormElements(elements => {
				return {
					...elements,
					[elName]: value!
				};
			});
		}
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		const elements = { ...formElements };

		if (elements.password.trim() === '' || elements.repeatPassword.trim() === '') {
			return setMessage({
				text: intl.formatMessage({ id: 'Screen.PasswordReset.PleaseFillOut', defaultMessage: 'Please fill out all the fields' }),
				type: 'error'
			});
		}

		if (elements.password.trim() !== elements.repeatPassword.trim()) {
			return setMessage({
				text: intl.formatMessage({ id: 'Screen.PasswordReset.PasswordMismatch', defaultMessage: 'Passwords don\'t match' }),
				type: 'error'
			});
		}

		setProcessing(true);
		setMessage(null);
		try {
			await Auth.forgotPasswordSubmit(props.email, formElements.code, elements.password);
			// TODO: Show a success message in the login screen
			navigate(routes.auth.getSignInRoute());
		} catch (e) {
				console.log('Error: ', e);
			if (e.code) {
				// Simplify password policy error messages
				if (e.message.indexOf('Password does not conform to policy: ') !== -1) {
					e.message = e.message.replace('Password does not conform to policy: ', '');
				}

				setMessage({
					type: 'error',
					text: intl.formatMessage({ id: `Screen.PasswordReset.${e.code}`, defaultMessage: e.message })
				});
			} else {
				console.log('Error: ', e);
				setMessage({
					type: 'error',
					text: intl.formatMessage({ id: 'Screen.PasswordReset.UnknownError', defaultMessage: 'There was an error processing your request. Please try again later.' })
				});
			}
		}

		setProcessing(false);
	};

	return (
		<AuthCard title={<FormattedMessage id="Screen.PasswordReset.Title" defaultMessage="Forgot Password" />}>
			<Spin indicator={<LoadingOutlined />} spinning={processing}>
				{message && (
					<>
						<Alert closable={false} message={message.text} type={message.type} />
						<br/>
					</>
				)}

				<form method="POST" onSubmit={handleSubmit}>
					<p className="text-muted"><FormattedMessage id="Screen.PasswordReset.Description" /></p>
					<Row>
						<Col span={24}>
							<FormGroup>
								<StyledInput i18nPlaceholderId="Screen.PasswordReset.Code" i18nPlaceholderDefaultMessage="Verification Code" type="text" name="code" onChange={handleChange} value={formElements.code}/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<FormGroup>
								<StyledInput i18nPlaceholderId="Screen.PasswordReset.NewPasswordField" i18nPlaceholderDefaultMessage="New Password" type="password" name="password" onChange={handleChange} value={formElements.password}/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<FormGroup>
								<StyledInput i18nPlaceholderId="Screen.PasswordReset.ConfirmPasswordField" i18nPlaceholderDefaultMessage="Confirm Password" name="repeatPassword" type="password" onChange={handleChange} value={formElements.repeatPassword}/>
							</FormGroup>
						</Col>
					</Row>

					<Row>
						<Col span={24}>
							<FormGroup className={styles.SubmitContainer}>
								<StyledButton title={<FormattedMessage id="Screen.PasswordReset.SubmitButton" defaultMessage="Reset Password" />} type="submit" className={styles.SubmitButton} />
							</FormGroup>
						</Col>
					</Row>
				</form>

				<div>
					<a href={routes.auth.getForgotPasswordRoute()}><FormattedMessage id="Screen.ForgotPassword.ResendCode" defaultMessage="Re-send reset code" /></a>
				</div>
			</Spin>
		</AuthCard>
	);
};

export default PasswordReset;
