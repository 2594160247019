import React, { Suspense } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './WithSuspenseLoader.module.css';
import RoundedModal from '@components/roundedModal/roundedModal';
import useTranslation from '@hooks/useTranslation';

type Props = {
  title?: React.ReactElement | string;
  children: React.ReactNode;
  /** used for testing purposes */
  forceShowLoader?: boolean;
}

// Temporarily replace the lazy-loaded component with one that always suspends
const KeepWaiting = () => {
  throw new Promise(() => ({}));
};


const WithSuspenseLoader = (props: Props) => {
  const t = useTranslation();

  return (
    <Suspense fallback={
      <div>
        <RoundedModal
          transitionName={''} // disable transitions
          maskTransitionName={''}
          title={props.title || t('Interface.LoadingResource')}
          noMask={true}
          visible={true}
          centered={true}
          closable={false}
        >
          <div className={styles.Content}>
            <Spin spinning={true} indicator={<LoadingOutlined />} />
          </div>
        </RoundedModal>
      </div>
    }>
      {props.forceShowLoader && (
        <KeepWaiting />
      )}

      {props.children}
    </Suspense>
  );
};

export default WithSuspenseLoader;
