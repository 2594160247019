import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { findThingInRecipe } from '@kemu-io/kemu-core/common/recipeCache';
import { WidgetBundleState, WidgetType , HubServiceState, HubServiceSubscription } from '@kemu-io/kemu-core/types';
import { LogicMapperState } from '../logicMapperSlice';
import { removeBundleFromRecipeStorage } from './common';
import * as recipeUtils from '@src/app/recipe/utils';
import { StatelessWidgetsMap, WidgetsMap } from '@src/types/core_t';
import { widgetMapToStatelessMap } from '@common/utils';
import ephemeralStorage from '@src/app/ephemeralStorage';
import { addToCleanupList } from '@hooks/useClearBundleCacheOnRefresh';
import connectionManager from '@src/app/kemuHub/connectionManager';

type RemoveWidgetsPayload = {
  /** the id of the recipe in the pool */
  recipeId: string;
  /** the id of the Thing in the recipe the widgets will be removed from */
  thingId: string;
  /** the list of widgets ids in the Thing to be removed */
  widgetsIds: string[];
  // /** if provided, `widgetsIds` is ignored and instead, all the currently selected widgets are removed. */
  // allSelected?: boolean;
  clearSelection?: boolean;
}

/**
 * Removes a list of widgets from the recipe.
 */
export const removeWidgetsAction = createAsyncThunk(
  'LogicMapper/removeWidgets',  async (
    payload: RemoveWidgetsPayload,
): Promise<{
  statelessMap: StatelessWidgetsMap;
  clearSelection?: boolean;
}> => {

  let remainingWidgetsInThing: WidgetsMap = {};
  const thing = findThingInRecipe(payload.recipeId, payload.thingId);
  if (!thing) {
    throw new Error(`Thing [${payload.thingId}] not found in recipe [${payload.recipeId}]`);
  }

  // Will keep track of services to unsubscribe from
  // const hubSubscriptionsToRemove: HubServiceSubscription['targetService'] [] = [];
  const subscriptionRemovalMap: Record<string, HubServiceSubscription['targetService']> = {};

  // const widgetsInThing = Object.keys(thing.gates);
  for (const widgetId of payload.widgetsIds) {
    // const widget = findWidgetInRecipe(payload.recipeId, payload.thingId, widgetId);
    const widget = thing.gates[widgetId];
    // If a widget bundle, make sure to remove is /tmp location in cache.
    if (widget?.type === WidgetType.widgetBundle) {
      const widgetState = widget.state as unknown as WidgetBundleState;
      // UPDATE 05/Feb/2024: If a user copies a bundle, removes it and attempts to paste it,
      // the reference to the cache location would not exist if we remove it here. 
      // Thus, commenting out the removal of the cache location.

      // Instead, we should add it to the cleanup list from `useClearBundleCacheOnRefresh`
      if (widgetState.$$cacheInfo) {
        const isTempBundle = widgetState.$$cacheInfo?.widgetThingId
                !== widgetState.collectionInfo?.widgetId;

        if (isTempBundle) {
          addToCleanupList({
            version: String(widgetState.$$cacheInfo.version),
            widgetId: widgetState.$$cacheInfo.widgetThingId,
          });
        }
      }

      // if (widgetState.$$cacheInfo) {
      //   const isTempStorage = widgetState.collectionInfo?.widgetId !== widgetState.$$cacheInfo.widgetThingId;
      //   if (isTempStorage) {
      //     await widgetBundleManager.removeWidgetFilesFromCache(
      //       widgetState.$$cacheInfo.widgetThingId,
      //       String(widgetState.$$cacheInfo.version),
      //       true
      //     );
      //   }
      // }


      // Also, remove data from storage unit as long as it's not being used by other bundles
      if (widgetState.storageUnitId) {
        // Pasting could brake if bundles are removed AFTER they are copied 
        // to the clipboard. Here we move the data to a temporal location in memory
        // that lasts until the page is reloaded.
        const currentData = recipeUtils.getStorageUnitFromKey(payload.recipeId, payload.thingId, widgetState.storageUnitId);
        if (currentData) {
          const dataCopy = new Uint8Array(currentData);
          ephemeralStorage.set(widgetState.storageUnitId, dataCopy);
        }

        // NOTE: Every widget (even copies) have their own storage unit id, so 
        // there is no need to check if other widgets are using the "to be removed" bundle storage.
        removeBundleFromRecipeStorage(payload.recipeId, payload.thingId, widgetState.storageUnitId);
      }
    }

    // Keep track of which subscriptions need to be removed.
    if (widget.type === WidgetType.hubService) {
      const hubServiceState = widget.state as HubServiceState;
      if (hubServiceState.service?.eventEmitter) {
        const key = `${hubServiceState.service.name}_${hubServiceState.service.version}`;
        if (!subscriptionRemovalMap[key]) {
          subscriptionRemovalMap[key] = {
            serviceName: hubServiceState.service.name,
            version: hubServiceState.service.version
          };
        }
      }
    }

    // Finally remove the actual widget reference from the recipe
    remainingWidgetsInThing = await recipeUtils.removeWidget(payload.recipeId, payload.thingId, widgetId);
  }

  // Check if there is any remaining widget also subscribed to any services to be removed
  for (const widgetId of Object.keys(remainingWidgetsInThing)) {
    const widget = remainingWidgetsInThing[widgetId];
    if (widget.type === WidgetType.hubService) {
      const hubServiceState = widget.state as HubServiceState;
      if (hubServiceState.service?.eventEmitter) {
        const key = `${hubServiceState.service.name}_${hubServiceState.service.version}`;
        if (subscriptionRemovalMap[key]) {
          delete subscriptionRemovalMap[key];
        }
      }
    }
  }

  // Unsubscribe from services
  const subscriptionsToRemove = Object.values(subscriptionRemovalMap);
  if (subscriptionsToRemove.length > 0) {
    const hc = connectionManager.getConnector();
    for (const subscription of subscriptionsToRemove) {
      hc.unsubscribeFromServiceEvents({
        targetService: subscription,
        listener: {
          recipeId: payload.recipeId
        }
      });
    }
  }


  const statelessMap = widgetMapToStatelessMap(remainingWidgetsInThing);
  return {
    statelessMap,
    clearSelection: payload.clearSelection
  };
});

export const removeWidgetsReducer = ((builder: ActionReducerMapBuilder<LogicMapperState>): void => {
  builder.addCase(removeWidgetsAction.fulfilled, (state, action) => {
    if (action.payload.clearSelection) {
      state.selectedWidgets = [];
    }

    state.gates = action.payload.statelessMap;
  });

  builder.addCase(removeWidgetsAction.rejected, (_draft, action) => {
    console.error('Failed to add widget from bundle: ', action.error);
  });
});
