
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Icon from '@ant-design/icons';
import classNames from 'classnames';
import VariableWidgetProcessor, {
	VariableWidgetState,
	getDefaultState
} from '@kemu-io/kemu-core/widgets/variable/index.js';
import variablesManager, {
	VarDefinitionChangeEvt
} from '@kemu-io/kemu-core/common/managers/variablesManager.js';
import { WidgetPortContext , VariableDefinition } from '@kemu-io/kemu-core/types';
import { Menu, Dropdown } from 'antd';
import { DataType } from '@kemu-io/hs-types';
import { GetPortsInformationFunction, GateUI, GateUIProps, GateCustomSettingsProps } from '../index';
import GateIcon from '../../gateIcon/gateIcon';
import { PortLocation } from '../../../types/canvas_t';
import { buildOutputPortsDescription } from '../common';
import styles from './variable.module.css';
import EditableTextbox from '@components/EditableTextbox/EditableTextbox';
import { ReactComponent as VariableWidgetIcon } from '@src/assets/img/gates/variable-widget.svg';
import useReactiveWidgetState, { NewStateOrFunction } from '@hooks/useReactiveWidgetState';
import useTranslation from '@hooks/useTranslation';
import { dataTypeToHumanReadable, getLastInputPortWithParent } from '@common/utils';
import { SETTINGS_CONTAINER_CLASS } from '@common/constants';
import KemuSwitch from '@components/form-control/kemuSwitch/KemuSwitch';
import { ReactComponent as JsonWidgetIcon } from '@src/assets/img/dataTypes/icon.svg';
import { ReactComponent as NumberIcon } from '@src/assets/img/dataTypes/numberIcon.svg';
import { ReactComponent as StringIcon } from '@src/assets/img/dataTypes/stringIcon.svg';
import { ReactComponent as ArrayIcon } from '@src/assets/img/dataTypes/arrayIcon.svg';
import { ReactComponent as ImageIcon } from '@src/assets/img/dataTypes/imageIcon.svg';
import StyledButton from '@components/form-control/styledButton/styledButton';

const createVariableDefinition = (
	setStateFn: (newState: NewStateOrFunction<VariableWidgetState>, triggerEvents?: boolean | undefined) => void,
	recipePoolId: string,
	thingRecipeId: string,
	widgetId: string,
	name: string,
	definition?: Partial<VariableDefinition & {
		reactive?: boolean;
	}>,
) => {
	setStateFn((current) => {
		const finalDefinition: VariableWidgetState =  {
			...current,
			...definition,
			name: name,
		};

		// Remove old subscription
		if (current.name && current.name !== name) {
			variablesManager.removeVariableDefinition(recipePoolId, thingRecipeId, widgetId, current.name,  {
				removeAllListeners: true,
			});
		}

		variablesManager.defineThingVariable(
			recipePoolId,
			thingRecipeId,
			widgetId,
			name,
			finalDefinition.type,
			{
				defaultValue: finalDefinition.defaultValue,
				storeValueInRecipe: definition?.storeValueInRecipe,
				isCustomFieldVar: false,
			}
		);

		return finalDefinition;
	});
};


const updateVariableSubscription = (
	setStateFn: (newState: NewStateOrFunction<VariableWidgetState>) => void,
	recipePoolId: string,
	thingRecipeId: string,
	widgetId: string,
	name: string,
) => {
	const variableNames = variablesManager.getVariableNames(recipePoolId, thingRecipeId, widgetId);
	const currentVariable = variableNames.find(variable => variable.name === name);
	if (!currentVariable) { return; }

	setStateFn((current) => {
		// Remove old subscription
		if (current.name && current.name !== name) {
			variablesManager.unsubscribeWidgetFromVariableChanges(recipePoolId, thingRecipeId, current.name, widgetId);
		}

		// Add new subscription
		variablesManager.subscribeWidgetToVariableChanges(recipePoolId, thingRecipeId, name, widgetId);
		return {
			...current,
			type: currentVariable.type,
			name,
		};
	});
};

const VariableWidget = (props: GateUIProps): React.JSX.Element => {
	const { recipeId, thingRecipeId, info } = props;
	const { id: widgetId } = info;
	const [state, setWidgetState] = useReactiveWidgetState<VariableWidgetState>(recipeId, thingRecipeId, info.id);
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const dropdownCntRef = useRef<HTMLDivElement>(null);
	// Use to force the variable names to update
	const [varCount, setVarCount] = useState(0);
	const t = useTranslation();

	const noNameSet = !!state.name;
	const selectedVarName = state.name;

	const reloadVarNames = useCallback(() => setVarCount(c => c + 1), []);
	// Get variable names
	const variableNames = useMemo(() => {
		const currentVars = variablesManager.getVariableNames(recipeId, thingRecipeId, widgetId);
		return currentVars;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		recipeId,
		thingRecipeId,
		widgetId,
		state.name,
		state.type,
		state.reactive,
		varCount,
	]);

	const selectedVarOwner = variableNames.find(variable => variable.name === selectedVarName)?.ownerWidgetId;
	const widgetIsVarOwner = selectedVarOwner === widgetId;

	const hideMenu = useCallback(() => {
		setDropdownVisible(false);
	}, []);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleMenuClick = useCallback((info: any) => {
		updateVariableSubscription(
			setWidgetState,
			recipeId,
			thingRecipeId,
			widgetId,
			info.key as string,
		);

		hideMenu();
	}, [setWidgetState, hideMenu, recipeId, thingRecipeId, widgetId]);



	// Subscribe to variable definition changes
	useEffect(() => {
		const vName = selectedVarName;
		const vOwner = selectedVarOwner;

		const handleVarDefinitionChanges = (event: VarDefinitionChangeEvt) => {
			const varRemoved = event.changes.includes('removed');
			// Ignore my own changes
			if (varRemoved && event.ownerId === widgetId) { return; }

			if (varRemoved || event.changes.includes('type')) {
				setWidgetState((current) => {
					if (current.name === event.variableName) {
						if (varRemoved) {
							return getDefaultState();
						}

						if (event.varDefinition) {
							return {
								...current,
								type: event.varDefinition.type,
								name: event.variableName,
							};
						}

						return current;
					}

					return current;
				});
			}
		};

		if (vName && vOwner) {
			variablesManager.onVariableDefinitionChange(handleVarDefinitionChanges, thingRecipeId, vOwner, vName);
		}

		return () => {
			if (vName && vOwner) {
				variablesManager.offVariableDefinitionChange(handleVarDefinitionChanges, thingRecipeId, vOwner, vName);
			}
		};
	}, [selectedVarName, thingRecipeId, widgetId, setWidgetState, selectedVarOwner]);


	const menu = (
		<Menu className={styles.DropdownMenu} onClick={handleMenuClick} selectedKeys={[state.name || '']}>
			{variableNames.map((variable) => (
				<Menu.Item key={`${variable.name}`}>
					{variable.name}
					<span className={styles.VarType}>{dataTypeToHumanReadable(t, variable.type)}</span>
				</Menu.Item>
			))}
		</Menu>
	);

	const onNewInputEntered = useCallback((name: string) => {
		if (!name.trim()) { return; }
		const exists = variableNames.find(input => input.name === name);
		if (!exists) {
			createVariableDefinition(
				setWidgetState,
				recipeId,
				thingRecipeId,
				widgetId,
				name,
			);
		} else {
			updateVariableSubscription(
				setWidgetState,
				recipeId,
				thingRecipeId,
				widgetId,
				name
			);
		}

		setDropdownVisible(false);
	}, [setWidgetState, variableNames, recipeId, thingRecipeId, widgetId]);

	const dropdownVisibilityChanged = useCallback((visible: boolean) => {
		setDropdownVisible(visible);
		if (visible) {
			reloadVarNames();
		}
	}, [reloadVarNames]);

	const getDropdownContainerEl = useCallback(() => {
		return dropdownCntRef.current as HTMLElement;
	}, []);

	useEffect(() => {
		if (!noNameSet) {
			const defaultState = getDefaultState();
			createVariableDefinition(
				setWidgetState,
				recipeId,
				thingRecipeId,
				widgetId,
				defaultState.name,
				{
					createdByWidgetId: widgetId,
					storeValueInRecipe: false,
					reactive: defaultState.reactive,
					type: defaultState.type,
					value: defaultState.defaultValue,
				}
			);
		}
	}, [noNameSet, setWidgetState, recipeId, thingRecipeId, widgetId]);

	// Force dropdown to close when the widget is moved
	useEffect(() => {
		setDropdownVisible(false);
	}, [info]);

	return (
		<div
			ref={dropdownCntRef}
			className={classNames(styles.GateBody, {
				[styles.WidgetDisabled]: props.info.disabled,
			})}
		>
			{/* <Icon component={VariableWidgetIcon}/> */}
			<Dropdown
				getPopupContainer={getDropdownContainerEl}
				overlay={menu}
				visible={dropdownVisible}
				className="gate-dropdown"
			>
				<EditableTextbox
					placeholder={t('PressEnter', 'Press ENTER')}
					onEnterKey={onNewInputEntered}
					defaultText={state.name}
					onFocusChanged={dropdownVisibilityChanged}
					dropdownIconClassName={styles.DropArrow}
					inputClassName={classNames(styles.InputElement, {
						[styles.VarOwner]: widgetIsVarOwner,
					})}
					editing={dropdownVisible}
				/>
			</Dropdown>
		</div>
	);
};

const GateCustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
	const { recipeId, blockId: thingRecipeId, gateInfo, onClose } = props;
	const widgetId = gateInfo.id;
	const [state, setWidgetState] = useReactiveWidgetState<VariableWidgetState>(recipeId, thingRecipeId, widgetId);
	const availableVariables = variablesManager.getVariableNames(recipeId, thingRecipeId, widgetId);
	const fixedState = {
		...getDefaultState(),
		...state
	};

	const [tmpState, setTmpState] = useState<VariableWidgetState>(fixedState);
	const stateChanged = JSON.stringify(tmpState) !== JSON.stringify(fixedState);
	const currentVariableName = tmpState.name;
	const rawT = useTranslation();
	const dt = useTranslation('Kemu.DataType.HumanReadable');
	const t = useTranslation('LogicMaker.Widgets.Variable.Settings');
	// const gT = useTranslation('LogicMaker.Widgets.Generic');

	const ownSelectedVar = availableVariables.find(variable =>
		variable.name === currentVariableName
		&& variable.ownerWidgetId === widgetId
	);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onUpdateType = useCallback(({ key }: any) => {
		setTmpState((current) => ({
			...current,
			type: Number(key),
		}));
	}, []);

	const typesMenu = useMemo(() => (
		<Menu className={styles.TypesMenu} onClick={onUpdateType}>
			<Menu.Item key={DataType.Number}>
				<span><Icon component={NumberIcon} /></span> {dt('Number', 'Number')}
			</Menu.Item>
			<Menu.Item  key={DataType.String}>
				<span><Icon component={StringIcon} /></span> {dt('String', 'String')}
			</Menu.Item>
			<Menu.Item  key={DataType.Array}>
				<span><Icon component={ArrayIcon} /></span> {dt('Array', 'Array')}
			</Menu.Item>
			<Menu.Item  key={DataType.JsonObj}>
				<span><Icon component={JsonWidgetIcon} /></span> {dt('JsonObj', 'Object')}
			</Menu.Item>
			<Menu.Item  key={DataType.ImageData}>
				<span><Icon component={ImageIcon} /></span> {dt('ImageData', 'Image')}
			</Menu.Item>
		</Menu>
	), [dt, onUpdateType]);

	const getLastConnectedInput = () => {
		return getLastInputPortWithParent(
			tmpState,
			VariableWidgetProcessor,
			props.gateInfo.id,
			props.blockId,
			props.recipeId,
			props.recipeType
		);
	};

	const handleDefaultValueChange = (event: ChangeEvent<HTMLInputElement>) => {
		setTmpState((current) => ({
			...current,
			defaultValue: event.target.value,
		}));
	};

	const handleSaveSettings = useCallback(() => {
		createVariableDefinition(
			setWidgetState,
			recipeId,
			thingRecipeId,
			widgetId,
			currentVariableName,
			tmpState,
		);

		onClose();
	}, [onClose, setWidgetState, recipeId, thingRecipeId, widgetId, currentVariableName, tmpState]);

	// TODO: Register the reactToVariable map in the widget itself
	const handleSwitchChange = (checked: boolean) => {
		const lastPortWithConnection = getLastConnectedInput();
		// if (warnIfInputAttached(lastPortWithConnection)) { return ; }
		setTmpState((current) => ({
			...current,
			reactive: checked,
		}));
	};

	return (
		<div className={classNames(styles.SettingsContainer, SETTINGS_CONTAINER_CLASS)}>
			{props.children}

			{ownSelectedVar && (
				<>
					<div className={styles.Row}>
						<div className={styles.Label}>Type</div>
						<div className={styles.Element}>
							<span className={styles.TypeName}>{dataTypeToHumanReadable(rawT, tmpState.type)}</span>
							<Dropdown overlay={typesMenu} placement="bottomLeft" trigger={['click']} >
								<span className={classNames(styles.ChooseType, `type-${tmpState.type}`)}>
									{tmpState.type === DataType.Number && (
										<Icon component={NumberIcon}/>
									)}

									{tmpState.type === DataType.String && (
										<Icon component={StringIcon} />
									)}

									{tmpState.type === DataType.Array && (
										<Icon component={ArrayIcon} />
									)}

									{tmpState.type === DataType.JsonObj && (
										<Icon component={JsonWidgetIcon} />
									)}

									{tmpState.type === DataType.ImageData && (
										<Icon component={ImageIcon} />
									)}
								</span>
							</Dropdown>
						</div>
					</div>

					{(tmpState.type === DataType.String || tmpState.type === DataType.Number) && (
						<div className={styles.Row}>
							<div className={styles.Label}>{t('DefaultValue', 'Default value')}</div>
							<div className={styles.Element}>
								<input
									className={styles.DefaultValue}
									type={tmpState.type === DataType.String ? 'text' : 'number'}
									value={tmpState.defaultValue}
									onChange={handleDefaultValueChange}
								/>
							</div>
						</div>
					)}
				</>
			)}


			<div className={styles.Row}>
				<div className={styles.Label}>{t('Switch', 'React to value changes')}</div>
				<div className={styles.Element}>
					<KemuSwitch
						size="small"
						checked={tmpState.reactive}
						onChange={handleSwitchChange}
					/>
				</div>
			</div>

			<div className={styles.Row}>
				<div className={styles.SaveBtn}>
					<StyledButton
						title={t('Save', 'Save')}
						onClick={handleSaveSettings}
						noShadow={true}
						disabled={!stateChanged}
					/>
				</div>
			</div>
		</div>
	);
};


/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={VariableWidgetIcon} />}/>
	);
};

const getPortsInformation: GetPortsInformationFunction = (state, widgetInfo) => {
	const portContext: WidgetPortContext = {
		recipePoolId: widgetInfo.recipePoolId,
		recipeType: widgetInfo.recipeType,
		thingRecipeId: widgetInfo.thingRecipeId,
		widgetId: widgetInfo.id,
	};

	const outputNames = VariableWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = VariableWidgetProcessor.getInputNames(state, portContext);

	const outputs = buildOutputPortsDescription(outputNames, 0.14);
	const positions: Record<string, PortLocation> = {
		'set': [0, 0.3, -1, 0],
		'trigger': [0, 0.7, -1, 0],
	};

	return {
		inputs: inputNames.map(input => ({
			name: input.name,
			type: input.type,
			position: positions[input.name]
		})),

		outputs,
	};
};


export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: VariableWidget,
	CustomSettingsDialog: GateCustomSettings,
	hasTitle: true,
	getWidgetTitle: (intl) => intl.formatMessage({ defaultMessage: 'Variable', id: 'LogicMapper.Widgets.Variable.Title' }),
} as GateUI;
