
import React, { useCallback } from 'react';
import Icon from '@ant-design/icons';
import ArrayCombineWidgetProcessor, { ArrayCombineState, getDefaultState } from '@kemu-io/kemu-core/widgets/arrayCombine/index.js';
import { FormattedMessage } from 'react-intl';
import { CustomWidgetState, WidgetPortContext } from '@kemu-io/kemu-core/types';
import classNames from 'classnames';
import GateIcon from '../../gateIcon/gateIcon';
import {
	GateCustomSettingsProps,
	GetPortsInformationFunction,
	GateUI,
	GateUIProps
} from '../index.ts';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import useTranslation from '../../../common/hooks/useTranslation';
import { PortLocation } from '../../../types/canvas_t';
import NumericInput from '../../WidgetsComponents/NumericInput/NumericInput';
import { SETTINGS_CONTAINER_CLASS } from '../../../common/constants';
import styles from './arrayCombine.module.css';
import { ReactComponent as ArrayCombineIcon } from './icon.svg';
import { removeWidgetPortConnections } from '@src/app/recipe/utils';

type Props = GateUIProps


const mergeStates = (state: ArrayCombineState) => {
	const fixedState: ArrayCombineState = {
		...getDefaultState(),
		...state
	};

	return fixedState;
};

const CombineArrayWidget = (props: Props): React.JSX.Element => {
	const [state] = useReactiveWidgetState<ArrayCombineState>(props.recipeId, props.thingRecipeId, props.info.id);
	const fixedState = mergeStates(state);

	// 54 = Default height
	// -1 = default height is calculated based on a minimum input number of 1
	// 10 = extra pixels per added port
	const bodyHeight = 30 + ((fixedState.totalInputs - 1) * 13.5);

	return (
		<div className={`${styles.GateBody}`}>

			<div className={styles.InnerBody} style={{ height: bodyHeight }}>
				<div className={`gate-input`}>
					<span>{fixedState.$$list.length} items</span>
				</div>
			</div>

		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={ArrayCombineIcon} />}/>
	);
};

const GateCustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
	const { recipeId, blockId, gateInfo, recipeType } = props;
	const [state, setState] = useReactiveWidgetState<ArrayCombineState>(props.recipeId, props.blockId, props.gateInfo.id);
	const fixedState = mergeStates(state);
	const t = useTranslation('LogicMaker.Gates.FirstEvent.');


	const handleInputChange = useCallback((value: number) => {
		setState((current) => {
			const portRemoved = current.totalInputs > value;
			if (portRemoved) {
				// Get the current inputs
				const inputNames = ArrayCombineWidgetProcessor.getInputNames(current, {
					recipePoolId: recipeId,
					recipeType: recipeType,
					thingRecipeId: blockId,
					widgetId: gateInfo.id,
				});

				// Ignore fixed ports
				const dynamicInputs = inputNames.filter((input) => input.name.startsWith('array'));
				// Get the last input port
				const inputToRemove = dynamicInputs[dynamicInputs.length - 1];
				// Disconnect anything from the last input port
				if (inputToRemove) {
					removeWidgetPortConnections(
						recipeId,
						blockId,
						gateInfo.id,
						inputToRemove.name,
					);
				}
			}

			return {
				...current,
				totalInputs: value
			};
		}, true);
	}, [setState, blockId, gateInfo.id, recipeId, recipeType]);

	return (
		<div className={classNames(styles.SettingsContainer, SETTINGS_CONTAINER_CLASS)}>
			{props.children}
			<div className={styles.InputContainer}>
				<label>
					<FormattedMessage id="LogicMaker.Gates.ArrayCombine.Settings.Title" defaultMessage="Total inputs" />
				</label>
				<NumericInput
					min={1}
					max={20}
					value={fixedState.totalInputs}
					onChange={handleInputChange}
				/>
			</div>
		</div>
	);
};

const getPortsInformation: GetPortsInformationFunction = (state: CustomWidgetState<ArrayCombineState>, widgetInfo) => {
	const portContext: WidgetPortContext = {
		recipePoolId: widgetInfo.recipePoolId,
		recipeType: widgetInfo.recipeType,
		thingRecipeId: widgetInfo.thingRecipeId,
		widgetId: widgetInfo.id,
	};
	const outputNames = ArrayCombineWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = ArrayCombineWidgetProcessor.getInputNames(state, portContext);

	const positionByOutputName: Record<string, PortLocation> = {
		'output': [1, 0.33, 1, 0],
		'totalItems': [1, 0.66, 1, 0]
	};

	const portSize = 0.01;
	const topPadding = inputNames.length <= 12 ? 0.09 : 0.05;
	const inputFraction = ((1 - (topPadding + portSize)) / inputNames.length);

	const getPositionFromIndex = (index: number): PortLocation => {
		let space = (inputFraction * index) + topPadding;
		if (state.totalInputs === 1) { space = 0.2 + (0.30 * index); }
		if (state.totalInputs === 2) { space = 0.15 + (0.23 * index); }
		if (state.totalInputs === 3) { space = 0.14 + (0.18 * index); }
		if (state.totalInputs === 4) { space = 0.11 + (0.16 * index); }
		if (state.totalInputs === 5) { space = 0.08 + (0.14 * index); }
		if (state.totalInputs === 6) { space = 0.07 + (0.12 * index); }

		return [0, space, -1, 0];
	};

	return {
		outputs: outputNames.map((output) => ({
			position: positionByOutputName[output.name],
			type: output.type,
			name: output.name,
		})),

		inputs: inputNames.map((input, i) => {

			return  {
				name: input.name,
				type: input.type,
				position: getPositionFromIndex(i)
			};
		})
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: CombineArrayWidget,
	CustomSettingsDialog: GateCustomSettings,
	hasTitle: true,
	getWidgetTitle: (intl) => intl.formatMessage({ id: 'LogicMaker.Gates.ArrayCombine.Title', defaultMessage: 'Combine Array' }),
} as GateUI;
