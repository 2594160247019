import React, { memo, MouseEventHandler, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDrag } from 'react-dnd';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import WarningFilled from '@ant-design/icons/WarningFilled';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Position } from '@kemu-io/kemu-core/types';
import { CustomWidgetVariant } from '@kemu-io/kemu-types';
import { satisfies } from 'compare-versions';
import { Tooltip } from 'antd';
import CustomWidgetIcon from '../CustomWidgetIcon/CustomWidgetIcon';
import styles from './LaunchpadWidgetIcon.module.css';
import { FolderPathInfo } from '@src/features/Workspace/workspaceSlice';
import { DroppableTypes } from '@common/constants';
import TruncatedText from '@components/truncatedText/truncatedText';

export interface LaunchpadWidgetInfo {
	name: string;
	icon?: string;
	color?: string;
	id: string;
	variant: CustomWidgetVariant;
	protocolVersion?: string;
}
interface Props {
	widgetInfo: LaunchpadWidgetInfo;
	currentFolder: FolderPathInfo | null;
	recipePoolId: string;
	/** indicates the widget comes from a Thing */
	// isThingTemplate: boolean;
	isSelected: boolean;
	disabled?: boolean;
	onClick?: (widgetId: string) => void;
	onDelete?: (widgetId: string) => void;
	onAddWidget: (widgetId: string, dropLocation: Position) => void;
	onHideLaunchpad: () => void;
}


const LaunchpadWidgetIcon = (props: Props): React.JSX.Element => {
	const [textExpanded, setTextExpanded] = useState(false);
	const { onAddWidget, widgetInfo, onHideLaunchpad } = props;

	const isV2 = satisfies(widgetInfo.protocolVersion || '0', '>=2.0');

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [{ isDragging }, drag, preview] = useDrag<any, Position, any>(() => ({
    type: DroppableTypes.CustomWidget,
		canDrag: !props.disabled,
    item: {
			color: widgetInfo.color,
			icon: widgetInfo.icon,
			isBundle: widgetInfo.variant === CustomWidgetVariant.Bundle,
		},
    end: (item, monitor) => {
      const dropLocation = monitor.getDropResult();
			if (dropLocation) {
				onAddWidget(props.widgetInfo.id, {
					x: dropLocation.x,
					y: dropLocation.y,
				});
			}
    },

    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }), [widgetInfo, onAddWidget]);


	const handleDelete: MouseEventHandler = (event): void => {
		event.stopPropagation();
		props.onDelete && props.onDelete(props.widgetInfo.id);
	};


	const textVisibilityChanged = (visible: boolean) => {
		setTextExpanded(visible);
	};


	useEffect(() => {
		const emptyImage = getEmptyImage();
		preview(emptyImage);
		if (isDragging) {
			onHideLaunchpad();
		}
	}, [preview, isDragging, onHideLaunchpad]);

	return (
		<>
			<div
				className={
					classNames(styles.CustomWidgetButton, {
						'text-expanded': textExpanded,
						'selected': props.isSelected ,
						'disabled': props.disabled
					})
				}
				data-kemu-type={`custom-widget-${props.widgetInfo.id}`}
				ref={drag}
				onClick={() => props.onClick && props.onClick(props.widgetInfo.id)}
			>

				<CustomWidgetIcon
					color={widgetInfo.color}
					icon={widgetInfo.icon}
					disabled={props.disabled}
					isBundle={widgetInfo.variant === CustomWidgetVariant.Bundle}
				/>

				{props.onDelete && (
					<div className={styles.DeleteBtn} onClick={handleDelete}>
						<CloseCircleFilled />
					</div>
				)}

				{!isV2 && widgetInfo.variant === CustomWidgetVariant.Group &&  (
					<Tooltip title="This widget is not fully compatible with the current version of the platform. Connections between widgets will be removed.">
						<div className={styles.WarningBtn}>
							<WarningFilled />
						</div>
					</Tooltip>
				)}

				<div className="noselect gate-name">
					<label>
						<TruncatedText
							text={props.widgetInfo.name}
							maxChars={24}
							onVisibilityChange={textVisibilityChanged}
							showFullOn="hover"
						/>
					</label>
				</div>
			</div>
		</>
	);
};

export default memo(LaunchpadWidgetIcon, (prevProps, nextProps) => {
	return prevProps.isSelected === nextProps.isSelected &&
		prevProps.disabled === nextProps.disabled &&
		prevProps.widgetInfo.id === nextProps.widgetInfo.id &&
		prevProps.widgetInfo.color === nextProps.widgetInfo.color &&
		prevProps.widgetInfo.icon === nextProps.widgetInfo.icon &&
		prevProps.widgetInfo.name === nextProps.widgetInfo.name &&
		prevProps.widgetInfo.variant === nextProps.widgetInfo.variant;
});
