import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { CustomWidgetVariant } from '@kemu-io/kemu-types';
import { buildWidgetRequestBody, dbEntityToCollection } from './utils';
import widgetCache from './widgetCache';
import { WidgetCollectionState, WidgetCollectionItem } from '@src/app/reducers/widget/widgetSlice';
import * as widgetApi from '@src/api/widget/widgetApi';
import { uploadFile } from '@src/api/utils';
import { AsyncRequestStatus } from '@src/types/core_t';
import { editCustomWidgetState } from '@src/app/recipe/utils';
import { getGlobalIntl } from '@src/assets/i18n/globalIntl';

/**
 * Updates the given widget in the database and uploads the contents to S3.
 * It also adds the updated version of the widget to the local cache.
*/
export const updateWidgetAction = createAsyncThunk('/widgets/updateWidgetAction', async (
	args: {
		widgetDbId: string;
		widgetRecipeId: string;
		recipeId: string;
		thingId: string;
		variant: CustomWidgetVariant;
	},
): Promise<WidgetCollectionItem> => {

	const t = getGlobalIntl();

	const {
		requestObject,
		contentsBuf,
		contentsStr,
		widgetState
	} = buildWidgetRequestBody(args.widgetRecipeId, args.thingId, args.recipeId, t, args.variant);

	const response = await widgetApi.updateWidget(args.widgetDbId, requestObject);

	// Store the widget locally
	await widgetCache.storeWidget(response.widget.id, response.widget.version, contentsStr);

	// If the upload url is missing, it means the widget does not need to be updated;
	if (!response.uploadUrl) {
		return dbEntityToCollection(contentsStr, response.widget);
	}

	// Upload file to S3
	await uploadFile(response.uploadUrl, contentsBuf);

	// Update the reference in the recipe
	editCustomWidgetState(args.recipeId, args.thingId, args.widgetRecipeId, {
		...widgetState,
		collectionInfo: {
			userId: response.widget.userId,
			version: response.widget.version,
			widgetId: response.widget.id
		}
	}, true);

	return dbEntityToCollection(contentsStr, response.widget);
});


export const updateWidgetReducer = ((builder: ActionReducerMapBuilder<WidgetCollectionState>/*, thunk: any*/): void => {

	builder.addCase(updateWidgetAction.pending, (state) => {
		const t = getGlobalIntl();
		state.asyncIndicator = {
			title: t('Widget.AsyncIndicator.Progress', '{action} Widget...', { action: 'Updating' }),
			asyncStatus: {
				status: AsyncRequestStatus.loading,
				error: undefined,
			}
		};
	});

	builder.addCase(updateWidgetAction.rejected, (state, action) => {
		const t = getGlobalIntl();
		state.asyncIndicator = {
			title: t('Widget.AsyncIndicator.Failure', 'Failed to {action} widget: ', { action: 'update' }),
			asyncStatus: {
				status: AsyncRequestStatus.error,
				error: action.error,
			}
		};
	});

	builder.addCase(updateWidgetAction.fulfilled, (state, action) => {
		const t = getGlobalIntl();
		state.asyncIndicator = {
			title: t('Widget.AsyncIndicator.Success', 'Widget {action}!', { action: 'updated' }),
			asyncStatus: {
				status: AsyncRequestStatus.completed,
				error: undefined,
			}
		};

		const otherWidgets = state.customWidgets.filter(widget => widget.dbId !== action.payload.dbId);

		state.customWidgets = [
			...otherWidgets,
			action.payload
		];

	});
});

