import React, { DragEventHandler, useCallback, useState } from 'react';
import { Upload } from 'antd';
import classNames from 'classnames';
import styles from './FileDropZone.module.css';

type Props = {
  onFileAdded: (data: Uint8Array) => void;
  className?: string;
  noFileElement: React.ReactNode;
  yesFileElement: React.ReactNode;
  accept: string;
  fileLoaded: boolean;
  hoverClass?: string;
}

type Options = {
  file: Blob;
}

const FileDropZone = (props: Props) => {
  const { onFileAdded, yesFileElement, noFileElement, accept, fileLoaded } = props;
  const [dropActive, setDropActive] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCustomRequest = useCallback(async (options: any) => {
    const binaryData = await (options as Options).file.arrayBuffer();
    const uint8 = new Uint8Array(binaryData);
    onFileAdded(uint8);
    setDropActive(false);
  }, [onFileAdded]);


  const handleDragEnter: DragEventHandler = (event) => {
    event.stopPropagation();
    setDropActive(true);
  };

  const handleDragLeave: DragEventHandler = (event) => {
    event.stopPropagation();
    setDropActive(false);
  };

  return (
    <div
      onDragOver={handleDragEnter}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDragLeave}
      className={styles.DropWrapper}
    >
      <Upload
        listType="text"
        className={classNames(props.className, {
          'dropzone-active': dropActive,
          [props.hoverClass || '']: dropActive
        })}

        accept={accept}
        showUploadList={false}
        customRequest={handleCustomRequest}
      >
        {fileLoaded ? (
          <>
            {yesFileElement}
          </>
        ) : (
          <>
            {noFileElement}
          </>
        )}
      </Upload>
    </div>
  );
};

export default FileDropZone;
