import { useCallback } from 'react';
import useActiveHubServices from './useActiveHubServices';

/**
 * @returns a function that can be use to get the latest
 * information about a hub service. 
 */
const useHubServiceInfo = () => {
  const services = useActiveHubServices();

  /**
   * @returns the latest information about a hub service or null if not found.
   */
  const getInfo = useCallback((serviceName: string, version: string) => {
    const match = services.find(s => s.name === serviceName && s.version === version);
    return match || null;
  }, [services]);

  return getInfo;
};

export default useHubServiceInfo;
