import React, { useState } from 'react';
import { Spin, Upload, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { DraggerProps, UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { AssetSection } from '@kemu-io/kemu-types';
import { UploadedAsset } from '../publisher';
import styles from './uploaderWithPreview.module.css';
import { getUploadUrl, deleteAsset, uploadAsset } from '@src/api/marketplace/marketplaceApi';
import FormGroup from '@components/form-control/formGroup/formGroup';


interface Props {
	label: React.ReactNode;
	title: React.ReactNode;
	description: React.ReactNode;
	icon?: React.ReactNode;
	className?: string;
	assets: UploadedAsset[];
	onFileAdded: (assets: UploadedAsset) => void;
	onFileRemoved: (assets: UploadedAsset) => void;
	recipeId: string;
	/** allows multiple files to be uploaded */
	multiple: boolean;
	/** unique key for the section. Eg: 'hero', 'thumbnail' */
	section: AssetSection;
	acceptedTypes: string;
}

const { Dragger } = Upload;

const getBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
		if (!file) { return resolve(''); }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
};


const UploaderWithPreview = (props: Props): React.JSX.Element => {
	const [inAsyncProcess, setInAsyncProcess] = useState<{[key in AssetSection]?: boolean}>({});
	const [imagePreview, setImagePreview] = useState<string | null>(null);


	const getBaseDraggerProps = (section: AssetSection) => {
		return {
			name: 'file',
			multiple: props.multiple,
			headers: {
				'content-type': 'application/octet-stream'
			},
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			customRequest: async (options: any) => {
				const binaryData = await (options.file as Blob).arrayBuffer();
				const results = await uploadAsset(options.action, binaryData, ((progressEvent) => {
					options.onProgress && options.onProgress({
						percent: progressEvent.percentage,
						total: progressEvent.total,
						loaded: progressEvent.loaded,
					});
				}));

				options.onSuccess && options.onSuccess(results, {} as XMLHttpRequest);
			},
			action: async (file: File) => {
				setInAsyncProcess(s => ({ ...s, [section]: true }));
				try {
					const uploadInfo = await getUploadUrl(props.recipeId, file.name);
					props.onFileAdded({
						size: file.size,
						type: file.type,
						uid: file.name,
						name: file.name,
						serverPath: uploadInfo.path,
						version: uploadInfo.version,
						section
					});
					setInAsyncProcess(s => ({ ...s, [section]: false }));
					return uploadInfo.uploadUrl;
				} catch (e) {
					console.error('Error: ', e);
					message.error('Failed to upload file');
					setInAsyncProcess(s => ({ ...s, [section]: false }));
				}

				return '';
			},

			onRemove: async (file: UploadFile): Promise<boolean> => {
				const fileIndex = props.assets.findIndex(asset => asset.name === file.name);
				if (fileIndex !== -1) {
					const removedAsset = props.assets[fileIndex];
					setImagePreview(null);
					// Gives the illusion the file is removed immediately
					props.onFileRemoved(removedAsset);
					deleteAsset(props.recipeId, props.assets[fileIndex].name, props.assets[fileIndex].version)
					.catch(e => {
						// Restore assets list and fail silently
						console.error('Error deleting asset: ', e);
						// Add it back to the list
						// If removing failed, restore the file
						props.onFileAdded(removedAsset);
					});

					return true;
				}

				return false;
			}
		};
	};

	const handleChange = async (info: UploadChangeParam): Promise<void> => {
		const file = info.file;
		if (!file.url && !file.preview) {
			if ((file?.type || '').includes('video')) { return; }
			file.preview = await getBase64(file.originFileObj as File);
			setImagePreview(file.preview || null);
		}
	};


	const draggerProps: DraggerProps = {
		className: styles.DropArea,
		listType: 'text',
		...getBaseDraggerProps(props.section),
		accept: props.acceptedTypes,
		fileList: props.assets,
		onChange: handleChange,
		beforeUpload: (file: File) => {
			const totalFiles = props.assets;
			if (totalFiles.length > 0 && !props.multiple) {
				message.error('Only 1 file allowed');
				return false;
			}

			return true;
		}
	};

	const backgroundStyle = `linear-gradient(to bottom, rgb(220 220 220 / 50%) 0%,rgb(250 250 250 / 80%) 100%), url('${imagePreview || ''}')`;

	return (
		<FormGroup column={true} className={styles.AssetsContainer}>
			<label>{props.label}</label>
			<Spin indicator={<LoadingOutlined />} spinning={!!inAsyncProcess[props.section]} wrapperClassName={styles.DropArea}>

				<Dragger
					{...draggerProps}
					style={{ backgroundImage: !!imagePreview && !props.multiple ? backgroundStyle : '' }}
					method="PUT"
				>
					<div className={styles.PreviewContainer}>
						<p className="ant-upload-drag-icon">
							{props.icon}
						</p>
						<p className="ant-upload-text">
							{props.title}
						</p>
						<p className="ant-upload-hint" style={{ textAlign: props.icon ? 'left' : 'center' }}>
							{props.description}
						</p>
					</div>
				</Dragger>
			</Spin>
		</FormGroup>
	);
};

export default UploaderWithPreview;
