/*
 * Written by Alexander Agudelo < alex@kemu.io >, 2021
 * Date: 13/Jan/2021
 * Last Modified: 26/06/2024, 8:10:19 pm
 * Modified By: Alexander Agudelo
 * Description: Stores non-serializable references (DOM references, etc). Kind of an ugly workaround.
 * 
 * ------
 * Copyright (C) 2021 Kemu - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */

import { VideoTrackInfo } from '../../types/twilioRTC';


let videoStream: VideoTrackInfo | null;
let audioStream: HTMLMediaElement | null;
let sendToDeviceFun: (data: string) => void;

const setVideoStream = (stream: VideoTrackInfo | null): void => {
	videoStream = stream;
};

const getVideoStream = (): VideoTrackInfo | null => videoStream;

const setAudioStream = (stream: HTMLMediaElement | null): void => {
	audioStream = stream;
};

const getAudioStream = (): HTMLAudioElement | null => audioStream;

const setSendToDeviceFun = (cb: (data: string) => void): void => {
	sendToDeviceFun = cb;
};

const sendToDevice = (data: string): void => {
	sendToDeviceFun && sendToDeviceFun(data);
};

export default {
	setAudioStream,
	getAudioStream,
	setVideoStream,
	getVideoStream,
	setSendToDeviceFun,
	sendToDevice
};
