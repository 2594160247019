import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import * as userApi from '../../../api/user/userApi';
import { CustomerState, PropertiesMap, UserActionEvent } from './userSlice';

export const trackUserEventAction = createAsyncThunk('/user/event/track', async (
	args: { eventName: UserActionEvent, properties: PropertiesMap },
	/* thunkAPI */
): Promise<PropertiesMap> => {

	// This is NOT async.
	userApi.trackEvent(args.eventName, args.properties);
	return args.properties;
});


export const trackUserEventReducer = ((builder: ActionReducerMapBuilder<CustomerState>/*, thunk: any*/): void => {
	// WARNING: we are assuming posthog event racking went OK. 
	// Here we update the user's integrations to keep data "in sync".
	builder.addCase(trackUserEventAction.fulfilled, (state, action: PayloadAction<PropertiesMap>) => {
		if (state.currentUserProfile.integrations.posthog) {
			state.currentUserProfile.integrations = {
				...state.currentUserProfile.integrations,
				posthog: {
					...state.currentUserProfile.integrations.posthog,
					properties: {
						...state.currentUserProfile.integrations.posthog.properties,
						...action.payload
					}
				}
			};
		}
	});

});
