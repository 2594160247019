import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { InterfaceState, setRecipeUploadProgress } from '../interfaceSlice';
import * as recipeApi from '../../../api/recipe/recipeApi';
import { AsyncRequestStatus } from '../../../types/core_t';

/**
 * Uploads the `storage` of a recipe directly to S3
 * @param recipeId the id of the recipe in the pool
 * @param name the name of the recipe
 */
export const uploadRecipeStorageAction = createAsyncThunk('/interface/uploadRecipeStorage', async (options: { disk: ArrayBuffer, name: string, uploadUrl: string }, thunkAPI): Promise<void> => {
	if (!options.disk || ! options.disk.byteLength) { throw new Error(`Recipe [${options.name}] has no storage defined`); }

	// Start actual upload process
	try {
		await recipeApi.uploadRecipeFile(options.uploadUrl, options.disk, (evt) => {
			// IMPORTANT: setting `hasStorage` to false will prevent the percentage calculation
			// to be divided by 2 which was required when the the recipe was first saved.
			thunkAPI.dispatch(setRecipeUploadProgress({ storage: evt.percentage, hasStorage: false }));
		});
	} catch (e) {
		console.log('Error uploading recipe storage: ', e);
		throw e;
	}
});


export const uploadRecipeStorageReducer = ((builder: ActionReducerMapBuilder<InterfaceState>): void => {
	builder.addCase(uploadRecipeStorageAction.pending, (state) => {
		state.recipeUploadDetails = {
			...state.recipeUploadDetails,
			storageProgress: 0,
			status: AsyncRequestStatus.loading
		};
	});

	builder.addCase(uploadRecipeStorageAction.fulfilled, (state) => {
		state.recipeUploadDetails = {
			...state.recipeUploadDetails,
			status: AsyncRequestStatus.completed
		};
	});

	builder.addCase(uploadRecipeStorageAction.rejected, (state, action) => {
		state.recipeUploadDetails = {
			...state.recipeUploadDetails,
			status: AsyncRequestStatus.error,
			error: action.error
		};
	});
});
