import React, { FocusEvent, forwardRef, LegacyRef, memo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input, InputRef } from 'antd';
import classNames from 'classnames';
import StyledLabel from '../styledLabel/styledLabel';
import styles from './styledInput.module.css';


interface Props {
	errorMessage?: string | null;
	placeholder?: string;
	i18nPlaceholderId?: string;
	i18nPlaceholderDefaultMessage?: string;
	label?: string | React.ReactNode;
	className?: string;
	title?: string;
	readOnly?: boolean;
	value?: string;
	defaultValue?: string;
	elementId?: string | number;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>, elementId?: string | number) => void;
	onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	onBlur?: (text: string, originalEvent: FocusEvent<HTMLInputElement>, elementId?: string | number) => void;
	type?: 'text' | 'password' | 'email' | 'file' | 'hidden' | 'submit' | 'url';
	name?: string;
	disabled?: boolean;
	/** An error message to show */
	children?: React.ReactNode;
	/** if true, the input won't use 100% width */
	noFluid?: boolean;
}

const StyledInputComponent = forwardRef<InputRef | HTMLInputElement, Props>((props, ref): React.JSX.Element => {
	const intl = useIntl();
	const {
		errorMessage, i18nPlaceholderId, i18nPlaceholderDefaultMessage,
		onBlur, defaultValue,
		elementId, onChange,
		...otherInputs
	} = props;
	const placeHolderText = i18nPlaceholderId ? intl.formatMessage({ id: i18nPlaceholderId, defaultMessage: i18nPlaceholderDefaultMessage }) : props.placeholder || undefined;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { className, ...inputProps } = otherInputs;

	const handleBlur = useCallback((evt: FocusEvent<HTMLInputElement>) => {
		onBlur && onBlur(evt.currentTarget.value, evt, elementId);
	}, [onBlur, elementId]);

	const handleChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
		onChange && onChange(evt, elementId);
	}, [elementId, onChange]);

	return (
		<>
			{props.label && (
				<StyledLabel text={props.label} />
			)}

			{props.type === 'password' ? (
				<Input.Password
					onBlur={handleBlur}
					defaultValue={defaultValue}
					onKeyDown={props.onKeyDown}
					onChange={handleChange}
					value={props.value}
					name={props.name}
					title={props.title}
					readOnly={props.readOnly}
					ref={ref as LegacyRef<InputRef>}
					disabled={props.disabled}
					className={classNames(
						styles.InputPassword,
						{
							[styles.InvalidInput]: !!errorMessage,
						},
						className
					)}
					placeholder={placeHolderText}
					iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
				/>
			) : (
				<input
					defaultValue={defaultValue}
					onBlur={handleBlur}
					disabled={props.disabled}
					type={props.type}
					name={props.name}
					onKeyDown={props.onKeyDown}
					onChange={handleChange}
					value={props.value}
					title={props.title}
					readOnly={props.readOnly}
					ref={ref as LegacyRef<HTMLInputElement>}
					className={classNames(
						styles.Input,
						{
							[styles.InvalidInput]: !!errorMessage,
							[styles.AutoWidth]: props.noFluid
						},
						className
					)}
					placeholder={placeHolderText}
				/>
			)}

			{props.children ? (
				props.children
			) : (
				<>
				{ errorMessage &&  <p className={styles.ErrorMessage}>{errorMessage}</p>}
				</>
			)}
		</>
	);
});

const StyledInput = memo(StyledInputComponent);
export default StyledInput;
