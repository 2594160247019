import { DEFAULT_THING_ID } from '@kemu-io/kemu-core/common/constants';
import { useCallback } from 'react';
import { getWidgetCanvasInfo } from '@src/app/recipe/utils';

/**
 * Checks directly from the recipe the dimensions of the widget.
 * @param recipeId the id of the recipe in the pool.
 * @param widgetId the id of the widget in the recipe.
 * @returns a function that can be used to get the dimensions of the widget.
 */
const useWidgetDimensions = (recipeId: string, widgetId: string) => {
  const getDimensions = useCallback(() => {
    const info = getWidgetCanvasInfo(recipeId, DEFAULT_THING_ID,  widgetId);
    return {
      width: info.dimensions?.width || 0,
      height: info.dimensions?.height || 0
    };
  }, [recipeId, widgetId]);

  return getDimensions;
};

export default useWidgetDimensions;
