import React, { useCallback, useMemo, useState } from 'react';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import styles from './VariableInput.module.css';
import useTranslation from '@hooks/useTranslation';
import StyledInput from '@components/form-control/styledInput/styledInput';

type Props = {
  index: number;
  recipePoolId?: string;
  thingRecipeId?: string;
  defaultVarName: string;
  duplicatedVarName?: boolean;
  onVarNameChange: (index: number, name: string) => void;
}

const VariableInput = (props: Props) => {
  const t = useTranslation('CustomWidgetModal.Settings');
  const { onVarNameChange, index, defaultVarName, duplicatedVarName } = props;
  const varName = defaultVarName;
  const varNameExists = duplicatedVarName;

  const handleVariableNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, elementId?: string | number) => {
    const index = elementId as number;
    const newName = event.target.value
      .replace(' ', '_')
      .replace(/\$/g, '');
      onVarNameChange(index, newName);

  }, [onVarNameChange]);

  return (
    <div className={classNames(styles.SettingsVariableName, styles.InputType)}>
      <Tooltip
        title={
          varNameExists
          ? t('VariableExists', 'A variable with the same name already exists')
          : null
        }
        // defaultVisible={varNameExists}
        placement="left"
      >
        <div className={styles.SettingsVariableNameInput}>
          {/* <span>$</span> */}
          <StyledInput
            elementId={index}
            onChange={handleVariableNameChange}
            value={varName}
            className={classNames({
              [styles.BadVariableInput]: varNameExists
            })}
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default VariableInput;
