import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Publisher from '../publisher/publisher';
import AlgoliaResultsGrid from '../AlgoliaResultsGrid/AlgoliaResultsGrid';
import styles from './RecipeList.module.css';
import RecipeCard from '@components/RecipeCard/RecipeCard';
import { clearPublishRecipeState } from '@components/layout/marketplace/marketplaceSlice';
import { RecipeResponse } from '@src/types/core_t';
import useQueryString from '@common/hooks/useQueryString';
import { SearchContext } from '@common/context/GlobalSearchContext/GlobalSearchContext';
import 'react-perfect-scrollbar/dist/css/styles.css';
import StyledPagination from '@components/StyledPagination/StyledPagination';

const SearchContextName = 'MyRecipes';
const resultsPerPage = 10;

const RecipeList = (): React.JSX.Element | null => {
	const {
		results,
		setSearchContext,
		getSearchContext,
		goToPage,
		clearSearchContext,
		refreshCache
	} = useContext(SearchContext);
	const dispatch = useDispatch();
	const queryString = useQueryString();
	const selectedCard = queryString.get('select');
  const validContext = getSearchContext() === SearchContextName;
	const refreshListTmrRef = useRef<NodeJS.Timeout | null>(null);
	const [showPublisherFor, setShowPublisherFor] = useState<RecipeResponse | null>(null);

	const handleReload = useCallback(() => {
		refreshCache();
	}, [refreshCache]);

	const hidePublisher = useCallback((reload?: boolean) => {
		setShowPublisherFor(null);
		dispatch(clearPublishRecipeState());
		if (reload) {
			// Refresh now
			refreshCache();
			// Refresh again in 2 seconds
			refreshListTmrRef.current = setTimeout(refreshCache, 2000);
		}
	}, [dispatch, refreshCache]);

	const handleShowPublisher = useCallback((selectedRecipe: RecipeResponse) => {
		setShowPublisherFor(selectedRecipe);
	}, []);

	const handlePageChange = useCallback((page: number) => {
		goToPage(page - 1);
	}, [goToPage]);

	/* const sortRecipes = useCallback((list: Hit<RecipeResponse>[]) => {
		const filtered = ([...list || []]).sort((a, b) => {
			return (new Date(b.createdAt)).getTime() - (new Date(a.createdAt)).getTime() ;
		});

		return filtered;
	}, []); */

	// Set the context the first time
  useEffect(() => {
    if (!validContext) {
      setSearchContext(SearchContextName, 'recipe', {
				hitsPerPage: resultsPerPage,
			});
    }
  }, [validContext, setSearchContext, clearSearchContext]);

	// Clear out timer on unmount
	useEffect(() => {
		return () => {
			refreshListTmrRef.current && clearTimeout(refreshListTmrRef.current);
		};
	});

  // Prevent rendering results from other searches
  if (!validContext) { return null; }

	return (
		<>
			{showPublisherFor && <Publisher
				onClose={hidePublisher}
				recipe={showPublisherFor}
			/>}

			<AlgoliaResultsGrid
				// onResults={sortRecipes}
				hits={results?.hits || []}
				hitRenderer={
					(hit: RecipeResponse) => (
						<RecipeCard
							recipe={hit}
							selected={selectedCard === hit.id}
							onShowPublisher={handleShowPublisher}
							onForceReload={handleReload}
						/>
					)
				}
			/>

			{!!results && (
				<StyledPagination
					wrapperClassName={styles.PaginationWrapper}
					current={results.stats.currentPage + 1}
					total={results.stats.totalHits}
					onChange={handlePageChange}
					pageSize={resultsPerPage}
					showSizeChanger={false}
				/>
			)}
		</>
  );
};


export default RecipeList;
