import React, { useCallback, useEffect, useState } from 'react';
import {
  TextConfig,
  WidgetGroupSetting,
  WidgetGroupState
} from '@kemu-io/kemu-core/types';
import variablesManager, { VarDefinitionChangeEvt } from '@kemu-io/kemu-core/common/managers/variablesManager.js';
import classNames from 'classnames';
import { Resizable, ResizeCallbackData } from 'react-resizable';
import { DataType } from '@kemu-io/hs-types';
import FieldLabel from '../FieldLabel/FieldLabel';
import styles from './TextField.module.css';
import StyledInput from '@components/form-control/styledInput/styledInput';
import FormGroup from '@components/form-control/formGroup/formGroup';
import { ABORT_DRAGGING_CLASS, NO_GLOBAL_RULES_CLASS } from '@common/constants';
import useSetWidgetState from '@hooks/useSetWidgetState';
import { jsonCloneObject } from '@common/utils';
import useForceReload from '@hooks/useForceReload';

type Props = TextConfig & {
  label: string;
  variableName: string;
  recipeId: string;
  widgetId: string;
  thingRecipeId: string;
  color?: string;
  /** indicates this is the only field in the widget */
  isSingleElement: boolean;
  isHiddenField: boolean;
  onRepaintWidgetPorts: () => void;
}

const DEFAULT_W = 165;
const DEFAULT_H = 60;

const TextField = (props: Props) => {
  const {
    variableName, recipeId, thingRecipeId, isHiddenField,
    widgetId, isSingleElement, label, onRepaintWidgetPorts
  } = props;

  const forceReload = useForceReload();
  const setWidgetState = useSetWidgetState<WidgetGroupState>(recipeId, thingRecipeId, widgetId);
  const currentVarValue = variablesManager.getThingVariableValue(recipeId, thingRecipeId, variableName, {
    ownerWidgetId: widgetId,
  });

  const [textValue, setTextValue] = useState<string>(currentVarValue as string || props.defaultValue || '');
  const textAreaFieldSize = {
    width: props.width || DEFAULT_W,
    height: props.height || DEFAULT_H,
  };

  const handleResize = useCallback((event: React.SyntheticEvent<Element, Event>, data: ResizeCallbackData) => {
    const size = {
      width: data.size.width,
      height: data.size.height
    };

    // Send notifications so the other fields react to the new size
    const sendStateChangeNotifications = true;

    setWidgetState((current) => {
      if (!current.settings) { return current; }
      const fieldsCopy = jsonCloneObject<WidgetGroupSetting<TextConfig>[]>(current.settings);

      // Change the width of ALL multiline fields since changing one should make the others grow/shrink
      for (const field of fieldsCopy) {
        if (field.type === 'text') {
          if (field.variableName === variableName) {
            field.config.width = size.width;
            field.config.height = size.height;
          } else {
            // Other fields, only update width if in the same visibility status
            if (isHiddenField === field.customSettingField) {
              field.config.width = size.width;
            }
          }
        }
      }

      onRepaintWidgetPorts();
      return {
        ...current,
        settings: fieldsCopy
      };
    }, sendStateChangeNotifications);

  }, [setWidgetState, variableName, onRepaintWidgetPorts, isHiddenField]);


  const handleTextChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    setTextValue(() => {
      variablesManager.setThingVariable(
        recipeId,
        thingRecipeId,
        widgetId,
        variableName,
        {
          type: DataType.String,
          value
        },
      );

      return value;
    });
  }, [recipeId, thingRecipeId, variableName, widgetId]);

  // Subscribe to value changes
  useEffect(() => {
    const handleVarChange = (evt: VarDefinitionChangeEvt) => {
      // Ignore my own events
      if (evt.callerWidgetId === widgetId) { return; }
      // Only process value change events
      if (!evt.changes.includes('value')) { return; }
      setTextValue(evt.varDefinition?.value as string);
    };

    variablesManager.onVariableDefinitionChange(handleVarChange, thingRecipeId, widgetId, variableName);

    return () => {
      variablesManager.offVariableDefinitionChange(handleVarChange, thingRecipeId, widgetId, variableName);
    };
  }, [thingRecipeId, widgetId, variableName, forceReload]);

  return (
    <FormGroup column={true} noMarginBottom>
      <FieldLabel color={props.color} label={label} />
      {props.multiline ? (
        <Resizable
          width={textAreaFieldSize.width}
          height={textAreaFieldSize.height}
          minConstraints={[DEFAULT_W, DEFAULT_H]}
          onResize={handleResize}
        >
          <div
            className={ABORT_DRAGGING_CLASS}
            style={{
              width: textAreaFieldSize.width,
              height: textAreaFieldSize.height,
            }}
          >
            <textarea
              value={textValue}
              placeholder={props.placeholder}
              onChange={handleTextChange}
              className={classNames(styles.TextField, NO_GLOBAL_RULES_CLASS, {
                [styles.SingleElement]: isSingleElement && !label,
              })}
            />
          </div>
        </Resizable>
      ) : (
        <StyledInput
          value={textValue}
          placeholder={props.placeholder}
          type={props.passwordMode ? 'password' : 'text'}
          onChange={handleTextChange}
          className={classNames(styles.TextField, NO_GLOBAL_RULES_CLASS, {
            [styles.SingleElement]: isSingleElement && !label,
          })}
        />
      )}
    </FormGroup>
  );
};

export default TextField;
