import React, { useCallback, useEffect, useState } from 'react';
import { Col,  Row } from 'antd';
import { TextConfig } from '@kemu-io/kemu-core/types';
import styles from './TextFieldConfig.module.css';
import FormGroup from '@components/form-control/formGroup/formGroup';
import StyledInput from '@components/form-control/styledInput/styledInput';
import StandardModal from '@components/modal/standardModal';
import useTranslation from '@hooks/useTranslation';
import KemuSwitch from '@components/form-control/kemuSwitch/KemuSwitch';

type Props = {
  open: boolean;
  fieldIndex: number;
  currentConfig: TextConfig | null;
  onCloseModal: (fieldIndex: number, newConfig: TextConfig | null) => void;
}

const TextFieldConfig = (props: Props) => {
  const t = useTranslation();
  const { currentConfig, onCloseModal, fieldIndex, open } = props;
  const [config, setConfig] = useState<TextConfig | null>(currentConfig);

  const handlePlaceholderChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setConfig((prevConfig) => {
      return {
        ...prevConfig,
        passwordMode: !!prevConfig?.passwordMode,
        multiline: !!prevConfig?.multiline,
        placeholder: value,
      };
    });
  }, []);

  const handleDefaultValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setConfig((prevConfig) => {
      return {
        ...prevConfig,
        passwordMode: !!prevConfig?.passwordMode,
        multiline: !!prevConfig?.multiline,
        defaultValue: value,
      };
    });
  }, []);

  const handlePasswordModeChange = useCallback((checked: boolean) => {
    setConfig((prevConfig) => {
      return {
        ...prevConfig,
        passwordMode: checked,
        multiline: !!prevConfig?.multiline,
      };
    });
  }, []);

  const handleMultilineModeChange = useCallback((checked: boolean) => {
    setConfig((prevConfig) => {
      return {
        ...prevConfig,
        passwordMode: !!prevConfig?.passwordMode,
        multiline: checked,
      };
    });
  }, []);

  const handleSaveSettings = useCallback(() => {
    onCloseModal(fieldIndex, config);
  }, [config, fieldIndex, onCloseModal]);

  const handleCloseModal = useCallback(() => {
    onCloseModal(fieldIndex, null);
  }, [fieldIndex, onCloseModal]);

  useEffect(() => {
    setConfig(currentConfig);
  }, [currentConfig]);

  return (
    <StandardModal
      title="Text Field Configuration"
      centered
      visible={open}
      width={310}
      onOk={handleSaveSettings}
      onCancel={handleCloseModal}
      okBtnLabel="Save"
      cancelBtnLabel="Cancel"
    >
      <Row>
        <Col span={24}>
          <FormGroup column={true}>
            <label>{t('CommonWords.Placeholder', 'Placeholder')}</label>
            <StyledInput
              value={config?.placeholder}
              onChange={handlePlaceholderChange}
              elementId="placeholder"
            />
          </FormGroup>
          <FormGroup column={true}>
            <label>{t('CommonWords.DefaultValue', 'Default value')}</label>
            <StyledInput
              value={config?.defaultValue}
              onChange={handleDefaultValueChange}
            />
          </FormGroup>

          {/* Multiline */}
          <FormGroup column={true}>
            <label>{t('CustomWidget.Settings.TextField.Multiline', 'Multiline')}</label>
            <p className={styles.Description}>
              {t('CustomWidget.Settings.TextField.Multiline.Description', 'CustomWidget.Settings.TextField.Multiline.Description')}
            </p>
            <KemuSwitch
              checked={Boolean(config?.multiline)}
              onChange={handleMultilineModeChange}
              size="default"
            />
          </FormGroup>

          <FormGroup column={true}>
            <label>{t('CustomWidget.Settings.TextField.PasswordMode', 'Password mode')}</label>
            <p className={styles.Description}>
              {t('CustomWidget.Settings.TextField.Password.Description', 'CustomWidget.Settings.TextField.Password.Description')}
              </p>
            <KemuSwitch
              checked={Boolean(config?.passwordMode)}
              onChange={handlePasswordModeChange}
              size="default"
            />
          </FormGroup>
        </Col>
      </Row>
    </StandardModal>
  );
};

export default TextFieldConfig;


