import React, { useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';

type AllowedValues = string | number | boolean | Date | null | undefined | React.ReactNode;
export type TranslateFunction = (id: string, defaultMessage?: string, values?: Record<string, AllowedValues>) => string;

/**
 * @param baseKey a string to be prepended to the message key. 
 * A dot (".") is automatically added to the end of the string if not provided.
 * @returns a function that can be used to access the language file. Eg:
 * ```
 * 	const t = useTranslation();
 *  const title = t('Messages.Title', 'Hello {name}`, {name: 'User'});
 * ```
 * 
 * You can also define a base Key path to be prepended to the language key id, Eg:
 * 
 * ```
 * 	const t = useTranslation('Interface.Messages.Module1');
 *  // This is the same as ('Interface.Messages.Module1.Title')
 *  const title = t('Title', 'Hello {name}`, {name: 'User'});	
 * ```
 */
const useTranslation = (baseKey?: string): TranslateFunction => {
	const intl = useIntl();
	const translate = useMemo(() => {
		return getTranslationFunction(intl, baseKey);
	}, [baseKey, intl]);

	return translate;
};



export const getTranslationFunction = (intl: IntlShape, baseKey?: string): TranslateFunction => {
	const formattedBase = baseKey ? baseKey.endsWith('.') ? baseKey : `${baseKey}.` : '';
	const translate = (id: string, defaultMessage?: string, values?: Record<string, AllowedValues>): string => {
		const translation = intl.formatMessage({
			id: `${formattedBase}${id}`,
			defaultMessage: defaultMessage || `${formattedBase}${id}`
		}, values || {});

		return translation as string;
	};

	return translate;
};

export default useTranslation;
