import { Spin } from 'antd';
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './spinner.module.css';

interface Props {
	children?: React.ReactNode;
	loading?: boolean;
	size?: 'small' | 'default' | 'large';
}

const Spinner = (props: Props): React.JSX.Element => {
	return (
		<Spin
			indicator={<LoadingOutlined />}
			spinning={props.loading}
			className={styles.Container}
			size={props.size || 'default'}
		>
			{props.children}
		</Spin>
	);
};

export default Spinner;
