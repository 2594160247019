import React, { useCallback } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { StyledButtonClassName } from '../styledButton/styledButton';
import styles from './iconButton.module.css';

interface Props {
	shape?: 'round' | 'square';
	color?: 'primary' | 'secondary' | 'danger' | 'warning' | 'light' | 'transparent';
	icon: React.ReactElement;
	size?: 'large' | 'small' | 'middle';
	disabled?: boolean;
	className?: string;
	noBoxShadow?: boolean;
	'data-kemu-meta'?: string;
	elementId?: string | number;
	onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>, elementId?: string | number)=> void;
}

const IconButton = (props: Props): React.JSX.Element => {
	const { onClick, elementId, ...attributes } = props;
	const settings: Props = {
		color: 'light',
		shape: 'square',
		size: 'middle',
		disabled: false,
		...attributes
	};

	const handleClick = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>) => {
		onClick && onClick(event, elementId);
	}, [elementId, onClick]);

	return (
		<Button
			className={classNames(StyledButtonClassName, styles.Button, props.className, {
				[styles.NoShadow]: props.noBoxShadow,
			})}
			onClick={handleClick}
			disabled={settings.disabled}
			icon={settings.icon}
			btn-type={props.color || 'primary'}
			size={settings.size}
			shape={props.shape === 'square' ? 'round' : 'circle'}
			{...props['data-kemu-meta'] ? { ['data-kemu-meta']: props['data-kemu-meta'] } : undefined}
		/>
	);
};

export default IconButton;
