import React, { useCallback, useEffect, useRef } from 'react';
import { ImageWarpWidgetState } from '@kemu-io/kemu-core/widgets/imageWarp/index.js';
import { Vertex } from '@kemu-io/kemu-core/widgets/imageWarp/types.js';
import { Point } from '@kemu-io/hs-types';
import { cursorPosition, drawVertices, getTransformedVertices, updateVertices } from './helpers';

type Props = {
  canvas: HTMLCanvasElement | null;
	context: CanvasRenderingContext2D | null;
	renderLastImage: () => Promise<void>;
  setState: (state: ImageWarpWidgetState | ((state: ImageWarpWidgetState) => ImageWarpWidgetState | Promise<ImageWarpWidgetState>)) => void;
}

const FPS_60 = 1000 / 30;

const VertexDrawer = (props: Props): React.JSX.Element | null => {
  const { canvas, setState, context, renderLastImage } = props;
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const processingRef = useRef<boolean>(false);
  const mouseDragRef = useRef<Vertex>({ x: 0, y: 0, drag: false });

  const handleMouseDrag = useCallback((position: Point, drag?: boolean) => {
		if (canvas) {
			const width = canvas.width;
			const height = canvas.height;

			if (drag !== undefined) {
				mouseDragRef.current = {
					...mouseDragRef.current,
					drag,
				};
			}

			if (position.x >= 0 && position.x < width) {
				mouseDragRef.current = {
					...mouseDragRef.current,
					x: position.x,
				};
			}

			if (position.y >= 0 && position.y < height) {
				mouseDragRef.current = {
					...mouseDragRef.current,
					y: position.y,
				};
			}
		}
	}, [canvas]);

	const handleCanvasMouseDown = useCallback(async (evt: MouseEvent) => {
		if (!canvas) { return; }

		const position = cursorPosition(canvas, evt);

		setState(async (currentState) => {
			let vertices = [...currentState.vertices];
			const anchors = [...currentState.anchors];

			if (anchors.length < 4) {
				anchors.push({ x: position.x, y: position.y });

				if (anchors.length === 4) {
					vertices = await getTransformedVertices(anchors);
				}

				const nextState = {
					...currentState,
					vertices,
					anchors,
				};

				return nextState;
			} else {
				return currentState;
			}
		});

		handleMouseDrag(position, true);
	}, [setState, handleMouseDrag, canvas]);

	const handleMouseEvent = useCallback((evt: MouseEvent) => {
		if (canvas) {
			const position = cursorPosition(canvas, evt);
			const buttonPressed = evt.buttons === 1;
			handleMouseDrag(position, buttonPressed);
		}
	}, [handleMouseDrag, canvas]);

  const render = useCallback(() => {
    setState(async (currentState) => {
      const anchors = currentState.anchors;
      const vertices = currentState.vertices;

			if (context) {
				await renderLastImage();
				drawVertices(context, currentState);
			}

			if (currentState.$$private?.perspective
        && anchors.length === 4
        && canvas
      ) {
        if (!processingRef.current) {
          processingRef.current = true;
          const {
            homography,
            updatedMatrix
          } = await updateVertices(anchors, vertices, canvas, mouseDragRef.current, currentState.matrix);

          const nextState = {
            ...currentState,
            $$private: {
              ...currentState.$$private,
            }
          };

          nextState.$$private.perspective.transform = homography;
					nextState.matrix = updatedMatrix;
          processingRef.current = false;

          return nextState;
        }
      }

      return currentState;
    });

    timeoutRef.current = global.setTimeout(render, FPS_60);
  }, [setState, canvas, context, renderLastImage]);


  useEffect(() => {
		if (canvas) {
			canvas.addEventListener('mousedown', handleCanvasMouseDown);
			canvas.addEventListener('mouseup', handleMouseEvent);
			canvas.addEventListener('mousemove', handleMouseEvent);
		}

		return () => {
			if (canvas) {
				canvas.removeEventListener('mousedown', handleCanvasMouseDown);
				canvas.removeEventListener('mouseup', handleMouseEvent);
				canvas.removeEventListener('mousemove', handleMouseEvent);
			}
		};

	}, [handleCanvasMouseDown, handleMouseEvent, canvas]);


  useEffect(() => {
		timeoutRef.current = global.setTimeout(render, FPS_60);

    return () => {
      if (timeoutRef.current) {
        global.clearTimeout(timeoutRef.current);
      }
    };
  }, [render]);

  return <></>;
};

export default VertexDrawer;

