/* eslint-disable @typescript-eslint/no-explicit-any */
/*
 * Written by Alexander Agudelo < alex@kemu.io >, 2024
 * Date: 05/Feb/2024
 * Last Modified: 05/02/2024, 6:08:16 pm
 * Modified By: Alexander Agudelo
 * Description:  An in-memory storage for storing data that should not persist between page reloads.
 * For example, to keep track of storage units while copying widgets
 * 
 * ------
 * Copyright (C) 2024 Kemu - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */

export type EphemeralStorage = Record<string, any>;

const storage: EphemeralStorage = {};

/** 
 * @returns a mutable reference to the stored value.
 **/
const get = <T = any>(key: string): T | undefined => storage[key];

/**
 * Saves a mutable reference to the value.
 */
const set = (key: string, value: any): void => {
  storage[key] = value;
};

export default {
  get,
  set
};
