import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import Icon from '@ant-design/icons';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import { Button } from 'antd';
import { Position } from '@kemu-io/kemu-core/types';
import { SerializableServiceInfo, ServiceWidgetInfo } from '@kemu-io/hs-types';
import { useDispatch } from 'react-redux';
import { DEFAULT_THING_ID } from '@kemu-io/kemu-core/common/constants';
import { CustomWidgetVariant } from '@kemu-io/kemu-types';
import ServiceButton from '../ServiceButton/ServiceButton';
import LaunchpadWidgetIcon from '../../LaunchpadWidgetIcon/LaunchpadWidgetIcon';
import SelectedServiceFooter, { FooterInfo } from '../SelectedServiceFooter/SelectedServiceFooter';
import styles from './ServiceWithSubServices.module.css';
import { ReactComponent as FolderIcon } from './service-folder.svg';
import { FolderPathInfo } from '@src/features/Workspace/workspaceSlice';
import TruncatedText from '@components/truncatedText/truncatedText';
import { calculateWidgetColors } from '@components/gates/common';
import useTranslation from '@hooks/useTranslation';
import { pasteFromClipboardAction } from '@src/features/LogicMapper/reducers/clipboardReducers';
import { getDecodedClipboardData } from '@src/features/LogicMapper/reducers/clipboardUtils';

interface Props {
	serviceInfo: SerializableServiceInfo;
	currentFolder: FolderPathInfo | null;
	recipePoolId: string;
	isSelected: boolean;
	disabled?: boolean;
	onClick?: (name: string, version: string) => void;
	onClearSelection: () => void;
	onAddService: (
		info: {
			name: string,
			version: string,
			variantId?: string,
		},
		dropLocation: Position
	) => void;
	hideLaunchpad: (hide: boolean) => void;
	setCustomFooter: (footer: React.ReactElement | null) => void;
}

const DefaultServiceColorVar = '--kemu-color-widget-type-custom';
const getStorageKey = (name: string, version: string) => `expanded-${name}-${version}`;

const saveExpandedState = (name: string, version: string, expanded: boolean) => {
	sessionStorage.setItem(getStorageKey(name, version), expanded ? 'true' : 'false');
};

const getExpandedState = (name: string, version: string) => {
	return sessionStorage.getItem(getStorageKey(name, version)) === 'true';
};


const ServiceWithSubServices = (props: Props): React.JSX.Element => {
	const { serviceInfo, onAddService, onClearSelection, hideLaunchpad, setCustomFooter } = props;
	const dispatch = useDispatch();
	const [isExpanded, setIsExpanded] = useState(getExpandedState(serviceInfo.name, serviceInfo.version));
	const [footerInfo, setFooterInfo] = useState<FooterInfo & {id: string} | null>(null);
	const cw = useTranslation('CommonWords');
	const isFirstRender = useRef(true);
	const serviceId = `${serviceInfo.name}-${serviceInfo.version}`;
	const smartName = serviceInfo.title || serviceInfo.shortTitle || serviceInfo.name.split('.').pop();

	// const iconRef = useRef<HTMLDivElement | null>(null);

	const colors = useMemo(() => {
		const docStyle = getComputedStyle(document.body);
    const customWidgetColor = docStyle.getPropertyValue(DefaultServiceColorVar);
		return calculateWidgetColors(serviceInfo.color || customWidgetColor);
	}, [serviceInfo.color]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	// const [{ isDragging }, drag, preview] = useDrag<any, Position, any>(() => ({
  //   type: DroppableTypes.CustomWidget,
	// 	canDrag: !props.disabled,
  //   item: {
	// 		color: serviceInfo.color,
	// 		textColor: colors.text,
	// 		isHubService: true,
	// 		contentColor: colors.content,
	// 		svgIcon: serviceInfo.svgIcon,
	// 	},
  //   end: (item, monitor) => {
  //     const dropLocation = monitor.getDropResult();
	// 		if (dropLocation) {
	// 			onAddService({
	// 				name: serviceInfo.name, 
	// 				version: serviceInfo.version,
	// 				variantId: serviceId,
	// 			}, {
	// 				x: dropLocation.x,
	// 				y: dropLocation.y,
	// 			});
	// 		}
  //   },

  //   collect: (monitor) => ({
  //     isDragging: monitor.isDragging(),
  //   }),
  // }), [serviceInfo, onAddService]);

	const handleExpandFolder = useCallback(() => {
		setIsExpanded(true);
		onClearSelection();
		setCustomFooter(null);
		saveExpandedState(serviceInfo.name, serviceInfo.version, true);
	}, [serviceInfo.name, serviceInfo.version, onClearSelection, setCustomFooter]);

	const handleCloseFolder = useCallback(() => {
		setIsExpanded(false);
		saveExpandedState(serviceInfo.name, serviceInfo.version, false);
		setFooterInfo(null);
	}, [serviceInfo.name, serviceInfo.version]);


	const handleServiceClick = useCallback((service: SerializableServiceInfo, variantId?: string) => {
		const matchingVariant = variantId ? service.variants?.find(v => v.id === variantId) : undefined;
		setFooterInfo({
			name: matchingVariant?.name || serviceInfo.name,
			title: !matchingVariant ? service.title : undefined,
			description: matchingVariant?.description || serviceInfo.description,
			id: variantId || serviceId,
			version: service.version,
		});
	}, [serviceInfo, serviceId]);

	const handleAddCustomWidget = useCallback((groupId: string, dropLocation: Position) => {
		const serviceWidget = serviceInfo.customWidgets?.find(w => w.rootGroupId === groupId);
		if (!serviceWidget) { return; }

		const decodedContents = getDecodedClipboardData(serviceWidget.contents);
		if (!decodedContents) { return; }

		dispatch(pasteFromClipboardAction({
			mouseLocation: dropLocation,
			recipeId: props.recipePoolId,
			thingId: DEFAULT_THING_ID,
			mapData: decodedContents
		}));

		hideLaunchpad(false);

	}, [dispatch, props.recipePoolId, serviceInfo.customWidgets, hideLaunchpad]);

	const handleHidePopup = useCallback(() => {
		hideLaunchpad(true);
	}, [hideLaunchpad]);

	const handleWidgetClick = useCallback((widget: ServiceWidgetInfo) => {
		setFooterInfo({
			name: widget.name,
			description: widget.description,
			id: widget.rootGroupId,
		});
	}, [setFooterInfo]);

	// useEffect(() => {
	// 	if (isDragging) {
	// 		const emptyImage = getEmptyImage();
	// 		preview(emptyImage);
	// 		hideLaunchpad(true);
	// 	}

	// 	isFirstRender.current = false;
	// }, [preview, isDragging, hideLaunchpad]);



	useEffect(() => {
		if (!footerInfo) {
			setCustomFooter(null);
		} else {
			setCustomFooter(
				<SelectedServiceFooter
					{...footerInfo}
				/>
			);
		}
	}, [footerInfo, setCustomFooter]);

	return (
		isExpanded ? (
			<div className={classNames(styles.ExpandedContainer, { [styles.FirstRender]: isFirstRender.current })}>
				<Button
					type="link"
					size="small"
					onClick={handleCloseFolder}
					className={styles.CloseBtn}
					title={cw('Close')}
					icon={<CloseCircleFilled />}
				/>
				<div className={styles.TopBar}>{smartName} ({serviceInfo.version})</div>
				<div className={styles.SubWidgetsGrid}>

					{/* the base service */}
					<ServiceButton
						service={serviceInfo}
						onClick={handleServiceClick}
						isSelected={footerInfo?.id === serviceId}
						onAddService={props.onAddService}
						onHideLaunchpad={handleHidePopup}
						disabled={props.disabled}
					/>

					{/* Service Variants */}
					{(serviceInfo.variants || []).map((variant) => (
						<ServiceButton
							key={variant.id}
							variantId={variant.id}
							service={serviceInfo}
							onClick={handleServiceClick}
							isSelected={footerInfo?.id === serviceId}
							onAddService={props.onAddService}
							onHideLaunchpad={handleHidePopup}
							disabled={props.disabled}
						/>
					))}

					{/* Any custom widgets */}
					{(serviceInfo.customWidgets || []).map((customW) => (
						<LaunchpadWidgetIcon
							onAddWidget={handleAddCustomWidget}
							onHideLaunchpad={handleHidePopup}
							onClick={() => handleWidgetClick(customW)}
							isSelected={footerInfo?.id === customW.rootGroupId}
							key={`${customW.rootGroupId}`}
							widgetInfo={{
								id: customW.rootGroupId,
								name: customW.name,
								variant: CustomWidgetVariant.Group,
								color: customW.color,
								icon: customW.icon,
								protocolVersion: customW.protocolVersion,
							}}
							currentFolder={props.currentFolder}
							recipePoolId={props.recipePoolId}
						/>
					))}
				</div>
			</div>
		) : (
			<div
				className={classNames(styles.FolderPlaceholder)}
				onClick={handleExpandFolder}
			>
				<div className={styles.FolderIconContainer}>
					<Icon component={FolderIcon} style={{ color: props.disabled ? 'gray' : colors.content }} />
				</div>
				<div className={classNames(styles.ServiceName, 'noselect')}>
					<label>
						<TruncatedText
							text={`${serviceInfo.title || serviceInfo.name}`}
							maxChars={24}
							showFullOn="hover"
							// suffix={<div>({serviceInfo.version})</div>}
						/>
					</label>
				</div>
			</div>
		)
	);
};

export default ServiceWithSubServices;
