import marketplaceRoutes from './marketplace';
import recipeRoutes from './recipe';
import tutorialRoutes from './tutorialBuilder';
import widgetsRoutes from './widgets';
import authRoutes from './auth';
import { getBaseRoute, getDirectActionRoute } from './common';

export default {
	getBaseRoute,
	getDirectActionRoute,
	tutorial: tutorialRoutes,
	recipe: recipeRoutes,
	widgets: widgetsRoutes,
	marketplace: marketplaceRoutes,
	auth: authRoutes,
};

