import React from 'react';
import { Dropdown } from 'antd';
import Icon from '@ant-design/icons';
import styles from './verticalDots.module.css';
import { ReactComponent as VerticalDotsIcon } from './verticalDots.svg';

interface Props {
	menu: React.JSX.Element;
}

const VerticalDots = (props: Props): React.JSX.Element => {
	return (
		<div className={styles.SettingsDotsContainer} data-kemu-meta="vertical-dots">
			<Dropdown overlay={props.menu} trigger={['click']} overlayClassName="vertical-dots-dropdown">
				<button className={styles.SettingsButton}>
					<Icon component={VerticalDotsIcon} />
				</button>
			</Dropdown>
		</div>
	);
};

export default VerticalDots;
