import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useSelector } from 'react-redux';
import { WIDGET_PORT_RADIUS } from '@common/constants';
import { PortDescription } from '@components/gates';
import { useForceReload } from '@hooks/index';
import { selectCanvasZoom } from '@src/features/LogicMapper/logicMapperSlice';

type Props = {
  inputs: PortDescription[];
  outputs: PortDescription[];
  hidden: boolean;
}

export type LabelsInstanceRef = {
  repaintLabels: () => void;
}

const getPortStyle = (
  portDescription: PortDescription,
  container: HTMLDivElement | null,
  zoom: number,
): React.CSSProperties => {
  // if (!labelsReady) { return props; }

  const portPosition = portDescription.position;
  // const container = isInput ? inPortRef.current : outPortRef.current;
  if (!container) { return { display: 'none' }; }

  const cH = container.getBoundingClientRect().height;
  if (portPosition === 'Left' || portPosition === 'Right') {
    return { top: `${((cH / 2) - WIDGET_PORT_RADIUS) / zoom}px` };
  } else {
    const verticalLocation = portPosition[1] * cH;
    return { top: `${(verticalLocation - WIDGET_PORT_RADIUS) / zoom}px` };
  }
};

const PortLabels = (props: {
  ports: PortDescription[];
  portType: 'input' | 'output';
  hidden: boolean;
  containerRef: React.RefObject<HTMLDivElement | null>;
}) => {
  const { ports, hidden, portType, containerRef } = props;
  const currentZoom = useSelector(selectCanvasZoom);

  return ports.map((port, index) => {
    return (
      <div key={index} className={`port is-${portType} type-${port.type}`} style={getPortStyle(port, containerRef.current, currentZoom)}>
        <div
          className={classNames('label', 'noselect', {
            ['break-text']: (port.label || port.name).length > 10,
            ['hidden']: hidden,
          })}
        >
          {port.label || port.name}
        </div>
      </div>
    );
  });
};

/**
 * Draws labels on ports
 */
const WidgetLabels = forwardRef<LabelsInstanceRef, Props>((props, instanceRef) => {
  const { inputs, outputs, hidden } = props;
  const inputsContainerRef = useRef<HTMLDivElement | null>(null);
  const outputsContainerRef = useRef<HTMLDivElement | null>(null);
  const refresh = useForceReload();

  useImperativeHandle(instanceRef, () => {
    return {
      repaintLabels: () => {
        console.log('repaint labels');
        refresh();
      }
    };
  }, [refresh]);

  return (
    <>
      <div className="in-ports-container" ref={inputsContainerRef}>
        <PortLabels
          ports={inputs}
          portType='input'
          hidden={hidden}
          containerRef={inputsContainerRef}
        />
      </div>

      <div className="out-ports-container" ref={outputsContainerRef}>
        <PortLabels
          ports={outputs}
          portType='output'
          hidden={hidden}
          containerRef={outputsContainerRef}
        />
      </div>
    </>
  );
});

export default WidgetLabels;
