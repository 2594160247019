import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AsyncRequestStatus, RecipeResponse } from '../../../../types/core_t';
import { MarketplaceState } from '../marketplaceSlice';
import * as recipeApi from '../../../../api/recipe/recipeApi';


export const fetchUserRecipes = createAsyncThunk('/marketplace/myRecipes', async (): Promise<RecipeResponse[]> => {
	const recipes = await recipeApi.getAllRecipes();
	return recipes;
});

export const getUserRecipesReducer = ((builder: ActionReducerMapBuilder<MarketplaceState>/*, thunk: any*/): void => {
	builder.addCase(fetchUserRecipes.pending, (state) => { state.fetchingState.status = AsyncRequestStatus.loading; });

	builder.addCase(fetchUserRecipes.fulfilled, (state, action: PayloadAction<RecipeResponse[]>) => {
		state.fetchingState.status = AsyncRequestStatus.completed;
		state.userRecipeList = action.payload;
	});

	builder.addCase(fetchUserRecipes.rejected, (state, action) => {
		state.fetchingState.status = AsyncRequestStatus.error;
		state.fetchingState.error = action.error;
	});
});
