import { useSelector } from 'react-redux';
import { selectCurrentRecipe } from '@src/features/Workspace/workspaceSlice';

/**
 * @returns the current recipe info.
 */
const useCurrentRecipeInfo = () => {
  const currentRecipe = useSelector(selectCurrentRecipe);
  return currentRecipe;
};

export default useCurrentRecipeInfo;
