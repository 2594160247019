import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import widgetBundleManager from '@kemu-io/kemu-core/widgetBundle';
import kemuCore from '@kemu-io/kemu-core';
import { findThingInRecipe } from '@kemu-io/kemu-core/common/recipeCache';
import { CustomWidgetState, Position, RecipeWidget, WidgetBundleState, WidgetType } from '@kemu-io/kemu-core/types';
import { cloneObj } from '@kemu-io/kemu-core/common';
import { LogicMapperState } from '../logicMapperSlice';
import * as recipeActions from '@src/app/recipe/utils';
import { StatelessRecipeWidget } from '@src/types/core_t';
import { getStatelessWidget, safeJsonClone } from '@common/utils';

type DuplicateWidgetActionPayload = {
  /** the id of the recipe in the pool */
	recipeId: string;
	/** the id of the Thing in the recipe */
	thingRecipeId: string;
	/** id of the widget in the Thing to duplicate */
	widgetThingId: string;
}

/**
 * Calculates the position of a duplicated gate with respect to its sourceWidget
 * @param recipeId the id of the recipe in the pool
 * @param blockId the id of the block in the recipe
 * @param sourceWidget the gate used as a source for the copy
 */
const getClonedWidgetPosition = (recipeId: string, blockId: string, sourceWidget: RecipeWidget): Position => {
	const DEFAULT_GATE_HEIGHT = 54;
	const domEl = recipeActions.getEntityDomEl(recipeId, blockId, sourceWidget.id);

	// TODO: make sure the newly added gate won't be outside the viewport.
	if (domEl) {
		const rect = domEl.getBoundingClientRect();
		return {
			x: sourceWidget.canvas.position.x,
			y: sourceWidget.canvas.position.y + rect.height + 5
		};
	} else {
		// This should not happen!.. but just in case
		console.warn(`Dom id not found for gate '${sourceWidget.id}' in block '${blockId}' for recipe '${recipeId}'`);
		return {
			// Add them vertically
			x: sourceWidget.canvas.position.x,
			y: sourceWidget.canvas.position.y + DEFAULT_GATE_HEIGHT,
		};
	}
};

/**
 * Duplicates a widget. In the case of widget bundles, it 
 * will also create new tmp locations and add the bundle to the
 * recipe storage.
 */
const duplicateWidgetAction = createAsyncThunk(
  'LogicMapper/duplicateWidget',  async (
    payload: DuplicateWidgetActionPayload,
    /* thunkApi */
) : Promise<StatelessRecipeWidget> => {
  const targetThing = findThingInRecipe(payload.recipeId, payload.thingRecipeId);
  if (!targetThing) {
    throw new Error(`Could not find thing with id ${payload.thingRecipeId}`);
  }

  const sourceWidgetId = payload.widgetThingId;
  const sourceWidget = targetThing.gates[sourceWidgetId];
	if (!sourceWidget) { throw new Error(`Widget ${sourceWidgetId} not found in Thing ${payload.thingRecipeId}`); }
  const newWidgetId = recipeActions.generateUniqueWidgetId(targetThing);

  // Handle bundle duplication
  if (sourceWidget.type === WidgetType.widgetBundle) {
    // Make a copy of the widget without their state's private properties
		const widgetCopy = safeJsonClone<RecipeWidget>(sourceWidget);
    widgetCopy.id = newWidgetId;
    widgetCopy.canvas.position = getClonedWidgetPosition(payload.recipeId, payload.thingRecipeId, sourceWidget);
    widgetCopy.children = [];

    // Restore certain properties
    const originalWidgetState: WidgetBundleState = sourceWidget.state as CustomWidgetState<WidgetBundleState>;
    const newBundleState = {
      ...widgetCopy.state,
      // Make sure we remove the reference to the storage unit created by the original widget.
      // Ours will be created later.
      storageUnitId: undefined,
      ...(originalWidgetState.$$cacheInfo ? { $$cacheInfo: cloneObj(originalWidgetState.$$cacheInfo) } : {}),
      ...(originalWidgetState.collectionInfo ? { collectionInfo: cloneObj(originalWidgetState.collectionInfo) } : {}),
    } as CustomWidgetState<WidgetBundleState>;

    widgetCopy.state = newBundleState;
    let shouldInitializeWidget = false;

    // Now we need to create a new tmp location for the bundle
    if (newBundleState.$$cacheInfo) {
      const isTempBundle = newBundleState.$$cacheInfo?.widgetThingId
        !== newBundleState.collectionInfo?.widgetId;

      // We only need to generate new tmp locations for tmp bundles because
      // those dropped from the Widgets Launchpad (coming from a collection)
      // point to the permanent location of the widget in the cache. It is only
      // if a collection widget is modified that a /tmp location is created.
      if (isTempBundle) {
        // We need to capture the previous cache location, otherwise we won't be able to duplicate its contents
        if (originalWidgetState.$$cacheInfo) {
          const oldWidgetId = originalWidgetState.$$cacheInfo.widgetThingId;
          const oldVersion = originalWidgetState.$$cacheInfo.version;
          const newVersion = 1;
          const { processor } = await widgetBundleManager.duplicateWidgetTempLocation(
            oldWidgetId, oldVersion, newWidgetId, newVersion
          );

          // IMPORTANT: point to the new version of the cloned widget and set a processor
          // otherwise the bundle will try to load its processor the memory cache.
          // Also we MUST update the `widgetThingId` to point to the new id
          newBundleState.$$cacheInfo.version = newVersion;
          newBundleState.$$cacheInfo.widgetThingId = newWidgetId;
          newBundleState.$$processor = processor;

          // Flag for initialization
          shouldInitializeWidget = true;
        }
      } else {
        shouldInitializeWidget = true;
      }
    }

    // IMPORTANT: we MUST add the new widget to the thing BEFORE initialization,
    // otherwise the initialization routing won't be able to find it.
    targetThing.gates[newWidgetId] = widgetCopy;

    if (shouldInitializeWidget) {
      // Initialize the widget so that it loads its processor
      await kemuCore.initializeWidget(payload.recipeId, payload.thingRecipeId, newWidgetId, {
        /** prevents replacing the current state with the default one stored in the zip file (state.json) */
        keepCurrentState: true
      });
    }

    const statelessClone = getStatelessWidget(widgetCopy);
    return statelessClone;
  }

  // Handle standard widgets
	// Clone everything, specially the 'state'
	const clonedWidget = cloneObj<RecipeWidget>(sourceWidget);
	clonedWidget.id = newWidgetId;
	clonedWidget.canvas.position = getClonedWidgetPosition(payload.recipeId, payload.thingRecipeId, sourceWidget);
	clonedWidget.children = [];

	targetThing.gates[newWidgetId] = clonedWidget;

  const statelessClone = getStatelessWidget(clonedWidget);
  return statelessClone;

});

const duplicateWidgetReducer = ((builder: ActionReducerMapBuilder<LogicMapperState>): void => {
  builder.addCase(duplicateWidgetAction.fulfilled, (state, action) => {
    const clonedWidget = action.payload;
    state.gates[clonedWidget.id] = clonedWidget;
  });

  builder.addCase(duplicateWidgetAction.rejected, (_draft, action) => {
    console.error('Failed to clone widget: ', action.error);
  });
});

export default {
  duplicateWidgetAction,
  duplicateWidgetReducer,
};
