import React from 'react';
import { useSelector } from 'react-redux';
import { selectRecipeUploadDetails } from '../../features/interface/interfaceSlice';
import StreamOperationIndicator from '../streamOperationIndicator/streamOperationIndicator';
import { AsyncRequestStatus } from '../../types/core_t';
import { useTranslation } from '../../common/hooks/index';

type MessageTypes = 'success' | 'progress' | 'error';

const RecipeUploadProgress = (): React.JSX.Element | null => {
	const asyncOperation = useSelector(selectRecipeUploadDetails);
	const uploadStatus = asyncOperation?.status;
	const t = useTranslation('UploadStorageIndicator');
	let message = t('Progress', 'Uploading recipe...');
	let messageType: MessageTypes = 'progress';

	if (uploadStatus === AsyncRequestStatus.completed) {
		messageType = 'success';
		message = t('Success', 'Recipe Saved!');
	} else if (uploadStatus === AsyncRequestStatus.error) {
		messageType = 'error';
		message = t('Failure', 'Failed to upload recipe data');
	}

	if (!uploadStatus) { return null; }
	const combinedPercentage = (asyncOperation?.contentProgress || 0) + (asyncOperation?.storageProgress || 0);

	return (
		<StreamOperationIndicator
			errorReason={asyncOperation?.error?.message}
			message={message}
			percent={Math.floor(combinedPercentage / (asyncOperation?.hasStorage ? 2 : 1))}
			// percent={Math.floor(combinedPercentage)}
			type={messageType}
		/>
	);
};

export default RecipeUploadProgress;
