import React, { useCallback, useEffect, useState } from 'react';
import { CheckboxConfig } from '@kemu-io/kemu-core/types';
import { Col,  Row } from 'antd';
import styles from './CheckboxConfig.module.css';
import FormGroup from '@components/form-control/formGroup/formGroup';
import StandardModal from '@components/modal/standardModal';
import KemuSwitch from '@components/form-control/kemuSwitch/KemuSwitch';
import useTranslation from '@hooks/useTranslation';

type Props = {
  open: boolean;
  fieldIndex: number;
  currentConfig: CheckboxConfig;
  onCloseModal: (fieldIndex: number, newConfig: CheckboxConfig | null) => void;
}


const CheckboxFieldConfig = (props: Props) => {
  const t = useTranslation();
  const { currentConfig, onCloseModal, fieldIndex, open } = props;
  const [config, setConfig] = useState<CheckboxConfig>(currentConfig);

  const handleChange = useCallback((checked: boolean) => {
    setConfig({
      defaultValue: checked,
    });
  }, []);

  const handleSaveSettings = useCallback(() => {
    onCloseModal(fieldIndex, config);
  }, [config, fieldIndex, onCloseModal]);

  const handleCloseModal = useCallback(() => {
    onCloseModal(fieldIndex, null);
  }, [fieldIndex, onCloseModal]);

  useEffect(() => {
    setConfig(currentConfig);
  }, [currentConfig]);


  return (
    <StandardModal
      title={t('CustomWidget.Settings.FieldConfiguration', '{fieldName} Field Configuration', {
        fieldName: 'Checkbox',
      })}
      centered
      visible={open}
      width={330}
      onOk={handleSaveSettings}
      onCancel={handleCloseModal}
      okBtnLabel={t('CommonWords.Save', 'Save')}
      cancelBtnLabel={t('CommonWords.Cancel', 'Cancel')}
    >
      {open && (
        <Row>
          <Col span={24}>
            <FormGroup column={true}>
              <label>{t('CommonWords.DefaultValue', 'Default value')}</label>
              <p className={styles.Description}>
                {t('CustomWidget.Settings.Checkbox.Description', 'Specifies whether the checkbox is selected by default.')}
              </p>

              <FormGroup row={true} className={styles.CheckboxField}>
                <KemuSwitch
                  checked={config?.defaultValue}
                  onChange={handleChange}
                  size="default"
                />
                <span>{config.defaultValue ? t('CommonWords.Yes', 'Yes') : t('CommonWords.No', 'No')}</span>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>
      )}
    </StandardModal>
  );
};

export default CheckboxFieldConfig;


