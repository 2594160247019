import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { recipeCache } from '@kemu-io/kemu-core/common';
import { GateCanvasInfo, WidgetCanvasDimensions, WidgetType, Position } from '@kemu-io/kemu-core/types';
import { DEFAULT_THING_ID } from '@kemu-io/kemu-core/common/constants';
import { openBlockAction } from '../Workspace/workspaceSlice';
import { connectGatesReducer } from './reducers/index';
import customWidgetReducers, { WidgetGroupStateWithId } from './reducers/customWidgetReducer';
import clipboardReducers, { pasteFromClipboardReducer } from './reducers/clipboardReducers';
import addWidgetFromTemplate from './reducers/addWidgetFromTemplate';
import { WidgetBundleStateWithId } from './reducers/showCreateWidgetBundleModal';
import { addWidgetBundleFromZipReducer } from './reducers/addWidgetBundleFromZip';
import { updateTempWidgetBundleReducer } from './reducers/updateTempWidgetBundle';
import addWidgetFromTemplateReducer from './reducers/addHubServiceWidget';
import { removeWidgetsReducer } from './reducers/removeWidgetsReducer';
import duplicateWidgetReducer from './reducers/duplicateWidgetReducer';
import { safeJsonClone, widgetMapToStatelessMap } from '@common/utils';
import { StatelessWidgetsMap, StatelessRecipeWidget } from '@src/types/core_t';
import { RootState } from '@src/app/store';
import * as RecipeActions from '@src/app/recipe/utils';

const { findThingInRecipe } = recipeCache;
export type SelectedWidgetInfo = {
	widgetId: string;
	// type: string;
}

export type LogicMapperState = {
	/** 
	 * used to force the logic mapper to re-render (thus reattaching connections)
	 **/
	renderCounter: number;
	showCreateWidgetModal: boolean | WidgetGroupStateWithId;
	showCreateWidgetBundleModal: boolean | WidgetBundleStateWithId;
	gates: StatelessWidgetsMap;
	/** widgets selected via drag selection */
	selectedWidgets: SelectedWidgetInfo[];
	lastMouseClick: Position;
	canvasZoom: number;
}

/** 
 * @deprecated use `LogicMapperState` instead
 **/
export type LogicMakerState = LogicMapperState;

const initialState: LogicMapperState = {
	renderCounter: 0,
	canvasZoom: 1,
	showCreateWidgetModal: false,
	showCreateWidgetBundleModal: false,
	selectedWidgets: [],
	lastMouseClick: { x: 0, y: 0 },
	gates: {},
};

interface AddGateProps {
	recipeId: string;
	blockRecipeId: string;
	parentGroupId?: string;
	gateType: WidgetType;
	position: Position;
}

interface GenericActionProps {
	/** recipe pool id */
	recipeId: string;
	/** id of the block in the recipe where the source gate is located */
	blockRecipeId: string;
	/** id of the gate for which the action was triggered */
	sourceGateId: string;
}

export interface ChildGateActionProps {
	/** recipe pool id */
	recipeId: string;
	/** id of the block in the recipe where the source gate is located */
	blockRecipeId: string;
	/** id of the gate in the block to be cloned */
	sourceGateId: string;
	/** id of the port in the source gate the connection originates from  */
	sourcePortId: string;
	/** id of the gate to connect to */
	targetGateId: string;
	/** id of the port in the target gate the connection attaches to */
	targetPortId: string;
}



export const logicMapperSlice = createSlice({
	name: 'logicMapper',
	initialState,
	reducers: {

		...customWidgetReducers,
		...clipboardReducers,

		forceLogicMapperRender: (state) => {
			state.renderCounter = state.renderCounter + 1;
		},

		setMouseLocation: (state, action: PayloadAction<Position>) => {
			state.lastMouseClick = action.payload;
		},

		setCanvasZoom: (state, action: PayloadAction<number>) => {
			state.canvasZoom = action.payload;
		},

		setGatePosition: (state, action: PayloadAction<GenericActionProps & { canvasInfo: GateCanvasInfo}>) => {
			// Modify the pool
			RecipeActions.setGateCanvasInfo(action.payload.recipeId, action.payload.blockRecipeId, action.payload.sourceGateId, action.payload.canvasInfo);
			// Update the local store
			state.gates[action.payload.sourceGateId] = {
				...state.gates[action.payload.sourceGateId],
				canvas: action.payload.canvasInfo
			};
		},

		/**
		 * Removes connections from a port regardless of weather it is a source or target port.
		 */
		removeWidgetPortConnectionsAction: (state, action: PayloadAction<{recipeId: string, widgetId: string, portName: string}>) => {
			// Checks in all the widgets in the sate those that have the given widget as a child or parent
			// and removes it as a child or parent.
			const widgets = state.gates;
			const targetWidget = widgets[action.payload.widgetId];

			for (const key in widgets) {
				const widget = widgets[key];
				// Skip the widget itself
				if (widget.id === action.payload.widgetId) { continue; }
				// Check if the widget has the given widget as a child
				const withoutTargetWidget = widget.children.filter((child) => {
					const isLinkedToPort = child.childId === action.payload.widgetId && child.targetPort === action.payload.portName;

					// Remove the connection
					if (isLinkedToPort) {
						RecipeActions.removeChildGate(
							action.payload.recipeId,
							DEFAULT_THING_ID,
							widget.id,
							action.payload.widgetId,
							child.sourcePort,
							action.payload.portName
						);
					}

					return !isLinkedToPort;
				});

				// Update the list of children of the parent without the target widget
				state.gates[widget.id].children = withoutTargetWidget;
			}

			// Now find all child connections from the given port and remove them
			const withoutConnections = targetWidget.children.filter((child) => {
				const isLinkedToPort = child.sourcePort === action.payload.portName;
				if (isLinkedToPort) {
					RecipeActions.removeChildGate(
						action.payload.recipeId,
						DEFAULT_THING_ID,
						action.payload.widgetId,
						child.childId,
						action.payload.portName,
						child.targetPort,
					);
				}

				return !isLinkedToPort;
			});

			state.gates[action.payload.widgetId].children = withoutConnections;
		},

		setWidgetDimensions: (state, action: PayloadAction<GenericActionProps & WidgetCanvasDimensions>) => {
			// Modify the pool
			const currentInfo = RecipeActions.getGateCanvasInfo(action.payload.recipeId, action.payload.blockRecipeId, action.payload.sourceGateId);
			const newPosition: GateCanvasInfo = {
				...currentInfo,
				dimensions: {
					width: action.payload.width,
					height: action.payload.height,
				},
			};

			RecipeActions.setGateCanvasInfo(
				action.payload.recipeId,
				action.payload.blockRecipeId,
				action.payload.sourceGateId,
				newPosition,
			);

			// Update the local store
			state.gates[action.payload.sourceGateId] = {
				...state.gates[action.payload.sourceGateId],
				canvas: newPosition,
			};
		},
		/** Sets the full list of gates, replacing any existing one */
		setGates: (state, action: PayloadAction<StatelessWidgetsMap>) => {
			state.gates = action.payload;
		},

		addGate: (state, action: PayloadAction<AddGateProps>) => {
			// Add to the recipe
			const addedGate = RecipeActions.addGateToBlock(
				action.payload.recipeId,
				action.payload.blockRecipeId,
				action.payload.gateType,
				action.payload.position,
				action.payload.parentGroupId
			);

			// Make sure the state is not included in the store
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { state: _, ...statelessGate } = addedGate;

			// Add to global state
			// NOTE: Avoid passing the original objects since immer will freeze them and
			// prevent us from updating properties in the future, such as gate position, etc
			state.gates[addedGate.id] = safeJsonClone(statelessGate);
		},

		// duplicateGate: (state, action: PayloadAction<GenericActionProps>) => {
		// 	const clonedGate = RecipeActions.duplicateGate(action.payload.recipeId, action.payload.blockRecipeId, action.payload.sourceGateId);
		// 	state.gates[clonedGate.id] = safeJsonClone(clonedGate);
		// },

		toggleWidgetOutputMode: (state, action: PayloadAction<GenericActionProps>) => {
			const clonedGate = RecipeActions.toggleReturnOriginalEvent(action.payload.sourceGateId, action.payload.blockRecipeId, action.payload.recipeId);
			state.gates[clonedGate.id] = safeJsonClone(clonedGate);
		},

		toggleWidgetState: (state, action: PayloadAction<GenericActionProps & {disabled: boolean}>) => {
			const updatedWidget = RecipeActions.disableWidget(
				action.payload.recipeId,
				action.payload.blockRecipeId,
				action.payload.sourceGateId,
				action.payload.disabled
			);

			state.gates[updatedWidget.id] = safeJsonClone(updatedWidget);
		},

		connectGates: connectGatesReducer,


		disconnectGate: (state, action: PayloadAction<ChildGateActionProps>) => {
			// NOTE: Disconnect events also get triggered when a gate is removed from the DOM
			// (user right clicked => delete (`removeGate` reducer). The event gets generated BEFORE redux update its
			// state and notify this component. However, the disparity of the situation (a gate no longer exists
			// and we are trying here to remove it as a child) is detected by the LogicMaker reducer and the state
			// is prevented from being updated again.
			const targetExists = state.gates[action.payload.targetGateId];
			if (targetExists) {
				const updatedChildrenList = RecipeActions.removeChildGate(
					action.payload.recipeId,
					action.payload.blockRecipeId,
					action.payload.sourceGateId,
					action.payload.targetGateId,
					action.payload.sourcePortId,
					action.payload.targetPortId
				);

				if (state.gates[action.payload.sourceGateId]) {
					state.gates[action.payload.sourceGateId].children = JSON.parse(JSON.stringify(updatedChildrenList));
				}
			}
		}
	},

	extraReducers: (builder) => {

		pasteFromClipboardReducer(builder);
		addWidgetFromTemplate.reducer(builder);
		addWidgetBundleFromZipReducer(builder);
		updateTempWidgetBundleReducer(builder);
		removeWidgetsReducer(builder);
		addWidgetFromTemplateReducer.reducer(builder);
		duplicateWidgetReducer.duplicateWidgetReducer(builder);

		// Intercept workspace action `openBlock`
    builder.addCase(openBlockAction, (state, action) => {
			if (!action.payload) {
				// Since no block is selected (user in workspace),
				// remove list of widgets 
				state.gates = {};
				state.selectedWidgets = [];
				return;
			}
			const thing = findThingInRecipe(action.payload.recipePoolId, action.payload.recipeId);
			if (thing) {
				// Check note in `addGate` (above) regarding immer
				// IMPORTANT: Some widgets (such as the the drawPixels widget) use private properties
				// to store references of large array buffers (ImageData), these arrays could have over
				// 100k items depending on the resolution of the image. Here we remove such properties before
				// attempting to stringify the gates, otherwise this will cause a HUGE performance drop.
				state.gates = widgetMapToStatelessMap(thing.gates);
			}
    });
	},
});

/** @deprecated use `selectCurrentWidgets` instead */
export const currentGates = (state: RootState): StatelessWidgetsMap => state.logicMapper.gates;
export const selectCurrentWidgets = (state: RootState): StatelessWidgetsMap => state.logicMapper.gates;
export const getGateInfoById = (id: string) => (state: RootState): StatelessRecipeWidget | null  => {
	return state.logicMapper.gates[id] || null;
};

export const selectShowCreateWidgetModal = (state: RootState): WidgetGroupStateWithId | boolean => state.logicMapper.showCreateWidgetModal;
export const selectShowCreateWidgetBundleModal = (state: RootState): WidgetBundleStateWithId | boolean => state.logicMapper.showCreateWidgetBundleModal;
export const selectSelectedWidgets = (state: RootState): SelectedWidgetInfo[] => state.logicMapper.selectedWidgets;
export const selectRenderCounter = (state: RootState): number => state.logicMapper.renderCounter;
export const selectCanvasZoom = (state: RootState): number => state.logicMapper.canvasZoom;

export const {
	// setGatePorts,
	addGate,
	toggleWidgetOutputMode,
	connectGates,
	disconnectGate,
	toggleWidgetState,
	setGates,
	removeWidgetPortConnectionsAction,
	forceLogicMapperRender: forceLogicMapperRenderAction,
	setMouseLocation: setMouseLocationAction,
	addCustomWidgetReducer: addCustomWidgetAction,
	editCustomWidgetReducer: editCustomWidgetAction,
	setShowCreateWidgetModalReducer: showCreateWidgetModalAction,
	showCreateWidgetBundleModal: showCreateWidgetBundleModalAction,
	setSelectedWidgetsReducer: setSelectedWidgetsAction,
	copySelectedWidgetsReducer: copySelectedWidgetsAction,
	setGatePosition,
	setWidgetDimensions,
	setCanvasZoom,
} = logicMapperSlice.actions;

export const addWidgetFromTemplateAction = addWidgetFromTemplate.action;
export const addHubServiceWidgetAction = addWidgetFromTemplateReducer.action;

export { addWidgetBundleFromZipAction } from './reducers/addWidgetBundleFromZip.ts';
export { updateTempWidgetBundleAction } from './reducers/updateTempWidgetBundle.ts';
export { removeWidgetsAction } from './reducers/removeWidgetsReducer.ts';
export const { duplicateWidgetAction } = duplicateWidgetReducer;
export default logicMapperSlice.reducer;
