import React, { useCallback } from 'react';
import { Select } from 'antd';
import { SelectProps, SelectValue, LabeledValue } from 'antd/lib/select';
import StyledLabel from '../styledLabel/styledLabel';
import styles from './styledSelect.module.css';

type Props = Omit<SelectProps<SelectValue>, 'onChange'> & {
	children?: React.ReactNode;
	label?: React.ReactNode;
	errorMessage?: React.ReactNode;
	elementId?: string | number;
	onChange?: (value: SelectValue, options: LabeledValue[], elementId?: string | number) => void;
}

const { Option } = Select;
export { Option };
export type { SelectValue, LabeledValue };

/**
 * Usage:
 * ```js
 *	import StyledSelect, {Option} from './styledSelect';
 * 
 *
 *	<StyledSelect label="My label" onChange={onOptionSelected} value="Default Value" errorMessage="Something went wrong">
 *		<Option value="Member">Member</Option>
 *		<Option value="Admin">Admin</Option>
 *	</StyledSelect>
 * ```
 */
const StyledSelect = (props: Props): React.JSX.Element => {
	const { errorMessage, onChange, elementId, ...attributes } = props;

	const handleChange = useCallback((value: SelectValue, options: unknown) => {
		onChange && onChange(value, options as LabeledValue[], elementId);
	}, [onChange, elementId]);

	return (
		<>
			{props.label && (
				<StyledLabel text={props.label} />
			)}

			<Select {...attributes} onChange={handleChange} className={`${styles.Select} ${errorMessage ? styles.InvalidInput : ''} ${props.className ? props.className : ''}`} >
				{props.children}
			</Select>

			{ errorMessage &&  <p className={styles.ErrorMessage}>{errorMessage}</p>}
		</>
	);
};

export default StyledSelect;
