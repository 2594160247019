
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Icon from '@ant-design/icons';
import ObjectWidgetProcessor, {
	ImageWarpWidgetState,
} from '@kemu-io/kemu-core/widgets/imageWarp/index.js';
import { CustomWidgetState, WidgetPortContext } from '@kemu-io/kemu-core/types';
import classNames from 'classnames';
import { Space } from 'antd';
import useTranslation from '../../../common/hooks/useTranslation';
import GateIcon from '../../gateIcon/gateIcon';
import {
	GateCustomSettingsProps,
	GetPortsInformationFunction,
	GateUI,
	PortDescription,
} from '../index.ts';
import StandardModal from '../../modal/standardModal';
import { LM_CANVAS_CONTAINER_CLASS } from '../../../common/constants';
import StyledButton from '../../form-control/styledButton/styledButton';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import ImageRenderer from './ImageRenderer';
import { getSpeedyIdentityMatrix } from './helpers';
import styles from './imageWarp.module.css';
import { ReactComponent as ImageWarpIcon } from './icon.svg';

const WIDGET_WRAPPER_CLASS = `image-warp-widget-wrapper`;


const CommonBody = (props: {children?: React.ReactNode}): React.JSX.Element => {

	return (
		<div className={classNames(styles.GateBody, {})}>
			<Icon component={ImageWarpIcon}/>
			{props.children}
		</div>
	);
};

const ImageWarpWidget = (): React.JSX.Element => {
	return (
		<CommonBody />
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={ImageWarpIcon} />}/>
	);
};

const noReRender = () => false;

const GateCustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
	const { onClose } = props;
	const t = useTranslation('LogicMaker.Gates.ImageWarp.Settings');
	const [canvasReady, setCanvasReady] = useState(false);
	const [modalVisible, setModalVisible] = useState(true);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, setState] = useReactiveWidgetState<ImageWarpWidgetState>(
		props.recipeId,
		props.blockId,
		props.gateInfo.id,
		noReRender
	);

	const canvasRef = useRef<HTMLCanvasElement | null>(null);
	const contextRef = useRef<CanvasRenderingContext2D | null>(null);
	const modalContainer = useMemo(() => document.querySelector(`.${LM_CANVAS_CONTAINER_CLASS}`)?.closest('section'), []);


	const handleSettingsClosed = useCallback(() => {
		canvasRef.current = null;
		contextRef.current = null;
		setCanvasReady(false);
		setModalVisible(false);
		onClose();
	}, [onClose]);

	const clearAll = useCallback(() => {
		if (canvasRef.current && contextRef.current) {
			const canvas = canvasRef.current;
			const ctx = contextRef.current;
			ctx.clearRect(0, 0, canvas.width, canvas.height); //clear html5 canvas
		}

		setState((currentState) => {
			const updatedState = { ...currentState };
			// Also reset the transform matrix
			if (updatedState.$$private?.perspective) {
				updatedState.$$private.perspective.transform = getSpeedyIdentityMatrix();
			}

			return {
				...updatedState,
				anchors: [],
				vertices: [],
			};
		});
	}, [setState]);

	useEffect(() => {
		const canvas = canvasRef.current;
		if (canvas && !contextRef.current) {
			contextRef.current = canvas.getContext('2d');
			setCanvasReady(true);
		}
	}, []);

	return (
			<>
			<div className={styles.SettingsHeader} />
			<CommonBody>
				<StandardModal
					destroyOnClose={true}
					dataKemuMeta="image-warp-custom-settings"
					onCancel={handleSettingsClosed}
					closeOnMaskClick={true}
					centered={false}
					bodyClassName={styles.SettingsModalBody}
					visible={modalVisible}
					width={600}
					title={'Image Warp Settings'}
					getContainer={() => modalContainer}
					customFooter={
						<div className={styles.FooterWrapper}>
						<Space size={20}>
							<StyledButton data-kemu-meta="modal-cancel-btn" color={'light'} title={t('ClearBtn', 'Reset Points')} onClick={clearAll}/>
							<StyledButton data-kemu-meta="modal-ok-btn" color={'primary'} title={t('DoneBtn', 'Done')} onClick={handleSettingsClosed} />
						</Space>
						</div>
					}
				>
					<div className={styles.CanvasWrapper}>
						<canvas className={styles.Canvas} ref={canvasRef}/>
						{/* <button onClick={clearAll}>Clear</button> */}
					</div>

					{canvasReady && modalVisible && (
						<>
							<ImageRenderer
								thingId={props.blockId}
								canvas={canvasRef.current}
								context={contextRef.current}
								gateInfo={props.gateInfo}
								recipeId={props.recipeId}
								setWidgetState={setState}
							/>
						</>
					)}
				</StandardModal>

			</CommonBody>
		</>
	);
};

const getPortsInformation: GetPortsInformationFunction = (state: CustomWidgetState<ImageWarpWidgetState>, widgetInfo) => {
	const portContext: WidgetPortContext = {
		recipePoolId: widgetInfo.recipePoolId,
		recipeType: widgetInfo.recipeType,
		thingRecipeId: widgetInfo.thingRecipeId,
		widgetId: widgetInfo.id,
	};
	const outputNames = ObjectWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = ObjectWidgetProcessor.getInputNames(state, portContext);

	const inputs: PortDescription[] = [{
		position: 'Left',
		name: inputNames[0].name,
		type: inputNames[0].type,
	}];

	return {
		outputs: [{
			position: 'Right',
			name: outputNames[0].name,
			type: outputNames[0].type,
		}],

		inputs,
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: ImageWarpWidget,
	CustomSettingsDialog: GateCustomSettings,
	getWrapperClass: () => WIDGET_WRAPPER_CLASS,
	hasTitle: true,
	getGatesBarTitle: (intl) => intl.formatMessage({ id: 'LogicMaker.Gates.ImageWarp.Title', defaultMessage: 'Crop Image' }),
	getWidgetTitle: (intl) => intl.formatMessage({ id: 'LogicMaker.Gates.ImageWarp.WidgetHeader', defaultMessage: 'Crop' }),
} as GateUI;
