import React from 'react';
import { Menu, Layout } from 'antd';
import { useNavigate, useMatch } from 'react-router-dom';
import Icon from '@ant-design/icons';
import classNames from 'classnames';
import routes from '../../../../common/routes/index';
import { ReactComponent as RecipeIcon } from '../../../../assets/img/menus/recipe.svg';
import useTranslation from '../../../../common/hooks/useTranslation';
import styles from './MarketplaceMenu.module.css';
import useQueryString from '@hooks/useQueryString';


const { Sider } = Layout;

const MarketplaceMenu = (): React.JSX.Element => {
  const navigate = useNavigate();
  const t = useTranslation('Marketplace.Navigation.LeftPanel');
  const queryStr = useQueryString();
	const inRecipes = queryStr.get('tab') === 'recipe';

  // const inRecipes = useMatch({ path: routes.marketplace.getMyRecipesRoute() });
	// const inTutorials = useMatch({ path: routes.marketplace.getMyTutorialsRoute() });
  const inMarketplace = useMatch({ path: routes.marketplace.getMarketplacePublicRoute() });


  const navigateToTab = (route: string) => {
		navigate(route);
	};

  return (
    <Sider className={styles.Sider} theme='light' width={200} breakpoint={'md'} collapsedWidth={0}>
      {/* ID added for specificity reasons only. ant styles were overriding my custom ones */}
      <Menu  mode="inline" className={`sidebar-menu`} selectable={false} id={styles.Menu}>
        <Menu.Item
          data-kemu-meta="recipes-section"
          onClick={() => navigateToTab(routes.marketplace.getMyRecipesRoute())}
          className={classNames(styles.MenuItem, {
            [styles.MenuItemSelected]: inRecipes,
          })}
          title=""
          key="1"
          icon={<Icon component={RecipeIcon} />}
        >
          {t('Recipes', 'Recipes')}
        </Menu.Item>

        {/* <Menu.Item
          data-kemu-meta="tutorials-section"
          onClick={() => navigateToTab(routes.marketplace.getMyTutorialsRoute())}
          className={`${styles.MenuItem} ${inTutorials ? styles.MenuItemSelected : ''}`}
          title=""
          key="2"
          icon={<Icon component={TutorialsIcon}/>}
        >
          {t('Tutorials', 'Tutorials')}
        </Menu.Item> */}

        {/* <Menu.Item
          data-kemu-meta="marketplace-section"
          onClick={() => navigateToTab(routes.marketplace.getPublicationListRoute('recipe'))}
          className={classNames(styles.MenuItem, {
            [styles.MenuItemSelected]: inMarketplace,
          })}
          title=""
          key="3"
          icon={<ShoppingCartOutlined />}
        >
          {t('Marketplace', 'Marketplace')}
        </Menu.Item> */}
      </Menu>
    </Sider>
  );
};

export default MarketplaceMenu;
