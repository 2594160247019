import React, { CSSProperties, memo } from 'react';
import Icon from '@ant-design/icons';
import { ReactSVG } from 'react-svg';

interface Props {
	style?: CSSProperties;
	size: number;
	/** svg URL */
	icon: string;
	className?: string;
}

const DynamicSVG = (props: Props): React.JSX.Element => {

	const onSVGInjection = (svg: SVGElement) => {
		svg.setAttribute('style', `width: ${props.size}px; height: ${props.size}px`);
	};

	const getDynamicSVG = (icon: string) => {
		return () => <ReactSVG src={icon} wrapper="span" beforeInjection={onSVGInjection}/>;
	};

	return (
		<Icon component={getDynamicSVG(props.icon)} style={props.style} className={props.className}/>
	);
};

export default memo(DynamicSVG);
