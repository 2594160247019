
import React, { useEffect } from 'react';
import Icon from '@ant-design/icons';
import classNames from 'classnames';
import SequenceWidgetProcessor, { SequenceWidgetState } from '@kemu-io/kemu-core/widgets/sequence/index.js';
import { BasicWidgetInfo, WidgetPortContext } from '@kemu-io/kemu-core/types';
import { useIntl } from 'react-intl';
import { createWidgetPortIdentifier } from '@kemu-io/kemu-core/common/utils';
import { GetPortsInformationFunction, GateUI, GateUIProps } from '../index';
import GateIcon from '../../gateIcon/gateIcon';
import { ReactComponent as SequenceWidgetIcon } from '../../../assets/img/gates/sequence-widget.svg';
import { PortLocation } from '../../../types/canvas_t';
import { buildOutputPortsDescription } from '../common';
import styles from './sequence.module.css';
import useReactiveWidgetState from '@hooks/useReactiveWidgetState';


const compareState = (prevState: SequenceWidgetState, nextState: SequenceWidgetState): boolean => {
	const changed = prevState.$$lastEventType !== nextState.$$lastEventType;
	return changed;
};

const SequenceWidget = (props: GateUIProps): React.JSX.Element => {
	const { rebuildPortClasses, recipeId, thingRecipeId, info, thingVersion, thingDbId } = props;
	const [state] = useReactiveWidgetState<SequenceWidgetState>(recipeId, thingRecipeId, info.id, compareState);
	const intl = useIntl();

	// Repaint widget when output type changes
	useEffect(() => {
		const basicInfo: BasicWidgetInfo = {
			id: info.id,
			recipePoolId: recipeId,
			recipeId: recipeId,
			recipeType: props.recipeType,
			thingDbId: thingDbId,
			thingRecipeId: thingRecipeId,
			thingVersion: thingVersion,
		};

		const summary = getPortsInformation(state, basicInfo, intl);
		// const inputPorts = summary.inputs.map((input, i) => ({ ...input, id: getUniqueWidgetPortId(i, 'input', info.id, input.name) }));
		// const outputPorts = summary.outputs.map((output, i) => ({ ...output, id: getUniqueWidgetPortId(i, 'output', info.id, output.name) }));
		const inputPorts = summary.inputs.map((input, i) => ({ ...input, id: createWidgetPortIdentifier(info.id, 'input', input.name) }));
		const outputPorts = summary.outputs.map((output, i) => ({ ...output, id: createWidgetPortIdentifier(info.id, 'output', output.name) }));

		rebuildPortClasses({
			inputPorts,
			outputPorts,
		});

	}, [state, rebuildPortClasses, recipeId, thingRecipeId, info.id, props.recipeType, thingDbId, thingVersion, intl]);

	return (
		<div className={classNames(styles.GateBody, {
			[styles.WidgetDisabled]: props.info.disabled,
		})}>
			<Icon component={SequenceWidgetIcon}/>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={SequenceWidgetIcon} />}/>
	);
};


const getPortsInformation: GetPortsInformationFunction = (state, widgetInfo) => {
	const portContext: WidgetPortContext = {
		recipePoolId: widgetInfo.recipePoolId,
		recipeType: widgetInfo.recipeType,
		thingRecipeId: widgetInfo.thingRecipeId,
		widgetId: widgetInfo.id,
	};
	const outputNames = SequenceWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = SequenceWidgetProcessor.getInputNames(state, portContext);

	const outputs = buildOutputPortsDescription(outputNames, 0.14);
	const positions: Record<string, PortLocation> = {
		'event': 'Left',
	};

	return {
		inputs: inputNames.map(input => ({
			name: input.name,
			type: input.type,
			position: positions[input.name]
		})),

		outputs,
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: SequenceWidget,
	hasTitle: false,
	getWidgetTitle: (intl) => intl.formatMessage({ defaultMessage: 'Sequence', id: 'LogicMapper.Widgets.Sequence.Title' }),
} as GateUI;
