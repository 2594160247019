import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { InterfaceState, setRecipeUploadProgress } from '../interfaceSlice';
import * as recipeApi from '../../../api/recipe/recipeApi';
import { AsyncRequestStatus } from '../../../types/core_t';

/**
 * Uploads the `contents` of a recipe directly to S3
 * @param recipeId the id of the recipe in the pool
 * @param name the name of the recipe
 */
export const uploadRecipeContentsAction = createAsyncThunk('/interface/uploadRecipeContents', async (options: {contents: ArrayBuffer, uploadUrl: string, hasStorage: boolean}, thunkAPI): Promise<void> => {
	// Start actual upload process
	try {
		await recipeApi.uploadRecipeFile(options.uploadUrl, options.contents, (evt) => {
			thunkAPI.dispatch(setRecipeUploadProgress({ content: evt.percentage, hasStorage: options.hasStorage }));
		});
	} catch (e) {
		console.log('Error uploading recipe contents: ', e);
		throw e;
	}
});


export const uploadRecipeContentsReducer = ((builder: ActionReducerMapBuilder<InterfaceState>): void => {
	builder.addCase(uploadRecipeContentsAction.pending, (state) => {
		state.recipeUploadDetails = {
			...state.recipeUploadDetails,
			contentProgress: 0,
			status: AsyncRequestStatus.loading
		};
	});

	builder.addCase(uploadRecipeContentsAction.fulfilled, (state) => {
		state.recipeUploadDetails = {
			...state.recipeUploadDetails,
			status: AsyncRequestStatus.completed
		};
	});

	builder.addCase(uploadRecipeContentsAction.rejected, (state, action) => {
		state.recipeUploadDetails = {
			...state.recipeUploadDetails,
			status: AsyncRequestStatus.error,
			error: action.error
		};
	});
});
