import React, { ChangeEvent, useCallback } from 'react';
import { Collapse } from 'antd';
import { GroupWidgetPort } from '@kemu-io/kemu-core/types';
import { useSelector } from 'react-redux';
import { DataType } from '@kemu-io/hs-types';
import IconSelector from '../iconSelector/iconSelector';
import PortsList from '../portsList';
import { WidgetGroupStateWithId } from '../../reducers/customWidgetReducer';
import styles from './DetailsTab.module.css';
import StyledInput from '@components/form-control/styledInput/styledInput';
import useTranslation from '@hooks/useTranslation';
import FormGroup from '@components/form-control/formGroup/formGroup';
import StyledTextArea from '@components/form-control/styledTextArea/styledTextArea';
import StyledLabel from '@components/form-control/styledLabel/styledLabel';
import ColorSwatch from '@components/colorSwatch/colorSwatch';
import { selectCurrentRecipeType, selectedBlock } from '@src/features/Workspace/workspaceSlice';
import { getRandomPortName } from '@src/app/recipe/utils';

type Props = {
  name: string;
  description: string;
  color?: string;
  icon?: string;
  widgetId?: string;
  inputs: GroupWidgetPort[];
  outputs: GroupWidgetPort[];
  existingWidgetInfo?: Partial<WidgetGroupStateWithId>;
  onNameChange: (name: string) => void;
  onDescriptionChange: (description: string) => void;
  onColorChange: (color: string) => void;
  onIconChange: (icon: string) => void;
  onInputsChange: (inputs: GroupWidgetPort[]) => void;
  onOutputsChange: (outputs: GroupWidgetPort[]) => void;
  // onAddPort: (type: 'input' | 'output') => void;
}

const defaultColors = [
	'#42a5f5', '#ab47bd', '#626060',
	'#68bb6c', '#eac43e', '#ff7070',
	'#CE69FC', '#FF6822', '#FF9361',
	'#FFB834', '#02AF97', '#777898',
	'#7CE898', '#FF8CA7', '#0291AF',
	'#0094DF', '#84D2F9', '#00B9DF',
	'#A9B8C2', '#A15D1A', '#20282E'
];

const getNextLabel = (prefix: string, labels: string[]): string => {
	let loops = labels.length;
	let label = `${prefix}${loops+1}`;
	while (labels.includes(label)) {
		loops++;
		label = `${prefix}${loops}`;
	}

	return label;
};

const { Panel } = Collapse;


const DetailsTab = (props: Props) => {
  const t = useTranslation('CustomWidgetModal');
  const currentBlockInfo = useSelector(selectedBlock);
  const currentRecipeType = useSelector(selectCurrentRecipeType);
  const {
    name, description, onNameChange, onDescriptionChange,
    color, onColorChange, icon, onIconChange, inputs, outputs,
    onInputsChange, onOutputsChange, widgetId, existingWidgetInfo,
  } = props;

  const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onNameChange(e.target.value);
  }, [onNameChange]);

  const handleDescriptionChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    onDescriptionChange(e.target.value);
  }, [onDescriptionChange]);

  const handleInputsChange = useCallback((index: number, port: GroupWidgetPort) => {
    const inputsCopy = inputs.map(port => ({ ...port }));
    inputsCopy[index] = port;
    onInputsChange(inputsCopy);
  }, [inputs, onInputsChange]);

  const handleOutputPortChange = useCallback((index: number, port: GroupWidgetPort) => {
    const outputsCopy = outputs.map(port => ({ ...port }));
    outputsCopy[index] = port;
    onOutputsChange(outputsCopy);
  }, [outputs, onOutputsChange]);

  const handleAddInputPort = useCallback(() => {
    // Make a copy and fix indices
    const copy = [...inputs];
    // const copy = inputs.map((port, i) => {
    //   return { ...port, index: i };
    // });
    const existingNames = copy.map(item => item.label || '');
    const label = getNextLabel('input', copy.map(item => item.label || ''));
    const portName = getRandomPortName(label, existingNames);
    copy.push({
      name: portName,
      label,
      type: [DataType.Anything],
      index: copy.length,
    });
    onInputsChange(copy);
	}, [onInputsChange, inputs]);

  const handleAddOutputPort = useCallback(() => {
    const copy = [...outputs];
    // const copy = outputs.map((port, i) => {
    //   return { ...port, index: i };
    // });

    const label = getNextLabel('output', copy.map(item => item.label || ''));
    const portName = getRandomPortName(label);
    copy.push({
      name: portName,
      label,
      type: [DataType.Anything],
      index: copy.length,
    });
    onOutputsChange(copy);
  } , [onOutputsChange, outputs]);


	const handleRemovePort = useCallback((type: 'input' | 'output', index: number) => {
		/* const warnIfInputAttached = (port: LastPortInfo | null, minIndex: number): boolean => {
			// Prevent adding ports if trigger is linked (otherwise new ports will obtain the connection from trigger)
			if (port && port.index >= minIndex) {
				const isInner = port.type === 'innerInput' || port.type === 'innerOutput';
				message.warning({
					content: t('RemoveWarning', 'Please remove all connections from {inner}port "{name}"',
					{
						name: port.label || port.portName,
						inner: isInner ? 'inner ' : ''
					})
				});
				return true;
			}

			return false;
		};

		if (existingWidgetInfo && currentBlockInfo && widgetId) {
			const connectedInput = getLastInputPortWithParent<WidgetGroupState>(
				existingWidgetInfo as WidgetGroupState,
				WidgetGroupProcessor,
				widgetId,
				currentBlockInfo.recipeId,
				currentBlockInfo.recipePoolId,
				currentRecipeType,
			);

			const connectedOutputs = getOutputPortsWithChildren<WidgetGroupState>(
				existingWidgetInfo as WidgetGroupState,
				WidgetGroupProcessor,
				widgetId,
				currentBlockInfo.recipeId,
				currentBlockInfo.recipePoolId,
				currentRecipeType
			);

			let ignoreConnectedPort = false;
			let portInfo: LastPortInfo | null = null;

			if (type === 'input') {
				// check for connected parents to outer inputs
				if (connectedInput) {
					const decodedName = decodePortName(connectedInput.portName);
          if (decodedName.type === 'innerOutput' || decodedName.type === 'input') {
            portInfo = connectedInput;
            if (warnIfInputAttached(portInfo, index)) { return ; }
          }
				}

				// Check for connected children to inner outputs
				if (!ignoreConnectedPort && connectedOutputs.length) {
					const innerPorts = connectedOutputs.filter(port =>  decodePortName(port.portName).type !== 'output');
					if (innerPorts.length) {
						portInfo = innerPorts[0];
						ignoreConnectedPort = false;
					}
				}

        if (!ignoreConnectedPort) {
          if (warnIfInputAttached(portInfo, index)) { return ; }
        }
			} else {
				// Check for connected children to outer ports 
				if (connectedOutputs.length) {
					const outerPorts = connectedOutputs.filter(port =>  decodePortName(port.portName).type !== 'innerInput');
					if (outerPorts.length) {
						portInfo = outerPorts[0];
						// ignoreConnectedPort = false;
            if (warnIfInputAttached(portInfo, index)) { return ; }
					}
				}

				// Check for connected parents to inner inputs
				if (!ignoreConnectedPort && connectedInput) {
					const decodedName = decodePortName(connectedInput.portName);
          console.log('decodedName', decodedName);
          if (decodedName.type === 'input' || decodedName.type === 'innerOutput') {
            portInfo = connectedInput;
            if (warnIfInputAttached(portInfo, index)) { return ; }
          }
				}
			}
		} */

    if (type === 'input') {
      const copy = [...inputs];
      copy.splice(index, 1);
      // Update the index of the remaining ports
      // copy = copy.map((port, i) => ({ ...port, index: i }));
      onInputsChange(copy);
    } else {
      const copy = [...outputs];
      copy.splice(index, 1);
      // Update the index of the remaining ports
      // copy = copy.map((port, i) => ({ ...port, index: i }));
      onOutputsChange(copy);
    }

	}, [
    inputs, outputs,
    onOutputsChange, onInputsChange,
    // inputs, outputs, existingWidgetInfo,
    // currentBlockInfo, widgetId, currentRecipeType, t,
    // onOutputsChange, onInputsChange
  ]);


  const handleRemoveInputPort = useCallback((index: number) => {
    handleRemovePort('input', index);
  }, [handleRemovePort]);

  const handleRemoveOutputPort = useCallback((index: number) => {
    handleRemovePort('output', index);
  }, [handleRemovePort]);

  return (
    <Collapse defaultActiveKey={['1']} ghost className={styles.Accordion}>
      <Panel header={t('DetailsAccordion', 'Widget Details')} key="1" >

        <FormGroup marginBottomLevel={3}>
          <StyledInput
            onChange={handleNameChange}
            value={name}
            label={t('Name.Label', 'Name')}
            placeholder={t('Name.Placeholder', 'Enter name of the widget')}
          />
        </FormGroup>

        <StyledTextArea
          preventResize
          value={description}
          onChange={handleDescriptionChange}
          label={t('Description.Label', 'Description')}
          showCount
          maxLength={200}
        />

        <div className={styles.ColorAndIconRow}>
          <div className={styles.ColorContainer}>
            <StyledLabel text={'Color'} />
            <ColorSwatch
              className={styles.Swatch}
              width={'350px'}
              popupClassName={styles.SwatchPopup}
              color={color}
              colors={defaultColors}
              onColorChange={onColorChange}
            />
          </div>

          <div>
            <StyledLabel text={'Icon'} />
            <IconSelector
              backgroundColor={color}
              onIconSelected={onIconChange}
              icon={icon}
            />
          </div>
        </div>
      </Panel>


      <Panel header={t('InputPorts.Title', 'Input Ports')} key="2">
        <PortsList
          data-kemu-meta="input-ports"
          ports={inputs}
          onPortUpdated={handleInputsChange}
          onAddPort={handleAddInputPort}
          onRemovePort={handleRemoveInputPort}
        />
      </Panel>

      <Panel header={t('OutputPorts.Title', 'Output Ports')} key="3">
        <PortsList
          data-kemu-meta="output-ports"
          ports={outputs}
          onPortUpdated={handleOutputPortChange}
          onAddPort={handleAddOutputPort}
          onRemovePort={handleRemoveOutputPort}
        />
      </Panel>
    </Collapse>
  );
};

export default DetailsTab;
