import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { Dropdown } from 'antd';
import { WidgetCategory } from '../../types/widgets';
import styles from './WidgetDropdownButton.module.css';

type Props = {
  name: string;
  wrapperClassName?: string;
  menu: React.JSX.Element;
  widgetType: WidgetCategory;
  disabled?: boolean;
}

/**
 * Created to replace antd's Button element which is conflicting with the `canvas` element
 * of the line chart.
 */

const WidgetDropdownButton = (props: Props): React.JSX.Element => {
  return (
    <div className={classNames(props.wrapperClassName, styles.DropDownContainer)}>
			<Dropdown overlay={props.menu} trigger={['click']} className="gate-dropdown">
				<div className={classNames(
          styles.ActionName, styles.Wrapper, props.widgetType, { [styles.Disabled]: props.disabled })}>
					{ props.name }
					<DownOutlined custom-dropdown="yes" className={styles.DropIcon}/>
				</div>
			</Dropdown>
		</div>
  );
};

export default WidgetDropdownButton;
