import React, { memo, MouseEventHandler, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Position } from '@kemu-io/kemu-core/types';
import { useDebouncedCallback } from 'use-debounce';
import { CollapsibleType } from 'antd/lib/collapse/CollapsePanel';
import { useDispatch } from 'react-redux';
import { Button, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { SerializableServiceInfo } from '@kemu-io/hs-types';
import LaunchpadHubServiceIcon from '../LaunchpadHubServiceIcon/LaunchpadHubServiceIcon';
import PanelTitle from '../PanelTitle/PanelTitle';
import { WidgetLaunchpadContext } from '../LaunchpadContext';
import styles from './HubServicesPanel.module.css';
import ServiceCreationModal, { findProjectCreatorService } from './ServiceCreationModal/ServiceCreationModal';
import ServiceInstallerModal, { findServiceInstaller } from './ServiceInstallerModal/ServiceInstallerModal';
import { ReactComponent as InstallIcon } from './install-icon.svg';
import SelectedServiceFooter from './SelectedServiceFooter/SelectedServiceFooter';
import ServiceWithSubServices from './ServiceWithSubServices/ServiceWithSubServices';
import { addHubServiceWidgetAction } from '@src/features/LogicMapper/logicMapperSlice';
import useTranslation from '@hooks/useTranslation';
import { FolderPathInfo } from '@src/features/Workspace/workspaceSlice';
import useActiveHubServices from '@hooks/useActiveHubServices';

// Taken from antd/Collapse.d.ts
interface PanelProps {
  isActive?: boolean;
  header?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  showArrow?: boolean;
  forceRender?: boolean;
  /** @deprecated Use `collapsible="disabled"` instead */
  disabled?: boolean;
  extra?: React.ReactNode;
  collapsible?: CollapsibleType;
}

type Props = {
  // filterTerm: string;
  /** Id of the current folder (widget group) */
  currentFolder: FolderPathInfo | null;
  recipePoolId: string;
  // handleFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // onClick: (widgetId: string) => void;
  onAddWidget: (widgetId: string, dropLocation?: Position) => void;
  // onHidePopup: () => void;
  /** a unique key to represent the panel when expanded */
  key: string;
}



const HubServicesPanel = (props: Props) => {
  const {
    recipePoolId, currentFolder,
    onAddWidget,
    ...collectionPanelProps
  } = props;
  const t = useTranslation('WidgetLaunchpad.HubServices');
  const dispatch = useDispatch();
  const activeServices = useActiveHubServices();
  const [filterTerm, setFilterTerm] = useState('');
  const [selectedService, setSelectedService] = useState<SerializableServiceInfo | null>(null);
  const { hideLaunchpad, setCustomFooter } = useContext(WidgetLaunchpadContext);
  const panelProps = collectionPanelProps as PanelProps;
  // The project creator is an internal service
  const projectCreatorAvailable = findProjectCreatorService(activeServices);
  const serviceInstallerAvailable = findServiceInstaller(activeServices);

  // Filter out internal services
  const hubServices = useMemo(() => {
    return activeServices.filter((service) => !service.internal);
  }, [activeServices]);

  const [filteredList, setFilteredList] = useState<SerializableServiceInfo[]>(hubServices);

  const servicesAvailable = hubServices.length > 0;
  const [showCreationModal, setShowCreationModal] = useState(false);
  const [showInstallerModal, setShowInstallerModal] = useState(false);

  const handleShowCreationModal: MouseEventHandler<HTMLButtonElement> = useCallback(async (evt) => {
    // Prevent closing the panel
    evt.stopPropagation();
    setShowCreationModal(true);
  }, []);

  const handleServiceCreationClose = useCallback(() => {
    setShowCreationModal(false);
  }, []);

  const handleShowInstallerModal: MouseEventHandler<HTMLButtonElement> = useCallback(async (evt) => {
    // Prevent closing the panel
    evt.stopPropagation();
    setShowInstallerModal(true);
  }, []);

  const handleInstallerCreationClose = useCallback(() => {
    setShowInstallerModal(false);
  }, []);

  const doFilter = useDebouncedCallback((filterTerm: string) => {
    setFilteredList(hubServices.filter((service) => {
      if (filterTerm === '') { return true; }

      const nameMatch = service.name.toLowerCase().includes(filterTerm.toLowerCase());
      const descriptionMatch = service.description.toLowerCase().includes(filterTerm.toLowerCase());
      return nameMatch || descriptionMatch;
    }));
  }, 300, { leading: true });

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filterTerm = e.target.value;
    setFilterTerm(filterTerm);
    doFilter(filterTerm);
  };

  const handleClearFooter = useCallback(() => {
    setCustomFooter(null);
  }, [setCustomFooter]);

  const handleAddService = useCallback((info: {name: string, version: string, variant?: string}, position: Position) => {
    const targetService = hubServices.find((service) => service.name === info.name && service.version === info.version);
    if (targetService) {
      dispatch(addHubServiceWidgetAction({
        service: targetService,
        currentGroupId: currentFolder?.groupId,
        variantId: info.variant,
        position,
        sessionId: targetService.sessionId,
      }));

      hideLaunchpad(false);;
    }
  }, [hubServices, dispatch, currentFolder, hideLaunchpad]);

  const handleClick = useCallback((name: string, version: string) => {
    const matchingService = hubServices.find((service) => service.name === name && service.version === version);
    if (matchingService) {
      setSelectedService(matchingService);
    }
  }, [hubServices]);

  const handleClearSelection = useCallback(() => {
    setSelectedService(null);
  }, []);

  const handleHidePopup = useCallback(() => {
    hideLaunchpad(true);
  }, [hideLaunchpad]);

  useEffect(() => {
    setFilteredList(hubServices);
  }, [hubServices]);

  // Allows restoring the footer when the panel is back expanded
  // it also reacts to click events via changes to `selectedService`.
  useEffect(() => {
    if (panelProps.isActive && selectedService && servicesAvailable) {
      setCustomFooter(
        <SelectedServiceFooter
          description={selectedService.description}
          name={selectedService.name}
          version={selectedService.version}
          clearFooter={handleClearFooter}
        />
      );
    } else if (!servicesAvailable) {
      setCustomFooter(null);
    }
  }, [
    panelProps.isActive, selectedService,
    setCustomFooter, servicesAvailable,
    handleClearFooter,
  ]);

  return (
    <>
      <PanelTitle
        {...panelProps}
        title={t('Title', 'Hub Services')}
        titleSuffixElement={
          <div className={styles.ActionIcons}>
            {projectCreatorAvailable && (
              <Tooltip title={t('CreateServiceTooltip')}>
                <Button
                  type="dashed"
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={handleShowCreationModal}
                />
              </Tooltip>
            )}

            {serviceInstallerAvailable && (
              <Tooltip title={t('InstallServiceTooltip')}>
                <Button
                  // type="dashed"
                  shape="circle"
                  // icon={<PlusOutlined />}
                  icon={<InstallIcon className={styles.CustomSvg} />}
                  onClick={handleShowInstallerModal}
                />
              </Tooltip>
            )}
          </div>
        }
        fontAwesomeIcon='cube'
        key={props.key}
      >
        <div
          className={styles.WidgetsSection}
        >
          {hubServices.length > 0 && (
            <div className={styles.SearchInputWrapper}>
              <input
                type="text"
                data-filter-type="widget-bundle"
                placeholder={t('SearchPlaceholder', 'Find a service')}
                className={styles.SearchInput}
                onChange={handleFilterChange}
                defaultValue={filterTerm}
              />
            </div>
          )}
          <div className={styles.WidgetsGrid}>
            {filteredList.length ? (
              <>
                {filteredList.map((service) => {
                  const serviceId = `${service.name}_${service.version}`;
                  if (service.customWidgets?.length || service.variants?.length) {
                    return (
                      <ServiceWithSubServices
                        isSelected={selectedService === service && !!panelProps.isActive}
                        onClearSelection={handleClearSelection}
                        onAddService={handleAddService}
                        hideLaunchpad={hideLaunchpad}
                        setCustomFooter={setCustomFooter}
                        key={serviceId}
                        serviceInfo={service}
                        currentFolder={props.currentFolder}
                        recipePoolId={props.recipePoolId}
                      />
                    );
                  } else {
                    return (
                      <LaunchpadHubServiceIcon
                        isSelected={selectedService === service && !!panelProps.isActive}
                        onClick={handleClick}
                        onAddService={handleAddService}
                        onHideLaunchpad={handleHidePopup}
                        key={serviceId}
                        serviceInfo={service}
                        currentFolder={props.currentFolder}
                        recipePoolId={props.recipePoolId}
                      />
                    );
                  }
                })}
              </>
            ) : (
              filteredList.length == 0 && (
                <div className={styles.EmptyWidgetsContainer}>
                  <span>{t('NoServicesRunning', 'No active services found')}</span>
                  {/* <StyledButton
                    color="secondary"
                    className={styles.LearnHowButton}
                    onClick={showHelpGuide}
                    title={t('LearnHowLink', 'Learn how')}
                  /> */}
                </div>
              )
            )}
          </div>
        </div>
      </PanelTitle>

      {projectCreatorAvailable && (
        <ServiceCreationModal
          visible={showCreationModal}
          onClose={handleServiceCreationClose}
        />
      )}

      {serviceInstallerAvailable && (
        <ServiceInstallerModal
          visible={showInstallerModal}
          onClose={handleInstallerCreationClose}
        />
      )}
    </>
  );
};

export default memo(HubServicesPanel);
