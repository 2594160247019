import { CustomWidgetVariant, WidgetMetaInfo } from '@kemu-io/kemu-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { AsyncRequestStatus, AsyncState } from '../../../types/core_t';
import { deleteWidgetReducer } from './deleteWidgetReducer';
import { importWidgetReducer } from './importWidgetReducer';
import { processUserWidgetsReducer } from './processWidgetsReducer';
import { saveWidgetAsReducer } from './saveWidgetAsReducer';
import { saveWidgetReducer } from './saveWidgetReducer';
import { shareWidgetReducer } from './shareWidgetReducer';
import { updateWidgetReducer } from './updateWidgetReducer';
import { saveWidgetBundleReducer } from './saveBundleInCollectionReducer';
import { updateWidgetBundleReducer } from './updateBundleInCollectionReducer';


export type WidgetCollectionItem = {
	dbId: string;
	name: string;
	description: string;
	icon?: string;
	color?: string;
	/** local copy of the widget contents */
	contents?: string;
	variant: CustomWidgetVariant;
	version: number;
	protocolVersion: string;
	meta: WidgetMetaInfo;
	/** public id used to import the widget */
	publicShareId?: string;
	author: {
		name: string;
		id: string;
	};
	/** determines if this is a system default widget or user-defined */
	isDefault: boolean;
}

type AsyncInfoStatus = {
	title: string;
	asyncStatus: AsyncState;
};

type SaveAsInfo = {
	visible: boolean;
	collectionInfo?: {
		thingId: string;
		dbId: string;
		widgetRecipeId: string;
		name: string;
		variant: CustomWidgetVariant;
	}
}

export interface WidgetCollectionState {
	// intl: TranslateFunction;
	/** collection of user widgets */
	customWidgets: WidgetCollectionItem[];
	/** Database id of the widget in the collection the user is currently editing */
	selectedWidget: string | null;
	asyncIndicator: AsyncInfoStatus;
	saveAsModal: SaveAsInfo;
	widgetDownloadProgress : {
		[dbId: string]: {
			percentage: number;
			name: string;
		}
	}
}

const initialState: WidgetCollectionState = {
	customWidgets: [],
	selectedWidget: null,
	asyncIndicator: {
		title: '',
		asyncStatus: {
			status: AsyncRequestStatus.idle,
		}
	},
	saveAsModal: {
		visible: false
	},
	widgetDownloadProgress: {},
};

export const customWidgetsSlice = createSlice({
	name: 'customWidget',
	initialState,
	reducers: {

		showSaveAsModal: (state, action: PayloadAction<Required<SaveAsInfo>['collectionInfo']>) => {
			state.saveAsModal = {
				...state.saveAsModal,
				visible: true,
				collectionInfo: {
					thingId: action.payload.thingId,
					dbId: action.payload.dbId,
					name: action.payload.name,
					widgetRecipeId: action.payload.widgetRecipeId,
					variant: action.payload.variant
				}
			};
		},

		hideSaveAsModal: (state) => {
			state.saveAsModal = {
				...state.saveAsModal,
				collectionInfo: undefined,
				visible: false
			};
		},

		setWidgetAsyncStatus: (state, action: PayloadAction<AsyncState & { title?: string }>) => {
			state.asyncIndicator.asyncStatus = {
				status: action.payload.status,
				error: action.payload.error,
			};

			if (action.payload.title) {
				state.asyncIndicator.title = action.payload.title;
			}
		},

		setCurrentWidget: (state, action: PayloadAction<string | null>) => {
			state.selectedWidget = action.payload;
		},

		setWidgetDownloadProgress: (state, action: PayloadAction<{name: string, id: string, percentage: number}>) => {
			state.widgetDownloadProgress = {
				...state.widgetDownloadProgress,
				[action.payload.id]: {
					name: action.payload.name,
					percentage: action.payload.percentage
				}
			};
		}
	},

	extraReducers: (builder) => {
		processUserWidgetsReducer(builder);
		updateWidgetReducer(builder);
		saveWidgetReducer(builder);
		saveWidgetAsReducer(builder);
		deleteWidgetReducer(builder);
		shareWidgetReducer(builder);
		importWidgetReducer(builder);
		saveWidgetBundleReducer(builder);
		updateWidgetBundleReducer(builder);
	}
});


export const {
	setWidgetDownloadProgress,
	showSaveAsModal,
	hideSaveAsModal,
	setWidgetAsyncStatus: setWidgetAsyncStatusAction,
	setCurrentWidget: setCurrentWidgetAction
} = customWidgetsSlice.actions;

export { saveBundleInCollectionAction } from './saveBundleInCollectionReducer.ts';
export { updateBundleInCollectionAction } from './updateBundleInCollectionReducer.ts';

export const selectUserWidgets = (state: RootState): WidgetCollectionItem[] => state.widget.customWidgets;
/**
 * @returns the id of the widget in the database or null if the widget has not been saved
 */
export const selectCurrentWidget = (state: RootState): string | null => state.widget.selectedWidget;
export const selectSaveAsModal = (state: RootState): SaveAsInfo | null => state.widget.saveAsModal || null;
export const selectWidgetAsyncStatus = (state: RootState): AsyncInfoStatus => state.widget.asyncIndicator;

export default customWidgetsSlice.reducer;
