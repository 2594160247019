import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import Kemu from '@kemu-io/kemu-core';
import { BlockMap } from '@kemu-io/kemu-core/types';
import { WorkspaceState } from '@src/features/Workspace/workspaceSlice';
import getInitialRecipeState from '@src/features/Workspace/initialRecipeState';

export interface MinimumRecipeInfo {
	blocks: BlockMap;
	isDefault: boolean;
	name: string
}

type TerminateRecipeOptions = {
	recipePoolId: string;
}

const terminateRecipeAction = createAsyncThunk<void, TerminateRecipeOptions>(
  '/Workspace/terminateRecipe', async (options) => {
	console.log('Terminating recipe: ', options.recipePoolId);
	await Kemu.terminateRecipe(options.recipePoolId);
});


const terminateRecipeReducer = ((builder: ActionReducerMapBuilder<WorkspaceState>): void => {
  builder.addCase(terminateRecipeAction.fulfilled, (state) => {
    if (state.currentRecipe.poolId) {
      state.selectedBlockInfo = null;
      state.folderPath = [];
      state.currentRecipe.poolId = null;
      const initialState = getInitialRecipeState();
      state.currentRecipe = initialState.currentRecipe;
    }
  });

  builder.addCase(terminateRecipeAction.rejected, (state, action) => {
    console.log('Error closing recipe: ', action);
  });
});


export default {
  terminateRecipeAction,
  terminateRecipeReducer,
};
