import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './CustomWidgetIcon.module.css';
import DynamicIcon from '@components/dynamicIcon/dynamicIcon';
import GateIcon from '@components/gateIcon/gateIcon';
import { WIDGET_DEFAULT_ICON_NAME } from '@common/constants';
import SvgFromString from '@components/SvgFromString/SvgFromString';

export interface CustomWidgetIconInfo {
	color?: string;
	icon?: string;
	disabled?: boolean;
	isBundle?: boolean;
}

const CustomWidgetIcon = (props: CustomWidgetIconInfo): React.JSX.Element => {
	return (
		<GateIcon
			style={{ backgroundColor: props.disabled ? 'gray' : props.color }}
			className={styles.WidgetIcon}
			icon={
				props.isBundle ? (
					<SvgFromString
						svgStr={props.icon || ''}
						width={32}
						height={32}
					/>
				) : (
					<DynamicIcon
						iconName={props.icon || WIDGET_DEFAULT_ICON_NAME}
						default={<FontAwesomeIcon icon={['fas', WIDGET_DEFAULT_ICON_NAME]}/>}
					/>
				)
			}
		/>
	);
};

export default CustomWidgetIcon;
