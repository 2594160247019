import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Space, Dropdown, Button, Typography } from 'antd';
import { Recipe, WithOptional } from '@kemu-io/kemu-core/types';
import { SerializableServiceInfo , BroadcastEvent, EnvironmentInfo, KemuHubFunctions, ChooseDirectoryDialogArgs } from '@kemu-io/hs-types';
import classNames from 'classnames';
import Icon, { DockerOutlined, DownOutlined, FolderOpenFilled, JavaScriptOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import useHandleServiceBroadcast from '../../common/hooks/useHandleServiceBroadcast';
import { generateRecipePackage } from '../../common/recipeActions/saveRecipe';
import useCurrentRecipeInfo from '../../common/hooks/useCurrentRecipeInfo';
import styles from './ExportRecipeDialog.module.css';
import { ReactComponent as LambdaIcon } from './lambda-icon.svg';
import { ReactComponent as TerminalIcon } from './terminal-icon.svg';
import connectionManager, { KemuAppIdIdentifier } from '@src/app/kemuHub/connectionManager';
import RoundedModal from '@components/roundedModal/roundedModal';
import useTranslation from '@hooks/useTranslation';
import FormGroup from '@components/form-control/formGroup/formGroup';
// import StyledInput from '@components/form-control/styledInput/styledInput';
import InputWithButtonSuffix from '@components/form-control/InputWithButtonSuffix/InputWithButtonSuffix';
import AutoScrollLogs from '@components/AutoScrollLogs/AutoScrollLogs';
import useActiveHubServices from '@hooks/useActiveHubServices';
import StyledLabel from '@components/form-control/styledLabel/styledLabel';

type Props = {
  visible: boolean;
  onClose: () => void;
}

type ExportRecipeConfig = {
  recipe: Recipe;
  mode: 'multi-thread' /* | 'single-thread' */; // single thread mode not supported yet
  exportPath: string;
  targetEnv: 'shell' | 'js-library' | 'aws-lambda' | 'docker';
  storage?: ArrayBuffer;
}

const RecipeExporterServiceName = 'kemu.io.recipeExporter';
const ExportRecipeEventName = 'exportRecipe';

/**
 * Helper function to find the project creator service in the list of active services.
 * @param services a list of active services
 * @returns a service info object if the project creator service is found, otherwise null.
 */
export const findService = (services: SerializableServiceInfo[]): SerializableServiceInfo | null => {
  const projectCreator  = services.find((service) => service.name === RecipeExporterServiceName && service.internal);
  return projectCreator || null;
};

const contentStyle: React.CSSProperties = {
  padding: 50,
  borderRadius: 4,
};


const getDefaultConfig = (): WithOptional<ExportRecipeConfig, 'recipe'> => ({
  mode: 'multi-thread',
  exportPath: '',
  targetEnv: 'shell',
});

const ExportRecipeDialog = (props: Props) => {
  const { visible, onClose } = props;
  const t = useTranslation('Modal.RecipeExport');
  const cw = useTranslation('CommonWords');
  const [exportInProgress, setExportInProgress] = useState<boolean>(false);
  const services = useActiveHubServices();
  const hubConnector = connectionManager.getConnector();
  const hubOnline = connectionManager.isReady();
  const [processSuccessful, setProcessSuccessful] = useState<boolean>(false);
  const [config, setConfig] = useState(getDefaultConfig());
  const [error, setError] = useState<string | null>(null);
  const [showingExportDialog, setShowingExportDialog] = useState<boolean>(false);
  const visibilityRef = useRef(visible);
  const recipeExporter = findService(services);
  const [logs, setLogs] = useState<string>('');
  const currentRecipe = useCurrentRecipeInfo();

  useHandleServiceBroadcast<BroadcastEvent>(recipeExporter?.name, recipeExporter?.version, (event) => {
    if (event.source.serviceName === RecipeExporterServiceName) {
      const stdout = event.outputs.find(output => output.name === 'stdout');
      if (stdout) {
        setLogs((l) => `${l}${stdout.value}\n`);
      }
    }
  }, KemuAppIdIdentifier, !visible);

  const handleClose = useCallback(() => {
    setConfig(getDefaultConfig());
    setProcessSuccessful(false);
    setExportInProgress(false);
    setShowingExportDialog(false);
    setLogs('');
    onClose();
    visibilityRef.current = false;
  },[onClose]);

  const handlePathChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setConfig((c) => ({ ...c, exportPath: e.target.value }));
  }, []);

  const handleShowFolderDialog = useCallback(async () => {
    try {
      setShowingExportDialog(true);
      const result = await hubConnector.executeHubFunction<string[]>(KemuHubFunctions.ChooseDirectoryDialog, [
        {
          title: t('Title'),
          allowMultiple: false,
        } as ChooseDirectoryDialogArgs
      ], {
        // disable timeouts
        timeout: 0,
      });

      // Ignore the result if the dialog was closed
      if (!visibilityRef.current) { return; }

      const exportPath = result?.[0];
      if (exportPath) {
        console.log('Result from choose directory dialog', exportPath);
        // const recipeName = currentRecipe.name;
        // const defaultRecipeName = `${recipeName}-${format(new Date(), 'yyyy_MM_dd-HH_mm_ss')}`;
        // const pathWithName = `${exportPath}/${defaultRecipeName}`.replace(/\/\//g, '/');
        setConfig((c) => ({ ...c, exportPath, }));
      }
    } catch (e) {
      console.error('Error showing folder dialog:', e);
    } finally {
      setShowingExportDialog(false);
    }

  }, [hubConnector, t]);

  const handleExport = useCallback(async () => {
    if (!hubOnline) {
      return;
    }

    if (!config.exportPath?.trim()) {
      setError(t('ExportPathRequired'));
      return;
    }

    try {
      if (!recipeExporter || !currentRecipe.poolId) { return; }
      setError(null);
      setLogs(t('InitializingExport' + '\n'));
      setExportInProgress(true);
      const { parsedContents, storage } = await generateRecipePackage(currentRecipe.poolId, currentRecipe.name);

      const exportConfig: ExportRecipeConfig = {
        mode: config.mode || 'multi-thread',
        exportPath: config.exportPath,
        recipe: parsedContents,
        targetEnv: config.targetEnv,
        storage: storage?.disk,
      };

      const result = await hubConnector.callProcessorHandler(
        recipeExporter.sessionId,
        {} as EnvironmentInfo,
        ExportRecipeEventName,
        exportConfig,
        {
          // Wait for up to 15 minutes for the project creation to conclude
          timeout: 900000,
        }
      );

      if (result.error) {
        setError(result.error);
      } else {
        setProcessSuccessful(true);
      }

    } catch (e) {
      console.error('Recipe export error:', e);
      setProcessSuccessful(false);
      setLogs((l) => `${l}${e.message || e as string}\n`);
    }

    setExportInProgress(false);

  }, [hubOnline, t, config, hubConnector, recipeExporter, currentRecipe]);

  const modeTitleByKey = {
    'shell': {
      label: t('Modes.Shell'),
      description: t('Modes.Shell.Description'),
    },
    'js-library': {
      label: t('Modes.JavascriptLib'),
      description: t('Modes.JavascriptLib.Description'),
    },
    'aws-lambda': {
      label: t('Modes.AWSLambda'),
      description: t('Modes.AWSLambda.Description'),
    },
    'docker': {
      label: t('Modes.Docker'),
      description: t('Modes.Docker.Description'),
    }
  };

  // Keep track of the visibility state
  useEffect(() => {
    visibilityRef.current = visible;
  }, [visible]);

  return (
    <RoundedModal
      title={t('Title')}
      visible={visible}
      onCancel={handleClose}
      closeOnMaskClick={false}
      closable={true}
      loadingOkBtn={exportInProgress}
      disableOkButton={
        processSuccessful
        || !hubOnline
        || exportInProgress
        || !config.exportPath?.trim()
        || !recipeExporter
      }
      disableCancelButton={exportInProgress}
      okBtnLabel={cw('Export')}
      cancelBtnLabel={processSuccessful ? cw('Close') : cw('Cancel')}
      onOk={handleExport}
    >

      <div className={styles.Content}>
        {hubOnline && (
          <>
            <FormGroup marginBottomLevel={3} spacing={10}>
              <Space.Compact style={{ width: '100%' }} direction='vertical'>
                <InputWithButtonSuffix
                  label={t('ExportPath')}
                  disabled={exportInProgress || processSuccessful || !recipeExporter}
                  onChange={handlePathChange}
                  value={config.exportPath}
                  buttonIcon={<FolderOpenFilled />}
                  buttonProps={{
                    onClick: handleShowFolderDialog,
                    disabled: exportInProgress || processSuccessful || !recipeExporter || showingExportDialog,
                  }}
                  className={classNames(styles.CompactInput)}
                />
              </Space.Compact>


              <div className={styles.ModeGroup}>
                <StyledLabel text={t('Modes.Title')} />
                <div className={styles.ModeDropdown}>
                  <Dropdown
                  disabled={exportInProgress || processSuccessful || !recipeExporter}
                    trigger={['click']}
                    menu={{
                      onClick: ({ key }) => setConfig((c) => ({ ...c, targetEnv: key as ExportRecipeConfig['targetEnv'] })),
                      items: [
                        {
                          key: 'shell',
                          icon: <Icon component={TerminalIcon} />,
                          label: modeTitleByKey.shell.label,
                        },
                        {
                          key: 'js-library',
                          icon: <JavaScriptOutlined />,
                          label: modeTitleByKey['js-library'].label,
                        },
                        /* {
                          key: 'aws-lambda',
                          icon: <Icon component={LambdaIcon} />,
                          label: modeTitleByKey['aws-lambda'].label,
                        },
                        {
                          key: 'docker',
                          icon: <DockerOutlined />,
                          label: modeTitleByKey.docker.label,
                        } */
                      ]
                    }}
                  >
                    <Button>
                      {modeTitleByKey[config.targetEnv].label}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </div>

                <div className={styles.ModeDescription}>
                  <Typography.Text>
                    {modeTitleByKey[config.targetEnv].description}
                  </Typography.Text>
                </div>
              </div>
            </FormGroup>

            {!!logs.trim().length && (
              <FormGroup marginBottomLevel={3} className={styles.GeneratingContainer} >
                <AutoScrollLogs text={logs} />
              </FormGroup>
            )}
          </>
        )}

        {processSuccessful && !error && hubOnline && (
          <Alert
            type="success"
            showIcon
            message={t('ExportSuccess')}
            style={{ marginBottom: 16 }}
          />
        )}

        {hubOnline && !recipeExporter && (
          <Alert
            type="error"
            showIcon
            message={t('ExporterNotFound')}
            style={{ marginBottom: 16 }}
          />
        )}

        {!hubOnline && (
          <Alert
            type="error"
            showIcon
            message={t('HubOffline')}
            style={{ marginBottom: 16 }}
          />
        )}

        {hubOnline && error && (
          <Alert
            type="error"
            showIcon
            message={error}
            style={{ marginBottom: 16 }}
            closable
            onClose={() => setError(null)}
          />
        )}

      </div>
    </RoundedModal>
  );
};

export default ExportRecipeDialog;
