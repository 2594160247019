import React, { useCallback, useState } from 'react';
import {
  WidgetGroupSetting,
  TextConfig,
  SettingType,
  NumberConfig,
  SliderConfig,
  DropDownConfig,
  CheckboxConfig,
  MultiSelectConfig
} from '@kemu-io/kemu-core/types';
import { PlusOutlined, DeleteFilled, SettingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import classNames from 'classnames';
import camelCase from 'camelcase';
import styles from './WidgetSettingsTab.module.css';
import NumberFieldConfig from './NumberFieldConfig/NumberFieldConfig';
import TextFieldConfig from './TextFieldConfig/TextFieldConfig';
import SliderFieldConfig from './SliderFieldConfig/SliderFieldConfig';
import DropDownFieldConfig from './DropDownFieldConfig/DropDownFieldConfig';
import FieldWrapper from './FieldWrapper';
import CheckboxFieldConfig from './CheckboxConfig/CheckboxConfig';
import MultiSelectFieldConfig from './MultiSelectFieldConfig/MultiSelectFieldConfig';
import VariableInput from './VariableInput/VariableInput';
import { SelectedBlockInfo } from '@src/types/core_t';
import useTranslation from '@hooks/useTranslation';
import IconButton from '@components/form-control/iconButton/iconButton';
import StyledSelect, { LabeledValue, Option, SelectValue } from '@components/form-control/styledSelect/styledSelect';
import StyledButton from '@components/form-control/styledButton/styledButton';
import KemuCheckbox from '@components/form-control/KemuCheckbox/KemuCheckbox';
import StyledInput from '@components/form-control/styledInput/styledInput';

type Props = {
  widgetSettingsFields: WidgetGroupSetting[];
  currentThing: SelectedBlockInfo | null;
  widgetId?: string;
  widgetName?: string;
  onFieldsUpdated: (fields: React.SetStateAction<WidgetGroupSetting<unknown>[]>) => void;
}


function getDefaultConfigByType<T> (type: SettingType): T {
  if (type === 'text') {
    const c: TextConfig = {
      defaultValue: '',
      placeholder: 'Enter text',
      passwordMode: false,
      multiline: false,
    };

    return c as T;
  }

  if (type === 'number') {
    const c: NumberConfig = {
      defaultValue: 0,
      placeholder: 'Enter value'
    };

    return c as T;
  }

  if (type === 'slider') {
    const c: SliderConfig = {
      defaultValue: 50,
      max: 100,
      min: 0,
      step: 1,
    };

    return c as T;
  }

  if (type === 'dropdown') {
    const c: DropDownConfig = {
      defaultValue: 'Option 2',
      options: ['Option 1', 'Option 2', 'Option 3']
    };

    return c as T;
  }

  if (type === 'checkbox') {
    const c: CheckboxConfig = {
      defaultValue: false
    };

    return c as T;
  }

  if (type === 'multiselect') {
    const c: MultiSelectConfig = {
      defaultValue: ['Option 1', 'Option 3'],
      options: ['Option 1', 'Option 2', 'Option 3']
    };

    return c as T;
  }

  return {} as T;
}

/**
 * Splits the widget name into words and picks up only the first word + the 3 first letter
 * of the second word (if any).
 */
const getShortName = (widgetName: string) => {
  const words = widgetName.split(' ');
  let variableName = words[0];
  if (words.length > 1) {
    variableName += ' ' + words[1].substring(0, 3);
  }
  return variableName;
};

export const generateUniqueName = (
  fieldType: string,
  currentFields: WidgetGroupSetting[],
  widgetName?: string
): string => {
  const widget = widgetName ? camelCase(getShortName(widgetName), { pascalCase: true }) : '';
  const name = `${widget}.${fieldType}`;

  // Checks all current fields that match the given time and generates a new name
  // by increasing index. Eg. If a field text1 exists, the next one will be text2, etc.
  // The next index is always the highest index + 1
  const existingFields = currentFields.filter((f) => f.variableName.includes(name));
  const highestIndex = existingFields.reduce((acc, item) => {
    const index = Number(item.variableName.replace(name, ''));
    if (index > acc) {
      return index;
    }

    return acc;
  }, 0);

  return `${name}${highestIndex + 1}`;
};

const WidgetSettingsTab = (props: Props) => {
  const { widgetSettingsFields, onFieldsUpdated, currentThing, widgetId, widgetName } = props;
  const t = useTranslation('CustomWidgetModal.Settings');
  const [showConfigModalIndex, setShowConfigModalIndex] = useState<number>(-1);
  const showConfigModal = showConfigModalIndex > -1 ? widgetSettingsFields[showConfigModalIndex] : null;
  // const currentVariables = variablesManager.getThingVariableDefinitions(
  //   currentThing?.recipePoolId,
  //   currentThing?.recipeId,

  // )
  // const currentVariables = currentThing ? variablesManager.getVariableNames(
  //   currentThing?.recipePoolId,
  //   currentThing?.recipeId,
  //   widgetId
  // ) : [];

  const fieldsVarNames = widgetSettingsFields.map((item) => item.variableName);
  // Find filed names that appear more than once
  const duplicatedVarNames = fieldsVarNames.filter((name, index) => fieldsVarNames.indexOf(name) !== index);
  // const duplicatedVarNames = widgetSettingsFields.reduce((acc, item, index) => {
  //   const varExistInOtherWidget = currentVariables.findIndex((variable) =>
  //     variable.name === item.variableName
  //     && variable.ownerWidgetId !== widgetId
  //   );

  //   const varExistsInSameWidget = widgetSettingsFields.findIndex((s2, index2) =>
  //     item.variableName === s2.variableName
  //     && index !== index2
  //   );

  //   if (varExistInOtherWidget !== -1 || varExistsInSameWidget !== -1) {
  //     acc.push(item.variableName);
  //   }

  //   return acc;
  // }, [] as string[]);

  const updateSettingValue = useCallback((index: number, values: Partial<WidgetGroupSetting<unknown>>) => {
    onFieldsUpdated((current) => {
      const newSettings = [...current];
      const newSetting = newSettings[index];
      newSettings[index] = { ...newSetting, ...values };

      return newSettings;
    });
  }, [onFieldsUpdated]);

  const handleElementTypeChange = useCallback((
    value: SelectValue,
    options: LabeledValue[],
    elementId?: string | number
  ) => {
    const index = elementId as number;
    // Make sure it is closed in case it was open previously
    setShowConfigModalIndex(-1);
    updateSettingValue(index, {
      type: value as SettingType,
      config: getDefaultConfigByType(value as SettingType),
      variableName: generateUniqueName(value as SettingType, widgetSettingsFields, widgetName),
    });
  }, [updateSettingValue, widgetSettingsFields, widgetName]);

  const handlePrivateCheckboxChange = useCallback((evt: CheckboxChangeEvent, elementId?: string | number) => {
    const index = elementId as number;
    updateSettingValue(index, { private: evt.target.checked });
  }, [updateSettingValue]);

  const handleRequiredFieldCheckboxChange = useCallback((evt: CheckboxChangeEvent, elementId?: string | number) => {
    const index = elementId as number;
    updateSettingValue(index, { required: evt.target.checked });
  }, [updateSettingValue]);

  const handleCustomSettingFieldChange = useCallback((evt: CheckboxChangeEvent, elementId?: string | number) => {
    const index = elementId as number;
    updateSettingValue(index, { customSettingField: evt.target.checked });
  }, [updateSettingValue]);

  const handleShowElementConfig = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>, elementId?: string | number) => {
    const index = elementId as number;
    // const setting = widgetSettingsFields[index];
    // setShowConfigModal(setting);
    setShowConfigModalIndex(index);
  }, []);

  const handleFieldConfigChange = useCallback((fieldIndex: number, config: WidgetGroupSetting['config'] | null) => {
    if (config) {
      onFieldsUpdated((current) => {
        const newList = [...current];
        if (newList[fieldIndex]) {
          const itemConfig = {
            ...newList[fieldIndex],
          };

          itemConfig.config = config;
          newList[fieldIndex] = itemConfig;
          return newList;
        }

        return current;
      });
    }

    setShowConfigModalIndex(-1);
  }, [onFieldsUpdated]);

  const handleLabelChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, elementId?: string | number) => {
    const index = elementId as number;
    updateSettingValue(index, { label: event.target.value });
  }, [updateSettingValue]);

  const handleVariableNameChange = useCallback((index: number, newName: string) => {
    updateSettingValue(index, { variableName: newName });
  }, [updateSettingValue]);

  const handleAddSetting = useCallback(() => {
    onFieldsUpdated((list) => [
      ...list,
      {
        type: 'text',
        private: false,
        required: true,
        label: 'New field',
        variableName: generateUniqueName('text', list, widgetName),
        config: getDefaultConfigByType('text'),
        customSettingField: true,
      }
    ]);
  }, [onFieldsUpdated, widgetName]);

  const handleRemoveField = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>, elementId?: string | number) => {
    const index = elementId as number;
    onFieldsUpdated((list) => {
      const newList = [...list];
      newList.splice(index, 1);
      return newList;
    });
  }, [onFieldsUpdated]);

  return (
    <div className={styles.Wrapper}>
      <FieldWrapper
        currentConfig={showConfigModal?.config as NumberConfig}
        open={showConfigModal?.type === 'number'}
        fieldIndex={showConfigModalIndex as number}
        onCloseModal={handleFieldConfigChange}
        component={NumberFieldConfig}
      />

      <FieldWrapper
        currentConfig={showConfigModal?.config as TextConfig}
        open={showConfigModal?.type === 'text'}
        fieldIndex={showConfigModalIndex as number}
        onCloseModal={handleFieldConfigChange}
        component={TextFieldConfig}
      />

      <FieldWrapper
        open={showConfigModal?.type === 'slider'}
        currentConfig={showConfigModal?.config as SliderConfig}
        fieldIndex={showConfigModalIndex as number}
        onCloseModal={handleFieldConfigChange}
        component={SliderFieldConfig}
      />

      <FieldWrapper
        open={showConfigModal?.type === 'dropdown'}
        currentConfig={showConfigModal?.config as DropDownConfig}
        fieldIndex={showConfigModalIndex as number}
        onCloseModal={handleFieldConfigChange}
        component={DropDownFieldConfig}
      />

      <FieldWrapper
        open={showConfigModal?.type === 'checkbox'}
        currentConfig={showConfigModal?.config as CheckboxConfig}
        fieldIndex={showConfigModalIndex as number}
        onCloseModal={handleFieldConfigChange}
        component={CheckboxFieldConfig}
      />

      <FieldWrapper
        open={showConfigModal?.type === 'multiselect'}
        currentConfig={showConfigModal?.config as MultiSelectConfig}
        fieldIndex={showConfigModalIndex as number}
        onCloseModal={handleFieldConfigChange}
        component={MultiSelectFieldConfig}
      />

      <p>{t('Description', 'Description')}</p>
      <div className={styles.RowsContainer}>
        <div className={styles.Headers}>
          <div>{t('Headers.FieldType', 'Field type')}</div>
          <div>{t('Headers.Label', 'Label')}</div>
          <div>{t('Headers.VariableName', 'Variable Name')}</div>
          <div className={styles.VerticalGroup}>
            <span>
              {t('Headers.Private', 'Private Field')}
            </span>
            <Tooltip title={t('Headers.Private.Description', 'Headers.Private.Description')}>
              <InfoCircleOutlined />
            </Tooltip>
          </div>

          {/* Not used as of 23/Jan/2024 */}
          {/* <div className={styles.VerticalGroup}>
            {t('Headers.Required', 'Required Field')}
            <Tooltip title={t('Headers.Required.Description', 'Headers.Required.Description')}>
              <InfoCircleOutlined />
            </Tooltip>
          </div> */}

          <div className={styles.VerticalGroup}>
            <span>
              {t('Headers.CustomSettingField', 'Hidden Field')}
            </span>
            <Tooltip title={t('Headers.CustomSettingField.Description', 'Headers.CustomSettingField.Description')}>
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        </div>
        {widgetSettingsFields.map((item, index) => (
          <div className={styles.SettingRow} key={`${item.type}-${index}`}>
            <div className={styles.RowGroup}>
              {/* UI Element Type */}
              <StyledSelect
                onChange={handleElementTypeChange}
                value={item.type}
                elementId={index}
                className={styles.FieldTypeSelect}
              >
                <Option value="text">{t('Type.Text', 'Text')}</Option>
                <Option value="number">{t('Type.Number', 'Text')}</Option>
                <Option value="slider">{t('Type.Slider', 'Slider')}</Option>
                <Option value="dropdown">{t('Type.Dropdown', 'Dropdown')}</Option>
                <Option value="checkbox">{t('Type.Checkbox', 'Checkbox')}</Option>
                <Option value="multiselect">{t('Type.MultiSelect', 'Multi-Select')}</Option>
              </StyledSelect>
              {/* Settings config */}
              <div className={styles.SettingGearIcon}>
                <IconButton
                  color="transparent"
                  shape="square"
                  elementId={index}
                  onClick={handleShowElementConfig}
                  icon={<SettingOutlined />}
                />
              </div>
            </div>

            {/* Label */}
            <div className={classNames(styles.InputType)}>
              <StyledInput
                elementId={index}
                onChange={handleLabelChange}
                value={item.label}
              />
            </div>

            {/* Variable Name */}
            <VariableInput
              defaultVarName={item.variableName}
              index={index}
              recipePoolId={currentThing?.recipePoolId}
              thingRecipeId={currentThing?.recipeId}
              onVarNameChange={handleVariableNameChange}
              duplicatedVarName={duplicatedVarNames.includes(item.variableName)}
            />

            {/* Private */}
            <KemuCheckbox
              className={styles.Checkbox}
              checked={item.private}
              onChange={handlePrivateCheckboxChange}
              elementId={index}
            />

            {/* Required */}
            {/* <KemuCheckbox
              className={styles.Checkbox}
              checked={item.required}
              onChange={handleRequiredFieldCheckboxChange}
              elementId={index}
            /> */}

            {/* Custom Setting Field */}
            <KemuCheckbox
              className={styles.Checkbox}
              checked={item.customSettingField}
              onChange={handleCustomSettingFieldChange}
              elementId={index}
            />

            <IconButton
              className={styles.RemoveFieldBtn}
              color="transparent"
              shape="square"
              elementId={index}
              onClick={handleRemoveField}
              icon={<DeleteFilled />}
							/>
          </div>
        ))}
      </div>
      <div className={styles.AddFieldContainer}>
        <StyledButton
          className={styles.AddSettingBtn}
          title={t('AddElementBtn', 'Add Element')}
          onClick={handleAddSetting}
          color="light"
          icon={<PlusOutlined />}
        />
      </div>
    </div>
  );
};

export default WidgetSettingsTab;
