/*
 * Written by Alexander Agudelo < alex@kemu.io >, 2021
 * Date: 06/Jan/2021
 * Last Modified: 04/04/2022, 7:37:10 am
 * Modified By: Alexander Agudelo
 * Description:  Validates input events and only allows numeric values. 
 * 
 * ------
 * Copyright (C) 2021 Kemu - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */

import React from 'react';


/**
 * Handle input element events allowing only numeric values
 * Usage: 
 * ```js
 * const numbersOnly = useNumericInput();
 * 
 * return (
 *   <input type="text"  onInput={numbersOnly}/>
 * )
 * ```
 */
function useNumericInput (): (evt: React.FormEvent<HTMLInputElement>) => void {
	return (evt) => {
		evt.currentTarget.value = evt.currentTarget.value.replace(/[^0-9.-]/g, '').replace(/(\..*)\./g, '$1');
	};
}

export default useNumericInput;
