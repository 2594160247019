import { KemuHubConnector } from '@kemu-io/kemu-core/types';
import { useEffect, useMemo, useState } from 'react';
import khConManager from '@src/app/kemuHub/connectionManager';
import { HubConnectionStatus } from '@src/types/kemuHub/hub_t';

const useKemuHubLink = (): {
  connector: KemuHubConnector,
  connected: boolean,
  status: HubConnectionStatus,
} => {
  const connector = useMemo(() => khConManager.getConnector(), []);
  const [status, setStatus] = useState(khConManager.getStatus());

  useEffect(() => {
    const onConnect = () => setStatus('connected');
    const onDisconnect = () => setStatus('disconnected');
    const onAck = () => setStatus('acknowledged');

    khConManager.on('connected', onConnect);
    khConManager.on('disconnected', onDisconnect);
    khConManager.on('acknowledged', onAck);

    return () => {
      khConManager.off('connected', onConnect);
      khConManager.off('disconnected', onDisconnect);
      khConManager.off('acknowledged', onAck);
    };
  }, []);

  return {
    connector,
    connected: status === 'connected',
    status,
  };
};

export default useKemuHubLink;
