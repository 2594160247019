import { useEffect, useRef, useState } from 'react';
import useKemuHubLink from './useHubLink';
import useActiveHubServices from './useActiveHubServices';

/**
 * Fetches the UI contents of a service from the cache.
 * @param name the name of the service.
 * @param version the version of the service.
 * @returns the cached contents.
 */
const useServiceUIContents = (name?: string, version?: string) => {
  const [contents, setContents] = useState<ArrayBuffer | null>(null);
  const services = useActiveHubServices();
  const prevInfoRef = useRef({ name, version });
  const { connector } = useKemuHubLink();

  useEffect(() => {
    const changed = prevInfoRef.current.name !== name || prevInfoRef.current.version !== version;
    let abort = false;

    const fetchNow = async () => {
      // NOTE: The hub connector guarantees that the contents for each service will only be fetched once
      const contents = await connector.getServiceContents(name, version);
      if (abort) { return; }
      setContents(contents);
    };

    if (name && version && changed) {
      prevInfoRef.current = { name, version };
      fetchNow();
    } else if (!version || !name) {
      // this usually means the service went offline, we clear the reference
      // to allow re-fetching when the service comes back online
      prevInfoRef.current = { name: undefined, version: undefined };
    }

    return () => {
      // fetchNow.cancel();
      abort = true;
    };
    // Re-check cache when `services` change
  }, [name, version, connector, services/* , fetchNow */]);

  return contents;
};

export default useServiceUIContents;
