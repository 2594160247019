/*
 * Written by Alexander Agudelo < alex@kemu.io >, 2021
 * Date: 04/Nov/2021
 * Last Modified: 09/07/2024, 12:19:26 pm
 * Modified By: Alexander Agudelo
 * Description:  Waits for all required resources to be loaded and loads a recipe directly.
 * 
 * ------
 * Copyright (C) 2021 Kemu - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */

import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { selectInstallDefaultThingStatus } from '../../../app/reducers/thing/thingSlice';
import routes from '../../../common/routes/index';
import useAlert from '../../../components/alert/useAlert';
import Spinner from '../../../components/spinner/spinner';
import { AsyncRequestStatus } from '../../../types/core_t';
import { recipeDownloadDetails } from '../../interface/interfaceSlice';
import { downloadRecipeAction } from '../../interface/reducers/downloadRecipeReducer';
import styles from './ViewRecipe.module.css';


const ViewRecipe = (/* props: Props */): React.JSX.Element => {
	// Wait for blocks to be downloaded
	// const installDefaultThingsStatus = useSelector(selectInstallDefaultThingStatus);
	const recipeDownloadInfo = useSelector(recipeDownloadDetails);
	const [isLoading, setIsLoading] = useState(true);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const Alert = useAlert();
	const path = window.location.pathname;
	const recipeId = routes.recipe.getRecipeIdFromPath(path);

	const loadRecipe = useCallback(async () => {
		if (recipeId) {
			dispatch(downloadRecipeAction({
				recipeId: recipeId,
				navigate,
				alert: Alert
			}));
		}
	}, [navigate, recipeId, Alert, dispatch]);

	useEffect(() => {
		loadRecipe();
		// if (installDefaultThingsStatus.status === AsyncRequestStatus.completed) {
		// }
	}, [loadRecipe]);

	useEffect(() => {
		if (recipeDownloadInfo?.status === AsyncRequestStatus.completed || recipeDownloadInfo?.status === AsyncRequestStatus.error) {
			setIsLoading(false);
			if (recipeDownloadInfo?.status === AsyncRequestStatus.error) {
				navigate(routes.getBaseRoute());
			}
		}
	}, [recipeDownloadInfo?.status, navigate]);

	return (
		<>
			{isLoading && recipeId ? (
				<div className={styles.Container}>
					<span className={styles.Message}>
						<FormattedMessage id="Interface.DirectView.Recipe.LoadingMessage" defaultMessage="Loading recipe..." />
					</span>
					<Spinner loading={isLoading} size="large"/>
				</div>
			) : null}
		</>
	);
};

export default ViewRecipe;
