import { Connection, Endpoint } from '@jsplumb/community/types/core';
import { SerializedError } from '@reduxjs/toolkit';
import { RecipeWidget, Thing, RecipeBlock } from '@kemu-io/kemu-core/types';
import { PublicationInformation, RecipeDbEntity } from '@kemu-io/kemu-types';
import { WidgetPort } from '@kemu-io/hs-types';
import { UICanvasInstance } from './canvas_t';
import { UISettingsPanelInstance } from './propertiesPanel_t';

// ==== From https://stackoverflow.com/a/49725198 ====
export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> =
	Pick<T, Exclude<keyof T, Keys>>
	& {
			[K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>
	}[Keys]


export type RequireOnlyOne<T, Keys extends keyof T = keyof T> =
	Pick<T, Exclude<keyof T, Keys>>
	& {
			[K in Keys]-?:
					Required<Pick<T, K>>
					& Partial<Record<Exclude<Keys, K>, undefined>>
	}[Keys]
// ====

type AllKeyOf<T> = T extends never ? never : keyof T
export type Optional<T, K> = { [P in Extract<keyof T, K>]?: T[P] }
export type WithOptional<T, K extends AllKeyOf<T>> = T extends never ? never : Omit<T, K> & Optional<T, K>

	interface PosInfo extends DOMRect {
	centerx: number;
	centery: number;
}


export type RecursivePartial<T> = {
	[P in keyof T]?: RecursivePartial<T[P]>;
};

export type PartialExcept<T, K extends keyof T> = RecursivePartial<T> & Pick<T, K>;

export interface PlumbDragEvent {
	e: MouseEvent;
	el: HTMLElement;
	pos: PosInfo
}

export interface SelectedBlockInfo {
	/** id of the block in the recipe */
	recipeId: string;
	/** Id of the block in the database */
	DbId: string;
	/** the id of the recipe in the pool */
	recipePoolId: string;
	version: string;
	/** title of the block as it appears in the canvas */
	title: string;
}


export type WidgetsMap = {
	[k: string]: RecipeWidget
}

export type StatelessRecipeWidget = Omit<RecipeWidget, 'state'> & { state: never };

export type StatelessWidgetsMap = {
	[k: string]: StatelessRecipeWidget;
}

export type SettingsPanelConstructor = new (props: {target: HTMLDivElement}) => UISettingsPanelInstance;
export type CanvasIconConstructor = new (props: {target: HTMLDivElement}) => UICanvasInstance;
export interface BlockUIElements {
	/** A function that can be used to build a new properties panel content and mount it to a target */
	SettingsPanel: SettingsPanelConstructor
	/** A function that can be used to build a new canvas icon element and mount it to a canvas target */
	CanvasIcon: CanvasIconConstructor
}
export interface BlockBundle extends BlockUIElements{
	Processor: Thing;
}

export enum AsyncRequestStatus {
	idle = 'idle',
	loading = 'loading',
	completed = 'completed',
	error = 'error',
}

export interface AsyncState {
	status: AsyncRequestStatus;
	error?: SerializedError;
}

/** how the server responds when queried for recipes */
export type RecipeResponse = RecipeDbEntity & PublicationInformation;


/**
 * Event signature of messages passed between canvas instances and the properties panel
 */
export interface EventBridgeData {
	type: string;
	data: unknown
}

export type EventBridgeHandler = (evt: EventBridgeData) => Promise<unknown>;

export type NativeCanvasAction = 'OpenPropertiesPanel' | 'DeleteBlock';

/**
 * Used by custom canvas instances to execute actions available to native
 * canvas instances. Eg. OpenPropertiesPanel
 */
export type TriggerCanvasActionHandler = (action: NativeCanvasAction, data?: unknown) => void;

// Missing typings
export interface JsPlumbBeforeDropEvent {
	connection: Connection;
	dropEndpoint: Endpoint;
	scope: string;
	source: unknown;
	sourceId: string;
	target: unknown;
	targetId: string;
}

export type KemuEndpointData = WidgetPort & {
	id: string;
}

export type KemuConnectionData = {
	sourceWidgetId: string;
	sourcePortId: string;
	targetPortId: string;
}

export interface NetworkBlock extends RecipeBlock {
	streamId: string;
	updatedAt: number;
}
