import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Icon, {
	ShareAltOutlined,
	DeleteFilled,
	PlusCircleFilled,
	ExclamationCircleFilled
} from '@ant-design/icons';
import { Popover, Button, Tooltip, Collapse } from 'antd';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Position, WidgetGroupState, CustomWidgetState } from '@kemu-io/kemu-core/types';
import { CustomWidgetVariant } from '@kemu-io/kemu-types';
import { compareVersions } from 'compare-versions';
// import DynamicSVG from '@components/DynamicSVG/DynamicSVG';
// import DynamicIcon from '@components/dynamicIcon/dynamicIcon';
import { addWidgetFromTemplateAction } from '../../logicMapperSlice';
import { pasteFromClipboardAction } from '../../reducers/clipboardReducers';
import { getDecodedClipboardData } from '../../reducers/clipboardUtils';
import CustomWidgetDragPreview from './CustomWidgetDragPreview/CustomWidgetDragPreview';
import LaunchpadWidgetIcon from './LaunchpadWidgetIcon/LaunchpadWidgetIcon';
import styles from './WidgetsLaunchpad.module.css';
import { ReactComponent as launchpadIcon } from './launchpadIcon.svg';
import PanelTitle from './PanelTitle/PanelTitle';
import HubServicesPanel from './HubServicesPanel/HubServicesPanel';
import WidgetLaunchpadContextProvider from './LaunchpadContext';
import ShareWidgetModal from '@components/ShareWidgetModal/ShareWidgetModal';
import StyledButton from '@components/form-control/styledButton/styledButton';
import GateIcon from '@components/gateIcon/gateIcon';
import { CustomWidgetByThing, FolderPathInfo, selectCustomWidgetTemplates } from '@src/features/Workspace/workspaceSlice';
import { selectUserWidgets, WidgetCollectionItem } from '@src/app/reducers/widget/widgetSlice';
import useAlert from '@components/alert/useAlert';
import useTranslation from '@common/hooks/useTranslation';
import { deleteWidgetAction } from '@src/app/reducers/widget/deleteWidgetReducer';
import { openHelpArticle, safeJsonParse } from '@common/utils';
// import { WidgetsMap } from '@src/types/core_t';
// import { selectUserSettings } from '@src/app/reducers/user/userSlice';

interface Props {
	/**
	 * Id of the current folder (widget group).
	 */
	currentFolder: FolderPathInfo | null;
	/**
	 * The id of the recipe in the pool.
	 */
	recipePoolId: string;
	/** 
	 * The id of the Thing in the recipe the user is currently inside of. 
	 **/
	thingId: string;
	/** id of the Thing in the DB */
	thingDbId: string;
}

interface FooterInfo {
	name: string;
	/** 
	 * id of the widget in the database in case of a custom widget 
	 **/
	dbId?: string;
	/** id of the thing the widget belongs to */
	thingDbId?: string;
	thingName: string;
	available: boolean;
	/** unique identifier withing the list of widget templates for the selected thing */
	thingWidgetKey?: string;
	description: string;
	publicShareId?: string;
	userWidgetIndex?: number;
}

type SearchFilterType = 'custom-widget' | 'widget-bundle';

const LAUNCHER_STORE = 'widgets-launcher';
const USER_WIDGETS_KEY = 'user-widgets';
const WIDGET_SERVICES_KEY = 'hub-widget-services';
// const WIDGETS_BUNDLES_KEY = 'widget-bundles';

/** 
 * @return the list of items from the local storage 
 **/
const getStorageItems = (): string[] => {
	const store = localStorage.getItem(LAUNCHER_STORE);
	if (	store ) {
		const json = safeJsonParse<string[]>(store);
		if (json) { return json; }
	}

	return [USER_WIDGETS_KEY];
};

/** prevents re-rendering unless the Things have changed */
const statesAreEqual = (prevState: CustomWidgetByThing[], nextState: CustomWidgetByThing[]): boolean => {
	const prevThings = prevState.map(thing => thing.thingDbId).join(' ');
	const nextThings = nextState.map(thing => thing.thingDbId).join(' ');
	return prevThings === nextThings;
};

const WidgetsLaunchpad = (props: Props): React.JSX.Element => {
	const { recipePoolId, thingId, currentFolder } = props;
	const userWidgets = useSelector(selectUserWidgets);
	const thingsWidgets = useSelector(selectCustomWidgetTemplates, statesAreEqual);
	// const userSettings = useSelector(selectUserSettings);
	// const canUseWidgetBundles = userSettings?.useWidgetBundles;
	const alert = useAlert();
	const dispatch = useDispatch();
	const t = useTranslation('Widget.Launchpad');
	const [footerInfo, setFooterInfo] = useState<FooterInfo | null>(null);
	const [customFooter, setCustomFooter] = useState<React.ReactElement | null>(null);
	// const [selectedUserWidgetIndex, setSelectedUserWidgetIndex] = useState<number | null>(null);
	const [showSharingModal, setShowSharingModal] = useState(false);
	const [preventClosingLaunchpad, setPreventClosingLaunchpad] = useState(false);
	const [launchpadVisible, setLaunchpadVisible] = useState(false);
	// temporarily hides the launcher while dragging
	const [isDragging, setIsDragging] = useState(false);
	const [expandedKeys, setExpandedKeys] = useState<string[]>(getStorageItems());
	const currentShareId = footerInfo?.userWidgetIndex !== undefined ? userWidgets[footerInfo.userWidgetIndex]?.publicShareId : undefined;
	const [filterTerm, setFilterTerm] = useState('');
	// const [filteredWidgets, setFilteredWidgets] = useState(userWidgets);
	const [customWidgetFilterTerm, setCustomWidgetFilterTerm] = useState('');
	// const [widgetBundleFilterTerm, setWidgetBundleFilterTerm] = useState('');
	const clearSearchTmrRef = useRef<NodeJS.Timeout | null>(null);


	// Groups widgets by variant
	const { customWidgets, widgetBundles } = useMemo(() => {
		return userWidgets.reduce((acc, widget) => {
			if (widget.variant === CustomWidgetVariant.Bundle) {
				acc.widgetBundles.push(widget);
			} else {
				acc.customWidgets.push(widget);
			}

			return acc;
		}, { widgetBundles: [] as WidgetCollectionItem[], customWidgets: [] as WidgetCollectionItem[] });
	}, [userWidgets]);


	const filteredCustomWidgets = useMemo(() => {
		const filtered = customWidgets.filter(widget => {
			return widget.name.toUpperCase().includes(customWidgetFilterTerm.toUpperCase());
		});

		// Sort by version, descending, in order to show compatible widgets (V2) first.
		const sortedByVersion = filtered.sort((a, b) => {
			return compareVersions(b.protocolVersion || '1', a.protocolVersion || '1');
		});

		return sortedByVersion;
	}, [customWidgets, customWidgetFilterTerm]);

	// const filteredWidgetsBundles = useMemo(() => {
	// 	return widgetBundles.filter(widget => {
	// 		return widget.name.toUpperCase().includes(widgetBundleFilterTerm.toUpperCase());
	// 	});
	// }, [widgetBundles, widgetBundleFilterTerm]);

	const handleHidePopup = useCallback(() => {
		setIsDragging(true);
	}, []);

	const handleShareModal = () => {
		setShowSharingModal(true);
	};

	const handleAddWidget = useCallback((widgetId: string, dropLocation?: Position) => {
		setIsDragging(false);
		const widget = userWidgets.find(widget => widget.dbId === widgetId);
		if (widget ) {
			dispatch(addWidgetFromTemplateAction({
				widget,
				recipeId: recipePoolId,
				thingId: thingId,
				position: dropLocation,
				currentGroupId: currentFolder?.groupId,
			}));
		}
	}, [dispatch, currentFolder?.groupId, recipePoolId, thingId, userWidgets]);

	const handleDelete = useCallback((widgetId: string): void => {
		const widget = userWidgets.find(widget => widget.dbId === widgetId);
		if (widget) {
			setPreventClosingLaunchpad(true);
			alert.confirm({
				title: t('DeleteModal.Title', 'Delete Custom Widget?'),
				content: t(
					'Description',
					'You are about to delete "{name}". This process cannot be undone.',
					{ name: widget.name }
				),
				okText: t('DeleteModal.OkButton', 'Delete'),
				cancelText: t('DeleteModal.CancelButton', 'Cancel'),
				okButtonColor: 'danger',
				iconColor: 'danger',
				onCancel: () => {
					setPreventClosingLaunchpad(false);
				},
				onOk: () => {
					setPreventClosingLaunchpad(false);
					dispatch(deleteWidgetAction({
						version: widget.version,
						widgetDbId: widget.dbId,
					}));
				}
			});
		}
	}, [dispatch, t, alert, userWidgets]);


	const showHelpGuide = () => {
		openHelpArticle(t('CreateWidgetArticleId', '51000211160'));
	};

	const handleVisibleChange = useCallback((visible: boolean) => {
		if (!preventClosingLaunchpad && !showSharingModal) {
			setLaunchpadVisible(visible);
			setIsDragging(false);
			if (!visible) {
				setFilterTerm('');
				setCustomWidgetFilterTerm('');
			}
		}
	}, [preventClosingLaunchpad, showSharingModal]);

	// const handleClickThingWidget = useCallback((widgetKey: string, thingDbId: string) => {
	// 	const thingWidgets = thingsWidgets.find(thingList => thingList.thingDbId === thingDbId);
	// 	if (thingWidgets) {
	// 		const widget = thingWidgets.widgets[widgetKey];
	// 		setCustomFooter(null);
	// 		setFooterInfo({
	// 			description: widget.description,
	// 			name: widget.name,
	// 			thingDbId,
	// 			thingName: thingWidgets.thingName,
	// 			thingWidgetKey: widgetKey,
	// 			available: !widget.restrictToSameBlock || thingDbId === props.thingDbId,
	// 		});
	// 	}
	// }, [thingsWidgets, props.thingDbId]);

	// const handleThingWidgetDrop = useCallback((widgetKey: string, dropLocation: Position, thingDbId: string) => {
	// 	setIsDragging(false);
	// 	const thingWidgets = thingsWidgets.find(thingList => thingList.thingDbId === thingDbId);
	// 	if (thingWidgets) {
	// 		const widget = thingWidgets.widgets[widgetKey];

	// 		// Abort if not available in the same block
	// 		if (widget.restrictToSameBlock && thingWidgets.thingDbId !== thingDbId) {
	// 			return;
	// 		}

	// 		// Here we need to update the color, icon and description in the widget's state
	// 		// to match that icon, color and description defined by the Thing. 
	// 		const widgetMapStr = getDecodedClipboardData(widget.contents);
	// 		if (widgetMapStr) {
	// 			const decodedJson = safeJsonParse<WidgetsMap>(widgetMapStr);
	// 			if (decodedJson) {
	// 				// There should be one widget definition in the map and it should not have a group (container) id.
	// 				const containerWidget = Object.keys(decodedJson).find(key => !decodedJson[key].groupId);
	// 				if (containerWidget) {
	// 					// Force the widget content's to match the icon and color definition
	// 					(decodedJson[containerWidget].state as CustomWidgetState<WidgetGroupState>).name = widget.name;
	// 					(decodedJson[containerWidget].state as CustomWidgetState<WidgetGroupState>).description = widget.description;
	// 					(decodedJson[containerWidget].state as CustomWidgetState<WidgetGroupState>).color = widget.color;
	// 					(decodedJson[containerWidget].state as CustomWidgetState<WidgetGroupState>).icon = widget.icon;
	// 					// Create a new string map. Not particularly efficient but it is done in order to re-use the reducer.
	// 					const updatedStringMap = JSON.stringify(decodedJson);
	// 					dispatch(pasteFromClipboardAction({
	// 						mouseLocation: dropLocation,
	// 						recipeId: props.recipePoolId,
	// 						thingId: props.thingId,
	// 						mapData: updatedStringMap
	// 					}));
	// 				}
	// 			}
	// 		}
	// 	}
	// }, [dispatch, props.recipePoolId, props.thingId, thingsWidgets]);

	const handleLaunchpadItemClick = useCallback((widgetId: string) => {
		const widgetIndex = userWidgets.findIndex(widget => widget.dbId === widgetId);
		if (widgetIndex !== -1) {
			const widget = userWidgets[widgetIndex];
			setCustomFooter(null);
			setFooterInfo({
				...widget,
				thingName: '',
				available: true,
				userWidgetIndex: widgetIndex,
			});
		}
	}, [userWidgets]);

	const handleFilterChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const text = event.target.value;
		const keyType = event.target.dataset.filterType;
		const filterType = keyType as SearchFilterType;

		const doSearch = () => {
			if (filterType === 'custom-widget') {
				setCustomWidgetFilterTerm(text);
			}

			// if (filterType === 'widget-bundle') {
			// 	setWidgetBundleFilterTerm(text);
			// }
		};

		clearSearchTmrRef.current && clearTimeout(clearSearchTmrRef.current);
		clearSearchTmrRef.current = setTimeout(doSearch, 100);
	}, []);

	const onCollapseChange = useCallback((keys: string | string[]) => {
		if (typeof keys === 'string') { keys = [keys]; }
		const lastKey = keys[keys.length - 1];

		localStorage.setItem(LAUNCHER_STORE, JSON.stringify([lastKey]));
		if (lastKey) {
			setExpandedKeys([lastKey]);
		} else {
			setExpandedKeys([]);
		}
	}, []);

	// const LaunchpadItemHeader = (props: { name: string, svgIcon?: string, fontAwesomeIcon?: string }) => {
	// 	return (
	// 		<div className={styles.PanelHeader}>
	// 			{props.svgIcon && (
	// 				<DynamicSVG icon={props.svgIcon} size={14} />
	// 			)}

	// 			{props.fontAwesomeIcon && (
	// 				<DynamicIcon iconName={props.fontAwesomeIcon} />
	// 			)}
	// 			<span className={styles.HeaderName}>{props.name}</span>
	// 		</div>
	// 	);
	// };

	const LaunchpadContent = () => {
		return (
			<div
				className={styles.LaunchpadContainer}
			>
				<div className={styles.ExpandableArea}>
					<Collapse
						// defaultActiveKey={['user-widgets']}
						activeKey={expandedKeys}
						onChange={onCollapseChange}
						className={styles.LaunchpadCollapse}
						destroyInactivePanel={true}
						expandIconPosition="end"
					>

						{/* {thingsWidgets.map((thingWithWidgets) => (
							// <Panel
							// 	className={styles.Panel}
							// 	key={thingWithWidgets.thingDbId}
							// 	// header={thingWithWidgets.thingName} 
							// 	header={<LaunchpadItemHeader name={thingWithWidgets.thingName} svgIcon={thingWithWidgets.thingIcon} />}
							// >
							<PanelTitle
								title={thingWithWidgets.thingName}
								svgIcon={thingWithWidgets.thingIcon}
								key={thingWithWidgets.thingDbId}
							>
								<div className={styles.WidgetsSection}>
									<div className={styles.WidgetsGrid}>
										{Object.entries(thingWithWidgets.widgets).map(([widgetKey, widget]) => {
											return (
												<LaunchpadWidgetIcon
													onDelete={handleDelete}
													onAddWidget={handleThingWidgetDrop}
													onHideLaunchpad={handleHidePopup}
													onClick={handleClickThingWidget}
													disabled={widget.restrictToSameBlock && props.thingDbId !== thingWithWidgets.thingDbId}
													isSelected={footerInfo?.thingDbId === thingWithWidgets.thingDbId && footerInfo?.thingWidgetKey === widgetKey}
													// isThingTemplate={true}
													key={widgetKey}
													widgetInfo={{
														...widget,
														variant: CustomWidgetVariant.Group,
														id: widgetKey,
													}}
													currentFolder={props.currentFolder}
													recipePoolId={props.recipePoolId}
													thingDbId={thingWithWidgets.thingDbId}
												/>
											);
										})}
									</div>
								</div>
							</PanelTitle>
						))} */}

						<PanelTitle
							title={t('MyWidgets', 'My Widgets')}
							fontAwesomeIcon={'folder'}
							key={USER_WIDGETS_KEY}
						>
							<div className={styles.WidgetsSection}>
								{customWidgets.length > 0 && (
									<div className={styles.SearchInputWrapper}>
										<input
											type="text"
											placeholder={t('SearchPlaceholder', 'Find a widget')}
											className={styles.SearchInput}
											data-filter-type="custom-widget"
											onChange={handleFilterChange}
											defaultValue={filterTerm}
										/>
									</div>
								)}
								<div className={styles.WidgetsGrid}>
									{filteredCustomWidgets.length ? (
										<>
											{filteredCustomWidgets.map((widget) => {
												return (
													<LaunchpadWidgetIcon
														isSelected={footerInfo?.dbId === widget.dbId}
														onDelete={handleDelete}
														onClick={handleLaunchpadItemClick}
														onAddWidget={handleAddWidget}
														onHideLaunchpad={handleHidePopup}
														// isThingTemplate={false}
														key={widget.dbId}
														widgetInfo={{
															...widget,
															id: widget.dbId,
														}}
														currentFolder={props.currentFolder}
														recipePoolId={props.recipePoolId}
													/>
												);
											})}
										</>
									) : (
										!customWidgets.length && (
											<div className={styles.EmptyWidgetsContainer}>
												<span>{t('NoWidgetsCreated')}</span>
												{/* <StyledButton
													color="secondary"
													className={styles.LearnHowButton}
													onClick={showHelpGuide}
													title={t('LearnHowLink', 'Learn how')}
												/> */}
											</div>
										)
									)}
								</div>
							</div>
						</PanelTitle>

						{/* {canUseWidgetBundles && (
							<Panel
								header={<LaunchpadItemHeader name={t('WidgetBundles', 'Widgets Bundles')} fontAwesomeIcon='cube' />}
								key={WIDGETS_BUNDLES_KEY} className={styles.Panel}
							>
								<div className={styles.WidgetsSection}>
									{widgetBundles.length > 0 && (
										<div className={styles.SearchInputWrapper}>
											<input
												type="text"
												data-filter-type="widget-bundle"
												placeholder={t('SearchPlaceholder', 'Find a widget')}
												className={styles.SearchInput}
												onChange={handleFilterChange}
												defaultValue={filterTerm}
											/>
										</div>
									)}
									<div className={styles.WidgetsGrid}>
										{filteredWidgetsBundles.length ? (
											<>
												{filteredWidgetsBundles.map((widget) => {
													return (
														<LaunchpadWidgetIcon
															isSelected={footerInfo?.dbId === widget.dbId}
															onDelete={handleDelete}
															onClick={handleLaunchpadItemClick}
															onAddWidget={handleAddWidget}
															onHideLaunchpad={handleHidePopup}
															isThingTemplate={false}
															key={widget.dbId}
															widgetInfo={{
																...widget,
																id: widget.dbId,
															}}
															currentFolder={props.currentFolder}
															recipePoolId={props.recipePoolId}
															thingDbId=''
														/>
													);
												})}
											</>
										) : (
											filteredWidgetsBundles.length == 0 && (
												<div className={styles.EmptyWidgetsContainer}>
													<span>{t('NoWidgetsBundlesCreated', 'You have not created any widget bundles yet.')}</span>
												</div>
											)
										)}
									</div>
								</div>
							</Panel>
						)} */}

						<HubServicesPanel
							recipePoolId={recipePoolId}
							currentFolder={currentFolder}
							onAddWidget={handleAddWidget}
							key={WIDGET_SERVICES_KEY}
						/>
					</Collapse>
				</div>

				<div className={styles.LaunchpadFooter}>
					{/* Shows any custom footer set by the user */}
					{customFooter && expandedKeys.includes(WIDGET_SERVICES_KEY) ? customFooter : null}
					{/* Default footer (customFooter takes priority) */}
					{!customFooter && footerInfo && !expandedKeys.includes(WIDGET_SERVICES_KEY) ? (
						<>
							<div className={styles.FooterTitle}>{footerInfo.name}
								{!footerInfo.available && (
										<span className={styles.NotAvailable}>
											<ExclamationCircleFilled />&nbsp;
											{t('Footer.ThingNotAvailable', 'available inside a "{name}" Thing', { name: footerInfo.thingName }) }
										</span>
								)}
							</div>
							<div className={styles.Description}>{footerInfo.description}</div>
							<div className={styles.FooterActionButtons}>
								{footerInfo.dbId && (
									<>
										<Tooltip mouseEnterDelay={0.5} title={
											currentShareId ? t('Share.LinkSharingOn', 'Link sharing is On') : t('Share.LinkSharingOff', 'Link sharing is Off')
										} placement="right">
											<Button
												className={classNames({ 'sharingOn': currentShareId !== undefined })}
												onClick={handleShareModal}
												type="link"
												icon={<ShareAltOutlined />}
											/>
										</Tooltip>
										<Tooltip mouseEnterDelay={0.5} title={t('DelButton', 'Delete widget')} placement="right">
											<Button className="delete-btn" onClick={() => footerInfo.dbId && handleDelete(footerInfo.dbId)} type="link" icon={<DeleteFilled />} />
										</Tooltip>
										<Tooltip mouseEnterDelay={0.5} title={t('Footer.AddButton', 'Add to workspace')} placement="right">
											<Button
												onClick={() => footerInfo.dbId && handleAddWidget(footerInfo.dbId)}
												type="link"
												icon={<PlusCircleFilled />}
											/>
										</Tooltip>
									</>
								)}
							</div>
						</>
					) : (
						!customFooter && (
							<div className={classNames(styles.Description, styles.Default)}>
								{t('Footer.DefaultDescription', 'Add a Widget by dragging it into the canvas.')}
							</div>
						)
					)}
				</div>
			</div>
		);
	};


	useEffect(() => {
		return () => {
			clearSearchTmrRef.current && clearTimeout(clearSearchTmrRef.current);
		};
	});


	return (
		<WidgetLaunchpadContextProvider value={{
			visible: launchpadVisible,
			setPreventClose: setPreventClosingLaunchpad,
			hideLaunchpad: setIsDragging,
			setCustomFooter,
		}}>
			<Popover
				mouseLeaveDelay={0}
				destroyTooltipOnHide={true}
				open={(launchpadVisible || preventClosingLaunchpad || showSharingModal) && !isDragging}
				overlayClassName={classNames(styles.LaunchpadOverlay)}
				placement="bottomLeft"
				onOpenChange={handleVisibleChange}
				content={LaunchpadContent}
				trigger="click"
			>
				<div
					className={classNames('logic-gate-element')}
					data-kemu-meta='widget-launchpad-btn'
				>
					<GateIcon className={styles.LaunchpadButton} icon={<Icon component={launchpadIcon} className={styles.BarIcon}/>}/>
					<div className="noselect gate-name">
						<label>{t('Title', 'Custom Widgets')}</label>
					</div>

					<ShareWidgetModal
						visible={showSharingModal}
						onClose={() => setShowSharingModal(false)}
						publicShareId={currentShareId}
						dbId={footerInfo?.dbId}
					/>
				</div>

			</Popover>

			<CustomWidgetDragPreview />
		</WidgetLaunchpadContextProvider>
	);
};

export default WidgetsLaunchpad;
