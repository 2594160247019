import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import KemuSwitch from '../form-control/kemuSwitch/KemuSwitch';
import { AsyncRequestStatus } from '../../types/core_t';
import StandardModal from '../modal/standardModal';
import useTranslation from '../../common/hooks/useTranslation';
import { selectWidgetAsyncStatus } from '../../app/reducers/widget/widgetSlice';
import StyledInput from '../form-control/styledInput/styledInput';
import StyledButton from '../form-control/styledButton/styledButton';
import routes from '../../common/routes/index';
import { shareWidgetAction } from '../../app/reducers/widget/shareWidgetReducer';
import styles from './ShareWidgetModal.module.css';
import FormGroup from '@components/form-control/formGroup/formGroup';

interface Props {
	publicShareId?: string;
	dbId?: string;
	visible: boolean;
	onClose: () => void;
}

const ShareWidgetModal = (props: Props): React.JSX.Element => {
	const dispatch = useDispatch();
	const asyncProcess = useSelector(selectWidgetAsyncStatus);
	const t = useTranslation('Widget.Launchpad.Share');
	const hasPublicLink = !!props.publicShareId;
	const linkUrl = routes.widgets.getPublicWidgetImportUrl(props.publicShareId);

	const onUrlCopied = () => {
		message.success({
			duration: 1,
			content: t('Link.CopySuccessful', 'Link copied'),
		});
	};

	const toggleLinkSharing = () => {
		if (props.dbId) {
			dispatch(shareWidgetAction({
				enable: props.publicShareId === undefined,
				widgetDbId: props.dbId
			}));
		}
	};


	return (
		<StandardModal
			destroyOnClose={true}
			dataKemuMeta="recipe-share-dialog"
			onCancel={props.onClose}
			onOk={props.onClose}
			closeOnMaskClick={true}
			okBtnLabel={t('DoneBtn', 'Done')}
			visible={props.visible}
			title={t('Title', 'Share With Others')}
		>

			<Spin indicator={<LoadingOutlined/>} spinning={asyncProcess.asyncStatus.status === AsyncRequestStatus.loading}>
				<div>
					<FormGroup row={true}>
						<KemuSwitch
							data-kemu-meta="share-widget-switch"
							className={styles.ByLinkSwitch}
							checked={hasPublicLink}
							onChange={toggleLinkSharing}
							size="default"
						/>
						<label>
							{hasPublicLink ? t('LinkSharingOn', 'Link sharing is On') : t('LinkSharingOff', 'Link sharing is Off') }
						</label>
					</FormGroup>

					{hasPublicLink && (
						<FormGroup className={styles.InputContainer} row={true}>
							<StyledInput
								readOnly
								className={styles.Input}
								label={t('Description', 'Anyone with this link will be able to use this widget')}
								value={linkUrl}
							/>

							<CopyToClipboard text={linkUrl} onCopy={onUrlCopied}>
								<StyledButton title={t('Link.CopyBtn', 'Copy Link')} color="light" className={styles.LinkCopyBtn}/>
							</CopyToClipboard>
						</FormGroup>
					)}
				</div>

				{/* Email sharing disabled for now (02/Mar/2021) */}
				{/* <div className={styles.PeopleContainer}>
					<p><FormattedMessage id="Marketplace.MyRecipes.Card.ShareDialog.ViaEmail" defaultMessage="Share this recipe with specific Kemu users" /></p>
					<FormGroup row={true}>
						<StyledInput ref={emailInputRef} onKeyDown={onKeyPressed} title="Share with specific people" i18nPlaceholderId="Marketplace.MyRecipes.Card.ShareDialog.EmailPlaceholder" i18nPlaceholderDefaultMessage="Enter email address" className={styles.UserAddressInput} />
						<IconButton onClick={onAddShareUser} color="light" icon={<PlusOutlined style={{ fontSize: 18 }} />} />
					</FormGroup>

					<div className={styles.PersonList}>
						{(props.shareList || []).map((user, index) => (
							<PersonItem onAccessModeSelected={onAccessModeSelected} key={index} name={user.name} email={user.email} index={index} accessMode={user.accessMode}/>
						))}
					</div>
				</div> */}
			</Spin>
		</StandardModal>
	);
};

export default ShareWidgetModal;
