import React from 'react';
import HTMLContent from '../htmlContent/htmlContent';
import styles from './imageContent.module.css';

const ImageContent = (props: {text?: string, url?: string}): React.JSX.Element => {
	const { text, url } = props;
	return (
		<>
			<div className={styles.Descriptor}>
				<HTMLContent content={text}/>
			</div>
			<div className={styles.ImageContainer}>
				<img src={url} />
			</div>
		</>
	);
};

export default ImageContent;
