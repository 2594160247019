import React, { forwardRef, ReactElement } from 'react';
import { InputNumber, } from 'antd';
import classNames from 'classnames';
import styles from './NumericInput.module.css';

type Props = {
	className?: string;
	min?: number;
	max?: number;
	defaultValue?: number;
	value?: number;
	placeholder?: string;
	disabled?: boolean;
	step?: number;
	wrapperClass?: string;
	title?: string;
	/** used to uniquely identify the input in event handlers */
	eventId?: string;
	/** Called when the user types in a value or string */
	// onInputChange?: (value: number | string) => void;
	/** calls when the value has changed either by keyboard or arrows */
	onChange?: (value: number, eventId?: string) => void;
}

// export type NumericInputRefType = {
// 	blur: ()=> void;
// 	focus: ()=> void;
// 	nativeElement: HTMLInputElement;
// }

const NumericInput = forwardRef<HTMLInputElement, Props>((props, ref): ReactElement => {
	// Handles keyboard entries
	const handleKeyboardInput = (value: number | string | undefined | null) => {
		if (!isNaN(Number(value)) && value !== null && value !== undefined) {
			const v = Number(value);
			// Abort notifications outside range
			if (props.min !== undefined && v < props.min) { return; }
			if (props.max !== undefined && v > props.max) { return; }
			props.onChange && props.onChange(v, props.eventId);
		}
	};




	return (
		/* VERY important to add 'no-child-drag' or up/down buttons will stop working */
		<div className={`gate-input no-child-drag ${props.wrapperClass || ''}`}>
			<InputNumber
				ref={ref}
				step={props.step}
				disabled={props.disabled}
				title={props.title}
				variant='borderless'
				className={classNames(styles.Container, props.className)}
				min={props.min}
				max={props.max}
				defaultValue={props.defaultValue}
				value={props.value}
				placeholder={props.placeholder}
				onChange={handleKeyboardInput}
				data-testid="numeric-input"
			/>
		</div>
	);
});

// export default NumericInput;


export default NumericInput;
