import React, { lazy, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { posthog } from 'posthog-js';
import { Route, useNavigate, Routes, useParams } from 'react-router-dom';
import LoadingEnvironmentScreen from '../LoadingEnvironmentScreen/LoadingEnvironmentScreen';
import WidgetBundleModal from '../LogicMapper/WidgetBundleModal/WidgetBundleModal';
import BrowserRecipeScreen from '../BrowserRecipeScreen/BrowserRecipeScreen';
import ViewRecipe from '../directView/recipe/ViewRecipe';
import CustomWidgetModal from '../LogicMapper/customWidgetModal/customWidgetModal';
import PageNotFound from '../404/PageNotFound';
import RecipeRouter from './RecipeRouter';
import SaveRecipeModal from '@components/SaveRecipeModal/SaveRecipeModal';
import GlobalNotifications from '@components/GlobalNotifications/GlobalNotifications';
import WithSuspenseLoader from '@components/WithSuspenseLoader/WithSuspenseLoader';
import RecipeDownloadIndicator from '@components/recipeDownloadIndicator/recipeDownloadIndicator';
import RecipeUploadProgress from '@components/recipeUploadProgress/recipeUploadProgress';
import SaveRecipeIndicator from '@components/saveRecipeIndicator/saveRecipeIndicator';
import VersionAvailablePopup from '@components/versionAvailablePopup/VersionAvailablePopup';
import WidgetAsyncIndicator from '@components/widgetAsyncIndicator/widgetAsyncIndicator';
import { UserSessionContext } from '@common/context/UserSessionContext/UserSessionContext';
import routes from '@common/routes/index';
// import EnvironmentModal from '../EnvironmentModal/EnvironmentModal';
// const BrowserRecipeScreen = React.lazy(() => import('../BrowserRecipeScreen/BrowserRecipeScreen'));
import MarketplaceLayout from '@components/layout/marketplace/marketplace';
// const MarketplaceLayout = lazy(() => import('@components/layout/marketplace/marketplace.tsx'));
import { selectSignedUserProfile } from '@src/app/reducers/user/userSlice';
import globals from '@common/globals';
import { initFreshChatWidget } from '@src/app/recipe/freshChatWidget';
import { initFreshDeskWidget } from '@src/app/recipe/freshDeskWidget';
import { fetchUserProfileAction } from '@src/app/reducers/user/fetchUserReducer';
// import helpHero from '@common/helpHero/helpHero';
import { AsyncRequestStatus } from '@src/types/core_t';
import useTranslation from '@hooks/useTranslation';
import useQueryString from '@hooks/useQueryString';
// import { createBrowserRecipeAction } from '@src/app/reducers/workspace/createBrowserRecipeReducer';
// import useWebsocketConnection from '@hooks/useWebsocketConnection';
// import WebsocketConnector from '../WebsocketConnector/WebsocketConnector';

type ReducedSessionInfo = {
	attributes: Record<string, string>;
	state: string;
}

export const Kemu = (): React.JSX.Element | null => {
	const dispatch = useDispatch();
	const session = useContext(UserSessionContext);
	const userProfile = useSelector(selectSignedUserProfile);
	const t = useTranslation();
	const navigate = useNavigate();
	const environmentLoaded = userProfile.asyncState.status === AsyncRequestStatus.completed;
	const isLoggedIn = session.state === 'LoggedIn';
	// const { id: recipeIdInPath } = useParams<{id?: string}>();
	const queryStr = useQueryString();
	const inMarketplace = queryStr.get('marketplace') === 'true';
	// const { readyState } = useWebsocketConnection();
	// TODO: make this dynamic depending on user locale
	initFreshDeskWidget('en');

	const freshChatWidget = initFreshChatWidget();
	// Convert the data we use to a primitive to prevent re-rendering this component when the session object changes
	const userInfoStr = JSON.stringify({
		attributes: session.user?.attributes,
		state: session.state,
	} as ReducedSessionInfo);

  useEffect(() => {
		// Account setup not completed yet, redirect to config
		const userInfo = JSON.parse(userInfoStr) as ReducedSessionInfo;
		if (userInfo.state === 'LoggedIn' && userInfo.attributes) {
			// Fetch user profile
			dispatch(fetchUserProfileAction());

			/* if (!userInfo.attributes['custom:organization']) {
				navigate(routes.auth.getAccountSelectionWithUserRoute(userInfo.attributes['sub']));
			} */
		}
	}, [userInfoStr, navigate, dispatch]);


	// Sets the user info in the chat widget and posthog
	useEffect(() => {
		if (userProfile.profile) {
			// const helphero = helpHero.initialize(globals.HELP_HERO_APP_ID);

			/* helphero.identify(userProfile.profile.id, {
				id: userProfile.profile.id,
				firstName: userProfile.profile.firstName,
				lastName: userProfile.profile.lastName,
				email: userProfile.profile.email,
				plan: userProfile.profile.organizationId ? 'organization' : 'individual'
			}); */

			posthog.init(
				globals.POSTHOG_PROJECT_API_TOKEN,
				{
					api_host: 'https://app.posthog.com',
					autocapture: false,
					'opt_in_site_apps': true,
				}
			);

			posthog.identify(userProfile.profile.id, {
				id: userProfile.profile.id,
				firstName: userProfile.profile.firstName,
				lastName: userProfile.profile.lastName,
				email: userProfile.profile.email,
				plan: userProfile.profile.organizationId ? 'organization' : 'individual',
				webAppVersion: globals.WEB_APP_VERSION,
			});

			// Set userback user info
			/* const userBackInstance = getUserbackInstance();
			if (userBackInstance) {
				userBackInstance.email = userProfile.profile.email as string;
				userBackInstance.name = `${userProfile.profile.firstName} ${userProfile.profile.lastName}`;
				// NOTE: Requires 'Company' or 'Premium' plan. Not currently working.
				userBackInstance.identify(userProfile.profile.id, {
					name: `${userProfile.profile.firstName} ${userProfile.profile.lastName}`,
					firstName: userProfile.profile.firstName,
					lastName: userProfile.profile.lastName,
					loginId: userProfile.profile.email || userProfile.profile.loginId,
					plan: userProfile.profile.organizationId ? 'organization' : 'individual'
				});
			} */

			if (freshChatWidget.initialized) {
				freshChatWidget.setUserInfo({
					id: userProfile.profile.id,
					firstName: userProfile.profile.firstName,
					lastName: userProfile.profile.lastName,
					email: userProfile.profile.email,
					plan: userProfile.profile.organizationId ? 'organization' : 'individual'
				});

				freshChatWidget.show();
			}
		}
	}, [freshChatWidget.initialized, freshChatWidget, userProfile.profile]);

	// useEffect(() => {
	// 	if (environmentLoaded && isLoggedIn && !recipeIdInPath) {
	// 		dispatch(createBrowserRecipeAction({
	// 			history
	// 		}));
	// 	}
	// }, [environmentLoaded, isLoggedIn, dispatch, history, recipeIdInPath]);


	useEffect(() => {
		if (session.state === 'NotLoggedIn') {
			// Only redirect once we are SURE the user is not logged in
			console.log('Redirecting to sign in');
			navigate(routes.auth.getSignInRoute());
		}
	}, [session.state, navigate]);


	if (session.state === 'NotLoggedIn') {
		return null;
	}

	if (!environmentLoaded) {
		return (
			<LoadingEnvironmentScreen title={t('Interface.LoadingEnvironment.Title')}/>
		);
	}

	return (
		<>
			{environmentLoaded && session.state === 'LoggedIn' && session.user?.attributes['custom:organization'] && (
				<WithSuspenseLoader title={t('Interface.LoadingResource')}>
					{/* <KeepWaiting /> */}
					<VersionAvailablePopup />
					<SaveRecipeIndicator />
					<RecipeUploadProgress />
					<RecipeDownloadIndicator />
					<WidgetAsyncIndicator />
					<CustomWidgetModal />
					<WidgetBundleModal />
					<SaveRecipeModal />
					{/* <WebsocketConnector /> */}
					<GlobalNotifications />

					{/* TODO: this should show in an outlet? */}
					{inMarketplace && <MarketplaceLayout /> }

					<Routes>
						<Route path={routes.recipe.getDirectRecipeRoute()} element={<ViewRecipe />} />
						<Route path={routes.recipe.getBrowserRecipeRoute()} element={<BrowserRecipeScreen />} />

						{/* <Route path={routes.marketplace.getMarketplaceRoute()} element={<MarketplaceLayout />} /> */}
						<Route path={routes.getBaseRoute()} element={<RecipeRouter />} />

						<Route path="*" element={<PageNotFound />} />
					</Routes>
				</WithSuspenseLoader>
			)}

		</>
	);
};
