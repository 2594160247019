import React from 'react';
import classNames from 'classnames';
import Icon from '@ant-design/icons';
import styles from './WidgetBodyWithIcon.module.css';

type Props = {
  className?: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
    title?: string;
  }>;
  children?: React.ReactNode;
}

/**
 * Common wrapper for widgets without body elements
 */
const WidgetBodyWithIcon = (props: Props) => {
  const { className, icon } = props;

  return (
		<div className={classNames(styles.WidgetBody, className)}>
			<Icon component={icon}/>
			{props.children}
		</div>
	);
};

export default WidgetBodyWithIcon;
