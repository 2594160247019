import React, { createContext } from 'react';
// import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { CognitoUser } from '@aws-amplify/auth';
import useAuthenticatedUser from '../../hooks/useAuthenticatedUser';

export interface CognitoUserExt extends CognitoUser {
  attributes: Record<string, string>;
  // pool?: CognitoUserPool;
}

export type UserSessionTokenContext = {
  user?: CognitoUserExt | null;
	state: 'Unknown' | 'LoggedIn' | 'NotLoggedIn';
  /** current user id token */
  jwtToken: string | null;
}

export const UserSessionContext = createContext<UserSessionTokenContext>({
  user: null,
  jwtToken: null,
  state: 'Unknown'
});


interface Props {
  children: React.ReactNode;
}

const UserSessionContextProvider = (props: Props): React.JSX.Element => {
  const session = useAuthenticatedUser();

  return (
    <UserSessionContext.Provider value={
      {
        user: session.user,
        state: session.state,
        jwtToken: session.jwtToken
      }
    }>
      {props.children}
    </UserSessionContext.Provider>
  );
};

export default UserSessionContextProvider;
