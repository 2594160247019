import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './WidgetIcon.module.css';
import DynamicIcon from '@components/dynamicIcon/dynamicIcon';
import { WIDGET_DEFAULT_ICON_NAME } from '@common/constants';

type Props = {
  icon?: string;
}

const WidgetIcon = (props: Props) => {
  const { icon } = props;
  return (
    <DynamicIcon
      className={styles.DynamicIcon}
      iconName={icon}
      default={
        <FontAwesomeIcon icon={['fas', WIDGET_DEFAULT_ICON_NAME]} className={styles.DynamicIcon}/>
      }
    />
  );
};

export default WidgetIcon;
