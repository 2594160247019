import React from 'react';
import classNames from 'classnames';
import { SettingFilled } from '@ant-design/icons';
import styles from './GroupHeader.module.css';

type Props = {
  title: string;
  isBrightColor?: boolean;
  headerColor?: string;
  showGearIcon: boolean;
  onOpenCustomSettings?: () => void;
  className?: string;
  titleClassName?: string;
}

const GroupHeader = (props: Props) => {
  const {
    title, headerColor, isBrightColor,
    showGearIcon, onOpenCustomSettings,
    className, titleClassName
  } = props;

  return (
    <div
      className={classNames(styles.GroupHeader, {
        [styles.DarkHeader]: isBrightColor,
        [styles.HasGearIcon]: showGearIcon,
      }, className)}
      style={{
        backgroundColor: headerColor,
      }}
    >
      <div className={classNames(styles.Title, titleClassName)} title={title}>{title}</div>

      {showGearIcon && (
        <div className={styles.GearBtnWrap} onClick={onOpenCustomSettings}>
          <SettingFilled />
        </div>
      )}
    </div>
  );
};

export default GroupHeader;
