import React, { useCallback, useMemo, useState } from 'react';
import { DropDownConfig } from '@kemu-io/kemu-core/types';
import variablesManager from '@kemu-io/kemu-core/common/managers/variablesManager.js';
import { Dropdown, Menu, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { DataType } from '@kemu-io/hs-types';
import FieldLabel from '../FieldLabel/FieldLabel';
import styles from './DropdownField.module.css';
import FormGroup from '@components/form-control/formGroup/formGroup';

type Props = DropDownConfig & {
  label: string;
  variableName: string;
  recipeId: string;
  thingRecipeId: string;
  widgetId: string;
  color?: string;
  disabled?: boolean;
  isSingleElement: boolean;
}
type MenuInfo = {
  key: string;
}

const DropdownField = (props: Props) => {
  const { variableName, recipeId, thingRecipeId, widgetId, disabled, isSingleElement, label } = props;
  const currentVarValue = variablesManager.getThingVariableValue(recipeId, thingRecipeId, variableName, {
    ownerWidgetId: widgetId,
  });

  const [selectedOption, setSelectedOption] = useState<string | undefined>(currentVarValue as string);

  const handleMenuClick = useCallback((event: MenuInfo) => {
    if (event.key) {
      const option = String(event.key);
      setSelectedOption(option);
      variablesManager.setThingVariable(recipeId, thingRecipeId, widgetId, variableName, {
        type: DataType.String,
        value: option,
      });
    }
  }, [recipeId, thingRecipeId, variableName, widgetId]);

  const menu = useMemo(() => (
		<Menu className={styles.DropdownMenu} onClick={handleMenuClick} selectedKeys={selectedOption ? [selectedOption] : []}>
			{/* <Menu.Item key="selected-itm" disabled>{selectedOption}</Menu.Item> */}
			{props.options.map((option) => (
				<Menu.Item key={`${option}`}>
					{option}
				</Menu.Item>
			))}
		</Menu>
	), [props.options, selectedOption, handleMenuClick]);


  // Load the current variable value on mount
  /* useEffect(() => {
    let abort = false;
    const load = async () => {
      const currentVariableValue = await variablesManager.getThingVariableValue(recipeId, thingRecipeId, variableName);
      if (abort) {  return; }
      setSelectedOption(currentVariableValue ? currentVariableValue as string : props.defaultValue);
    };

    load();

    return () => {
      abort = true;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); */

  return (
    <FormGroup column={true} noMarginBottom>
      <FieldLabel color={props.color} label={props.label} />
      <Dropdown
        overlay={menu}
        placement="bottomLeft"
        trigger={['click']}
        disabled={disabled}
        className={classNames({
          [styles.SingleDropDown]: isSingleElement && !label,
        })}
      >
        <div className={styles.DropDownTrigger}>
          <Tooltip title={selectedOption} mouseEnterDelay={0.6}>
            <span className={styles.SelectedOption} >
              {selectedOption}
            </span>
          </Tooltip>
          <DownOutlined
            className={styles.DropIcon}
          />
        </div>
      </Dropdown>
    </FormGroup>
  );
};

export default DropdownField;
