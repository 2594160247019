import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { WebRTCTokenPair } from '../../../types/user';
import * as userApi from '../../../api/user/userApi';
import { AsyncRequestStatus } from '../../../types/core_t';
import { CustomerState } from './userSlice';

export const fetchWebRTCSessionTokenAction = createAsyncThunk('/user/webrtc/token', async (): Promise<WebRTCTokenPair> => {
	const tokens = await userApi.getWebRtcSessionToken();
	return tokens;
});

export const fetchWebRTCSessionTokenReducer = ((builder: ActionReducerMapBuilder<CustomerState>/*, thunk: any*/): void => {

	builder.addCase(fetchWebRTCSessionTokenAction.pending, (state) => {
		state.webRTCSession.asyncState.status = AsyncRequestStatus.loading;
	});

	builder.addCase(fetchWebRTCSessionTokenAction.rejected, (state, action) => {
		state.webRTCSession.asyncState.status = AsyncRequestStatus.error;
		state.webRTCSession.asyncState.error = action.error;
	});

	builder.addCase(fetchWebRTCSessionTokenAction.fulfilled, (state, action: PayloadAction<WebRTCTokenPair>) => {
		state.webRTCSession.asyncState.status = AsyncRequestStatus.completed;
		state.webRTCSession.token = action.payload;
	});
});
