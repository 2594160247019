import { BrowserJsPlumbDefaults }  from '@jsplumb/community/types/dom/browser-jsplumb-instance.js';
import { EndpointOptions }  from '@jsplumb/community/types/core';
import { JsonTypeShape } from '@kemu-io/hs-types';
import { PortLocation } from '../../types/canvas_t';

export type PortSettingsParameters = {
	isTarget: boolean;
	jsonShape?: JsonTypeShape;
}

type Options = {
	connectionClass: string;
}

/** Default connection between nodes */
export const getDefaultSettings = (options: Options):BrowserJsPlumbDefaults => {
	return {
		connector: ['Bezier', { curviness: 80, cssClass: options.connectionClass }],
		// endpoint: ['Dot', { radius: 5 }],
		connectionOverlays: [
			[
				'Arrow', {
					foldback: 0,
					height: 10,
					length: 8,
					location: 1,
					width: 12,
				}
			],
		// 	// [
		// 	// 	'Label',
		// 	// 	{
		// 	// 		location: 0.5,
		// 	// 		id: 'label',
		// 	// 		label: '',
		// 	// 		cssClass: 'conn-label'
		// 	// 	}
		// 	// ],
		],

		// paintStyle: {
		// 	stroke: 'yellow',
		// 	strokeWidth: 5
		// },
		// hoverPaintStyle,
	};
};

/** builds the default settings of a port */
export const getEndpointSetting = (isTarget: boolean, position: PortLocation, radius=6): EndpointOptions => {
	const opts: EndpointOptions = {
		endpoint: ['Dot', { radius }],
		paintStyle: {
			fill: 'none',
		},
		isSource: !isTarget,
		reattach: false,
		connectionsDetachable: true,
		maxConnections: -1,
		isTarget,
		anchor: position,
	};

	return opts;
};
