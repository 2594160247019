import { safeJsonParse, safeJSONStringify } from '../../../common/utils';
import { WidgetsMap } from '../../../types/core_t';

export const KEMU_CLIPBOARD_PREFIX = 'KMU-CB-v1';

let waitFocusCounter = 0;
interface Clipboard {
  writeText(newClipText: string): Promise<void>;
  readText(): Promise<string>;
}
export interface NavigatorWithClipboard {
  // Only available in a secure context.
  readonly clipboard?: Clipboard;
}


const stringEncode = (str: string): string => {
	return encodeURI(str);
};

const stringDecode = (str: string): string => {
	return decodeURI(str);
};


/**
 * Adds the given string to the clipboard as a kemu-item
 * @param widgetsMap a dictionary of widgets to add to the clipboard.
 * Each widget is expected to have their private properties already removed.
 */
export const addToKemuClipboard = (widgets: WidgetsMap): void => {

	const safeString = JSON.stringify(widgets);
	const prefixed = `${KEMU_CLIPBOARD_PREFIX}${safeString}`;
	(navigator as NavigatorWithClipboard)?.clipboard?.writeText(stringEncode(prefixed));

	// TODO: A future implementation could use a custom mime type to store bundle's binary data
	// READ More: https://developer.chrome.com/blog/web-custom-formats-for-the-async-clipboard-api/
	/* const clipItem = new ClipboardItem({
		['web kemu/clipboard']: new Blob([new Uint8Array([1, 2, 3, 4])], { type: 'web kemu/clipboard' }),
	});

	navigator.clipboard.write([clipItem]); */
};

const waitNavigatorToFocus = () => {
	return new Promise((resolve) => setTimeout(resolve, 100));
};

export const clipboardHasValidKemuStructure = async (): Promise<boolean> => {
	try {
		const clipboardText = await getFromKemuClipboard();
		if (clipboardText) {
			const decoded = safeJsonParse(clipboardText);
			waitFocusCounter = 0;
			if (decoded) { return true; }
		}

		waitFocusCounter = 0;
		return false;
	} catch (e) {
		// Navigator not focused
		if (e.code === 0 && e.name === 'NotAllowedError') {
			if (waitFocusCounter++ < 8) {
				await waitNavigatorToFocus();
				return clipboardHasValidKemuStructure();
			}
		} else {
			console.log('Failed to access the clipboard: ', e);
		}
	}

	waitFocusCounter = 0;
	return false;
};

/**
 * Extracts a valid kemu-structure from the clipboard
 * @returns an stringified object of a valid kemu structure or null
 * if the clipboard does not contain a valid structure.
 */
export const getFromKemuClipboard = async (): Promise<string | null> => {
	// TODO: if custom binary data is used, process them in this way:
	/* const clipboardItems = await navigator.clipboard.read();
	for (const clipboardItem of clipboardItems) {
    for (const type of clipboardItem.types) {
      // Discard any types that are not web custom formats.
      if (!type.startsWith('web ')) {
        continue;
      }
      const blob = await clipboardItem.getType(type);
      // Sanitize the blob if you need to, then process it in your app.
    }
  } */

	const clipboardText = await (navigator as NavigatorWithClipboard)?.clipboard?.readText();
	const data = getDecodedClipboardData(clipboardText);
	return data;
};

/**
 * Extracts the data section from a valid string compatible with the kemu clipboard.
 * @param data the string in kemu clipboard format to decode
 * @returns the decoded string or null if the data is not valid.
 */
export const getDecodedClipboardData = (data?: string): string | null => {
	if (data && data.startsWith(KEMU_CLIPBOARD_PREFIX)) {
		// remove prefix before responding
		const jsonStr = data.substr(KEMU_CLIPBOARD_PREFIX.length);
		const decoded = stringDecode(jsonStr);
		return decoded;
	}

	return null;
};

/**
 * Adds the given text to the clipboard without any formatting.
 */
export const copyPlainText = (text: string): void => {
	(navigator as NavigatorWithClipboard)?.clipboard?.writeText(text);
};
