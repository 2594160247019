import React, { useCallback, useEffect, useState } from 'react';
import { Col,  Row } from 'antd';
import { NumberConfig } from '@kemu-io/kemu-core/types';
import styles from './NumberFieldConfig.module.css';
import FormGroup from '@components/form-control/formGroup/formGroup';
import StyledInput from '@components/form-control/styledInput/styledInput';
import StandardModal from '@components/modal/standardModal';
import StyledNumericInput from '@components/form-control/StyledNumericInput/StyledNumericInput';

type Props = {
  open: boolean;
  fieldIndex: number;
  currentConfig: NumberConfig;
  onCloseModal: (fieldIndex: number, newConfig: NumberConfig | null) => void;
}

const NumberFieldConfig = (props: Props) => {
  const { currentConfig, onCloseModal, fieldIndex, open } = props;
  const [config, setConfig] = useState<NumberConfig>(currentConfig);

  const handlePlaceholderChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setConfig((prevConfig) => {
      return {
        ...prevConfig,
        placeholder: value,
      };
    });
  }, []);

  const handleDefaultValueChange = useCallback((value: number) => {
    setConfig((prevConfig) => {
      return {
        ...prevConfig,
        defaultValue: value,
      };
    });
  }, []);

  const handleSaveSettings = useCallback(() => {
    onCloseModal(fieldIndex, config);
  }, [config, fieldIndex, onCloseModal]);

  const handleCloseModal = useCallback(() => {
    onCloseModal(fieldIndex, null);
  }, [fieldIndex, onCloseModal]);

  useEffect(() => {
    setConfig(currentConfig);
  }, [currentConfig]);

  return (
    <StandardModal
      title="Number Field Configuration"
      centered
      visible={open}
      width={310}
      onOk={handleSaveSettings}
      onCancel={handleCloseModal}
      okBtnLabel="Save"
      cancelBtnLabel="Cancel"
    >
      {open && (
        <Row>
          <Col span={24}>
            <FormGroup column={true}>
              <label>Placeholder</label>
              <StyledInput
                value={config?.placeholder}
                onChange={handlePlaceholderChange}
                elementId="placeholder"
              />
            </FormGroup>
            <FormGroup column={true}>
              <label>Default value</label>
              <StyledNumericInput
                value={config?.defaultValue}
                onValueChange={handleDefaultValueChange}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </StandardModal>
  );
};

export default NumberFieldConfig;


