import widgetBundleManager from '@kemu-io/kemu-core/widgetBundle';
import { CustomWidgetDbEntity, CustomWidgetVariant } from '@kemu-io/kemu-types';
import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import widgetCache from './widgetCache';
import { WidgetCollectionState, WidgetCollectionItem } from '@src/app/reducers/widget/widgetSlice';
import { AsyncRequestStatus } from '@src/types/core_t';

/**
 * Compares the given list of widgets with what is stored locally (using id and version number)
 * and downloads any missing content file and automatically adds it to the local cache.
 * @returns a copy of widgets with the contents property guaranteed to contain the actual widget information.
*/
export const processUserWidgetsAction = createAsyncThunk('/widgets/processWidgetsAction', async (
	args: { widgets: CustomWidgetDbEntity[] },
): Promise<CustomWidgetDbEntity[]> => {

	if (!widgetCache.isInitialized()) {
		await widgetCache.initialize();
	}

	const widgetDownloads = args.widgets.map(async (widget) => {
		// Widget Bundles are handled directly by kemu-core since they require
		// a different download and processing logic than regular widgets.
		if (widget.variant === CustomWidgetVariant.Bundle) {
			// UPDATE: 31/Aug/2024: Ignore widget bundles
			/*
			// the downloadWidgetBundle also detects if the widget is already installed (unzipped into cache)
			// and bypasses the download if that is the case.
			await widgetBundleManager.downloadWidgetBundle(widget.id, widget.version, {
				widgetInfo: widget,
				cb: (progress) => {
					console.log(`Downloading widget bundle [${widget.id}] ${progress.percentage}%`);
				}
			});

			return {
				...widget,
			};*/
			return null;
		} else {
			const contents = await widgetCache.getWidget(widget.id, widget.version, widget.contents);
			return {
				...widget,
				contents
			} as CustomWidgetDbEntity;
		}
	});

	const widgets = await Promise.all(widgetDownloads);
	const nonBundles = widgets.filter(Boolean) as CustomWidgetDbEntity[];
	return nonBundles;
});


export const processUserWidgetsReducer = ((builder: ActionReducerMapBuilder<WidgetCollectionState>/*, thunk: any*/): void => {

	builder.addCase(processUserWidgetsAction.pending, (state) => {
		state.asyncIndicator = {
			title: '',
			asyncStatus: {
				status: AsyncRequestStatus.loading,
				error: undefined,
			}
		};
	});

	builder.addCase(processUserWidgetsAction.rejected, (state, action) => {
		state.asyncIndicator = {
			title: 'Error processing: ',
			asyncStatus: {
				status: AsyncRequestStatus.error,
			error: action.error,
			}
		};
	});

	builder.addCase(processUserWidgetsAction.fulfilled, (state, action: PayloadAction<CustomWidgetDbEntity[]>) => {
		state.asyncIndicator = {
			title: '',
			asyncStatus: {
				status: AsyncRequestStatus.completed,
				error: undefined,
			}
		};

		// Clear out download progress
		state.widgetDownloadProgress = {};
		const cleanList: WidgetCollectionItem[] = action.payload.map((widget) => {
			return {
				publicShareId: widget.publicShareId,
				dbId: widget.id,
				description: widget.htmlDescription,
				isDefault: !!widget.isDefault,
				meta: widget.meta,
				name: widget.name,
				protocolVersion: widget.protocolVersion,
				version: widget.version,
				color: widget.color,
				icon: widget.icon,
				contents: widget.contents,
				author: widget.author,
				variant: widget.variant,
			};
		});

		state.customWidgets = cleanList;
	});
});
