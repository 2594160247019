
import React from 'react';
import ConvolutionImageProcessor, { ImageConvolutionState, getDefaultState } from '@kemu-io/kemu-core/widgets/imageConvolution/index.js';
import Icon from '@ant-design/icons';
import { M3x3 } from '@kemu-io/kemu-core/widgets/imageConvolution/convolute.js';
import { WidgetPortContext } from '@kemu-io/kemu-core/types';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import { GateUI, GateUIProps, GetPortsInformationFunction } from '../index';
import GateIcon from '../../gateIcon/gateIcon';
import NumericInput from '../../WidgetsComponents/NumericWithoutArrows/NumericWithoutArrows';
import { ReactComponent as ExtractImageIcon } from './imageConvolution.svg';
import styles from './imageConvolution.module.css';

interface Props extends GateUIProps {
	dataSources?: {
		name: string;
		inputs: string[]
	}[]
}

const mergeStates = (state: ImageConvolutionState) => {
	const fixedState: ImageConvolutionState = {
		...getDefaultState(),
		...state
	};

	return fixedState;
};

const ExtractImage = (props: Props): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<ImageConvolutionState>(props.recipeId, props.thingRecipeId, props.info.id);
	const fixedState = mergeStates(state);

	const onMatrixChange = (value: number, index: number) => {
		const matrix: M3x3 = [...fixedState.matrix];
		matrix[index] = Number(value);

		setState({
			...fixedState,
			matrix
		}, true);
	};


	return (
		<div className={`extractImage-gate standard-body ${styles.GateBody}`}>
			<div className={styles.Row}>
				<div className="gate-input">
					<NumericInput
						value={fixedState.matrix[0] || 0}
						onChange={(v) => onMatrixChange(v, 0)}
					/>
				</div>

				<div className="gate-input">
					<NumericInput
						value={fixedState.matrix[1] || 0}
						onChange={(v) => onMatrixChange(v, 1)}
					/>
				</div>

				<div className="gate-input">
					<NumericInput
						value={fixedState.matrix[2] || 0}
						onChange={(v) => onMatrixChange(v, 2)}
					/>
				</div>
			</div>

			<div className={styles.Row}>
				<div className="gate-input">
					<NumericInput
						value={fixedState.matrix[3] || 0}
						onChange={(v) => onMatrixChange(v, 3)}
					/>
				</div>

				<div className="gate-input">
					<NumericInput
						value={fixedState.matrix[4] || 0}
						onChange={(v) => onMatrixChange(v, 4)}
					/>
				</div>

				<div className="gate-input">
					<NumericInput
						value={fixedState.matrix[5] || 0}
						onChange={(v) => onMatrixChange(v, 5)}
					/>
				</div>
			</div>

			<div className={styles.Row}>
				<div className="gate-input">
					<NumericInput
						value={fixedState.matrix[6] || 0}
						onChange={(v) => onMatrixChange(v, 6)}
					/>
				</div>

				<div className="gate-input">
					<NumericInput
						value={fixedState.matrix[7] || 0}
						onChange={(v) => onMatrixChange(v, 7)}
					/>
				</div>

				<div className="gate-input">
					<NumericInput
						value={fixedState.matrix[8] || 0}
						onChange={(v) => onMatrixChange(v, 8)}
					/>
				</div>
			</div>

		</div>
	);
};


/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={ExtractImageIcon} />}/>
	);
};


const getPortsInformation: GetPortsInformationFunction = (state, widgetInfo) => {
	const portContext: WidgetPortContext = {
		recipePoolId: widgetInfo.recipePoolId,
		recipeType: widgetInfo.recipeType,
		thingRecipeId: widgetInfo.thingRecipeId,
		widgetId: widgetInfo.id,
	};
	const outputNames = ConvolutionImageProcessor.getOutputNames(state, portContext);
	const inputNames = ConvolutionImageProcessor.getInputNames(state, portContext);

	return {
		inputs: [{
			name: inputNames[0].name,
			type: inputNames[0].type,
			position: 'Left'
		}],

		outputs: [{
			name: outputNames[0].name,
			type: outputNames[0].type,
			position: 'Right'
		}]
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: ExtractImage,
	hasTitle: true,
	getWidgetTitle: (intl) => intl.formatMessage({ defaultMessage: 'Convolution Filter', id: 'LogicMaker.Gates.ImageConvolution.Title' }),
} as GateUI;
