
import React, { useEffect } from 'react';
import { Menu, message } from 'antd';
import Icon from '@ant-design/icons';
import classNames from 'classnames';
import IfWidgetProcessor, { IfWidgetState, getDefaultState } from '@kemu-io/kemu-core/widgets/if/index.js';
import { WidgetPortContext } from '@kemu-io/kemu-core/types';
import { GetPortsInformationFunction, GateUI, GateUIProps, PortDescription, GateCustomSettingsProps } from '../index';
import GateIcon from '../../gateIcon/gateIcon';
import WidgetDropdownButton from '../../WidgetDropdownButton/WidgetDropdownButton';
import styles from './ifGate.module.css';
import useReactiveWidgetState from '@common/hooks/useReactiveWidgetState';
import { ReactComponent as ElapsedGateIcon } from '@src/assets/img/gates/if-gate.svg';
import useTranslation from '@hooks/useTranslation';
import { PortLocation } from '@src/types/canvas_t';
import { LastPortInfo } from '@common/utils';
import { SETTINGS_CONTAINER_CLASS } from '@common/constants';
import KemuSwitch from '@components/form-control/kemuSwitch/KemuSwitch';
import { removeWidgetPortConnections } from '@src/app/recipe/utils';

interface Props extends GateUIProps {
	repaint: () => void;
}

const CustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<IfWidgetState>(
		props.recipeId,
		props.blockId,
		props.gateInfo.id
	);

	const fixedState = {
		...getDefaultState(),
		...state
	};

	const t = useTranslation('LogicMaker.Gates.IfGate.Settings');
	const gT = useTranslation('LogicMaker.Gates.Generic');

	const warnIfInputAttached = (info: LastPortInfo | null): boolean => {
		// Prevent adding ports if trigger is linked (otherwise new ports will obtain the connection from trigger)
		if (info && info?.portName === 'evaluation') {
			message.warning({
				content: gT('RemoveWarning', 'Please remove all connections from port "{name}"',
				{ name: info.portName })
			});
			return true;
		}

		return false;
	};

	/* const getLastConnectedInput = () => {
		return getLastInputPortWithParent(
			fixedState,
			IfWidgetProcessor,
			props.gateInfo.id,
			props.blockId,
			props.recipeId,
			props.recipeType
		);
	}; */
	const handleSwitchChange = (checked: boolean) => {
		if (!checked) {
			removeWidgetPortConnections(props.recipeId, props.blockId, props.gateInfo.id, 'data');
		}

		setState({ ...fixedState, useDataPort: checked });
	};

	return (
		<div className={classNames(styles.SettingsContainer, SETTINGS_CONTAINER_CLASS)}>
			{props.children}

			<div className={styles.SwitchContainer}>
				<label>{t('Switch', 'Use data port')}</label>
				<KemuSwitch
					size="small"
					checked={fixedState.useDataPort}
					onChange={handleSwitchChange}
				/>
			</div>
		</div>
	);
};

const IfWidget = (props: Props): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<IfWidgetState>(props.recipeId, props.thingRecipeId, props.info.id);
	const t = useTranslation('LogicMaker.Gates.IfGate');
	const fixedState = {
		...getDefaultState(),
		...state,
	};

	const { repaint } = props;

	const inputChangedCb = (evt: React.FormEvent<HTMLInputElement>) => {
		const value = evt.currentTarget.value;
		setState({
			...fixedState,
			value: value
		}, true);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	function handleMenuClick (e: any) {
		setState({
			...fixedState,
			condition: e.key
		});
	}

	// re-calculate ports position since the width of the element changes
	// when new option is selected
	useEffect(() => {
		if (state.condition) {
			repaint();
		}

	}, [repaint, state]);

	const menu = (
		<Menu onClick={handleMenuClick}>
			<Menu.Item key="Equal">
				{t('Equal', 'Equal')}
			</Menu.Item>
			<Menu.Item key="Not Equal">
				{t('NotEqual', 'Not Equal')}
			</Menu.Item>
			<Menu.Item key="Greater">
				{t('GreaterThan', 'Greater Than')}
			</Menu.Item>
			<Menu.Item key="Less">
				{t('LessThan', 'Less Than')}
			</Menu.Item>
			<Menu.Item key="Starts With">
				{t('Starts With', 'Starts With')}
			</Menu.Item>
			<Menu.Item key="Ends With">
				{t('Ends With', 'Ends With')}
			</Menu.Item>
			<Menu.Item key="Includes">
				{t('Includes', 'Includes')}
			</Menu.Item>
			<Menu.Item key="Matches">
				{t('Matches', 'Matches')}
			</Menu.Item>
		</Menu>
	);

	return (
		<div className={styles.GateBody}>
			<div className="condition-selector">
				<WidgetDropdownButton
					widgetType="condition"
					disabled={props.info.disabled}
					name={fixedState.condition}
					menu={menu}
					wrapperClassName={classNames({ 'is-disabled': props.info.disabled }, styles.DropDown)}
				/>
			</div>

			<input
				onChange={inputChangedCb}
				value={fixedState.value}
				className={styles.Input}
			/>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={ElapsedGateIcon} />}/>
	);
};


const getPortsInformation: GetPortsInformationFunction = (state, widgetInfo) => {
	const portContext: WidgetPortContext = {
		recipePoolId: widgetInfo.recipePoolId,
		recipeType: widgetInfo.recipeType,
		thingRecipeId: widgetInfo.thingRecipeId,
		widgetId: widgetInfo.id,
	};

	const outputNames = IfWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = IfWidgetProcessor.getInputNames(state, portContext);

	const inputPositions: Record<string, PortLocation> = {
		'data': [0, 0.7, -1, 0],
		'evaluation': [0, 0.3, -1, 0],
	};

	const outputPositions: Record<string, PortLocation> = {
		'then': [1, 0.33, 1, 0],
		'else': [1, 0.66, 1, 0]
	};

	let inputs: PortDescription[] = [{
		name: inputNames[0].name,
		type: inputNames[0].type,
		position: 'Left'
	}];

	const outputs = outputNames.map((output) => ({
		name: output.name,
		type: output.type,
		position: outputPositions[output.name]
	}));

	if (state.useDataPort) {
		inputs = inputNames.map((input) => ({
			name: input.name,
			type: input.type,
			position: inputPositions[input.name]
		}));
	}

	return {
		inputs,
		outputs,

	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: IfWidget,
	hasTitle: true,
	CustomSettingsDialog: CustomSettings,
	getWidgetTitle: (intl) => intl.formatMessage({ defaultMessage: 'Conditional', id: 'LogicMaker.Gates.IfGate.Title' }),
} as GateUI;
