import React, { useCallback } from 'react';
import classNames from 'classnames';
import styles from './StyledNumericInput.module.css';
import NumericWithoutArrows from '@components/WidgetsComponents/NumericWithoutArrows/NumericWithoutArrows';

type Props = {
  value?: number;
  defaultValue?: number;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  elementId?: string | number;
  onValueChange?: (value: number, elementId?: string | number) => void;
  inputClassName?: string;
}

/**
 * A Kemu-styled numeric input without arrows
 */
const StyledNumericInput = (props: Props) => {
  const {
    value, onValueChange, disabled, elementId,
    className, defaultValue, placeholder, inputClassName
  } = props;

  const handleValueChange = useCallback((value: number) => {
    onValueChange && onValueChange(value, elementId);
  }, [onValueChange, elementId]);

  return (
    <NumericWithoutArrows
      containerClass={classNames(className, styles.NumberInput)}
      className={inputClassName}
      value={value}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={handleValueChange}
      placeholder={placeholder}
    />
  );
};

export default StyledNumericInput;
