import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import widgetBundleManager from '@kemu-io/kemu-core/widgetBundle';
import kemuCore from '@kemu-io/kemu-core';
import { findThingInRecipe } from '@kemu-io/kemu-core/common/recipeCache';
import { CustomWidgetState, RecipeWidget, WidgetBundleState, WidgetType } from '@kemu-io/kemu-core/types';
import { removePrivateProperties } from '@kemu-io/kemu-core/common/utils';
import { LogicMapperState, showCreateWidgetBundleModalAction } from '../logicMapperSlice';
import { addBundleToRecipeStorage } from './common';
import * as recipeActions from '@src/app/recipe/utils';
import { RootState } from '@src/app/store';
import { StatelessWidgetsMap } from '@src/types/core_t';
import { widgetMapToStatelessMap } from '@common/utils';

type AddWidgetBundleFromZipPayload = {
  /** the raw bundle file contents. This is assumed to be a valid bundle file */
  zipFile: Uint8Array;
  // zipHandler: ValidatedBundle['zipHandler'];
  /** the id of the recipe in the pool */
  recipeId: string;
  /** the id of the thing in the recipe the widget will be added to */
  thingId: string;
  /** the id of the parent widget (if any) */
  parentGroup?: string;
  name: string;
  description: string;
  color: string;
  icon: string;
}

export const addWidgetBundleFromZipAction = createAsyncThunk(
  'LogicMapper/addWidgetBundleFromZip',  async (
    payload: AddWidgetBundleFromZipPayload,
    thunkApi
) : Promise<StatelessWidgetsMap> => {

  const { logicMapper } = thunkApi.getState() as RootState;
  const targetPosition = logicMapper.lastMouseClick;

  // First we need to create a new empty widget bundle in the thing.
  const targetThing = findThingInRecipe(payload.recipeId, payload.thingId);
  if (!targetThing) {
    throw new Error(`Could not find thing with id ${payload.thingId}`);
  }

  // Create a new widget id to be added to the thing
  const newWidgetId = recipeActions.generateUniqueWidgetId(targetThing);
  const newWidgetVersion = 1;

  // now we install the zip file into a tmp location in cache
  const processedBundle = await widgetBundleManager.installBundleInTempCache(
    payload.zipFile,
    newWidgetId,
    newWidgetVersion,
  );

  if (!processedBundle) {
    throw new Error('Failed to process bundle file');
  }

  if (!processedBundle.storedState) {
    throw new Error('The given bundle file does not contain a valid state file');
  }

  // Create a dummy initial state since it will be replaced by the one in the bundle
	const widgetState: CustomWidgetState<WidgetBundleState> = {
    ...processedBundle.storedState,
    description: payload.description,
    name: payload.name,
    inputs: processedBundle.storedState.inputs || [],
    outputs: processedBundle.storedState.outputs || [],
    color: payload.color,
    // icon: processedBundle.iconFile,
    $$processor: processedBundle.processor,
    $$cacheInfo: {
      version: newWidgetVersion,
      widgetThingId: newWidgetId,
    }
  };

  // Update the stored state with the name and description
  const stateWithoutPrivateProps = removePrivateProperties<WidgetBundleState>(widgetState);
  const createTmpLocation = true;
  await widgetBundleManager.updateStoredBundleState(
    newWidgetId,
    newWidgetVersion,
    {
      ...stateWithoutPrivateProps,
    },
    createTmpLocation,
  );

  const newBundleWidget: RecipeWidget = {
    id: newWidgetId,
    canvas: {
      position: targetPosition,
    },
    children: [],
    state: widgetState,
    groupId: payload.parentGroup,
    type: WidgetType.widgetBundle,
  };


  targetThing.gates[newWidgetId] = newBundleWidget;
  // Run the bundle's init routine
  await kemuCore.initializeWidget(payload.recipeId, payload.thingId, newWidgetId);

  const updatedWidgets = widgetMapToStatelessMap(targetThing.gates);
  // Hide the modal
  thunkApi.dispatch(showCreateWidgetBundleModalAction(false));



  return updatedWidgets;
});

export const addWidgetBundleFromZipReducer = ((builder: ActionReducerMapBuilder<LogicMapperState>): void => {
  builder.addCase(addWidgetBundleFromZipAction.fulfilled, (state, action) => {
    state.gates = action.payload;
  });

  builder.addCase(addWidgetBundleFromZipAction.rejected, (_draft, action) => {
    console.error('Failed to add widget from bundle: ', action.error);
  });
});
