import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { CustomWidgetDbEntity, CustomWidgetVariant } from '@kemu-io/kemu-types';
import widgetBundleManager from '@kemu-io/kemu-core/widgetBundle';
import widgetCache from './widgetCache';
import { WidgetCollectionState } from '@src/app/reducers/widget/widgetSlice';
import * as widgetApi from '@src/api/widget/widgetApi';
import { AsyncRequestStatus } from '@src/types/core_t';
import Logger from '@common/logger';

const logger = Logger('importWidgetReducer');

/**
 * Adds a public widget to the user's collection.
*/
export const importWidgetAction = createAsyncThunk('/widgets/importWidgetAction', async (
	args: { publicId: string },
): Promise<CustomWidgetDbEntity> => {

	// Initialize the widgets cache;
	if (!widgetCache.isInitialized()) {
		await widgetCache.initialize();
	}

	const importedWidget = await widgetApi.importPublicWidget(args.publicId);

	if (importedWidget.variant === CustomWidgetVariant.Bundle) {
		await widgetBundleManager.downloadWidgetBundle(importedWidget.id, importedWidget.version, {
			widgetInfo: importedWidget,
			cb: (progress) => {
				logger.log(`Downloading widget bundle [${importedWidget.id}] ${progress.percentage}%`);
			}
		});

		return {
			...importedWidget,
		};
	} else {
		// the `getWidget` checks the local cache and uses the pre-signed download url in `contents` if not found.
		// because this widget is new, it should ALWAYS download and cache the new widget contents.
		const widgetContents = await widgetCache.getWidget(importedWidget.id, importedWidget.version, importedWidget.contents);

		return {
			...importedWidget,
			contents: widgetContents,
		};
	}
});


export const importWidgetReducer = ((builder: ActionReducerMapBuilder<WidgetCollectionState>): void => {

	builder.addCase(importWidgetAction.pending, (state) => {
		state.asyncIndicator = {
			title: '',
			asyncStatus: {
				status: AsyncRequestStatus.loading,
				error: undefined,
			}
		};
	});

	builder.addCase(importWidgetAction.rejected, (state, action) => {
		state.asyncIndicator = {
			...state.asyncIndicator,
			asyncStatus: {
				status: AsyncRequestStatus.error,
				error: action.error,
			}
		};
	});

	builder.addCase(importWidgetAction.fulfilled, (state/* , action */) => {
		state.asyncIndicator = {
			...state.asyncIndicator,
			asyncStatus: {
				status: AsyncRequestStatus.completed,
				error: undefined,
			}
		};

		// no need to add it to the custom collection because the user does not 
		// have access to the app from the import screen, otherwise...
		// state.customWidgets = [
		// 	...state.customWidgets,
		// 	dbEntityToCollection(action.payload.contents, action.payload)
		// ];

	});
});

