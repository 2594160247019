import { str2ab } from '@kemu-io/kemu-core/common/utils';
import { CustomWidgetState, GroupWidgetPort, WidgetGroupState , WidgetBundlePort } from '@kemu-io/kemu-core/types';
import {
	BaseWidgetInfo,
	CustomWidgetPortInfo,
	CustomWidgetVariant,
	WidgetMetaInfo
} from '@kemu-io/kemu-types';
import { ArrayBuffer as MD5ArrayBuffer } from 'spark-md5';
import { getCustomWidgetInnerWidgets } from '../../recipe/utils';
import { WidgetCollectionItem } from './widgetSlice';
import { dataTypeToHumanReadable } from '@common/utils';
import { ContentLessEntity, ParsedWidgetContents } from '@src/types/widgets';
import { WidgetsMap } from '@src/types/core_t';
import { GlobalTranslateFunction } from '@src/assets/i18n/globalIntl';

/**
 * Transforms a map of widgets into a map of ParsedWidgetsContents, marking
 * only the widget that matches the given containerId as 'isContainer'.
 */
const mapToParsedContents = (widgets: WidgetsMap, containerId: string): ParsedWidgetContents => {
	const result: ParsedWidgetContents = {};
	// We need to mark the widget that contains the rest so we can find it later
	Object.keys(widgets).forEach(mapKey => {
		// Mark the actual widget as a container
		result[mapKey] = {
			...widgets[mapKey],
			isContainer: mapKey === containerId,
		};
	});

	return result;
};

/**
 * Transforms a widget entity into a local collection item
 */
const dbEntityToCollection = (
	contents: string,
	widgetEntity: ContentLessEntity
): WidgetCollectionItem => {
	return  {
		contents,
		dbId: widgetEntity.id,
		description: widgetEntity.htmlDescription,
		isDefault: !!widgetEntity.isDefault,
		meta: widgetEntity.meta,
		name: widgetEntity.name,
		protocolVersion: widgetEntity.protocolVersion,
		version: widgetEntity.version,
		color: widgetEntity.color,
		icon: widgetEntity.icon,
		author: widgetEntity.author,
		variant: widgetEntity.variant,
	};
};

/**
 * Transforms a list of ports from a widget state into a CustomWidgetPortInfo
 * to be consumed by a widget service.
 */
const widgetStatePortToPortInfo = (ports: GroupWidgetPort[], translateFunction: GlobalTranslateFunction): CustomWidgetPortInfo[] => {
	const widgetPorts = ports.map(port => ({
		dataType: port.type.map(t => dataTypeToHumanReadable(translateFunction, t)),
		name: port.label || port.name
	} as CustomWidgetPortInfo));

	return widgetPorts;
};


/**
 * Transforms a list of ports from a widget state into a CustomWidgetPortInfo
 * to be consumed by a widget service.
 */
const widgetBundleStatePortToPortInfo = (ports: WidgetBundlePort[], translateFn: GlobalTranslateFunction): CustomWidgetPortInfo[] => {
	const widgetPorts = ports.map(port => ({
		dataType: port.dataType.map(t => dataTypeToHumanReadable(translateFn, t)),
		name: port.name
	} as CustomWidgetPortInfo));

	return widgetPorts;
};


type BuildWidgetRequestBodyObject = {
	/* the base object to be used as request body */
	requestObject: BaseWidgetInfo;
	/** the raw widget contents */
	contentsStr: string;
	/** the raw contents in array buffer format */
	contentsBuf: ArrayBuffer;
	/** A copy of the widget state. Changing this object has no effects over the state of the widget in the recipe */
	widgetState: CustomWidgetState<WidgetGroupState>;
}

/**
 * Builds an object with all the basic information required for a save/update widget request
 * @param widgetId the id of the widget in the recipe 
 * @param thingId the id of the thing (block) the widget belongs to
 * @param recipeId the id of the recipe in the pool
 * @param translateFunction an instance of useTranslation
 * @param replaceName a new name that will replace the name in the entity and the state in the contents. 
 * IMPORTANT: The change to the state property 'name' is done in the copy of the widget, hence the current
 * recipe will preserve the previous name.
 */
const buildWidgetRequestBody = (
	widgetId: string,
	thingId: string,
	recipeId: string,
	translateFunction: GlobalTranslateFunction,
	variant: CustomWidgetVariant,
	replaceName?: string,
): BuildWidgetRequestBodyObject => {
	// Get widget info from recipe
	const allWidgets = getCustomWidgetInnerWidgets(widgetId, thingId, recipeId) as ParsedWidgetContents;

	// We need to mark the widget that contains the rest so we can find it later
	const parsedWidgets = mapToParsedContents(allWidgets, widgetId);
	const groupWidgetState = parsedWidgets[widgetId].state as CustomWidgetState<WidgetGroupState>;

	if (replaceName) {
		groupWidgetState.name = replaceName;
	}

	const contentsStr = JSON.stringify(parsedWidgets);
	const contentsBuf = str2ab(contentsStr);
	const widgetMeta: WidgetMetaInfo = {
		contentsLength: contentsStr.length,
		// NOTE: currently, this will ALWAYS change due to the widget `state.version` property which
		// changes on every save. Currently we depend on the content having an accurate representation
		// of the widget state (which includes its version), thus making contentsChecksum unnecessary at this point.
		// Leaving it here as the backend implementation requires it, and since it could be useful in the future
		// to validate data integrity.
		contentsChecksum: MD5ArrayBuffer.hash(contentsBuf)
	};


	const widgetInputs = widgetStatePortToPortInfo(groupWidgetState.inputs, translateFunction);
	const widgetOutputs = widgetStatePortToPortInfo(groupWidgetState.outputs, translateFunction);

	const widgetInfo: BaseWidgetInfo = {
		name: replaceName || groupWidgetState.name,
		meta: widgetMeta,
		htmlDescription: groupWidgetState.description,
		widgets: Object.values(parsedWidgets).map(widget => ({ type: widget.type as string })),
		inputs: widgetInputs,
		outputs: widgetOutputs,
		color: groupWidgetState.color,
		icon: groupWidgetState.icon,
		protocolVersion: groupWidgetState.protocolVersion,
		variant,
	};

	return {
		requestObject: widgetInfo,
		widgetState: groupWidgetState,
		contentsBuf,
		contentsStr
	};
};


export {
	mapToParsedContents,
	dbEntityToCollection,
	widgetStatePortToPortInfo,
	buildWidgetRequestBody,
	widgetBundleStatePortToPortInfo
};
