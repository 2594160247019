import React, { useCallback, useRef, useState, memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StandardModal from '../modal/standardModal';
import StyledInput from '../form-control/styledInput/styledInput';
import { selectSaveAsModalVisible, showSaveAsDialog } from '../../features/interface/interfaceSlice';
import { storeRecipeAction } from '../../features/interface/reducers/storeRecipeReducer';
import { selectCurrentRecipe } from '../../features/Workspace/workspaceSlice';


const SaveRecipeModal = (): React.JSX.Element => {
	const intl = useIntl();
	const recipeInfo = useSelector(selectCurrentRecipe);
	const inputRef = useRef<HTMLInputElement>(null);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [recipeName, setRecipeName] = useState(recipeInfo.name);
	const showSaveDialog = useSelector(selectSaveAsModalVisible);

	const handleSave = useCallback((name: string) => {
		dispatch(showSaveAsDialog(false));
		if (recipeInfo.poolId) {
			dispatch(storeRecipeAction({
				recipePoolId: recipeInfo.poolId,
				newName: name,
				navigate,
			}));
		}
	}, [dispatch, recipeInfo, navigate]);

	// Clear out the error message when they type again
	const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const text = evt.currentTarget.value;
		setRecipeName(text);
		setErrorMessage(() => null);
	};

	const handleOk = useCallback(() => {
		let name = inputRef.current?.value || '';
		name = name.trim();
		if (name === '') { return setErrorMessage(intl.formatMessage({ id: 'Recipe.Modal.InvalidName' })); }
		setErrorMessage(null);
		handleSave(name);
	}, [intl, handleSave]);


	const handleCancel = useCallback(() => {
		dispatch(showSaveAsDialog(false));
	}, [dispatch]);


	return (
		<StandardModal
			closeOnMaskClick={false}
			onOk={handleOk}
			onCancel={handleCancel}
			okBtnLabel={intl.formatMessage({ id: 'Recipe.Modal.SaveBtn' })}
			cancelBtnLabel={intl.formatMessage({ id: 'Recipe.Modal.CancelBtn' })}
			title={intl.formatMessage({ id: 'Recipe.Modal.Title' })}
			visible={showSaveDialog}
			width={300}
		>
			<StyledInput onChange={handleInputChange} value={recipeName} ref={inputRef} errorMessage={errorMessage} label={<FormattedMessage id="Recipe.Modal.InputLabel" defaultMessage="Name" />} i18nPlaceholderId="Recipe.Modal.InputPlaceholder" i18nPlaceholderDefaultMessage="Enter a name for your recipe" />

		</StandardModal>
	);
};

export default memo(SaveRecipeModal);
