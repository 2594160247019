import React, { ReactElement, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Auth } from '@aws-amplify/auth';
import { Row, Col, Alert, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import FormGroup from '../../../components/form-control/formGroup/formGroup';
import AuthCard from '../AuthCard/AuthCard';
import StyledButton from '../../../components/form-control/styledButton/styledButton';
import StyledInput from '../../../components/form-control/styledInput/styledInput';
import routes from '../../../common/routes/index';
import styles from './confirm.module.css';

type Props = {
	destination: string;
	username: string;
	password: string;
	userId: string;
}

type CodeSentState = 'success' | 'error' | 'verified';

function ConfirmCode (props: Props): ReactElement {
	const codeRef = useRef<HTMLInputElement>(null);
	const [errorMsg, setErrorMsg] = useState<string | null>(null);
	const [codeSentState, setCodeSentState] = useState<CodeSentState | null>(null);
	const [processing, setProcessing] = useState(false);
	const navigate = useNavigate();
	const intl = useIntl();

	const resendConfirmationCode = async () => {
		setProcessing(true);
		// Clear previous confirmation messages
		setCodeSentState(null);
		setErrorMsg(null);
    try {
      const r = await Auth.resendSignUp(props.username);
			console.log('code resent successfully: ', r);
			setCodeSentState('success');
    } catch (err) {
			console.log('error resending code: ', err);
			setCodeSentState('error');
		}

		setProcessing(false);
	};


	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		if (codeRef.current) {
			const value = codeRef.current.value.trim();
			if (!value) { return setErrorMsg(intl.formatMessage({ id: 'Screen.Confirm.RequiredField', defaultMessage: '* required' })); }

			// Clear previous confirmation messages
			setErrorMsg(null);
			setCodeSentState(null);
			setProcessing(true);
			try {
				const confirmation = await Auth.confirmSignUp(props.username, value);

				if (confirmation === 'SUCCESS') {
					// TODO: Auto sign in
					const res = await Auth.signIn(props.username, props.password);
					console.log('Auto sign in: ', res);
					navigate(routes.getBaseRoute());
				} else {
					console.log('Confirmation results: ', confirmation);
				}
			} catch (e) {
				console.log('Error: ', e);
				if (e.code === 'ExpiredCodeException') {
					setErrorMsg(intl.formatMessage({ id: 'Screen.Confirm.ExpiredCodeException', defaultMessage: 'Invalid code provided, please request a code again' }));
				} else {
					setErrorMsg(e.message);
				}
			}

			setProcessing(false);
		}
	};

	return (
		<AuthCard title={<FormattedMessage id="Screen.Confirm.Title" defaultMessage="Verify email" />} >
			<Spin indicator={<LoadingOutlined className={styles.LoadingIcon}/>} spinning={processing}>
				{codeSentState && (
					<>
						{codeSentState === 'success' ? (
							<Alert closable={true} message={<FormattedMessage id="Screen.Confirm.ResendSuccess" defaultMessage="A new confirmation code was sent successfully" />} type="success" />
						) : codeSentState == 'verified' ? (
							<Alert closable={true} message={<FormattedMessage id="Screen.Confirm.RegistrationSuccessful" defaultMessage="Account successfully verified" />} type="success" />
							// <Redirect to='/auth/login' />
						): (
							<Alert closable={true} message={<FormattedMessage id="Screen.Confirm.ResendFail" defaultMessage="There was an error sending a new confirmation code" />} type="error" />
						)}
						<br/>
					</>
				)}

				<p><FormattedMessage id="Screen.Confirm.Description" values={{ email: props.destination }} defaultMessage="An email with a verification code was just sent to {email}" /></p>
				<form method="POST" onSubmit={handleSubmit}>

					<Row >
						<Col span={24}>
							<FormGroup>
								<StyledInput i18nPlaceholderId="Screen.Confirm.Placeholder" i18nPlaceholderDefaultMessage="Enter code" ref={codeRef} errorMessage={errorMsg} />
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<FormGroup className={styles.Centered}>
								<a className={styles.TryAgain} onClick={resendConfirmationCode}>
									<FormattedMessage id="Screen.Confirm.TryAgain" defaultMessage="Send again"/>
								</a>

								<StyledButton className={styles.Button} title={<FormattedMessage id="Screen.Confirm.SubmitButton" defaultMessage="Confirm" />} type="submit" />
							</FormGroup>
						</Col>
					</Row>
				</form>
			</Spin>
		</AuthCard>
	);
}

export default ConfirmCode;
