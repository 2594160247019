const getAuthBaseRoute = (): string => `/auth`;
const getAccountSelectionRoute = (): string => `${getAuthBaseRoute()}/account/details`;
const getAccountSelectionWithUserRoute = (userId: string): string => `${getAuthBaseRoute()}/account/details?sub=${userId}`;
const getForgotPasswordRoute = (): string => `${getAuthBaseRoute()}/password/forgot`;
// const getPasswordResetRoute = (): string => `${getAuthBaseRoute()}/password/reset`;
const getSignInRoute = (): string => `${getAuthBaseRoute()}/login`;
const getSignUpRoute = (): string => `${getAuthBaseRoute()}/signUp`;

/** terms and conditions route */
const getTermsRoute = (): string => `https://support.kemu.io/support/solutions/articles/51000298264-terms-and-conditions`;
/** Privacy policy route */
const getPrivacyPolicyRoute = (): string => `https://support.kemu.io/support/solutions/articles/51000298266-privacy-policy`;

export default {
	getAccountSelectionRoute,
  getAccountSelectionWithUserRoute,
  getForgotPasswordRoute,
  // getPasswordResetRoute,
  getSignInRoute,
  getSignUpRoute,
  getAuthBaseRoute,
  getTermsRoute,
  getPrivacyPolicyRoute
};
