import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip, Popover, Button } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import styles from './LogicMapperSettings.module.css';
import useTranslation from '@hooks/useTranslation';
import {
  LogicMapperSettings,
  selectLogicMapperSettings,
  setLogicMapperSettingValue
} from '@src/features/interface/interfaceSlice.ts';
import KemuSwitch from '@components/form-control/kemuSwitch/KemuSwitch';

type SettingType = 'WidgetInfoPanel';

type Props = {
  hasItems?: boolean;
}

const LogicMapperSettingsPanel = (props: Props) => {
  const { hasItems } = props;
  const t = useTranslation('LogicMapper.Settings');
  const userSettings = useSelector(selectLogicMapperSettings);
  const dispatch = useDispatch();
  const [popOverVisible, setPopOverVisible] = useState(false);

  const toggleSetting = (checked: boolean, event: React.MouseEvent, settingKey: keyof LogicMapperSettings) => {
    if (settingKey) {
      dispatch(setLogicMapperSettingValue({
        key: settingKey,
        value: checked,
      }));
    }
  };

  const content = (
    <div className={styles.ContentBody}>
      <div className={styles.Row}>
        <label>{t('PortDebugPanel', 'Port Debugging Panel')}</label>
        <KemuSwitch
          data-kemu-meta="logic-mapper-setting::toggle-show-port-info-panel"
          data="debugPanelVisible"
          size='default'
          checked={userSettings.debugPanelVisible}
          onChange={toggleSetting}
        />
      </div>

      <div className={styles.Row}>
        <label>{t('AnimateConnectionEvent', 'Show Event Animation')}</label>
        <KemuSwitch
          data-kemu-meta="logic-mapper-setting::toggle-show-connection-animation"
          data="showConnectionAnimations"
          size='default'
          checked={userSettings.showConnectionAnimations}
          onChange={toggleSetting}
        />
      </div>
    </div>
  );

  return (
    <div className={classNames(styles.ButtonWrapper, hasItems && styles.HasBreadcrumb)}>
      <Tooltip title={t('Tooltip', 'Settings')} mouseEnterDelay={0.5}>
        <Popover
          onOpenChange={setPopOverVisible}
          content={content}
          title={<b>{t('Title', 'Settings')}</b>}
          trigger="click"
          placement='bottom'
        >
          <Button
            type="link"
            className={styles.Button}
            data-setting-type="settings"
            shape="circle"
            icon={<SettingOutlined />}
          />
        </Popover>
      </Tooltip>
    </div>
  );
};

export default LogicMapperSettingsPanel;
