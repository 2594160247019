import {
  TextConfig,
  WidgetGroupState,
  CustomWidgetState,
  RecipeWidget,
  WidgetType
} from '@kemu-io/kemu-core/types';
import { variablesManager } from '@kemu-io/kemu-core/common';
import { getDataTypeFromWidgetFieldType } from './common';

/**
 * Loop though the widget's settings fields and re-define the variables (if non-exist)
 * @param recipeId the id of the recipe in the pool
 * @param thingId the id of the thing in the recipe
 * @param widget the widget to define the variables for
 */
const defineGroupFieldVariables = (
  recipeId: string,
  thingId: string,
  widget: RecipeWidget
) => {
  // For widget groups, we need to loop though its settings fields
  // and re-define the variables (if non-exist)
  if (widget.type === WidgetType.widgetGroup) {
    const groupState = widget.state as CustomWidgetState<WidgetGroupState>;
    const fields = groupState.settings || [];
    // const existingVars = variablesManager.getVariableNames(recipeId, thingId);
    for (const field of fields) {
      if (field.variableName) {
        const fieldConfig = field.config as TextConfig | undefined;
        const dataType = getDataTypeFromWidgetFieldType(field.type);
        variablesManager.defineThingVariable(
          recipeId,
          thingId,
          widget.id,
          field.variableName,
          dataType,
          {
            ...(fieldConfig?.defaultValue !== undefined ? { defaultValue: fieldConfig.defaultValue } : {}),
            isCustomFieldVar: true,
            storeValueInRecipe: field.private,
          }
        );
        // const matchingVar = existingVars.find((thingVar) => thingVar.name === field.variableName);
        // if (!matchingVar) {
        // }
      }
    }
  }
};

export {
  defineGroupFieldVariables,
};
