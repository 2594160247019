import React, { useCallback, useState } from 'react';
import { NumberConfig } from '@kemu-io/kemu-core/types';
import variablesManager from '@kemu-io/kemu-core/common/managers/variablesManager.js';
import classNames from 'classnames';
import { DataType } from '@kemu-io/hs-types';
import FieldLabel from '../FieldLabel/FieldLabel';
import styles from './NumericField.module.css';
import StyledNumericInput from '@components/form-control/StyledNumericInput/StyledNumericInput';
import FormGroup from '@components/form-control/formGroup/formGroup';
import { NO_GLOBAL_RULES_CLASS } from '@common/constants';

type Props = NumberConfig & {
  label: string;
  variableName: string;
  recipeId: string;
  thingRecipeId: string;
  widgetId: string;
  color?: string;
  /** indicates this is the only field in the widget */
  isSingleElement: boolean;
}

const NumericField = (props: Props) => {
  const { variableName, recipeId, thingRecipeId, widgetId, label, isSingleElement } = props;
  const currentVarValue = variablesManager.getThingVariableValue(recipeId, thingRecipeId, variableName, {
    ownerWidgetId: widgetId,
  });

  const [numberValue, setNumberValue] = useState<number>(currentVarValue as number || 0);

  const handleValueChange = useCallback((value: number) => {
    setNumberValue(() => {
      variablesManager.setThingVariable(recipeId, thingRecipeId, widgetId, variableName, {
        type: DataType.Number,
        value
      });

      return value;
    });
  }, [recipeId, thingRecipeId, variableName, widgetId]);

  return (
    <FormGroup column={true} noMarginBottom>
      <FieldLabel color={props.color} label={label} />
      <StyledNumericInput
        value={numberValue}
        placeholder={props.placeholder}
        onValueChange={handleValueChange}
        inputClassName={NO_GLOBAL_RULES_CLASS}
        className= {classNames({
          [styles.SingleElement]: isSingleElement && !label
        })}
      />
    </FormGroup>
  );
};

export default NumericField;
