/*
 * Written by Alexander Agudelo < alex.agudelo@kitsunei.com >, 2020
 * Date: 11/Dec/2020
 * Last Modified: 31/05/2024, 4:20:39 pm
 * Modified By: Alexander Agudelo
 * Description:  Shows a loading animation when a recipe is being saved
 * 
 * ------
 * Copyright (C) 2020 Kitsunei - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */

import React, { ReactNode, useEffect } from 'react';
import { message, Progress } from 'antd';
import styles from './streamOperationIndicator.module.css';


const getContent = (msg: string, progress?: number): React.ReactNode => {
	return (
		<div className={styles.Container}>
			<label>{msg}</label>
			<Progress percent={progress || 0} />
		</div>
	);
};

interface Props {
	message: string;
	percent: number;
	type: 'progress' | 'success' | 'error';
	errorReason?: string;
}

const StreamOperationIndicator = (props: Props): React.JSX.Element => {
	const msgKey = 'streamInProgress';
	const { type, message: text, percent, errorReason } = props;

	useEffect(() => {
		let messageConfig = {
			duration: 0,
			icon: <></> as ReactNode,
			key: msgKey,
			content: getContent(text, percent)
		};

		let showMessage = message.info;
		if (type === 'error') {
			messageConfig = {
				...messageConfig,
				duration: 5,
				icon: undefined,
				content: `${text}${ errorReason ? `: ${errorReason}` : ''}`
			};

			showMessage = message.error;
		} else if (type === 'success') {
			messageConfig.duration = 3;
			showMessage = message.success;
		}

		showMessage({ ...messageConfig });
	}, [text, percent, type, errorReason]);


	return (<></>);
};

export default StreamOperationIndicator;
