import { Position , WidgetType } from '@kemu-io/kemu-core/types';
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { satisfies } from 'compare-versions';
import { LogicMapperState } from '../logicMapperSlice';
import { defineGroupFieldVariables } from './widgetGroupHelpers';
import { WidgetCollectionItem } from '@src/app/reducers/widget/widgetSlice';
import { StatelessRecipeWidget, StatelessWidgetsMap } from '@src/types/core_t';
import * as RecipeActions from '@src/app/recipe/utils';
import { safeJsonClone } from '@common/utils';

type AddWidgetFromTemplateProps = {
  widget: WidgetCollectionItem,
  recipeId: string,
  thingId: string,
  currentGroupId?: string,
  position?: Position,
}

/**
 * Adds custom widgets and widget bundles to the current recipe
 * from a template. 
 * 
 * NOTE: For bundles, this handles widgets already installed, not
 * newly created widgets, for that checkout `addWidgetBundleFromZip`.
 */
const addWidgetFromTemplateAction = createAsyncThunk(
  '/LogicMapper/addWidgetFromTemplate', async (
  payload: AddWidgetFromTemplateProps
) : Promise<StatelessWidgetsMap> => {

  const isV2 = satisfies(payload.widget.protocolVersion || '0', '>=2.0');

	const { addedWidgets, thingWidgets } = await RecipeActions.addCustomWidgetFromTemplate(
		payload.widget,
		payload.recipeId,
		payload.thingId,
		payload.position,
		payload.currentGroupId,
    {
      // Remove inner connections for old widgets.
      removeConnections: !isV2
    }
	);

  // Create a stateless dictionary of the widgets to store in redux
	const stateLessWidgets: StatelessWidgetsMap = {};

	Object.values(thingWidgets).map((widget) => {
    // Before the state is removed, we need to define the group field variables
    if (widget.type === WidgetType.widgetGroup) {
      // Only re-define variables of the newly added widgets
      if (addedWidgets[widget.id]) {
        defineGroupFieldVariables(payload.recipeId, payload.thingId, widget);
      }
    }

		const { state: _state, ...stateLess } = widget;
		stateLessWidgets[widget.id] = stateLess as StatelessRecipeWidget;
	});

  const updatedWidgets = safeJsonClone(stateLessWidgets);


  return updatedWidgets;
});


const addWidgetFromTemplateReducer = ((builder: ActionReducerMapBuilder<LogicMapperState>): void => {
  builder.addCase(addWidgetFromTemplateAction.fulfilled, (state, action) => {
    state.gates = action.payload;
  });

  builder.addCase(addWidgetFromTemplateAction.rejected, (_state, action) => {
    console.error('Failed to add widget from template: ', action.error);
  });
});

export default {
  action: addWidgetFromTemplateAction,
  reducer: addWidgetFromTemplateReducer,
};
