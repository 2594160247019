import React, { ReactElement } from 'react';
import { Row, Col } from 'antd';
import CardWithBorder from '../../../components/cardWithBorder/cardWithBorder';
import styles from './AuthCard.module.css';

interface Props {
	children: React.JSX.Element[] | JSX.Element;
	title: string | ReactElement;
}

function AuthCard (props: Props): ReactElement {
	return (
		<Row>
			<Col className={styles.CardContainer} xs={20} sm={20} md={12} lg={12} xl={8} >
				<CardWithBorder title={props.title}>
					{props.children}
				</CardWithBorder>
			</Col>
		</Row>
	);
}

export default AuthCard;
