import { UserEntity, UserIntegrations, UserTokens ,
	GetUserProfileResponse,
} from '@kemu-io/kemu-types';
import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import gatesManager from '@kemu-io/kemu-core/widgetsManager';
import * as userApi from '../../../api/user/userApi';
import { processUserWidgetsAction } from '../widget/processWidgetsReducer';
import { UserAccountState } from '../../../types/user';
import { AsyncRequestStatus } from '../../../types/core_t';
import { updateRecipeCacheMeta } from '../../recipe/utils';
import { RootState } from '../../store';
import { CustomerState, setUserProfile } from './userSlice';
import { safeJsonParse } from '@common/utils';

type UserProfileActionResponse = {
	profile: UserEntity,
	accountState: UserAccountState,
	integrations: UserIntegrations,
	tokens: UserTokens
};

/**
 * Loads the last user profile from the local storage
 * and performs a request to the server to get the latest
 * user profile and updates the local storage with it.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getOptimisticProfile = (dispatch: any): Promise<GetUserProfileResponse> => {
	return new Promise((resolve, reject) => {
		let alreadyResolved = false;
		userApi.getProfile().then((latestProfile) => {
			localStorage.setItem('user-profile', JSON.stringify(latestProfile));
			if (!alreadyResolved) {
				resolve(latestProfile);
			} else {
				// update user state
				dispatch(setUserProfile(latestProfile.user));
				// update user widgets list
				dispatch(processUserWidgetsAction({ widgets: latestProfile.widgets }));
			}
		}).catch((error) => {
			console.error('Failed to get user profile', error);
			if (!alreadyResolved) {
				reject(error);
			}
		});

		const previousProfile = localStorage.getItem('user-profile');
		const parsed = safeJsonParse<GetUserProfileResponse>(previousProfile);
		if (parsed) {
			resolve(parsed);
			alreadyResolved = true;
		}
	});
};

/**
 * Fetches Kemu's user profile for the current user
 */
export const fetchUserProfileAction = createAsyncThunk('/user/fetch/profile', async (_, thunkAPI): Promise<UserProfileActionResponse> => {
	// Update title based on current language
	const loadWidgetsPromise = gatesManager.loadWidgets();
	const loadProfilePromise = getOptimisticProfile(thunkAPI.dispatch);

	const [profile] = await Promise.all([loadProfilePromise, loadWidgetsPromise]);
	const { user, widgets, integrations, tokens } = profile;

	localStorage.setItem('user-profile', JSON.stringify(profile));
	// const { user, widgets, integrations, tokens } = await userApi.getProfile();

	let hasKemuAccessLicense = !!user.subscriptionInfo?.stripeSubscriptions?.find(licence => licence.type === 'KemuAccess');
	// Users that belong to an organization and are NOT the owner should not be in charge of billing
	if (user.organization && user.organization.role !== 'Owner') { hasKemuAccessLicense = true; }

	const isAdmin = user.organization?.role === 'Admin';
	const isOwner = user.organization?.role === 'Owner';
	const belongsToOrganization = !!(user.organization && user.organizationId);

	// Process the user's custom widgets and download any missing one
	// const processWidgetsPromise = thunkAPI.dispatch(processUserWidgetsAction({ widgets }));
	await thunkAPI.dispatch(processUserWidgetsAction({ widgets }));

	// await Promise.all([processWidgetsPromise, installThingsPromise, loadWidgetsPromise]);

	// Update the current recipe information now that we have info about the author
	const { workspace } = thunkAPI.getState() as RootState;
	if (workspace.currentRecipe.poolId) {
		updateRecipeCacheMeta(workspace.currentRecipe.poolId, {
			authorId: user.id,
		});
	}

	return {
		profile: user,
		integrations,
		tokens,
		accountState: {
			belongsToOrganization,
			hasKemuAccessLicense: hasKemuAccessLicense,
			isAdmin,
			isOwner
		}
	};
});

export const fetchUserProfileReducer = ((builder: ActionReducerMapBuilder<CustomerState>): void => {

	builder.addCase(fetchUserProfileAction.pending, (state) => {
		state.currentUserProfile.asyncState.status = AsyncRequestStatus.loading;
	});

	builder.addCase(fetchUserProfileAction.rejected, (state, action) => {
		state.currentUserProfile.asyncState.status = AsyncRequestStatus.error;
		state.currentUserProfile.asyncState.error = action.error;
	});

	builder.addCase(fetchUserProfileAction.fulfilled, (state, action: PayloadAction<UserProfileActionResponse>) => {
		state.currentUserProfile.asyncState.status = AsyncRequestStatus.completed;
		state.currentUserProfile.accountState = action.payload.accountState;
		state.currentUserProfile.profile = action.payload.profile;
		state.currentUserProfile.tokens = action.payload.tokens;
		state.currentUserProfile.integrations = action.payload.integrations;
	});
});
