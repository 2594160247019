import { HubBroadcastEvent, HubServiceSubscription , DeepReadOnly } from '@kemu-io/kemu-core/types';
import { useEffect, useRef } from 'react';
import { BroadcastEvent } from '@kemu-io/hs-types';
import useKemuHubLink from './useHubLink';
import useCurrentRecipeInfo from './useCurrentRecipeInfo';
import { KemuAppIdIdentifier } from '@src/app/kemuHub/connectionManager';

/**
 * Registers an event listener to invoke whenever a hub service produces
 * a broadcast event. This hook takes care of creating and removing the subscription
 * when the component is mounted and unmounted.
 * @param name the name of the event to listen for. If not specified this hook does nothing.
 * @param version the version of the event to listen for. Set it to '*' to listen to all versions.
 * @param callback a function to invoke when the event is received. This function does not need
 * to be memoized since the hook will handle the memoization.
 * @param callerWidgetId the id of the widget that is listening for the event. This is used to
 * route the event to the correct widget. It defaults to the Kemu App Id.
 * @param disabled whether to disable the event listener.
 * 
 * @example
 * ```tsx
 * 
 * const MyComponent = () => {
 *  useHandleServiceBroadcast('my.service.name', '1.0', (event) => {
 *    console.log('Received event:', event);
 *  })
 * 
 *  return <div>My Component</div>
 * }
 * ```
 */
const useHandleServiceBroadcast = <E extends HubBroadcastEvent | BroadcastEvent = BroadcastEvent>(
  name: string | undefined | null,
  version: string | undefined | null,
  callback: (event: DeepReadOnly<E>) => Promise<void> | void,
  callerWidgetId = KemuAppIdIdentifier,
  disabled?: boolean
) => {
  const { connector } = useKemuHubLink();
  const lastCallbackRef = useRef(callback);
  const { poolId } = useCurrentRecipeInfo();

  useEffect(() => {
    if (disabled) { return; }
    if (!name || !version) { return; }
    if (!poolId) { return; }

    const handleEvent = async (event: DeepReadOnly<E>) => {
      if (lastCallbackRef.current) {
        return lastCallbackRef.current(event);
      }
    };

    const subscription: HubServiceSubscription = {
      listener: {
        recipeId: poolId,
      },
      targetService: {
        serviceName: name,
        version,
      }
    };

    // Create a subscription
    connector.subscribeToServiceEvents(subscription);

    const unsubscribe = connector.onBroadcastEvent<E>(callerWidgetId, name, version, handleEvent);
    return () => {
      // Destroy the event listener
      unsubscribe && unsubscribe();
      // Remove the subscription
      subscription && connector.unsubscribeFromServiceEvents(subscription);
    };
  }, [connector, poolId, disabled, callerWidgetId, name, version]);

  // Keep track of the target callback 
  lastCallbackRef.current = callback;
};

export default useHandleServiceBroadcast;
