import React from 'react';
import Icon from '@ant-design/icons';
import BetweenWidgetProcessor, { BetweenGateState } from '@kemu-io/kemu-core/widgets/between/index.js';
import { WidgetPortContext } from '@kemu-io/kemu-core/types';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import { GetPortsInformationFunction, GateUI, GateUIProps } from '../index';
import GateIcon from '../../gateIcon/gateIcon';
import { PortLocation } from '../../../types/canvas_t';
import NumericWithoutArrows from '../../WidgetsComponents/NumericWithoutArrows/NumericWithoutArrows';
import { ReactComponent as ElapsedGateIcon } from './between.svg';

import styles from './between.module.css';

interface Props extends GateUIProps {
	repaint: ()=> void;
}


const BetweenGate = (props: Props): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<BetweenGateState>(props.recipeId, props.thingRecipeId, props.info.id);
	// NOTE: the actual 'default' state is defined at the processor level (kemu-core/src/gates/if/index.ts),
	// here we just make sure we show the same
	const min = state.min === undefined ? 1 : state.min;
	const max = state.max === undefined ? 10 : state.max;

	const minInputChanged = (newValue: number) => {
		setState({
			...state,
			min: Number(newValue)
		}, true);
	};

	const maxInputChanged = (newValue: number) => {
		setState({
			...state,
			max: Number(newValue)
		}, true);
	};


	return (
		<div className={styles.GateBody}>
			<div className={`gate-input ${styles.MinInput}`}>
				<NumericWithoutArrows
					value={min}
					onChange={minInputChanged}
					className={styles.MinInput}
				/>
			</div>
			<div className={`gate-input ${styles.MaxInput}`}>
				<NumericWithoutArrows
					value={max}
					onChange={maxInputChanged}
					className={styles.MaxInput}
				/>
			</div>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={ElapsedGateIcon} />}/>
	);
};

const getPortsInformation: GetPortsInformationFunction = (state, widgetInfo) => {
	const portContext: WidgetPortContext = {
		recipePoolId: widgetInfo.recipePoolId,
		recipeType: widgetInfo.recipeType,
		thingRecipeId: widgetInfo.thingRecipeId,
		widgetId: widgetInfo.id,
	};
	const outputNames = BetweenWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = BetweenWidgetProcessor.getInputNames(state, portContext);

	const positionByOutputName: Record<string, PortLocation> = {
		'then': [1, 0.3, 1, 0],
		'else': [1, 0.7, 1, 0]
	};

	return {
		inputs: [{
			position: 'Left',
			type: inputNames[0].type,
			name: inputNames[0].name
		}],

		outputs: outputNames.map(output => ({
			position: positionByOutputName[output.name],
			type: output.type,
			name: output.name
		})),
	};
};


export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: BetweenGate,
	hasTitle: true,
	getWidgetTitle: (intl) => intl.formatMessage({ defaultMessage: 'If Between', id: 'LogicMaker.Gates.Between.Title' }),
} as GateUI;
