import React from 'react';
import { Pagination } from 'antd';
import classNames from 'classnames';
import styles from './StyledPagination.module.css';

type Props = {
  onChange: (page: number, pageSize?: number) => void;
  showSizeChanger?: boolean;
  current: number;
  pageSize?: number;
  total: number;
  wrapperClassName?: string;
}

const StyledPagination = (props: Props): React.JSX.Element => {
  return (
    <div className={classNames(styles.PaginationContainer, props.wrapperClassName)}>
      <Pagination
        onChange={props.onChange}
        total={props.total}
        pageSize={props.pageSize}
        current={props.current}
        showSizeChanger={false}
      />
    </div>
  );
};

export default StyledPagination;
