import React, { memo, useMemo } from 'react';
// import SVG from 'react-inlinesvg';

type Props = {
  svgStr: string;
  className?: string;
  width?: number;
  height?: number;
}

const SvgFromString = (props: Props) => {
  const { svgStr, className, width, height } = props;
  const base64data = useMemo(() => {
    return btoa(decodeURIComponent(encodeURIComponent(svgStr)));
  }, [svgStr]);

  return (
    <img
      className={className}
      width={width}
      height={height}
      style={{
        objectFit: 'contain',
      }}
      src={`data:image/svg+xml;base64,${base64data}`}
      alt="svg"
    />
  );
};



export default memo(SvgFromString);
