import { DataType } from '@kemu-io/hs-types';
import { addToStorageUnit, removeStorageUnit } from '@kemu-io/kemu-core/common/utils';
import { SettingType } from '@kemu-io/kemu-core/types';

/**
 * Adds a zip bundle file to the given recipe storage.
 * @param recipePoolId the id of the recipe in the pool
 * @param thingId the id of the thing in the recipe the widget bundle belongs to.
 * @param data the raw zip file data.
 * @param key the key to use for the file in the storage. If not provided, a new key will be generated.
 * @returns the key used to store the file, or the given key if provided.
 */
const addBundleToRecipeStorage = async (
	recipePoolId: string,
	thingId: string,
	data: Uint8Array,
	key?: string
): Promise<string> => {
	console.log(`Saving bundle (${data.byteLength}) bytes in the recipe storage`);
	const addedKey = addToStorageUnit(recipePoolId, thingId, data, key);
	console.log(`Saved as key ${addedKey}`);
	return addedKey;
};

/**
 * Removes a file from the recipe storage.
 * @param recipePoolId the id of the recipe in the pool
 * @param thingId  the id of the thing in the recipe the widget bundle belongs to.
 * @param key the key to remove from storage.
 */
const removeBundleFromRecipeStorage = (
  recipePoolId: string,
  thingId: string,
  key: string
) => {
  console.log('Removing file from the recipe storage');
  const removed = removeStorageUnit(recipePoolId, thingId, key);
  if (!removed) {
    console.warn(`Failed to remove file with key ${key} from the recipe storage`);
  }
};

/**
 * @returns the correct data type based on the widget field
 */
const getDataTypeFromWidgetFieldType = (fieldType: SettingType): DataType => {
	switch (fieldType) {
		case 'number':
			return DataType.Number;
		case 'text':
			return DataType.String;
		case 'checkbox':
			return DataType.Boolean;
		case 'dropdown':
			return DataType.String;
		case 'multiselect':
			return DataType.Array;
		case 'slider':
			return DataType.Number;
		default:
			return DataType.String;
	}
};

export {
  addBundleToRecipeStorage,
  removeBundleFromRecipeStorage,
  getDataTypeFromWidgetFieldType,
};
