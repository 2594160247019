import React, { useCallback, useEffect, useState } from 'react';
import { SliderConfig } from '@kemu-io/kemu-core/types';
import { Col,  Row } from 'antd';
import styles from './SliderFieldConfig.module.css';
import FormGroup from '@components/form-control/formGroup/formGroup';
import StandardModal from '@components/modal/standardModal';
import StyledNumericInput from '@components/form-control/StyledNumericInput/StyledNumericInput';

type Props = {
  open: boolean;
  fieldIndex: number;
  currentConfig: SliderConfig | null;
  onCloseModal: (fieldIndex: number, newConfig: SliderConfig | null) => void;
}


const SliderFieldConfig = (props: Props) => {
  const { currentConfig, onCloseModal, fieldIndex, open } = props;
  const [config, setConfig] = useState<SliderConfig | null>(currentConfig);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleValueChange = useCallback((value: number, elementId: any) => {
    setConfig((prevConfig) => {
      return {
        step: 1,
        defaultValue: 0,
        ...prevConfig,
        [elementId as string]: value,
      };
    });
  }, []);

  const handleSaveSettings = useCallback(() => {
    onCloseModal(fieldIndex, config);
  }, [config, fieldIndex, onCloseModal]);

  const handleCloseModal = useCallback(() => {
    onCloseModal(fieldIndex, null);
  }, [fieldIndex, onCloseModal]);

  useEffect(() => {
    setConfig(currentConfig);
  }, [currentConfig]);


  return (
    <StandardModal
      title="Slider Field Configuration"
      centered
      visible={open}
      width={310}
      onOk={handleSaveSettings}
      onCancel={handleCloseModal}
      okBtnLabel="Save"
      cancelBtnLabel="Cancel"
    >
      {open && (
        <Row>
          <Col span={24}>
            <FormGroup column={true}>
              <label>Min Value</label>
              <StyledNumericInput
                value={config?.min}
                onValueChange={handleValueChange}
                elementId="min"
              />
            </FormGroup>
            <FormGroup column={true}>
              <label>Max Value</label>
              <StyledNumericInput
                value={config?.max}
                onValueChange={handleValueChange}
                elementId="max"
              />
            </FormGroup>

            <FormGroup column={true}>
              <label>Step</label>
              <StyledNumericInput
                value={config?.step}
                onValueChange={handleValueChange}
                elementId="step"
              />
            </FormGroup>

            <FormGroup column={true}>
              <label>Default Value</label>
              <StyledNumericInput
                value={config?.defaultValue}
                onValueChange={handleValueChange}
                elementId="defaultValue"
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </StandardModal>
  );
};

export default SliderFieldConfig;


