const getElementPosition = (trigger: Element): DOMRect => {
	return trigger.getBoundingClientRect();
};

/** 
 * @returns true if the element is visible in the viewport.
 * NOTE: It does not detect if the element is occluded by another element.
 */
const isVisible = (el: Element): boolean => {
	return (el as HTMLElement)?.offsetParent !== null;
};

/**
 * Calculates the z-index of the given element based on its style property
 * or that of its parents.
 */
const getEffectiveZIndex = (elm: Element | (Node & ParentNode) | null): number => {
	let elementZIndex, style, zIndex = 0;

	while (elm && elm !== document) {
		style = getComputedStyle(elm as Element);

		if (style) {
			elementZIndex = parseInt(style.getPropertyValue('z-index'), 10);

			if (elementZIndex > zIndex) {
				zIndex = elementZIndex;
			}
		}

		elm = elm.parentNode;
	}

	return zIndex;
};

type CodeSections = {
	onClick?: string;
	/** code to prepend before other declarations */
	prepend?: string;
}

type CompiledCodeFun = <T=unknown>(code: string | CodeSections) => T & {
	/** Destroys all internal instances */
	destroy: () => void
};

/**
 * Compiles a string into code, disabling the following features:
 * - setInterval
 * - setTimeout
 * - requestAnimationFrame
 * @param code the raw code to be compiled or an object with specific sections 
 * @returns an object that can be used to run your code.
 */
const getCompiledCode: CompiledCodeFun = (code) => {
	const codeWrapper = `
		var setTimeout = ()=> {};
		var setInterval = ()=> {};
		var requestAnimationFrame = ()=> {};
		var __dynamicObj = {};

		${typeof code === 'string' ? code : `
		
		${code.prepend || ';'};

		__dynamicObj.onClick = () => {
				${code.onClick || 'void;'};
			}
		`};

		__dynamicObj.destroy = function () {
			__dynamicObj.onClick = null;
			__dynamicObj = null;
		}

		return __dynamicObj;
	`;
	const userCode = new Function(codeWrapper)();
	return userCode;
};

export {
	getElementPosition,
	isVisible,
	getEffectiveZIndex,
	getCompiledCode,
};
