import initHelpHero, { HelpHero } from 'helphero';

let heroInstance: HelpHero | null = null;

const initialize = (apiKey?: string): HelpHero => {
	if (!apiKey) { throw new Error('HelpHero apiKey is required'); }
	heroInstance = initHelpHero(apiKey);

	// Cancel any previously active tours (workaround)
	heroInstance?.cancelTour();

	return heroInstance;
};

const getInstance = (): HelpHero | null => heroInstance;

export default {
	initialize,
	getInstance
};
