import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import widgetCache from './widgetCache';
import { WidgetCollectionState } from './widgetSlice';
import * as widgetApi from '@src/api/widget/widgetApi';
import { AsyncRequestStatus } from '@src/types/core_t';
import { getGlobalIntl } from '@src/assets/i18n/globalIntl';

/**
 * Deletes the given widget in the database.
 * It also removes the widget from the local cache.
*/
export const deleteWidgetAction = createAsyncThunk('/widgets/deleteWidgetAction', async (
	args: { widgetDbId: string, version: number },
): Promise<string> => {

	const response = await widgetApi.deleteWidget(args.widgetDbId);
	if (!response.success) { throw new Error(`Invalid server response`); }

	// Store the widget from the local cache
	await widgetCache.deleteWidget(args.widgetDbId/* , args.version */);
	return args.widgetDbId;
});


export const deleteWidgetReducer = ((builder: ActionReducerMapBuilder<WidgetCollectionState>): void => {

	builder.addCase(deleteWidgetAction.pending, (state) => {
		const t = getGlobalIntl();
		state.asyncIndicator = {
			title: t('Widget.AsyncIndicator.Progress', '{action} Widget...', { action: 'Deleting' }),
			asyncStatus: {
				status: AsyncRequestStatus.loading,
				error: undefined,
			}
		};
	});

	builder.addCase(deleteWidgetAction.rejected, (state, action) => {
		const t = getGlobalIntl();
		state.asyncIndicator = {
			title: t('Widget.AsyncIndicator.Failure', 'Failed to {action} widget: ', { action: 'delete' }),
			asyncStatus: {
				status: AsyncRequestStatus.error,
				error: action.error,
			}
		};
	});

	builder.addCase(deleteWidgetAction.fulfilled, (state, action) => {
		const t = getGlobalIntl();
		state.asyncIndicator = {
			title: t('Widget.AsyncIndicator.Success', 'Widget {action}!', { action: 'deleted' }),
			asyncStatus: {
				status: AsyncRequestStatus.completed,
				error: undefined,
			}
		};

		const otherWidgets = state.customWidgets.filter(widget => widget.dbId !== action.payload);

		state.customWidgets = [
			...otherWidgets,
		];

	});
});

