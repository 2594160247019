
import React, { useEffect, useMemo, useRef } from 'react';
import { isImageDataType } from '@kemu-io/kemu-core/common/utils';
import { DataType } from '@kemu-io/hs-types';
import useGateParentEventDetector, { ParentEventResponse } from '@hooks/useGateParentEventDetector';

type Props = {
  inputPortName: string;
  recipeId: string;
  thingId: string;
  widgetId: string;
  /** if true the canvas element wont' be refreshed */
  disablePainting?: boolean;
  hideCanvas?: boolean;
  /** 
   * what kind of data types will be allowed to be painted and
   * sent to the parent component.
   **/
  allowBooleans?: boolean;
  allowImageData?: boolean;
  allowNumbers?: boolean;
  onImageData?: (imageData: ImageData, sourceWidgetId: string) => void;
  onNumericValue?: (value: number | string, sourceWidgetId: string) => void;
  onEvent?: (event: ParentEventResponse) => void;
  /** invoked when the event data contains an image size that differs from the previous one. */
  onImageSizeChange?: (width: number, height: number) => void;
  canvasClassName?: string;
  canvasWidth?: number;
  canvasHeight?: number;
  canvasStyle?: React.CSSProperties;
  canvasRef?: React.RefObject<HTMLCanvasElement>;
}

/** a faster way to evaluate if a value is NaN */
const fastIsNaN = (value: number): boolean => {
	return !(value <= 0) && !(value > 0);
};

/**
 * A canvas element that is updated whenever
 * a parent component sends an event with an ImageData type
 * to the given input port name
 * @param props 
 * @returns 
 */
const ReactiveCanvas = (props: Props) => {

// Forward canvas ref
// const ReactiveCanvas = React.forwardRef<HTMLCanvasElement, Props>((props, canvasRef) => {
  const {
    recipeId, thingId,  widgetId, onNumericValue,
    onImageData, onEvent, canvasClassName, canvasWidth,
    canvasHeight, canvasStyle, hideCanvas, disablePainting, inputPortName,
    allowBooleans, allowImageData, allowNumbers, canvasRef, onImageSizeChange,
  } = props;

	const event = useGateParentEventDetector(recipeId, thingId, widgetId);
  const lastImageSizeRef = useRef<{width: number, height: number} | null>(null);
  const canvasContextRef = useRef<CanvasRenderingContext2D | null>(null);

  const allowedTypes = useMemo(() => {
    const types = [];
    if (allowBooleans) { types.push(DataType.Boolean); }
    if (allowNumbers) { types.push(DataType.Number); }
    if (allowImageData) { types.push(DataType.ImageData); }
    return types;
  }, [allowBooleans, allowNumbers, allowImageData]);

	useEffect(() => {
		if (event) {
			const isNumeric = !fastIsNaN(Number(event.data.value));
			const evtValue = event.data.value;
      if (event.targetPort !== inputPortName) { return; }

			if (onEvent) { onEvent(event); }
      let validEvent = false;

      const isImageData = event.data.type === DataType.ImageData;
      const imageData = event.data.value as ImageData;
			const isImage = !isNumeric && isImageDataType(evtValue);

			if (allowedTypes.includes(event.data.type) && isNumeric && !isImage) {
				onNumericValue && onNumericValue(String(event.data.value as string), event.sourceGate.id);
        validEvent = true;
			} else if (isImageData || isImage) {
				onImageData && onImageData(imageData, event.sourceGate.id);
        validEvent = true;
			}

      if (validEvent && isImageData) {
        let firstEvent = false;
        if (!lastImageSizeRef.current) {
          firstEvent = true;
          lastImageSizeRef.current = {
            width: imageData.width,
            height: imageData.height
          };
        }

        const sizeChanged = lastImageSizeRef.current.width !== imageData.width
          || lastImageSizeRef.current.height !== imageData.height;

        if (sizeChanged || firstEvent) {
          onImageSizeChange && onImageSizeChange(imageData.width, imageData.height);
        }
      }

      if (!disablePainting && validEvent && canvasRef?.current && isImageData) {
        const displayCanvas = canvasRef.current;

        if (!canvasContextRef.current) {
          canvasContextRef.current = displayCanvas.getContext('2d');
        }

        const ctx = canvasContextRef.current;
        ctx?.putImageData(event.data.value as ImageData, 0, 0);
      }
		}

	}, [
    onImageSizeChange, onNumericValue, onImageData, onEvent,
    event, allowedTypes, inputPortName, disablePainting, canvasRef,
  ]);

  const memorizedCanvas = useMemo(() => {
    return (
      <canvas
        ref={canvasRef}
        className={canvasClassName}
        width={canvasWidth}
        height={canvasHeight}
        style={canvasStyle}
      />
    );
  }, [canvasClassName, canvasWidth, canvasHeight, canvasStyle, canvasRef]);

	if (hideCanvas) { return null; }

  return memorizedCanvas;
// });
};

export default ReactiveCanvas;
