/**
 * Returns the base url of a tutorial WITHOUT a step path.
 * @param id the id of the tutorial being accessed.
 */
// const getTutorialRouteBase = (id: string): string => `/tutorial/${id}`;

/**
 * Returns the base url of a tutorial including the step path.
 * @param id the id of the tutorial being accessed.
 * @param step the step number to select by default.
 */
const getTutorialRoute = (id: string, step: string | number): string => `?tutorial=${id}&step=${step}`;

export default {
	getTutorialRoute,
	// getTutorialRouteBase,
};

