import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTwilioWebRTC from '../../common/hooks/useTwilioWebRTC';
import EventBridge from '../../app/eventBridge/EventBridge';
import MediaLink from '../../app/mediaLink/mediaLink';
import { twilioConnectionToken, setConnectedPhoneInfo } from '../../features/interface/interfaceSlice';
import { HardwareBlock } from '../../types/kemuCommLink';
import { registerHardwareBlock, unregisterHardwareBlock } from '../mainMenu/mainMenuSlice';
import styles from './statusIndicator.module.css';

type IndicatorType = 'idle' | 'positive' | 'negative' | 'warning' | 'warning pulse' | 'negative pulse' | 'positive pulse';

const StatusIndicator = (): React.JSX.Element => {
	const dispatch = useDispatch();
	const [twilioLink, twilioStatus, connectedParticipant, onTrackUpdated] = useTwilioWebRTC();
	const twilioToken = useSelector(twilioConnectionToken);

	useEffect(() => {
		// ==== Hard coded block type ====
		// TODO: This should check the latest version from the database
		// and match the correct block by name instead
		const cameraBlock: HardwareBlock = {
			device: {
				appVersion: '0.0.0',
				device: 'android'
			},
			// FIXME: this should be dynamic from the DB
			id: 'phone-camera',
			version: '0.12.0',
			lastActiveAt: Date.now()
		};

		if (onTrackUpdated.trackType === 'video') {

			// Set or clear global reference
			MediaLink.setVideoStream(twilioLink.videoTrack);

			if (twilioLink.videoTrack) {
				// Store the reference to the stream
				// Add block to menu bar
				dispatch(registerHardwareBlock(cameraBlock));

				// Notify existing canvas instances 
				EventBridge.emitToAllCanvas(cameraBlock.id, cameraBlock.version, {
					type: 'AttachStream',
					data: twilioLink.videoTrack
				});
			} else {
				// Remove block from menu bar
				dispatch(unregisterHardwareBlock(cameraBlock));
				// Notify existing canvas instances
				EventBridge.emitToAllCanvas(cameraBlock.id, cameraBlock.version, {
					type: 'DetachStream',
					data: null
				});
			}
		}

		// IMU_DB_ID: 'wtvWIqCWTmWVI5ovdeTY',
		// IMU_VER: '0.0.1',
	}, [onTrackUpdated, dispatch, twilioLink]);


	useEffect(() => {
		dispatch(setConnectedPhoneInfo( connectedParticipant ? {
			name: connectedParticipant.name,
		} : null ));
	}, [connectedParticipant, dispatch]);

	useEffect(() => {
		if (twilioToken) {
			twilioLink.connect(twilioToken);
			MediaLink.setSendToDeviceFun(twilioLink.sendString);
		}
	}, [twilioToken, twilioLink]);


	let indicator: IndicatorType = 'idle';
	if (twilioStatus === 'disconnected') { indicator = 'idle'; }
	if (twilioStatus === 'connected') { indicator = 'positive'; }
	if (twilioStatus === 'connecting') { indicator = 'warning pulse'; }
	if (twilioStatus === 'error') { indicator = 'negative pulse'; }


	return (
		<div className={styles.Container}>
			<span className={`${styles.Dot} ${indicator}`} title={`${twilioStatus}`}></span>
		</div>
	);
};

export default StatusIndicator;
