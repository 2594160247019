import React, { useCallback, useMemo } from 'react';
import { TextConfig, WidgetGroupSetting } from '@kemu-io/kemu-core/types';
import classNames from 'classnames';
import { calculateWidgetColors } from '../../common';
import GroupHeader from '../GroupHeader/GroupHeader';
import GroupFieldsRenderer from '../GroupFieldsRenderer/GroupFieldsRenderer';
import styles from './GroupWithVisibleFields.module.css';
import { WIDGET_GROUP_FIELDS_CONTAINER_CLASS } from '@common/constants';

type Props = {
  settings?: WidgetGroupSetting[];
  name: string;
  thingRecipeId: string;
  recipeId: string;
  widgetId: string;
  color?: string;
  disabled?: boolean;
  /* the maximum number of ports, inputs or outputs */
  maxPorts: number;
  hasHiddenFields: boolean;
  onOpenCustomSettings?: () => void;
  onRepaintWidgetPorts: () => void;
}

const GroupWithVisibleFields = (props: Props) => {
  const {
    settings, recipeId, thingRecipeId,
    widgetId, color, disabled, maxPorts,
    hasHiddenFields, onOpenCustomSettings,
    onRepaintWidgetPorts,
  } = props;

  const multiPort = maxPorts > 2;

  const colors = useMemo(() => {
    if (!color) { return undefined; }

    const docStyle = getComputedStyle(document.body);
    const disabledColor = docStyle.getPropertyValue('--kemu-color-disabled-widget');

    const colors = calculateWidgetColors(disabled ? disabledColor : color);
    return colors;
  }, [color, disabled]);


  if (!settings) { return null; }
  if (settings.length === 0) { return null; }

  const visibleFields = settings.filter((setting) => setting.customSettingField === false);
  const hasTextArea = visibleFields.some((field) => field.type === 'text' && (field.config as TextConfig).multiline);

  return (
    <div
      className={classNames(styles.GroupBody, {
        [styles.BrightBody]: colors?.isBrightColor,
        [styles.DisabledWidget]: disabled,
        [styles.HasFreeWidth]: hasTextArea,
      })}
      style={{
        ...(colors?.isBrightColor ? {
          borderColor: colors?.header
        } : {}),
      }}
    >
      <GroupHeader
        title={props.name}
        headerColor={colors?.header}
        isBrightColor={colors?.isBrightColor}
        showGearIcon={hasHiddenFields}
        onOpenCustomSettings={onOpenCustomSettings}
      />
      <div
        style={{
          backgroundColor: colors?.content,
        }}
        className={classNames(styles.Content, WIDGET_GROUP_FIELDS_CONTAINER_CLASS, {
          [styles.SingleElement]: visibleFields.length === 1 && !visibleFields[0].label,
          [styles.MultiPort]: multiPort,
        })}
      >
        <GroupFieldsRenderer
          repaintWidgetPorts={onRepaintWidgetPorts}
          thingRecipeId={thingRecipeId}
          recipeId={recipeId}
          widgetId={widgetId}
          color={color}
          disabled={disabled}
          multiPort={multiPort}
          fields={visibleFields}
          isBrightColor={colors?.isBrightColor}
          isInnerSetting={false}
        />
      </div>
    </div>
  );
};

export default GroupWithVisibleFields;
