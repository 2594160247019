import React, { useCallback, useEffect, useState } from 'react';
import { DropDownConfig } from '@kemu-io/kemu-core/types';
import { Col,  Row } from 'antd';
import styles from './DropDownFieldConfig.module.css';
import FormGroup from '@components/form-control/formGroup/formGroup';
import StandardModal from '@components/modal/standardModal';
import StyledInput from '@components/form-control/styledInput/styledInput';
import StyledTextArea from '@components/form-control/styledTextArea/styledTextArea';
import useTranslation from '@hooks/useTranslation';

type Props = {
  open: boolean;
  fieldIndex: number;
  currentConfig: DropDownConfig;
  onCloseModal: (fieldIndex: number, newConfig: DropDownConfig | null) => void;
}


const DropDownFieldConfig = (props: Props) => {
  const t = useTranslation();
  const { currentConfig, onCloseModal, fieldIndex, open } = props;
  const [config, setConfig] = useState<DropDownConfig>(currentConfig);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOptionsChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setConfig((prevConfig) => {
      return {
        ...prevConfig,
        options: event.target.value.split('\n')
      };
    });
  }, []);

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.value = event.currentTarget.value.split('\n')
        .map((option) => option.trim())
        .join('\n');
    }
  }, []);

  const handleDefaultValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setConfig((prevConfig) => {
      return {
        ...prevConfig,
        options: prevConfig?.options || [],
        defaultValue: value,
      };
    });
  }, []);

  const handleSaveSettings = useCallback(() => {
    onCloseModal(fieldIndex, config);
  }, [config, fieldIndex, onCloseModal]);

  const handleCloseModal = useCallback(() => {
    onCloseModal(fieldIndex, null);
  }, [fieldIndex, onCloseModal]);

  useEffect(() => {
    setConfig(currentConfig);
  }, [currentConfig]);


  return (
    <StandardModal
      title={t('CustomWidget.Settings.FieldConfiguration', '{fieldName} Field Configuration', {
        fieldName: 'Dropdown',
      })}
      centered
      visible={open}
      width={330}
      onOk={handleSaveSettings}
      onCancel={handleCloseModal}
      okBtnLabel={t('CommonWords.Save', 'Save')}
      cancelBtnLabel={t('CommonWords.Cancel', 'Cancel')}
    >
      {open && (
        <Row>
          <Col span={24}>
            <FormGroup column={true}>
              <label>
                {t('CommonWords.DefaultValue', 'Default value')} ({String(t('CommonWords.Optional', 'Optional')).toLowerCase()})
              </label>
              <StyledInput
                value={config?.defaultValue}
                onChange={handleDefaultValueChange}
              />
            </FormGroup>
            <FormGroup column={true}>
            <label>{t('CommonWords.Options', 'Options')}</label>
              <StyledTextArea
                onKeyDown={handleKeyDown}
                preventResize={true}
                className={styles.TextArea}
                value={config?.options?.join('\n')}
                onChange={handleOptionsChange}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </StandardModal>
  );
};

export default DropDownFieldConfig;


