import React from 'react';
import { InputWidgetState } from '@kemu-io/kemu-core/types';
import Icon from '@ant-design/icons';
import { Dropdown, Tooltip } from 'antd';
import InputWidgetProcessor, {
	getDefaultState,
} from '@kemu-io/kemu-core/widgets/input/index.js';
import { DataType } from '@kemu-io/hs-types';
import classNames from 'classnames';
import {
  GateUI,
	GateUIProps,
  GetPortsInformationFunction
} from '../index.js';
import { buildOutputPortsDescription } from '../common';
import styles from './InputWidget.module.css';
import TextInput from '@components/WidgetsComponents/TextInput/TextInput';
import useReactiveWidgetState from '@hooks/useReactiveWidgetState';
import GateIcon from '@components/gateIcon/gateIcon';
import { ReactComponent as JsonWidgetIcon } from '@src/assets/img/dataTypes/icon.svg';
import { ReactComponent as NumberIcon } from '@src/assets/img/dataTypes/numberIcon.svg';
import { ReactComponent as StringIcon } from '@src/assets/img/dataTypes/stringIcon.svg';
import { ReactComponent as ArrayIcon } from '@src/assets/img/dataTypes/arrayIcon.svg';
import { ReactComponent as ImageIcon } from '@src/assets/img/dataTypes/imageIcon.svg';
import { ReactComponent as InputWidgetIcon } from '@src/assets/img/gates/input-widget.svg';
import { ReactComponent as UnknownIcon } from '@src/assets/img/dataTypes/unknownIcon.svg';
import useTranslation from '@hooks/useTranslation';

type Props = GateUIProps


const InputWidget = (props: Props) => {
  const [state, setState] = useReactiveWidgetState<InputWidgetState>(props.recipeId, props.thingRecipeId, props.info.id);
  const t = useTranslation('Kemu.DataType.HumanReadable');
  const inputT = useTranslation('Widget.Input');

	const fixedState = {
		...getDefaultState(),
		...state
	};

  const updateName = (name: string) => {
    setState((s) => ({
      ...s,
      inputName: name,
    }));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onUpdateType = ({ key }: any) => {
    setState((s) => ({
      ...s,
      dataType: Number(key),
    }));
  };


  return (
    <div className={styles.InputWrapper}>
      <TextInput
        value={fixedState.inputName}
        placeholder={inputT('Placeholder')}
        onChange={(e) => updateName(e.currentTarget.value)}
      />

      <Dropdown
        menu={{
          onClick: onUpdateType,
          items: [
            {
              key: DataType.Number,
              label: t('Number'),
              icon: <Icon component={NumberIcon} />,
            },
            {
              key: DataType.String,
              label: t('String'),
              icon: <Icon component={StringIcon} />,
            },
            {
              key: DataType.Array,
              label: t('Array'),
              icon: <Icon component={ArrayIcon} />,
            },
            {
              key: DataType.JsonObj,
              label: t('JsonObj'),
              icon: <Icon component={JsonWidgetIcon} />,
            },
            {
              key: DataType.ImageData,
              label: t('ImageData'),
              icon: <Icon component={ImageIcon} />,
            },
          ]
        }}
        placement="bottomLeft"
        trigger={['click']}
      >
        <Tooltip title={inputT('DataTypeTooltip')}>
          <span className={classNames(styles.ChooseType, `type-${fixedState.dataType}`)} >
            {fixedState.dataType === DataType.Number && (
              <Icon component={NumberIcon}/>
            )}

            {fixedState.dataType === DataType.String && (
              <Icon component={StringIcon} />
            )}

            {fixedState.dataType === DataType.Array && (
              <Icon component={ArrayIcon} />
            )}

            {fixedState.dataType === DataType.JsonObj && (
              <Icon component={JsonWidgetIcon} />
            )}

            {fixedState.dataType === DataType.ImageData && (
              <Icon component={ImageIcon} />
            )}

            {fixedState.dataType === DataType.Anything && (
              <Icon component={UnknownIcon} />
            )}
          </span>
        </Tooltip>
      </Dropdown>
    </div>
  );
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={InputWidgetIcon} />}/>
	);
};


const getPortsInformation: GetPortsInformationFunction = (state, widgetInfo) => {
	const outputNames = InputWidgetProcessor.getOutputNames(state, widgetInfo);
	const outputs = buildOutputPortsDescription(outputNames, 0.5);

	return {
		inputs: [],
		outputs,
	};
};

export default {
	getPortsInformation,
  hasTitle: true,

	BarIcon: GateBarIcon,
	Element: InputWidget,
	getGatesBarTitle: (intl) => intl.formatMessage({ id: 'Widget.Input.Title' }),
} as GateUI;
