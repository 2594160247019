import { BrowserJsPlumbInstance } from '@jsplumb/community';
import { useCallback } from 'react';
import { generateDomId } from '@common/utils';

/**
 * Hook to help components repaint the ports of specific widgets
 * @returns a function that can be used to repaint the given widget.
 */
const useRepaintWidgetPorts = (
  jsPlumbInstance: BrowserJsPlumbInstance | null,
  recipeId?: string,
  thingRecipeId?: string,
  widgetId?: string
) => {
  const repaint = useCallback(() => {
    if (recipeId !== undefined && thingRecipeId !== undefined && widgetId !== undefined) {
      const canvasDomId = generateDomId(recipeId, thingRecipeId, widgetId);
      if (jsPlumbInstance) {
        jsPlumbInstance.revalidate(canvasDomId);
      }
    }
  }, [recipeId, thingRecipeId, widgetId, jsPlumbInstance]);

  return repaint;
};

export default useRepaintWidgetPorts;
