import { useLocation } from 'react-router-dom';
/**
 * Access query string variables. Eg:
 * ```js
 * const queryString = useQueryString();
 * const email = queryString.get('email');
 * ```
 */
const useQueryString = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

export default useQueryString;
