import { safeJsonParse } from './utils';

type PrimitiveValues = string | number | boolean | null;
type SerializableValue = PrimitiveValues | PrimitiveValues[] | Record<string, PrimitiveValues>;
type SerializableObject = Record<string, SerializableValue>;

const getStoredObject = <T=SerializableObject>(key: string, defaultValue: T): T => {
  const str = localStorage.getItem(key);
  const settings = safeJsonParse<T>(str || '');
  return settings || defaultValue;
};

const updateObject = (key: string, value: SerializableObject): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

export default {
  getStoredObject,
  updateObject,
};
