import { DeepReadOnly } from '@kemu-io/kemu-core/types';
import { useEffect } from 'react';
import { BroadcastEvent } from '@kemu-io/hs-types';
import useKemuHubLink from './useHubLink';

/**
 * Registers an event listener to invoke whenever a broadcast event is received
 * from the service targeting the widget.
 * @param widgetId the id of the widget in the recipe
 * @param serviceName the name of the service to listen for
 * @param serviceVersion the version of the service to listen for
 * @param callback a memoized callback to invoke when the event is received
 * @param disabled whether to disable the event listener.
 */
const useHubBroadcastEvent = (
  widgetId: string,
  serviceName: string,
  serviceVersion: string,
  callback: (event: DeepReadOnly<BroadcastEvent>) => Promise<void> | void,
  disabled?: boolean
) => {
  const { connector } = useKemuHubLink();

  useEffect(() => {
    if (disabled) { return; }
    const unsubscribe = connector.onBroadcastEvent<BroadcastEvent>(widgetId, serviceName, serviceVersion, callback);

    return () => {
      unsubscribe();
    };
  }, [connector, widgetId, serviceName, serviceVersion, callback, disabled]);
};

export default useHubBroadcastEvent;
