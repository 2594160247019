import { RecipeType } from '@kemu-io/kemu-types';
import { WorkspaceState } from './workspaceSlice';
import { AsyncRequestStatus } from '@src/types/core_t';


const getInitialRecipeState = (): WorkspaceState => {
  const initialState: WorkspaceState = {
    openingRecipe: false,
    selectedBlockInfo: null,
    blockInstallationProcess: {},
    folderPath: [],
    customWidgetsByThing: {},
    creatingCloudRecipe: null,
    baseRecipeLoaded: false,
    currentRecipe: {
      name: '',
      needsSaving: false,
      entityInfo: null,
      poolId: null,
      cloud: null,
      type: RecipeType.Browser,
      openedWithErrors: false,
      blocks: {
        collection: {},
        status: AsyncRequestStatus.idle
      },
    },
  };

  return initialState;
};

export default getInitialRecipeState;
