import { useEffect, useState } from 'react';
import { SerializableServiceInfo } from '@kemu-io/hs-types';
import { useDebouncedCallback } from 'use-debounce';
import useKemuHubLink from '@hooks/useHubLink';

/**
 * Finds a service that is compatible with the given service name and version using semantic versioning.
 * Only patches and minor versions are considered compatible.
 * @param serviceName the name of the service to find
 * @param serviceVersion the version of the service to find
 * @returns the service info if found, null otherwise
 */
const useCompatibleHubService = (serviceName?: string, serviceVersion?: string) => {
  const { connector } = useKemuHubLink();
  const [matchingService, setMatchingService] = useState<SerializableServiceInfo | null>(null);

  const getServicesNow = useDebouncedCallback(async () => {
    if (!serviceName || !serviceVersion) { return; }

    const svSp = serviceVersion.split('.');
    // Caret versioning only works if the major version is not 0, so we use >= for 0.x.x versions.
    const compareMethod = svSp[0] === '0' && svSp[1] === '0' ? '>=' : '^';
    const found = await connector.getCompatibleService(serviceName, serviceVersion, compareMethod);
    setMatchingService(found);

  }, 100, { leading: true });

  useEffect(() => {
    getServicesNow();

    return () => {
      getServicesNow.cancel();
    };
  });

  return matchingService;
};

export default useCompatibleHubService;
