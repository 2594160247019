import React, { ReactElement } from 'react';
import { Popover, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Auth } from '@aws-amplify/auth';
import { useDispatch, useSelector } from 'react-redux';
import { selectSignedUserProfile } from '../../app/reducers/user/userSlice';
import globals from '../../common/globals';
import styles from './AccountMenu.module.css';
import useTranslation from '@hooks/useTranslation';
import { currentRecipePoolId, terminateRecipeAction } from '@src/features/Workspace/workspaceSlice';

type Props = {
	className?: string;
}

const AccountMenu = (props: Props): ReactElement => {
	const userProfile = useSelector(selectSignedUserProfile);
	const t = useTranslation();
	const dispatch = useDispatch();
	const currentRecipe = useSelector(currentRecipePoolId);

	const handleSignOut = async () => {
		try {
			if (currentRecipe) {
				await dispatch(terminateRecipeAction({ recipePoolId: currentRecipe }));
			}
			await Auth.signOut({ global: true });
		} catch (e) {
			console.log('Error signing out: ', e);
		}
	};

	const handleOpenDashboard = () => {
		window.location.href = `https://${globals.DASHBOARD_APP_DOMAIN}/dashboard/billing/portal`;
	};


	return (
		<Popover
			trigger="click"
			placement='right'
			content={(
				<>
					<div className={styles.UserInfo}>
						{userProfile.profile?.firstName || ''} {userProfile.profile?.lastName || ''}
						{userProfile.profile?.email && (
							<small>({userProfile.profile?.email})</small>
						)}
					</div>
					<Button type="link" onClick={handleOpenDashboard}>
						<div>{t('AccountMenu.ManageSubscriptions')}</div>
					</Button>

					<div className={styles.LogoutContainer}>
						<Button onClick={handleSignOut} type="primary">
							{t('AccountMenu.SignOut')}
						</Button>
					</div>
				</>
			)}
    >
			<div className={props.className || ''}>
      	<Button type="text" icon={<UserOutlined style={{ fontSize: 26 }} />} />
			</div>
    </Popover>
	);
};

export default AccountMenu;
