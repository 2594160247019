import React from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import classNames from 'classnames';
import styles from './gateSettingsCloseBtn.module.css';

interface Props {
	onClose: () => void;
	className?: string;
}

const GateSettingsCloseBtn = (props: Props): React.JSX.Element => {
	return (
		<span
			className={classNames('settings-close-btn', styles.CloseBtn, props.className)}
			onClick={props.onClose}
		>
			<CloseCircleFilled/>
		</span>
	);
};

export default GateSettingsCloseBtn;
