/*
 * Written by Alexander Agudelo < alex@kemu.io >, 2021
 * Date: 07/Jul/2021
 * Last Modified: 26/06/2024, 8:10:42 pm
 * Modified By: Alexander Agudelo
 * Description:  Fetches information and keeps track of page updates to display the elements
 * 
 * ------
 * Copyright (C) 2021 Kemu - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */

import { useCallback, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import { DefaultHotspotSettings, Hotspot, HotspotMinConfig } from '../types';
import { fetchContent } from '../apis/fetchContent';
import useMountEffect from '@hooks/useMountEffect';

let mutationsCounter = 0;
const globalSettings: Pick<Hotspot, 'triggers'> = {
	triggers: ['hover'],
};

type GlobalConfig = {
	javascript?: string;
}

type UseHotspotResponse = {
	hotspots: Hotspot[];
	globals: GlobalConfig;
}

const useHotspotManager = (/* settings?: HotspotSettings */): UseHotspotResponse => {
	// Contains the actual hotspots visible in the page
	const [pageHotspots, setPageHotspots] = useState<Hotspot[]>([]);
	const observerRef = useRef<MutationObserver | null>(null);
	const defaultSettingsRef = useRef<GlobalConfig>({});
	// Contains ALL hotspots defined by the user
	const userHotspotsRef = useRef<Hotspot[]>([]);

	// TODO: Restore when http requests are implemented
	// if(!settings.apiKey) { throw new Error(`Missing apiKey`); }
	// if(!settings.userId) { throw new Error(`Missing .userId`); }
	// // Just in case this is initialized twice
	// pluginSettings = settings;


	/**
	 * Invoked in every dom change to verify if hotspots are visible/invisible
	 */
	const reValidateHotspots = useCallback(() => {

		const visibleHotspots: Hotspot[] = [];
		for (let i = 0; i < userHotspotsRef.current.length; i++) {
			if (userHotspotsRef.current[i].enabled) {
				const pageElement = document.querySelector(userHotspotsRef.current[i].selectorSettings.selector);
				if (pageElement) {
					visibleHotspots.push({ ...userHotspotsRef.current[i] });
				}
			}
		}

		mutationsCounter = 0;
		setPageHotspots(visibleHotspots);

	}, [userHotspotsRef]);

	const configureMutations = useCallback((hotspots: HotspotMinConfig[], defaultSettings: DefaultHotspotSettings) => {
		// Update hotspots
		userHotspotsRef.current = hotspots.map(spot => ({ ...globalSettings, ...defaultSettings, ...spot, runtimeId: nanoid() }));
		// userHotspotsRef.current;
		// Create new mutation observers for the body
		observerRef.current = new MutationObserver(function () {
			if (!mutationsCounter) { requestAnimationFrame(reValidateHotspots); }
			mutationsCounter ++;
		});

		// Set default settings
		defaultSettingsRef.current.javascript = defaultSettings.javascript;

		const container = document.querySelector('body');
		if (container) {
			observerRef.current.observe(container, {
				childList: true,
				subtree: true
			});
		}
	}, [reValidateHotspots]);


	useMountEffect(() => {
		const fetchData = async () => {
			const response = await fetchContent();
			if (!response) { return; }
			configureMutations(response.hotspots, response.defaultSettings);
		};

		fetchData();
	});

	return {
		hotspots: pageHotspots,
		globals: defaultSettingsRef.current

	};
};




export default useHotspotManager;
