import { storageMapToUnit } from '@kemu-io/kemu-core/common/utils';
import { ArrayBuffer as MD5ArrayBuffer } from 'spark-md5';
import { CachedRecipe } from '@kemu-io/kemu-core/types';
import { getRecipeStorage, prepareRecipeForSaving } from '@src/app/recipe/utils';

/**
 * Generates a recipe package and storage.
 * @param poolId the id of the recipe in the pool to save
 * @param name an optional new name for the recipe
 */
const generateRecipePackage = async (poolId: string, name?: string): Promise<{
  /** stringified recipe contents */
  contents: string,
  storage?: {
    disk: ArrayBuffer;
    size: number;
    checksum: string;
  }
  /** the original object that generated `contents` */
  parsedContents: CachedRecipe,
}> => {
  // TODO: Currently, we don't update the id or author info in the recipe content's file 
	// because at the time of generating the checksum we don't know what version number of id
	// the recipe will have, so if we were to update the id after the server responds, the checksum
	// would no longer match. This however creates a lot of confusion because the Cached recipe instance
	// does contain id and author properties that we are supposed to ignore. 
	const { contents, parsedContents } = await prepareRecipeForSaving(poolId, name);
  const recipeStorage = getRecipeStorage(poolId);

  // Combine all storage units
	let storageSize = 0;
	let checksum = '0';
	let disk: ArrayBuffer | undefined;
	if (recipeStorage) {
		// WARNING: heavy operation
		disk = storageMapToUnit(recipeStorage);
		if (disk.byteLength) {
			storageSize = disk.byteLength;
			// WARNING: heavy operation 2
			checksum = MD5ArrayBuffer.hash(disk);
		}
	}

  return {
    contents,
    parsedContents,
    ...(disk && { storage: { disk, size: storageSize, checksum } })
  };
};

export { generateRecipePackage };
