import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Logger from '../../../common/logger';
import styles from './publisher.module.css';
import { UploadedAsset } from './publisher';

const debug = Logger('recipeThumbnail');
interface Props {
	className?: string;
	assets: UploadedAsset[];
	recipeId: string;
}

const RecipePublish = (props: Props): React.JSX.Element => {
	const [inAsyncProcess, setInAsyncProcess] = useState(false);

	return (
		<div className={props.className || ''}>
			<h2>
				<FormattedMessage id="Marketplace.MyRecipes.PublishWizard.PublishTitle" defaultMessage="PublishTitle" />
			</h2>
			<p>
				<FormattedMessage id="Marketplace.MyRecipes.PublishWizard.PublishMessage" values={{
					breakingLine: <br />,
				}}/>
			</p>
		</div>
	);
};

export default RecipePublish;
