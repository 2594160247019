import { useCallback, useEffect, useRef } from 'react';
import { GateChild, RecipeWidget } from '@kemu-io/kemu-core/types';
import { BrowserJsPlumbInstance } from '@jsplumb/community';
import { findThingInRecipe } from '@kemu-io/kemu-core/common/recipeCache';
import { useSelector } from 'react-redux';
import { KemuConnectionData } from '@src/types/core_t';
import Logger from '@common/logger';
import { selectVisibleGroup } from '@src/features/Workspace/workspaceSlice';

const logger = Logger('useAlwaysLinked');

/**
 * A function that can be use to re-paint all the connections of the given widget.
 * The hook guarantees that re-painting will only happen if the number of ports have
 * changed, otherwise, invoking this method will do nothing.
 * 
 * @param forceRepaint set to true to force the method to repaint ports on the first render.
 * @param groupId if provided, only the widgets in the given group id will be linked.
 */
type UseAlwaysLinkResponse = (forceRepaint?: boolean, groupId?: string) => void;

/**
 * Encapsulates the logic for decoding child references and linking widgets
 */
export const childWidgetLink = (parentId: string, child: GateChild, plumbInstance: BrowserJsPlumbInstance): void => {
	const sourcePort = `${child.sourcePort}`;
	const targetPort = `${child.targetPort}`;
	const endpointsKeys = Object.keys(plumbInstance.endpointsByUUID);

	const matchingSourcePort = endpointsKeys.find((key) => key === sourcePort);
	const matchingTargetPort = endpointsKeys.find((key) => key === targetPort);

	if (matchingSourcePort && matchingTargetPort) {

		// Prevent creating duplicated connections
		const connectionExists = plumbInstance.getAllConnections().find((conn) =>
			conn.data.sourceWidgetId === parentId
			&& conn.data.sourcePortId === matchingSourcePort
			&& conn.data.targetPortId === matchingTargetPort
		);

		if (connectionExists) { return; }

		const conn = plumbInstance.connect({ uuids: [matchingSourcePort, matchingTargetPort] });
		if (conn) {
			// Used to identify this connection when triggering animations
			const data: KemuConnectionData = {
				sourceWidgetId: parentId,
				sourcePortId: matchingSourcePort,
				targetPortId: matchingTargetPort,
			};

			conn.setData(data);
		}
	} else {
		logger.error(`Could not find matching endpoints for ${sourcePort} and ${targetPort}`);
	}
};

/** 
 * Re-builds connects to and from the given widget when the number of inputs
 * or outputs change.
 **/
const useAlwaysLinked = (
	recipeId: string,
	thingId: string,
	widgetId: string,
	totalInputs: number,
	totalOutputs: number,
	plumbInstance: BrowserJsPlumbInstance
): UseAlwaysLinkResponse => {
	const firstTimeRef = useRef(true);
	// const canRePaint = useRef(false);
	// const totalPorts = `${totalInputs}_${totalOutputs}`;
	const currentGroup = useSelector(selectVisibleGroup);
	const visibleGroup = currentGroup?.groupId;

	/**
 	* Re-draws all connections for the given widget, including those of the parent.
	* Keep in mind this method will trigger `connection` events.
	* 
	* @param forceRepaint set to true to force the method to repaint ports on the first render.
	*/
	const redrawAllConnectionsForWidget = useCallback((/* forceRepaint=false, visibleGroup?: string */) => {
		if (plumbInstance /* && (canRePaint.current || forceRepaint) */) {
			logger.log(`Re-drawing connections for widget ${widgetId}`);
			// Always use the latest recipe state to draw connections as we can't rely
			// on the local redux store for this. The store is only updated during certain UI events.
			const thing = findThingInRecipe(recipeId, thingId);
			const widgets = { ...thing?.gates };

			// canRePaint.current = forceRepaint ? true : false;
			plumbInstance.batch(() => {
				const drawWidgetConnections = (widget: RecipeWidget, targetId?: string) => {
					// Allow painting if:
					// - Not inside a group
					// - A group is visible and widget is inside
					// - The widget itself is the group
					if (
						// !visibleGroup || 
						// The widget is inside the current group (at the root level, both groupId and visibleGroup should be undefined)
						widget?.groupId === visibleGroup
						// The widget itself is the current group
						|| widget.id === visibleGroup
					) {
						widget.children.forEach(child => {
							if (!targetId || child.childId === targetId) {
								// Make sure the target child is also INSIDE the group (if a group is active)
								// or if the child itself is the group
								if (!widgets[child.childId]) {
									console.error('WARNING: A widget was saved with a child reference that does not exist.');
								}

								if (
									// Not inside of any group
									// !visibleGroup || /* update 10/feb/2024: we should don't draw connections of widget not inside the same group */
									// The target widget is inside the current group (at the root level, both groupId and visibleGroup should be undefined)
									widgets[child.childId]?.groupId === visibleGroup
									// The child itself is the current group
									|| child.childId === visibleGroup
								) {
									childWidgetLink(widget.id, child, plumbInstance );
								}
							}
						});
					}
				};

				// Find any widget that has the requested widget as a child
				const parents = Object.keys(widgets).filter(wId => widgets[wId].children.find(child => child.childId === widgetId));

				// Draw connections from parent to myself ONLY, otherwise other connections from the parents to 
				// other children would get drawn twice
				parents.forEach(parentId => drawWidgetConnections(widgets[parentId], widgetId));
				// Draw connections from myself to any child
				drawWidgetConnections(widgets[widgetId]);
			});
		} else {
			logger.log(`No repaint required, port count has not changed for widget ${widgetId}`);
		}
	}, [plumbInstance, widgetId, recipeId, thingId, visibleGroup]);


	// Enables re-painting only when the port count has changed
	// useEffect(() => {
	// 	// the first mount should not link anything
	// 	if (!firstTimeRef.current) {
	// 		canRePaint.current = true;
	// 	} else {
	// 		logger.log(`Ignore redraw all connections because this is the first time for ${widgetId}`);
	// 	}

	// 	firstTimeRef.current = false;
	// }, [totalPorts, widgetId]);

	// Repaint connections of inner widgets when there are not children (port to port connections)
	useEffect(() => {
		if (visibleGroup === widgetId) {
			redrawAllConnectionsForWidget();
		}
	}, [visibleGroup, widgetId, redrawAllConnectionsForWidget]);


	return redrawAllConnectionsForWidget;
};

export default useAlwaysLinked;
