import axios from 'axios';
import globals from '@common/globals';
import { GetUserHotspotsResponse } from '../types';

const SERVER_URL = 'https://hotspot.dev.asurantech.com.au';
/** Fetches the initial data once the page loads */
export const fetchContent = async (): Promise<GetUserHotspotsResponse | null> => {

	// TODO: To be replaced with HTTP request to the hotspot platform
	try {
		const response = await axios.post<GetUserHotspotsResponse>(`${SERVER_URL}/hotspots/en`, {
			apiKey: globals.ASURAN_HOTSPOT_KEY,
		});

		if (response.status === 200) {
			return response.data;
		}

		console.error('Error fetching hotspots: ', response.statusText);
	} catch (e) {
		console.error('Failed to fetch hotspots: ', e);
	}

	return null;
};
