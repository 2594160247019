import marked from 'marked';
import DOMPurify from 'dompurify';
import { useCallback } from 'react';


// const renderer = new marked.Renderer();
// renderer.link = function(href, title, text) {
// 	const link = marked.Renderer.prototype.link.call(this, href, title, text);
// 	const replaced = link.replace('<a','<a target="_blank" ');
// 	return replaced;
// };

/**
 * Parses text in Markdown format with a custom renderer:
 * - Text is sanitized
 * - Links are opened in a new tab
 * @param text the text to parse
 * @returns a function that can be used to parse text
 */
const useMarkedText = (): (text: string) => string => {

	const convert = useCallback((text: string) => {
		const html = marked(text, { breaks: true });

		/**
		 * Add target at the sanitize step to force any non traditional markdown links to open in a new tab
		 * Non-traditional link Eg: 
		 * ```
		 * Some text [Click Here], more text.
     * 
     * [ClickHere]: https://kemu.io
     * ```
     * The above would not be intercepted by `renderer.link`
		 **/
		DOMPurify.addHook('afterSanitizeAttributes', function (node) {
			// set all elements owning target to target=_blank
			if ('target' in node) { node.setAttribute('target', '_blank'); }
			// set non-HTML/MathML links to xlink:show=new
			if (!node.hasAttribute('target') && (node.hasAttribute('xlink:href') || node.hasAttribute('href'))) {
				node.setAttribute('xlink:show', 'new');
			}
		});

		const clean = DOMPurify.sanitize(html);
		return clean;
	}, []);

	return convert;
};


export default useMarkedText;
