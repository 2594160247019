
import React, { useCallback, useEffect } from 'react';
import { Radio, Layout, Button, theme } from 'antd';
import Icon, { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate, useMatch, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MyRecipesList from '../../marketplace/RecipeList/RecipeList';
import PageModal from '../../pageModal/pageModal';
import SectionHeaderWithButton from '../../sectionHeaderWithButton/sectionHeaderWithButton';
import AlgoliaSearchBox from '../../marketplace/AlgoliaSearchBox/AlgoliaSearchBox';
import { selectSignedUserProfile } from '../../../app/reducers/user/userSlice';
import { ReactComponent as KemuLogoAndText } from '../../../assets/img/kemuAndText.svg';
import MarketplaceMenu from './MarketplaceMenu/MarketplaceMenu';
import styles from './marketplace.module.css';
import GlobalSearchContextProvider from '@common/context/GlobalSearchContext/GlobalSearchContext';
import useCurrentWorkspacePath from '@common/hooks/useCurrentWorkspacePath';
import useTranslation from '@hooks/useTranslation';
import routes from '@common/routes/index';
import globals from '@common/globals';
// import 'antd/dist/antd.min.css';
import '../core.css';
import useQueryString from '@hooks/useQueryString';

const { Header, Content } = Layout;

export const MARKETPLACE_CONFIG_NAME = 'marketplace';
export interface MarketplaceCacheState {
	myRecipes: number;
	myTutorials: number;
	publicRecipes: number;
	publicThings: number;
}

export const MarketplaceCacheDefaultState: MarketplaceCacheState = {
	myRecipes: 5,
	publicRecipes: 5,
	myTutorials: 5,
	publicThings: 10,
};

const RecipeTabs = (props: {inMyRecipes: boolean}): React.JSX.Element => {
	const navigate = useNavigate();
	// const inMyRecipes = useMatch({ path: routes.marketplace.getMyRecipesRoute(), end: true });
	// const queryStr = useQueryString();
	// const inMyRecipes = queryStr.get('tab') === 'recipe';
	const t = useTranslation('Marketplace');

	const navigateToTab = (route: string) => {
		navigate(route);
	};

	return (
		<>
			<Radio.Button
				onClick={() => navigateToTab(routes.marketplace.getMyRecipesRoute())}
				type="link"
				className={`my-recipes-tab ${styles.SubCategoryBarBtn} ${props.inMyRecipes ? styles.SelectedSubCategory : ''}`}
			>
				{t('Navigation.MyRecipes', 'My Recipes')}
			</Radio.Button>
		</>
	);
};

const MarketplaceLayout = (): React.JSX.Element => {
	const navigate = useNavigate();
	const { tokens } = useSelector(selectSignedUserProfile);
	const { token: { colorBgContainer, colorPrimary } } = theme.useToken();
	const inMarketplaceRoot = useMatch({ path: routes.marketplace.getMarketplaceRoute(), end: true });
	const t = useTranslation('Marketplace');
	const getWorkspacePath = useCurrentWorkspacePath();
	const queryStr = useQueryString();
	const inMyRecipes = queryStr.get('tab') === 'recipe';
	// const inMyRecipes = useMatch({ path: routes.marketplace.getMyRecipesRoute(), end: true });

	// const inPublicRecipes = useMatch({ path: routes.marketplace.getPublicationListRoute('recipe') });
	const inPublicThings = useMatch({ path: routes.marketplace.getPublicationListRoute('thing') });




	const closeMarketplace = useCallback(() => {
		const workspacePath = getWorkspacePath();
		navigate(workspacePath);
	}, [getWorkspacePath, navigate]);


	const handleCreateRecipe = useCallback(() => {
		window.open(`https://${globals.KEMU_APP_DOMAIN}`, '_blank');
	}, []);

	// If no route defined, navigate to my recipes by default
	// useEffect(() => {
	// 	if (inMarketplaceRoot) {
	// 		navigate(routes.marketplace.getMyRecipesRoute());
	// 	}
	// }, [inMarketplaceRoot, navigate]);



	// const MarketplaceTabs = (): React.JSX.Element => {
	// 	return (
	// 		<>
	// 			<Radio.Button
	// 				onClick={() => navigateToTab(routes.marketplace.getPublicationListRoute('recipe'))}
	// 				type="link"
	// 				className={`public-recipes-tab ${styles.SubCategoryBarBtn} ${inPublicRecipes ? styles.SelectedSubCategory : ''}`}
	// 			>
	// 				{t('Navigation.PublicRecipes', 'Public Recipes')}
	// 			</Radio.Button>
	// 		</>
	// 	);
	// };


	return (
		<PageModal visible={true} fullScreen={true}>
			<div id="layout-container" className="light-theme marketplace">
				<Layout className="site-layout">
					<Header
						// className={`site-layout-background ${styles.Header}`}
						className={styles.Header}
						style={{
							backgroundColor: colorPrimary,
						}}
					>
						<Icon component={KemuLogoAndText} className={styles.HeaderLogo} />
						<Button data-kemu-meta="close-marketplace-btn" type="text"className={styles.BackButton} onClick={closeMarketplace} icon={<CloseOutlined style={{ fontSize: 24 }}/>} />
					</Header>


					<Layout>
						{/* Left Menu */}
						<MarketplaceMenu />
						{/* Wait for the user token to be provided before rendering anything */}
						{!!tokens && (
							<GlobalSearchContextProvider appId={globals.ALGOLIA_APP_ID} apiKey={tokens.algolia}>
								<Content>
									<div className={`${styles.Section} section`}>
										<div className={`subcategory-bar `} id={styles.SubCategoryBar}>
											<Radio.Group value='large'>
												{inMyRecipes && <RecipeTabs inMyRecipes />}
											</Radio.Group>

											{(inPublicThings || inMyRecipes) && (
												<div data-kemu-meta={'search-box'} className={styles.SearchBoxContainer}>
													<AlgoliaSearchBox
														placeholder={inMyRecipes ?
															t('MyRecipes.SearchPlaceholder', 'Search for recipes...') :
															t('PublicThings.SearchPlaceholder', 'Search for things...')
														}
													/>
												</div>
											)}
										</div>

										<div className={`${styles.SectionHeader} section-header`}>

											{inMyRecipes && (
												<SectionHeaderWithButton
													data-kemu-meta="create-new-recipe"
													// disabled={createTutorialState.status === AsyncRequestStatus.loading}
													// loading={createTutorialState.status === AsyncRequestStatus.loading}
													onClick={handleCreateRecipe}
													color="light"
													className={styles.CreateTutorialBtn}
													sectionTitle={t('Navigation.MyRecipes', 'My Recipes')}
													buttonTitle={
														<>
															{/* {createTutorialState.status !== AsyncRequestStatus.loading && <PlusOutlined /> } */}
															<PlusOutlined />
															<span>{t('MyRecipes.Header.CreateNewBtn', 'Create New')}</span>
														</>
													}
												/>
											)}
										</div>

										<div className={styles.SectionBody}>
											{inMyRecipes && <MyRecipesList />}
											{/* <Route path={routes.marketplace.getPublicationListRoute('recipe')} component={PublicRecipes} exact={true}/> */}
											{/* <Route path={routes.marketplace.getGenericPublicationDetailsRoute()} component={PublicationDetailsView} exact={true} /> */}
											{/* <Route path={routes.marketplace.getMyTutorialsRoute()} component={MyTutorials} exact={true} /> */}

											{/* <Route
												path={routes.marketplace.getPublicationListRoute('thing')}
												component={PublicThings}
												exact={true}
											/> */}

										</div>
									</div>
								</Content>
							</GlobalSearchContextProvider>
						)}
					</Layout>
				</Layout>
			</div>
		</PageModal>
	);
};

export default MarketplaceLayout;
