/*
 * Written by Alexander Agudelo < alex.agudelo@kitsunei.com >, 2020
 * Date: 11/Dec/2020
 * Last Modified: 26/06/2024, 8:10:19 pm
 * Modified By: Alexander Agudelo
 * Description:  Shows a loading animation when a recipe is being saved
 * 
 * ------
 * Copyright (C) 2020 Kitsunei - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { AsyncRequestStatus } from '../../types/core_t';
import AsyncOperationIndicator from '../asyncOperationIndicator/asyncOperationIndicator';
import { asyncOperationState } from '../../features/interface/interfaceSlice';


type MessageTypes = 'success' | 'progress' | 'error';

const SaveRecipeIndicator = (): React.JSX.Element | null => {
	const intl = useIntl();
	const operationState = useSelector(asyncOperationState);
	if (operationState?.action !== 'save') { return null; }

	const hasStorage = operationState.extra === true;
	const uploadStatus = operationState?.status;
	let message = intl.formatMessage({ id: 'SaveRecipeIndicator.Progress', defaultMessage: 'Saving Recipe...' });
	let messageType: MessageTypes = 'progress';
	const skipNotification = hasStorage === true && uploadStatus === AsyncRequestStatus.completed;

	if (uploadStatus === AsyncRequestStatus.completed) {
		// Abort showing success message if there is storage that needs to be uploaded
		if (hasStorage) { return null; }
		messageType = 'success';
		message = intl.formatMessage({ id: 'SaveRecipeIndicator.Success', defaultMessage: 'Recipe Saved!' });
	} else if (uploadStatus === AsyncRequestStatus.error) {
		messageType = 'error';
		message = intl.formatMessage({ id: 'SaveRecipeIndicator.Failure', defaultMessage: 'Failed to save recipe' });
	}


	if (operationState && !skipNotification) {
		return (
			<AsyncOperationIndicator message={message} type={messageType} errorReason={operationState.error?.message} />
		);
	}

	return null;
};

export default SaveRecipeIndicator;
