
import React, { useEffect } from 'react';
import { Menu } from 'antd';
import Icon from '@ant-design/icons';
import ParserWidgetProcessor, { ParserGateState } from '@kemu-io/kemu-core/widgets/parser/index.js';
import { useIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { WidgetPortContext } from '@kemu-io/kemu-core/types';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import { GetPortsInformationFunction, GateUI, GateUIProps } from '../index';
import GateIcon from '../../gateIcon/gateIcon';
import useNumericInput from '../../../common/hooks/useNumericInput';
import WidgetDropdownButton from '../../WidgetDropdownButton/WidgetDropdownButton';
import { ReactComponent as ElapsedGateIcon } from './parser.svg';
import styles from './parser.module.css';

type Props = GateUIProps

const ParserGate = (props: Props): React.JSX.Element => {
	const intl = useIntl();
	const [state, setState] = useReactiveWidgetState<ParserGateState>(props.recipeId, props.thingRecipeId, props.info.id);
	const numbersOnly = useNumericInput();
	const { repaint } = props;

	const fixedState: ParserGateState = {
		convertTo: 'Integer',
		floatPlaces: 2,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		...(state as any)
	};

	const inputChangedCb = (evt: React.ChangeEvent<HTMLInputElement>) => {
		let newValue = (evt.currentTarget.value as unknown) as number;
		if (isNaN(newValue)) { newValue = 1; }
		setState({
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			...(fixedState as any),
			floatPlaces: newValue < 1 ? 1 : newValue,
		}, true);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onConverterChosen = (e: any) => {
		setState({
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			...(state as any),
			convertTo: e.key
		});

	};

	useEffect(repaint, [state, repaint]);

	const menu = (
		<Menu onClick={onConverterChosen}>
			<Menu.Item key="String">
				<FormattedMessage id="LogicMaker.Gates.Parser.Converters.String" defaultMessage="String"/>
			</Menu.Item>
			<Menu.Item key="Integer">
				<FormattedMessage id="LogicMaker.Gates.Parser.Converters.Integer" defaultMessage="Integer"/>
			</Menu.Item>
			<Menu.Item key="Round">
				<FormattedMessage id="LogicMaker.Gates.Parser.Converters.Round" defaultMessage="Round"/>
			</Menu.Item>
			<Menu.Item key="Decimal">
				<FormattedMessage id="LogicMaker.Gates.Parser.Converters.Decimal" defaultMessage="Decimal"/>
			</Menu.Item>
			<Menu.Item key="Abs">
				<FormattedMessage id="LogicMaker.Gates.Parser.Converters.Abs" defaultMessage="Abs"/>
			</Menu.Item>
		</Menu>
	);


	return (
		<div className={`${styles.GateBody}`}>
			<WidgetDropdownButton
				menu={menu}
				name={fixedState.convertTo}
				widgetType="transformer"
			/>

			{state.convertTo === 'Decimal' && (
				<div className={classNames('gate-input', styles.DecimalsInput, {
					[styles.DisabledWidget]: props.info.disabled
				})}>
					<input
						title={intl.formatMessage({ id: 'LogicMaker.Gates.Parser.Tooltip',
						defaultMessage: 'decimal places' })}
						type="text"
						value={fixedState.floatPlaces}
						onChange={inputChangedCb}
						onInput={numbersOnly}
						className={styles.DecimalsInput}
					/>
				</div>
			)}
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={ElapsedGateIcon} />}/>
	);
};


const getPortsInformation: GetPortsInformationFunction = (state, widgetInfo) => {
	const portContext: WidgetPortContext = {
		recipePoolId: widgetInfo.recipePoolId,
		recipeType: widgetInfo.recipeType,
		thingRecipeId: widgetInfo.thingRecipeId,
		widgetId: widgetInfo.id,
	};
	const outputNames = ParserWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = ParserWidgetProcessor.getInputNames(state, portContext);

	return {
		inputs: [{
			name: inputNames[0].name,
			type: inputNames[0].type,
			position: 'Left'
		}],

		outputs: [{
			name: outputNames[0].name,
			type: outputNames[0].type,
			position: 'Right'
		}]
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: ParserGate,
	hasTitle: true,
	getWidgetTitle: (intl) => intl.formatMessage({ defaultMessage: 'Value Converter', id: 'LogicMaker.Gates.Parser.Title' }),
} as GateUI;
