import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import logicMapperReducer from '../features/LogicMapper/logicMapperSlice';
import workspaceReducer from '../features/Workspace/workspaceSlice';
import interfaceReducer from '../features/interface/interfaceSlice';
import marketplaceReducer from '@components/layout/marketplace/marketplaceSlice';
import mainMenuReducer from '@components/mainMenu/mainMenuSlice';
import publisherReducer from '@components/marketplace/publisher/publisherSlice';
import userReducer from './reducers/user/userSlice';
import widgetReducer from './reducers/widget/widgetSlice';
import websocketReducer from './reducers/websocket/websocketSlice';

export const store = configureStore({
  reducer: {
    workspace: workspaceReducer,
    logicMapper: logicMapperReducer,
    interface: interfaceReducer,
    marketplace: marketplaceReducer,
    recipePublisher: publisherReducer,
    mainMenu: mainMenuReducer,
    user: userReducer,
    widget: widgetReducer,
    websocket: websocketReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
