
import React, { CSSProperties, useMemo } from 'react';
import { WarningOutlined } from '@ant-design/icons';
import WidgetBundleProcessor, {
	WidgetBundleState,
	getDefaultState,
} from '@kemu-io/kemu-core/widgets/widgetBundle/index.js';
import { Tooltip } from 'antd';
import { CustomWidgetState, WidgetPortContext } from '@kemu-io/kemu-core/types';
import classNames from 'classnames';
import widgetBundleManager from '@kemu-io/kemu-core/widgetBundle';
import { WIDGET_BUNDLE_ICON_FILE_NAME } from '@kemu-io/kemu-core/common/constants';
import tinyColor from 'tinycolor2';
import {
	GetPortsInformationFunction,
	GateUI,
	GateUIProps,
} from '../index.ts';
import styles from './widgetBundle.module.css';
import useReactiveWidgetState from '@hooks/useReactiveWidgetState';
import useTranslation from '@hooks/useTranslation';
import SvgAsImage from '@components/SvgAsImage/SvgAsImage';
import { PortLocation } from '@src/types/canvas_t';

const compareStates = (prev: WidgetBundleState, next: WidgetBundleState): boolean => {
	const render = prev.$$processor !== next.$$processor
		|| prev.$$cacheInfo?.version !== next.$$cacheInfo?.version
		|| prev.storageUnitId !== next.storageUnitId;

	return render;
};


const widgetDefaultWidth = 70;
const widgetDefaultHeight = 50;

const WidgetBundleComponent = (props: GateUIProps): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<WidgetBundleState>(props.recipeId, props.thingRecipeId, props.info.id);
	const t = useTranslation('WidgetBundle');
	const fixedState = {
		...getDefaultState(),
		...state
	};

	const complementColor = useMemo(() => {
		if (fixedState.color) {
			return tinyColor(fixedState.color).complement().toString();
		}

		// Black by default
		return '#000';
	}, [fixedState.color]);

	const iconCachePath = fixedState.$$cacheInfo ?
		widgetBundleManager.getCacheLocation(
			fixedState.$$cacheInfo.widgetThingId,
			fixedState.$$cacheInfo.version,
			{
				isTempStorage:
					fixedState.collectionInfo?.widgetId !== fixedState.$$cacheInfo.widgetThingId,
				fullStorageRoot: true,
			}
		)
		: '';

	const styledBody: CSSProperties = fixedState.color && !props.info.disabled ? {
		backgroundColor: fixedState.color,
		// FIXME: These should not be set if the widget's processor has a GUI element
		width: widgetDefaultWidth,
		height: widgetDefaultHeight,
	} : {};

	return (
		<div className={`${styles.WidgetBody}`}>
			<div className={classNames(styles.Header)}>
				Bundle
			</div>
			<div className={classNames(styles.BundleContainer)} style={styledBody}>
				<div className={classNames(styles.Name)}>
					<label>{fixedState.name}</label>
				</div>

				{fixedState.$$processor ? (
					<Tooltip title={fixedState.description} placement="bottom" mouseEnterDelay={0.8}>
						<div className={styles.Icon}>
							<SvgAsImage
								width={42}
								height={42}
								src={`${iconCachePath}/${WIDGET_BUNDLE_ICON_FILE_NAME}` || ''}
							/>
						</div>
					</Tooltip>
				) : (
					<div
						className={styles.MissingProcessor}
						style={{ color: complementColor }}
					>
						Missing Processor
					</div>
				)}
			</div>
			{state.$$initializationError && (
				<div className={styles.InitErrorWarning}>
					<Tooltip
						placement='bottom'
						title={t('InitError', 'WidgetBundle.InitError', {
							errorMessage: state.$$initializationError,
						})}
					>
						<WarningOutlined />
					</Tooltip>
				</div>
			)}
		</div>
	);
};

// const GateCustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
// 	const [state, setState] = useReactiveWidgetState<WidgetBundleState>(
// 		props.recipeId,
// 		props.blockId,
// 		props.gateInfo.id,
// 		compareStates,
// 	);

// 	const fixedState = {
// 		...getDefaultState(),
// 		...state
// 	};

// 	const t = useTranslation('LogicMapper.Widgets.WidgetBundle.Settings');

// 	/* const handleFileAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
// 		// Get the file content's
// 		const files = event.target.files;
// 		if (!files?.length) {
// 			return;
// 		}

// 		// Get the first file
// 		const file = files[0];
// 		const reader = new FileReader();
// 		reader.onload = async (event) => {
// 			const result = event.target?.result;
// 			console.log('Processing bundle', result);
// 			const fileData = ab2u8Arr(result as ArrayBuffer);
// 			const process = await processTempBundleFile(fileData, props.gateInfo.id);
// 			if (!process) {
// 				console.log(`Invalid zip file, not a widget bundle`);
// 				return;
// 			}

// 			const prevCache = fixedState.$$tmpBundleFile?.cacheLocation;

// 			// By default new bundles are added to the recipe storage. Even if the user 
// 			// saves the widget as its own entity, it will still be saved in the recipe storage,
// 			// this means opening recipes with widget bundles should ALWAYS use the data
// 			// stored in the recipe and not that of the widget entity.
// 			const storageKey = addBundleToRecipeStorage(
// 				props.recipeId,
// 				props.blockId,
// 				fileData,
// 				fixedState.storageUnitId
// 			);

// 			setState({
// 				...fixedState,
// 				storageUnitId: storageKey,
// 				$$processor: process.processor,
// 				$$tmpBundleFile: {
// 					cacheLocation: process.storageFolder,
// 					zipFile: fileData,
// 				}
// 			});

// 			// Clear out previous cache

// 			if (prevCache) {
// 				deleteFilesInLocation(prevCache);
// 			}
// 		};

// 		reader.readAsArrayBuffer(file);
// 	}; */


// 	return (
// 		<div className={classNames(styles.SettingsContainer, SETTINGS_CONTAINER_CLASS)}>
// 			{props.children}

// 			<div>{fixedState.$$processor ? 'Loaded' : 'Empty' }</div>
// 		</div>
// 	);
// };

const getPortsInformation: GetPortsInformationFunction = (state: CustomWidgetState<WidgetBundleState>, widgetInfo) => {
	const portContext: WidgetPortContext = {
		recipePoolId: widgetInfo.recipePoolId,
		recipeType: widgetInfo.recipeType,
		thingRecipeId: widgetInfo.thingRecipeId,
		widgetId: widgetInfo.id,
	};

	const outputNames = WidgetBundleProcessor.getOutputNames(state, portContext);
	const inputNames = WidgetBundleProcessor.getInputNames(state, portContext);
	const portSize = 0.02;

	const getPositionFromIndex = (total: number, index: number, isInput: boolean): PortLocation => {
		const topPadding = total <= 12 ? 0.15 : 0.05;
		const inputFraction = ((1 - (topPadding + portSize)) / total);
		let space = (inputFraction * index) + topPadding;
		const totalPorts = total + (state.useTriggerPort ? 1 : 0);
		if (totalPorts === 1) { space = 0.5; }
		if (totalPorts === 2) { space = 0.30 + (0.4 * index); }
		if (totalPorts === 3) { space = 0.25 + (0.25 * index); }
		if (totalPorts === 4) { space = 0.27 + (0.17 * index); }
		if (totalPorts === 5) { space = 0.20 + (0.15 * index); }
		if (totalPorts === 6) { space = 0.20 + (0.13 * index); }

		return [isInput ? 0 : 1, space, isInput ? -1 : 1, 0];
	};

	return {
		outputs: outputNames.map((output, i) => {
			return  {
				name: output.name,
				type: output.type,
				position: getPositionFromIndex(outputNames.length, i, false),
				...(output.jsonShape ? { jsonShape: output.jsonShape } : undefined),
				...(output.label ? { label: output.label } : undefined),
			};
		}),

		inputs: inputNames.map((input, i) => {
			return  {
				name: input.name,
				type: input.type,
				position: getPositionFromIndex(inputNames.length, i, true),
				...(input.jsonShape ? { jsonShape: input.jsonShape } : undefined),
				...(input.label ? { label: input.label } : undefined),
			};
		})
	};
};

const intlKey = 'LogicMapper.Widgets.WidgetBundle';

export default {
	getPortsInformation,
	// BarIcon: GateBarIcon,
	Element: WidgetBundleComponent,
	// CustomSettingsDialog: GateCustomSettings,
	// hasTitle: true,
	// getGatesBarTitle: (intl) => {
	// 	return intl.formatMessage({
	// 		id: `${intlKey}.Title`,
	// 		defaultMessage: 'Widget Bundle'
	// 	});
	// },
	// getWidgetTitle: () => 'Bundle',
	getWrapperClass: () => styles.WidgetWrapper,
} as GateUI;
