
import React, { ChangeEvent, memo } from 'react';
import PixelsDrawProcessor, { PixelDrawState, getDefaultState } from '@kemu-io/kemu-core/widgets/pixelDraw/index.js';
import Icon from '@ant-design/icons';
import { WidgetState, WidgetPortContext } from '@kemu-io/kemu-core/types';
import classNames from 'classnames';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import { GateCustomSettingsProps, GateUI, GateUIProps, GetPortsInformationFunction } from '../index';
import GateIcon from '../../gateIcon/gateIcon';
import { PortLocation } from '../../../types/canvas_t';
import useTranslation from '../../../common/hooks/useTranslation';
import NumericWithoutArrows from '../../WidgetsComponents/NumericWithoutArrows/NumericWithoutArrows';
import NumericInput from '../../WidgetsComponents/NumericInput/NumericInput';
import ColorSwatch from '../../colorSwatch/colorSwatch';
import { SETTINGS_CONTAINER_CLASS } from '../../../common/constants';
import { ReactComponent as ExtractImageIcon } from './pixelDraw.svg';
import styles from './pixelDraw.module.css';
import TextInput from '@components/WidgetsComponents/TextInput/TextInput';

interface Props extends GateUIProps {
	dataSources?: {
		name: string;
		inputs: string[]
	}[]
}

const mergeStates = (state: PixelDrawState) => {
	const fixedState: PixelDrawState = {
		...getDefaultState(),
		...state
	};

	return fixedState;
};

const shouldUpdateState = (prev: WidgetState<PixelDrawState>, newState: WidgetState<PixelDrawState>): boolean => {
	const prevHadPixels = !!prev.$$pixels;
	const newHasPixels = !!newState.$$pixels;
	const prevHadFrame = !!prev.$$lastFrame;
	const newHasFrame = !!newState.$$lastFrame;

	return prevHadPixels !== newHasPixels
		|| prevHadFrame !== newHasFrame
		|| prev.color !== newState.color
		|| prev.size !== newState.size;
};

const PixelDraw = (props: Props): React.JSX.Element => {
	const t = useTranslation('Widgets.PixelDraw');
	const [state] = useReactiveWidgetState<PixelDrawState>(props.recipeId, props.thingRecipeId, props.info.id, shouldUpdateState);

	return (
		<div className={`extractImage-gate standard-body ${styles.GateBody}`}>
			<div className="gate-input">
				<input type="text" value={state.$$pixels && state.$$lastFrame ? t('Active', 'Active') : t('Waiting', '...')} disabled/>
			</div>
		</div>
	);
};



const GateCustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
	const t = useTranslation('Widgets.PixelDraw');
	const [state, setState] = useReactiveWidgetState<PixelDrawState>(
		props.recipeId,
		props.blockId,
		props.gateInfo.id,
		// shouldUpdateState
	);

	const fixedState = mergeStates(state);

	const handleColorChange = (color: string) => {
		setState({
			...state,
			color: color
		});
	};

	const onChangeSize = (size: number) => {
		setState({
			...state,
			size
		});
	};

	const handleLabelPropChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setState({
			...state,
			labelProp: value
		});
	};

	return (
		<div className={classNames(styles.SettingsContainer, SETTINGS_CONTAINER_CLASS)}>
			{props.children}
			<div>
				<div className={styles.Row}>
					<label>{t('Settings.ColorTitle', 'Color')}</label>

					<ColorSwatch
						color={state.color}
						onColorChange={handleColorChange}
						popupClassName={styles.ColorPicker}
					/>
				</div>

				<div className={styles.Row}>
					<label>{t('Settings.Size', 'Color')}</label>
					<NumericInput
						min={1}

						className={classNames(styles.Input)}
						value={fixedState.size}
						onChange={onChangeSize}
					/>
				</div>

				<div className={styles.Row}>
					<label>{t('Settings.LabelProperty')}</label>
					<TextInput
						className={classNames(styles.LabelProp, styles.Input)}
						value={fixedState.labelProp || ''}
						onChange={handleLabelPropChange}
					/>
				</div>

				<div className={styles.Row}>
					<label>{t('Settings.FontSize')}</label>
					<NumericInput
						min={1}
						disabled={!state.labelProp}
						className={classNames(styles.Input)}
						value={fixedState.fontSize}
						onChange={(v) => setState({ ...state, fontSize: v })}
					/>
				</div>
			</div>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={ExtractImageIcon} />}/>
	);
};


const getPortsInformation: GetPortsInformationFunction = (state, widgetInfo) => {
	const portContext: WidgetPortContext = {
		recipePoolId: widgetInfo.recipePoolId,
		recipeType: widgetInfo.recipeType,
		thingRecipeId: widgetInfo.thingRecipeId,
		widgetId: widgetInfo.id,
	};
	const outputNames = PixelsDrawProcessor.getOutputNames(state, portContext);
	const inputNames = PixelsDrawProcessor.getInputNames(state, portContext);

	const positions: Record<string, PortLocation> = {
		'image': [0, 0.33, -1, 0],
		'pixels': [0, 0.66, -1, 0]
	};

	return {
		inputs: inputNames.map(input => ({
			name: input.name,
			type: input.type,
			position: positions[input.name]
		})),

		outputs: [{
			name: outputNames[0].name,
			type: outputNames[0].type,
			position: 'Right'
		}]
	};
};

export default {
	CustomSettingsDialog: memo(GateCustomSettings),
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: memo(PixelDraw),
	hasTitle: true,
	getWidgetTitle: (intl) => intl.formatMessage({ defaultMessage: 'Draw Pixels', id: 'Widgets.PixelDraw.Title' }),
} as GateUI;
