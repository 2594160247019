import React, { useRef, useState } from 'react';
import { usePreview } from 'react-dnd-preview';
import { usePreviewStateFull } from 'react-dnd-preview/dist/cjs/usePreview.js';
import CustomWidgetIcon, { CustomWidgetIconInfo } from '../CustomWidgetIcon/CustomWidgetIcon';
import styles from './CustomWidgetDragPreview.module.css';
import { DroppableTypes } from '@common/constants';
import InjectSvg from '@components/InjectSvg/InjectSvg';

type ServicesDragContext = {
	svgIcon: string;
	textColor: string;
	contentColor: string;
	isHubService: true;
}

/** Shows up when a widget is being dragged */
const CustomWidgetDragPreview = (): React.JSX.Element | null => {
	const {
		display,
		style,
		itemType,
		item: widgetInfo
	} = usePreview() as usePreviewStateFull<CustomWidgetIconInfo | ServicesDragContext>;

	const serviceDragInfo = (widgetInfo as ServicesDragContext)?.isHubService ? widgetInfo as ServicesDragContext : null;
	const widget = !serviceDragInfo ? widgetInfo as CustomWidgetIconInfo : null;

	const counterRef = useRef(0);
	const [svgRef, setTargetSvgRef] = useState<HTMLElement | null>(null);

	if (!display || itemType !== DroppableTypes.CustomWidget) {
		return null;
  }

	return (
		<div className={styles.DragPreview} style={{ ...style }}>
			{serviceDragInfo?.svgIcon ? (
				<div
					ref={(e) => setTargetSvgRef(e)}
					className={styles.ServiceButton}
					style={{ backgroundColor: serviceDragInfo.contentColor }}
				>
					<InjectSvg
						contents={serviceDragInfo.svgIcon}
						fill={serviceDragInfo.textColor}
						targetElement={svgRef}
						className={styles.Icon}
					/>
				</div>
			) : (
				<CustomWidgetIcon
					color={widget?.color}
					icon={widget?.icon}
					isBundle={widget?.isBundle}
				/>
			)}
		</div>
	);
};


export default CustomWidgetDragPreview;
