import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserSessionContext } from '@common/context/UserSessionContext/UserSessionContext';
import routes from '@common/routes/index';

type Props = {
  children: React.ReactNode;
  onSessionSet?: (loaded: boolean) => void;
}

const DirectRouteWrapper = (props: Props) => {
  const { onSessionSet } = props;
  const session = useContext(UserSessionContext);
  const navigate = useNavigate();

  useEffect(() => {
		if (session.state === 'NotLoggedIn') {
			// Only redirect once we are SURE the user is not logged in
			console.log('Redirecting to sign in');
			navigate(routes.auth.getSignInRoute());
		} else if (session.state === 'LoggedIn') {
      onSessionSet && onSessionSet(true);
    }
	}, [session.state, navigate, onSessionSet]);


	if (session.state === 'Unknown') {
    return null;
	}

	if (session.state === 'NotLoggedIn') {
		return null;
	}

  return props.children;
};

export default DirectRouteWrapper;
