import React, { useCallback, useState } from 'react';
import { Modal } from 'antd';
import { ButtonColors, StyledButtonClassName } from '../form-control/styledButton/styledButton';
import styles from './alert.module.css';

interface AlertOptions {
	title: string | React.ReactNode;
	iconColor?: 'info' | 'warning' | 'danger' | 'success';
	icon?: React.JSX.Element;
	content: string | React.ReactNode;
	okText: string;
	cancelText?: string;
	okButtonColor?: ButtonColors;
	cancelButtonColor?: ButtonColors;
	onOk?: ()=> void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onCancel?: (...args: any[]) => any;
  afterClose?: () => void;
}


export interface UseAlertInstance {
	/** alert with yes/no buttons */
	confirm: (options: AlertOptions) => void;
	info: (options: AlertOptions) => void;
	warn: (options: AlertOptions) => void;
	success: (options: AlertOptions) => void;
	error: (options: AlertOptions) => void;
}

const getStandardProps = (options: AlertOptions) => {
	return {
		...options,
		cancelButtonProps: {
			className: StyledButtonClassName,
			'btn-type': options.cancelButtonColor || 'light'
		},

		okButtonProps: {
			className: StyledButtonClassName,
			'btn-type': options.okButtonColor || 'primary'
		}
	};
};

const useAlert = (): UseAlertInstance => {
	const confirm = useCallback((options: AlertOptions) => {
		const settings = getStandardProps(options);
		if (options.iconColor) {
			delete options.icon;
			// @ts-expect-error className is a custom property from Modal.confirm
			settings.className = `${styles.Wrapper} ${options.iconColor}`;
		}

		Modal.confirm(settings);
	}, []);

	const info = useCallback((options: AlertOptions) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { cancelButtonProps, ...settings } = getStandardProps(options);
		Modal.info(settings);
	}, []);

	const error = useCallback((options: AlertOptions) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { cancelButtonProps, ...settings } = getStandardProps({ okButtonColor: 'danger', ...options });
		Modal.error(settings);
	}, []);

	const warn = useCallback((options: AlertOptions) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { cancelButtonProps, ...settings } = getStandardProps({ okButtonColor: 'warning', ...options });
		Modal.warning(settings);
	}, []);

	const success = useCallback((options: AlertOptions) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { cancelButtonProps, ...settings } = getStandardProps({ okButtonColor: 'primary', ...options });
		Modal.success(settings);
	}, []);

	const [alert] = useState({
		confirm,
		info,
		error,
		warn,
		success
	});

	return alert;
};

export default useAlert;
