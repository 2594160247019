/* eslint-disable @typescript-eslint/no-explicit-any */
/*
 * Written by Alexander Agudelo < alex@kemu.io >, 2024
 * Date: 22/May/2024
 * Last Modified: 20/07/2024, 10:34:24 am
 * Modified By: Alexander Agudelo
 * Description:  Kemu Hub Communication Link. A websocket client that connects to the Kemu Hub server.
 * 
 * ------
 * Copyright (C) 2024 Kemu - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */

// import { handleKlMessage } from './klMessageManager';
import { createTransmissionManager } from '@kemu-io/hs/transmissionManager';
import bKlProtocol from '@kemu-io/hs/bkl';
import { MessageHandler, ServerClientConnectedCb, ServerCommandCb } from '@src/types/kemuHub/hub_t';
import { KLHeaderSize, KLMessageInFlight } from '@src/types/kemuHub/kemuLink_t';
import createLogger from '@common/logger';

const KEMU_DEFAULT_HOST_URL = 'ws://localhost:8080';

let socket: WebSocket | null = null;
const logger = createLogger('link');
const handleKlMessage = createTransmissionManager<ArrayBuffer>();

let onCommandCb: ServerCommandCb;
// let onExecutionFuncCb: ExecuteFunctionHandler;
let onConnectedCb: ServerClientConnectedCb;
let onDisconnectedCb: () => void;
let onMessageReceivedCb: MessageHandler;

let activeMessage: KLMessageInFlight<ArrayBuffer> | null = null;
let reconnectTmr: NodeJS.Timeout | null = null;

/**
 * Starts the IPC client, connecting to the server
 */
const connect = (config?: { url?: string, reconnectInterval?: number}): Promise<void> => {
  return new Promise((resolve) => {
    if (socket && socket.readyState !== WebSocket.CLOSED) {
      logger.log(`Socket is in connecting state ${socket.readyState}. Aborting connection request`);
      return resolve();
    }

    const defaultInterval = config?.reconnectInterval ?? 1500;
    logger.log('Connecting...');
    socket = new WebSocket(config?.url || KEMU_DEFAULT_HOST_URL);
    socket.binaryType = 'arraybuffer'; // Set to receive binary data as ArrayBuffer  

    socket.onopen = function () {
      logger.log('Connected to the server');
      onConnectedCb && onConnectedCb();
      resolve();
    };

    const scheduleReconnect = () => {
      if (reconnectTmr) { return; }

      if (defaultInterval > 0) {
        reconnectTmr = setTimeout(() => {
          reconnectTmr = null;
          connect({ url: config?.url, reconnectInterval: defaultInterval });
        }, defaultInterval);
      }
    };

    socket.onclose = () => {
      logger.log('Disconnected from the server');
      onDisconnectedCb && onDisconnectedCb();
      socket = null;
      scheduleReconnect();
    };

    socket.onerror = function (error) {
      logger.error('WebSocket error: ', (error as any).message);
      if (socket) {
        socket.onclose = null;
        socket.onopen = null;
        socket.onerror = null;
        socket.onmessage = null;
        if (socket.readyState !== WebSocket.CLOSED) {
          socket.close();
        }
        socket = null;
      }

      scheduleReconnect();
    };

    socket.onmessage = function (event) {
      const data = event.data as ArrayBuffer;

      if (typeof data !== 'string') {
        logger.log(`Socket received binary data: ${data.byteLength} bytes`);
        handleKlMessage(data, activeMessage, (um) => activeMessage = um, (result) => {
          // logger.log('Received data:', data);
          if (!result.complete) { return; }

          if (result.command) {
            logger.log(`Received command: ${result.command}`);
            onCommandCb && onCommandCb(result.command);
            return;
          }

          if (result.klMessage) {
            onMessageReceivedCb && onMessageReceivedCb({
              json: result.klMessage.json,
              send: sendBuffer,
              transmission: {
                sourceServiceId: result.sourceServiceId ?? -1,
                targetServiceId: result.targetServiceId ?? -1,
                rawMessage: result.rawMessage!,
              }
            });
            return;
          }
        });
      }
    };
  });
};


const sendBuffer = (buf: ArrayBuffer) => {
  if (socket && socket.readyState === 1) {
    socket.send(buf);
  } else {
    logger.error('Socket is not connected');
  }
};

/**
 * Sends raw data to a client without encoding it.
 * @param clientId the id of the client to send the message to
 * @param message the raw string data to send
 * @returns false if the client is not connected, true otherwise.
 */
const sendCommand = (message: string): boolean => {
  if (message.length >= KLHeaderSize) {
    logger.error(`Message is too long to be a command. Use sendMessage instead.`);
    return false;
  }

  const b = bKlProtocol.encodeCommand(message);
  sendBuffer(b);
  return true;
};


const disconnect = () => {
  if (socket) {
    socket.close();
    socket = null;
  }
};

const isConnected = () => {
  return Boolean(socket && socket.readyState === 1);
};

export default {
  connect,
  disconnect,
  isConnected,
  sendBuffer,
  onCommand: (cb: ServerCommandCb) => onCommandCb = cb,
  onMessageReceived: (cb: MessageHandler) => onMessageReceivedCb = cb,
  onConnected: (cb: ServerClientConnectedCb) => onConnectedCb = cb,
  onDisconnected: (cb: () => void) => onDisconnectedCb = cb,
  sendCommand,
};
