import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import decamelize from 'decamelize';

interface Props {
	className?: string;
	/** Name of the font-awesome icon */
	iconName?: string;
	/** 
	 * Default icon to show if the given name is not found. If not provided
	 * a `cube` icon is shown instead.
	 **/
	default?: React.ReactNode;
}

const ignoreIcons = [
	'faPastarianism',
	'faFontAwesomeLogoFull',
	'faStopwatch20',
	'fas',
	'prefix'
];

export const getValidIconNames = (): string[] => {
	const validNames = Object.keys(Icons)
		.filter((key) => !ignoreIcons.includes(key))
		.map((icon) => decamelize(icon, { separator: '-' }).replace(/fa-/g, ''));

	return validNames;
};

/**
 * Loads FontAwesome icons by name
 */
const DynamicIcon = (props: Props): React.JSX.Element => {

	const DefaultIcon = () => {
		return (
			<>
				{props.default}
			</>
		);
	};

	const SafeIcon = (safeProps: { name: string }): React.JSX.Element => {
		if (getValidIconNames().includes(safeProps.name)) {
			return <FontAwesomeIcon icon={['fas', safeProps.name as IconName]} className={props.className} />;
		} else {
			return <DefaultIcon />;
		}
	};

	return (
		<>
			{props.iconName ? (
				<SafeIcon name={props.iconName} />
			) : (
				<>
					{props.default ? (
						<>{props.default}</>
					) : (
						<FontAwesomeIcon icon={['fas', 'cube']} className={props.className}/>
					)}
				</>
			)}
		</>
	);
};

export default DynamicIcon;
