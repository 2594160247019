import React from 'react';
import HTMLContent from '../htmlContent/htmlContent';
import styles from './videoContent.module.css';

const VideoContent = (props: {text?: string, videoUrl?: string}): React.JSX.Element => {
	const { text, videoUrl } = props;
	return (
		<>
			<div className={styles.Descriptor}>
				<HTMLContent content={text}/>
			</div>
			<div className={styles.VideoContainer}>
				<video autoPlay loop src={videoUrl}>Video no supported</video>
			</div>
		</>
	);
};

export default VideoContent;
