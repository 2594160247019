
import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import CounterWidgetProcessor from '@kemu-io/kemu-core/widgets/counter/index.js';
import { WidgetPortContext } from '@kemu-io/kemu-core/types';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import { GateUI, GateUIProps, GetPortsInformationFunction } from '../index';
import GateIcon from '../../gateIcon/gateIcon';
import { PortLocation } from '../../../types/canvas_t';
import NumericWithoutArrows from '../../WidgetsComponents/NumericWithoutArrows/NumericWithoutArrows';
import useTranslation from '../../../common/hooks/useTranslation';

interface Props extends GateUIProps {
	dataSources?: {
		name: string;
		inputs: string[]
	}[]
}


const CounterGate = (props: Props): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState(props.recipeId, props.thingRecipeId, props.info.id);
	const initialValue = state.currentValue || 0;
	const t = useTranslation();

	const inputChangedCb = (value: number) => {
		setState({ ...state, currentValue: value }, true);
	};

	return (
		<div className="counter-gate standard-body">
			<div className="gate-input">
				<NumericWithoutArrows
					onChange={inputChangedCb}
					value={initialValue as string}
					placeholder={t('LogicMaker.Gates.Counter.Placeholder', 'Initial value')}
				/>
			</div>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<PlusOutlined />}/>
	);
};


const getPortsInformation: GetPortsInformationFunction = (state, widgetInfo) => {
	const portContext: WidgetPortContext = {
		recipePoolId: widgetInfo.recipePoolId,
		recipeType: widgetInfo.recipeType,
		thingRecipeId: widgetInfo.thingRecipeId,
		widgetId: widgetInfo.id,
	};
	const outputNames = CounterWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = CounterWidgetProcessor.getInputNames(state, portContext);

	const positions: Record<string, PortLocation> = {
		in: [0, 0.24, -1, 0],
		reset: [0, 0.5, -1, 0],
		increment: [0, 0.76, -1, 0]
	};

	return {
		inputs: inputNames.map(input => ({
			name: input.name,
			type: input.type,
			position: positions[input.name]
		})),

		outputs: [{
			name: outputNames[0].name,
			type: outputNames[0].type,
			position: 'Right'
		}]
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: CounterGate,
	hasTitle: true,
	getWidgetTitle: (intl) => intl.formatMessage({ defaultMessage: 'Counter', id: 'LogicMaker.Gates.Counter.Title' }),
} as GateUI;
