/*
 * Written by Alexander Agudelo < alex@asurantech.io >, 2021
 * Date: 07/Jul/2021
 * Last Modified: 26/06/2024, 8:10:41 pm
 * Modified By: Alexander Agudelo
 * Description:  Main component, loads the manager.
 * 
 * ------
 * Copyright (C) 2021 Asurantech - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */

import React from 'react';
import useHelpHeroTourState from '../../common/hooks/useHelpHeroTourState';
import TooltipHotspot from './components/tooltip';
import useHotspotManager from './hooks/useHotspotManager';

const Hotspots = (): React.JSX.Element => {
	const { active } = useHelpHeroTourState();
	const { hotspots, globals } = useHotspotManager();

	return (
		<>
			{!active && hotspots.map((hotspot) => (
				hotspot.mode === 'tooltip' && (
					<TooltipHotspot
						key={hotspot.runtimeId}
						info={hotspot}
						scriptPrepend={globals.javascript || ';'}
					/>
				)
			))}
		</>
	);
};

export default Hotspots;
