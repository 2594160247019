import React from 'react';
import Icon from '@ant-design/icons';
import useTranslation from '../../../common/hooks/useTranslation';
import styles from './publisher.module.css';

import { UploadedAsset } from './publisher';
import { ReactComponent as HeroIcon } from './images/hero.svg';
import { ReactComponent as SlideIcon } from './images/slide.svg';
import { ReactComponent as ThumbnailIcon } from './images/thumbnail.svg';
import UploaderWithPreview from './uploadWithPreview/uploaderWithPreview';

interface Props {
	className?: string;
	assets: UploadedAsset[];
	onFileAdded: (assets: UploadedAsset) => void;
	onFileRemoved: (assets: UploadedAsset) => void;
	recipeId: string;
}


const RecipeImages = (props: Props): React.JSX.Element => {
	const t = useTranslation('Marketplace.MyRecipes.PublishWizard');

	return (
		<div className={props.className || ''}>
			<UploaderWithPreview
				{...props}
				section="hero"
				multiple={false}
				acceptedTypes=".jpg, .jpeg, .png, .mp4, .ogg, .gif"
				assets={props.assets.filter(file => file.section === 'hero')}
				label={t('FileDragHero.Label', 'Hero Image')}
				title={t('FileDragHero.Title', 'This will be the main image of your recipe')}
				description={t('FileDragHero.Description', 'Supported files are images or videos (.jpg, .jpeg, .png, .mp4, .ogg)')}
				className={styles.AssetsContainer}
				icon={<Icon component={HeroIcon} />}
			/>


			<UploaderWithPreview
				{...props}
				section="thumbnail"
				multiple={false}
				acceptedTypes=".jpg, .jpeg, .png"
				assets={props.assets.filter(file => file.section === 'thumbnail')}
				label={t('FileDragThumbnail.Label', 'Thumbnail Image')}
				title={t('FileDragThumbnail.Title', 'This is the image shown as a preview in the marketplace')}
				description={t('FileDragThumbnail.Description', 'Supported files are images (.jpg, .jpeg, .png)')}
				className={styles.AssetsContainer}
				icon={<Icon component={ThumbnailIcon} />}
			/>

			<UploaderWithPreview
				{...props}
				section="asset"
				multiple={true}
				acceptedTypes=".jpg, .jpeg, .png, .mp4, .ogg"
				assets={props.assets.filter(file => file.section === 'asset')}
				label={t('FileDragOther.Label', 'Other Images & Videos')}
				title={t('FileDragOther.Title', 'Add other images and videos for your recipe')}
				description={t('FileDragOther.Description', 'Supported files are images or videos (.jpg, .jpeg, .png, .mp4, .ogg, .gif)')}
				className={styles.AssetsContainer}
				icon={<Icon component={SlideIcon} />}
			/>
		</div>
	);
};

export default RecipeImages;
