/**
 * @returns the base route from which all other routes are derived
 */
const getBaseRoute = (): string => '/';

/**
 * Used to launch actions directly without loading the workspace. Eg, Importing a public recipe
 * @param action 
 * @returns 
 */
const getDirectActionRoute = (): string => `/d`;

export {
  getBaseRoute,
  getDirectActionRoute
};
