import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import Badge from 'antd/lib/badge';
import Icon from '@ant-design/icons';
import { Position } from '@kemu-io/kemu-core/types';
import { SerializableServiceInfo } from '@kemu-io/hs-types';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import styles from './ServiceButton.module.css';
import GateIcon from '@components/gateIcon/gateIcon';
import InjectSvg from '@components/InjectSvg/InjectSvg';
import TruncatedText from '@components/truncatedText/truncatedText';
import { ReactComponent as DefaultServiceIcon } from '@src/assets/img/gates/hubService.svg';
import { DroppableTypes } from '@common/constants';
import { calculateWidgetColors } from '@components/gates/common';

type Props = {
  onClick: (service: SerializableServiceInfo, variantId?: string) => void;
  onAddService: (info: {
    name: string,
    version: string,
    variant?: string
  },
  dropLocation: Position
) => void;
	onHideLaunchpad: () => void;
  service: SerializableServiceInfo;
  disabled?: boolean;
  variantId?: string;
  isSelected: boolean;
  disableDragging?: boolean;
  noBadge?: boolean;
  customSvgIcon?: React.ReactElement;
  wrapperClassName?: string;
  iconClassName?: string;
}

const DefaultServiceColorVar = '--kemu-color-widget-type-custom';

const ServiceButton = (props: Props) => {
  const {
    service, onAddService, onHideLaunchpad, customSvgIcon,
    disabled, onClick, disableDragging, noBadge, variantId,
  } = props;
  const [iconTarget, setIconTarget] = useState<HTMLElement | null>(null);
  const [textExpanded, setTextExpanded] = useState(false);
  const matchingVariant = service.variants?.find(v => v.id === variantId);

  const textVisibilityChanged = useCallback((visible: boolean) => {
		setTextExpanded(visible);
	}, []);

  const colors = useMemo(() => {
		const docStyle = getComputedStyle(document.body);
    const widgetColor = matchingVariant?.color || service.color;
    const customWidgetColor = docStyle.getPropertyValue(DefaultServiceColorVar);
		return calculateWidgetColors(widgetColor || customWidgetColor);
	}, [service.color, matchingVariant]);


  const [{ isDragging }, drag, preview] = useDrag<any, Position, any>(() => ({
    type: DroppableTypes.CustomWidget,
		canDrag: !disabled && !disableDragging,
    item: {
			color: service.color,
			textColor: colors.text,
			isHubService: true,
			contentColor: colors.content,
			svgIcon: service.svgIcon,
		},
    end: (item, monitor) => {
      const dropLocation = monitor.getDropResult();
			if (dropLocation) {
				onAddService({
          name: service.name,
          version: service.version,
          variant: variantId,
        }, {
					x: dropLocation.x,
					y: dropLocation.y,
				});
			}
    },

    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }), [service, onAddService]);

  /** shows a preview image and hides the launchpad while dragging */
  useEffect(() => {
		const emptyImage = getEmptyImage();
		preview(emptyImage);
		if (isDragging) {
			onHideLaunchpad();
		}
	}, [preview, isDragging, onHideLaunchpad]);

  return (
    <div
      className={
        classNames(styles.ServiceButton, {
          'text-expanded': textExpanded,
          [styles.Selected]: props.isSelected,
          'disabled': props.disabled
        }, props.wrapperClassName)
      }
      data-kemu-meta={`hub-service-${service.name}-${service.version}`}
      ref={drag}
      onClick={() => onClick(service, variantId)}
    >
      <Badge
        count={'dev'}
        style={{
          display: !service.devMode || noBadge ? 'none': 'block'
        }}
        size='small'
        showZero
        offset={[-5, 0]}
        color='red'
      >
        <div
          ref={e => setIconTarget(e)}
          style={{ backgroundColor: props.disabled ? 'gray' : colors.content }}
          className={classNames(styles.Icon, props.iconClassName)}
        >
          {customSvgIcon ? customSvgIcon : (
            service.svgIcon ? (
              <InjectSvg
                contents={matchingVariant?.svgIcon || service.svgIcon}
                fill={colors.text}
                targetElement={iconTarget}
              />
            ) : (
              <GateIcon
                style={{ backgroundColor: props.disabled ? 'gray' : colors.content }}
                icon={<Icon component={DefaultServiceIcon} />}
              />
            )
          )}
        </div>
      </Badge>

      <div className={classNames(styles.ServiceName, 'noselect')}>
        <label>
          <TruncatedText
            text={`${matchingVariant?.name || service.title || service.name}`}
            maxChars={24}
            onVisibilityChange={textVisibilityChanged}
            showFullOn="hover"
            // suffix={<div>({service.version})</div>}
          />
        </label>
      </div>
    </div>
  );
};

export default ServiceButton;
