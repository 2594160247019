import { PayloadAction, Draft } from '@reduxjs/toolkit';
import { InterfaceState } from '../interfaceSlice';

export const setRecipeUploadProgressReducer = (state: Draft<InterfaceState>, action: PayloadAction<{
	content?: number;
	storage?: number,
	hasStorage: boolean
}>): void => {
	if (state.recipeUploadDetails) {
		if (action.payload.content) {
			state.recipeUploadDetails.contentProgress = action.payload.content;
		}

		if (action.payload.storage) {
			state.recipeUploadDetails.storageProgress = action.payload.storage;
		}

		state.recipeUploadDetails.hasStorage = action.payload.hasStorage;
	}
};
