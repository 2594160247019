import React, { useState } from 'react';
import StyledInput from '../form-control/styledInput/styledInput';
import StandardModal from '../modal/standardModal';
import useTranslation from '@hooks/useTranslation';

interface Props {
	onSubmit: (name: string) => void;
	onCancel: () => void;
	currentName: string;
	/** default to false */
	closeOnMaskClick?: boolean;
	/** default true */
	submitOnEnter?: boolean;
	/** default 'Save' */
	okLabel?: string | React.ReactNode;
	/** default 'Cancel' */
	cancelLabel?: string | React.ReactNode;
	/** default 'Rename' */
	title?: string;
	placeholder?: string;
	width?: number;
}

const RenameModal = (props: Props): React.JSX.Element => {
	const [newName, setNewName] = useState(props.currentName);
	const [visible, setVisible] = useState(true);
	const t = useTranslation('Interface.Modals');

	const handleOk = () => {
		props.onSubmit && props.onSubmit(newName);
	};

	const handleCancel = () => {
		setVisible(false);
	};

	const reportClosed = () => {
		props.onCancel && props.onCancel();
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			handleOk();
		}
	};

	return (
		<StandardModal
			dataKemuMeta="rename-modal"
			closeOnMaskClick={props.closeOnMaskClick || false}
			onOk={handleOk}
			onCancel={handleCancel}
			onAfterClosed={reportClosed}
			title={props.title || t('Rename.Title', 'Rename')}
			okBtnLabel={props.okLabel || t('Rename.OkBtn', 'Save')}
			cancelBtnLabel={props.cancelLabel || t('Rename.CancelBtn', 'Cancel')}
			width={props.width || 300}
			disableOkButton={!newName.trim().length}
			visible={visible}
		>
			<StyledInput
				onChange={(e) => setNewName(e.currentTarget.value)}
				value={newName}
				onKeyDown={handleKeyDown}
				placeholder={props.placeholder !== undefined
					? props.placeholder
					: t('Rename.Placeholder', 'Type a new name')
				}
			/>

	</StandardModal>
	);
};

export default RenameModal;
