import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { notification } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as KemuBotIcon } from '../../assets/img/kemu-bot.svg';
import { newVersionAvailable } from '../../features/interface/interfaceSlice';
import style from './VersionAvailablePopup.module.css';

type NotificationProps = {
	title: string;
	description: React.ReactNode;
}

const NotificationContent = (props: NotificationProps): React.JSX.Element => {
	return (
		<div className={style.NotificationContainer}>
			<Icon component={KemuBotIcon} className={style.Icon} />
			<div>
				<span className={style.Title}>
					{props.title}
				</span>
				<br/>
				<span className={style.Body}>
					{props.description}
				</span>
			</div>
		</div>
	);
};

const VersionAvailablePopup = (): null => {
	const intl = useIntl();
	const versionAvailable = useSelector(newVersionAvailable);


	useEffect(() => {
		if (versionAvailable) {
			const t = intl.formatMessage;

			const handleRefresh = () => {
				window.location.reload();
			};

			const title = t({ id: 'Interface.Modals.VersionAvailablePopup.Title', defaultMessage: 'Kemu has been upgraded.' });
			const description = t(
				{
					id: 'Interface.Modals.VersionAvailablePopup.Description',
					defaultMessage: '{refreshBrowserLink} to activate it.',
				},
				{
					refreshBrowserLink: <a onClick={handleRefresh}>
						{
							t({
								id: 'Interface.Modals.VersionAvailablePopup.RefreshLinkText',
								defaultMessage: 'Refresh this page'
							})
						}
					</a>
				}
			);

			notification.open({
				key: 'VersionNotifier',
				className: style.Container,
				duration: 0,
				placement: 'bottomLeft',
				message: '',
				description: <NotificationContent title={title} description={description}/>
			});
		}

	}, [versionAvailable, intl]);

	return null;
};

export default VersionAvailablePopup;
