import React from 'react';
import useMarkedText from '@hooks/useMarkedText';

interface Props {
	content?: string;
	noMarkdown?: boolean;
}

const HTMLContent = (props: Props): React.JSX.Element => {
	const convert = useMarkedText();
	if (!props.content) { return <></>; }

	return (
		<div dangerouslySetInnerHTML={{ __html: props.noMarkdown ? props.content : convert(props.content) }} />
	);
};

export default HTMLContent;
