import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { message } from 'antd';
import { selectWidgetAsyncStatus } from '../../app/reducers/widget/widgetSlice';
import { AsyncRequestStatus } from '../../types/core_t';


const UPLOAD_ID = 'uploading';
const WidgetAsyncIndicator = (): null => {
	const asyncOperation = useSelector(selectWidgetAsyncStatus);

	useEffect(() => {
		if (asyncOperation.asyncStatus.status === AsyncRequestStatus.loading && asyncOperation.title) {
			message.loading({
				content: asyncOperation.title,
				key: UPLOAD_ID,
				duration: 0
			});
		}

		if (asyncOperation.asyncStatus.status === AsyncRequestStatus.completed && asyncOperation.title) {
			message.success({
				content: asyncOperation.title,
				key: UPLOAD_ID,
				duration: 2
			});
		}

		if (asyncOperation.asyncStatus.status === AsyncRequestStatus.error) {
			message.error({
				content: `${asyncOperation.title || ''}${asyncOperation.asyncStatus.error?.message || 'Unknown'}`,
				key: UPLOAD_ID,
				duration: 5
			});
		}
	}, [asyncOperation]);

	return null;
};

export default WidgetAsyncIndicator;
