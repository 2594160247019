import React, { useCallback, useMemo } from 'react';
import {  WidgetGroupSetting } from '@kemu-io/kemu-core/types';
import classNames from 'classnames';
import { calculateWidgetColors } from '../../common';
import WidgetIcon from '../WidgetIcon/WidgetIcon';
import GroupHeader from '../GroupHeader/GroupHeader';
import styles from './GroupWithHiddenFields.module.css';

type Props = {
  settings?: WidgetGroupSetting[];
  name: string;
  thingRecipeId: string;
  recipeId: string;
  color?: string;
  icon?: string;
  disabled?: boolean;
  onOpenCustomSettings?: () => void;
}

/**
 * Groups with only hidden fields display the HEADER title and the 
 * content is just their default icon.
 */
const GroupWithHiddenFields = (props: Props) => {
  const { settings, recipeId, thingRecipeId, color, disabled, icon, onOpenCustomSettings } = props;

  const colors = useMemo(() => {
    if (!color) { return undefined; }

    const docStyle = getComputedStyle(document.body);
    const disabledColor = docStyle.getPropertyValue('--kemu-color-disabled-widget');

    const colors = calculateWidgetColors(disabled ? disabledColor : color);
    return colors;
  }, [color, disabled]);


  if (!settings) { return null; }
  if (settings.length === 0) { return null; }

  return (
    <div
      className={classNames(styles.GroupBody, {
        [styles.BrightBody]: colors?.isBrightColor,
        [styles.DisabledWidget]: disabled,
      })}
      style={{
        ...(colors?.isBrightColor ? {
          borderColor: colors?.header
        } : {}),
      }}
    >
      <GroupHeader
        title={props.name}
        headerColor={colors?.header}
        isBrightColor={colors?.isBrightColor}
        showGearIcon={true}
        onOpenCustomSettings={onOpenCustomSettings}
      />
      <div
        style={{
          backgroundColor: colors?.content,
        }}
        className={styles.Content}
      >
        <WidgetIcon icon={icon} />
      </div>
    </div>
  );
};

export default GroupWithHiddenFields;
