import React, { useCallback, useEffect, useRef, useState } from 'react';
import { InputRef, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import styles from './EditableTextbox.module.css';

interface Props {
	defaultText: string;
	onEnterKey?: (text: string) => void;
	onFocusChanged: (focused: boolean) => void;
	inputClassName?: string;
	dropdownIconClassName?: string;
	placeholder?: string;
	disabled?: boolean;
	/** 
	 * set to false to cancel inner user typing effect, this will 
	 * cause the input to display 'defaultText' instead of the user's typed text.
	 **/
	editing?: boolean;
}

const EditableTextbox = (props: Props): React.JSX.Element => {
	const {
		defaultText,
		inputClassName,
		placeholder,
		onFocusChanged,
		onEnterKey,
		editing,
		dropdownIconClassName,
		disabled,
	} = props;

	const [userTyping, setUserTyping] = useState(false);
	const inputRef = useRef<InputRef>(null);


	const handleKeyPressed = useCallback((evt: React.KeyboardEvent<HTMLInputElement>) => {
		if (evt.key === 'Enter') {
			evt.preventDefault();
			onEnterKey && onEnterKey(evt.currentTarget.value.trim());
		}
	}, [onEnterKey]);

	const handleFocusIn = useCallback(() => {
		if (disabled) { return; }
		onFocusChanged(true);
		setUserTyping(true);
	}, [onFocusChanged, disabled]);

	const handleFocusOut = useCallback((evt: React.FocusEvent<HTMLInputElement>) => {
		const nativeEvent = evt.nativeEvent.relatedTarget as HTMLUListElement | null;
		// Ignore clicks on menu list
		if (!nativeEvent?.classList.contains('ant-dropdown-menu-item') && !nativeEvent?.classList.contains('ant-dropdown-menu')) {
			onFocusChanged(false);
		}

		setUserTyping(false);
	}, [onFocusChanged]);

	useEffect(() => {
		setUserTyping(!!editing);
	}, [editing]);

	return (
		<Input
			value={userTyping ? undefined : defaultText}
			// onFocus={handleFocusIn}
			placeholder={placeholder}
			disabled={disabled}
			onMouseDown={handleFocusIn}
			onBlur={handleFocusOut}
			ref={inputRef}
			onKeyDown={handleKeyPressed}
			className={classNames(styles.InputName, inputClassName)}
			suffix={
				<DownOutlined
					onClick={handleFocusIn}
					custom-dropdown="yes"
					className={classNames(styles.DropIcon, dropdownIconClassName)}
				/>
			}
		/>

	);
};

export default EditableTextbox;
