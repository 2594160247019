/*
 * Written by Alexander Agudelo < alex.agudelo@kitsunei.com >, 2020
 * Date: 11/Dec/2020
 * Last Modified: 19/08/2023, 3:24:02 pm
 * Modified By: Alexander Agudelo
 * Description:  Shows a loading animation when a recipe is being saved
 * 
 * ------
 * Copyright (C) 2020 Kitsunei - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */

import React, { useEffect } from 'react';
import { message } from 'antd';

interface Props {
	message: string;
	type: 'progress' | 'success' | 'error';
	errorReason?: string;
	hideNotification?: boolean;
}

const AsyncOperationIndicator = (props: Props): React.JSX.Element => {
	const msgKey = 'streamInProgress';
	const { type, message: text, errorReason, hideNotification } = props;

	useEffect(() => {
		const messageConfig = {
			duration: 0,
			key: msgKey,
		};

		if (hideNotification) {
			return message.destroy(msgKey);
		}

		let showMessage = message.loading;
		if (type === 'error') {
			messageConfig.duration = 3;
			showMessage = message.error;
		} else if (type === 'success') {
			messageConfig.duration = 2;
			showMessage = message.success;
		}

		showMessage({ ...messageConfig, content: `${text}${errorReason ? `: ${errorReason}` : ''}` });
	}, [text, type, errorReason, hideNotification]);


	return (<></>);
};

export default AsyncOperationIndicator;
