import { PayloadAction, Draft } from '@reduxjs/toolkit';
import * as RecipeActions from '../../../app/recipe/utils';
import { LogicMakerState, ChildGateActionProps } from '../logicMapperSlice';

export const connectGatesReducer = (state: Draft<LogicMakerState>, action: PayloadAction<ChildGateActionProps>): void => {
	// Prevent connecting widgets already linked
	const alreadyChild = state.gates[action.payload.sourceGateId].children.find(child => {
		return (
			child.childId === action.payload.targetGateId &&
			child.sourcePort === action.payload.sourcePortId &&
			child.targetPort === action.payload.targetPortId
		);
	});

	if (alreadyChild) { return; }

	const updatedChildrenList = RecipeActions.registerChildGate(
		action.payload.recipeId,
		action.payload.blockRecipeId,
		action.payload.sourceGateId,
		action.payload.targetGateId,
		action.payload.sourcePortId,
		action.payload.targetPortId
	);

	state.gates[action.payload.sourceGateId].children = JSON.parse(JSON.stringify(updatedChildrenList));
};
