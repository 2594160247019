import classNames from 'classnames';
import React from 'react';
import styles from './formGroup.module.css';

interface Props{
	children: | React.ReactNode;
	className?: string;
	row?: boolean;
	noMarginBottom?: boolean;
	vCenter?: boolean;
	column?: boolean;
	/** Changes the default margin button from 25px to multiples of 0.3 rem */
	marginBottomLevel?: 1 | 2 | 3 | 4;
	spacing?: number;
}

const FormGroup = (props: Props): React.JSX.Element => {
	return (
		<div
			className={
				classNames(
					styles.FormGroup,
					{
						'v-align': props.vCenter,
						'mb1': props.marginBottomLevel === 1,
						'mb2': props.marginBottomLevel === 2,
						'mb3': props.marginBottomLevel === 3,
						'mb4': props.marginBottomLevel === 4,
						'row': props.row,
						'column': props.column,
						'mb0': props.noMarginBottom
					},
					props.className
				)
			}
			style={{
				...(props.spacing ? { gap: `${props.spacing}px` } : {})
			}}
		>
			{ props.children }
		</div>
	);
};

export default FormGroup;
