import { BrowserJsPlumbInstance } from '@jsplumb/community';
import { WidgetType } from '@kemu-io/kemu-core/types';
import classNames from 'classnames';
import { CUSTOM_WIDGET_PORT_COLOR_FLAG, CUSTOM_WIDGET_PORT_COLOR_PREFIX, DISABLED_WIDGET_CLASS, OFFLINE_SERVICE_CLASS } from '@common/constants';
import { PortSettingsParameters } from '@common/jsPlumb/settings';
import { getCategoryFromType } from '@common/widgetCategories';
import { WidgetPortsSummary } from '@components/gates';
import { KemuEndpointData } from '@src/types/core_t';
import { calculateWidgetColors } from '@components/gates/common';

const buildPortClasses = (config: {
  plumbInstance: BrowserJsPlumbInstance,
  summary: WidgetPortsSummary,
  canvasDomId: string,
  widgetDisabled: boolean,
  serviceOffline: boolean,
  widgetType: WidgetType,
}) => {
  const { plumbInstance, summary, canvasDomId, widgetDisabled, serviceOffline, widgetType } = config;
  const plumbPorts = plumbInstance.getEndpoints(canvasDomId);
  // Wait for jsPlumb to create the ports
  if (!plumbPorts.length) { return; }

  const allPorts = summary.inputPorts.concat(summary.outputPorts);
  allPorts.forEach(port => {
    const plumbPort = plumbPorts.find(pp => pp.portId === port.id);
    if (plumbPort) {
      const portParameters = plumbPort.getParameters();
      // Update the json shape in case it changed
      plumbPort.setParameter('jsonShape' as keyof PortSettingsParameters, port.jsonShape);

      // Remove all existing classes
      plumbPort.removeClass(plumbPort.endpoint.classes[0]);

      // Build a new list of classes
      const portTypes = (Array.isArray(port.type) ? port.type : [port.type]).map((arrType) => `type-${arrType}`);
      const cssClasses = classNames(
        portParameters.isTarget ? 'target-port': 'source-port',
        'port',
        'gate-port',
        `ctgry-${getCategoryFromType(widgetType)}`,
        ...portTypes,
        {
          // 'original-event': gateInfo.returnOriginalEvent,
          [DISABLED_WIDGET_CLASS]: widgetDisabled,
          [OFFLINE_SERVICE_CLASS]: serviceOffline,
          [CUSTOM_WIDGET_PORT_COLOR_FLAG]: summary?.color,
          [`${CUSTOM_WIDGET_PORT_COLOR_PREFIX}${summary.color?.replace('#', '')}`]: summary?.color,
        }
      );

      // This is used by the port debugging panel
      const endpointData: KemuEndpointData = {
        type: port.type,
        name: port.name,
        label: port.label,
        id: port.id,
        jsonShape: port.jsonShape
      };

      plumbPort.setData(endpointData);

      plumbPort.addClass(cssClasses);
    } else {
      console.error(`Port [${port.name}] not found!`);
    }
  });
};

/**
 * Re-validates the given element to repaint its ports.
 * @param plumbInstance 
 * @param targetEl 
 */
const repaintPorts = (plumbInstance: BrowserJsPlumbInstance, targetEl?: HTMLElement | null) => {
  if (targetEl) {
    plumbInstance.revalidate(targetEl);
  }
};

/**
	 * Adds a custom style to widget group ports that have a custom color.
	 */
const updateCustomPortClasses = () => {
  const ports = document.querySelectorAll<HTMLElement>(`.port.${CUSTOM_WIDGET_PORT_COLOR_FLAG}`);
  ports.forEach(portEl => {
    const classColor = portEl.classList.value.split(' ').find(className => className.startsWith(CUSTOM_WIDGET_PORT_COLOR_PREFIX));
    const isDisabled = portEl.classList.value.includes(DISABLED_WIDGET_CLASS);
    const isOffline = portEl.classList.value.includes(OFFLINE_SERVICE_CLASS);
    if (classColor) {
      const hexColor = classColor.replace(CUSTOM_WIDGET_PORT_COLOR_PREFIX, '');
      if (!isDisabled && !isOffline) {
        const { border, brightness } = calculateWidgetColors(hexColor);
        portEl.style.border = `solid 2px ${brightness < 250 ? `#${hexColor}` : border}`;
      } else {
        // clear it out
        portEl.style.border = '';
      }
    }
  });
};

export {
  buildPortClasses,
  repaintPorts,
  updateCustomPortClasses,
};
