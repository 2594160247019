import {
  HubServiceState,
  Position,
  WidgetState,
  WidgetType,
} from '@kemu-io/kemu-core/types';
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { getDefaultState } from '@kemu-io/kemu-core/widgets/hubService/index.js';
import { findThingInRecipe } from '@kemu-io/kemu-core/common/recipeCache';
import { SerializableServiceInfo } from '@kemu-io/hs-types';
import kemuCore from '@kemu-io/kemu-core';
import { RemoveInvokeError } from '@kemu-io/hs/ri';
import { LogicMapperState } from '../logicMapperSlice';
import { getDefaultWidgetPosition, addWidgetToThing, removeWidget } from '@src/app/recipe/utils';
import { widgetMapToStatelessMap } from '@common/utils';
import { RootState } from '@src/app/store';
import hubConnectionManager from '@src/app/kemuHub/connectionManager';
import { StatelessWidgetsMap } from '@src/types/core_t';
import { showGlobalNotification } from '@src/features/interface/interfaceSlice';
import { getGlobalIntl } from '@src/assets/i18n/globalIntl';

type AddHubServiceWidgetProps = {
  service: SerializableServiceInfo,
  variantId?: string,
  currentGroupId?: string,
  position?: Position,
  /** the currently active session of the service (if online) */
  sessionId?: number,
}

/**
 * Adds a HubService type widget into the current recipe.
 */
const addHubServiceWidgetAction = createAsyncThunk(
  '/LogicMapper/addHubServiceWidgetAction', async (
  payload: AddHubServiceWidgetProps,
  thunkApi,
) : Promise<StatelessWidgetsMap> => {

  const reduxState = thunkApi.getState() as RootState;
  const { currentRecipe, selectedBlockInfo } = reduxState.workspace;

  const recipePoolId = currentRecipe.poolId;
  const thingId = selectedBlockInfo?.recipeId;

  if (!thingId) { throw new Error('No thing selected'); }
  if (!recipePoolId) { throw new Error('No recipe selected'); }

  const hc = hubConnectionManager.getConnector();
  let serviceDefaultState: WidgetState = {};

  if (payload.sessionId) {
    try {
      serviceDefaultState = await hc.getDefaultState(payload.sessionId);
    } catch (e) {
      console.error('Failed to get default state for service, using default.', e);
    }
  }
  // Get a list of currently running services
  // Build the service state
  const serviceState: HubServiceState = {
    ...getDefaultState(), // this is the default state of the HubService widget wrapper
    customState: serviceDefaultState, // this is the custom state of the actual service
    $$$serviceId: payload.sessionId,
    variantId: payload.variantId,
    // manifest: payload.service,
    service: payload.service, /* {
      inputs: payload.service.inputs || [],
      outputs: payload.service.outputs || [],
      name: payload.service.name,
      version: payload.service.version,
      eventEmitter: payload.service.eventEmitter,
      ignoreParentEvents: payload.service.ignoreParentEvents,
      color: payload.service.color,
    } */
  };

  const thing = findThingInRecipe(recipePoolId, thingId);
  if (!thing) { throw new Error(`Thing [${thingId}] not found in recipe`); }
  const t = getGlobalIntl();

  const currentWidgets = thing.gates;
  const defaultPosition = payload.position || getDefaultWidgetPosition(
    recipePoolId,
    thingId,
    WidgetType.hubService,
    currentWidgets,
  );

  const addedWidgetInstance = addWidgetToThing(
    recipePoolId,
    thingId,
    WidgetType.hubService,
    defaultPosition,
    payload.currentGroupId,
    serviceState,
  );

  // Check if the service is an event emitter and if so, subscribe to the events
  if (payload.service.eventEmitter) {
    // NOTE: consider waiting to control error. Maybe updating the interface?s
    hc.subscribeToServiceEvents({
      targetService: {
        serviceName: payload.service.name,
        version: payload.service.version,
      },
      listener: {
        recipeId: recipePoolId,
      }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }).catch((e: any) => {
      console.error(`Failed to subscribe to service [${payload.service.name} (${payload.service.version})] events: `, e);
    });
  }

  // NOTE: the currentWidgets map should also contain the newly added reference.
  let statelessMap = widgetMapToStatelessMap(currentWidgets);

  // Initialize the service
  try {
    await kemuCore.initializeWidget(recipePoolId, thingId, addedWidgetInstance.id);
  } catch (e) {
    // Allow unimplemented init functions to be ignored
    if (e?.errCode !== RemoveInvokeError.FunctionNotFound) {
      // Backwards compatibility with older versions of remoteInvoker without custom errors
      const functionNotDefined = !!(typeof e === 'string' && e.match('^Function ".*" not found.$'));
      // Remove the widget if the initialization fails
      if (!functionNotDefined) {
        // TODO: Show a notification
        const updatedMap = await removeWidget(recipePoolId, thingId, addedWidgetInstance.id);
        statelessMap = widgetMapToStatelessMap(updatedMap);
        thunkApi.dispatch(showGlobalNotification({
          title: t('Errors.ServiceInitError'),
          message: typeof e === 'string' ? e : e.error || e.message,
          type: 'error',
        }));
      }
    }
  }

  // Update the default state
  return statelessMap;
});


const addWidgetFromTemplateReducer = ((builder: ActionReducerMapBuilder<LogicMapperState>): void => {
  builder.addCase(addHubServiceWidgetAction.fulfilled, (state, action) => {
    state.gates = action.payload;
  });

  builder.addCase(addHubServiceWidgetAction.rejected, (_state, action) => {
    console.error('Failed to add widget from template: ', action.error);
  });
});

export default {
  action: addHubServiceWidgetAction,
  reducer: addWidgetFromTemplateReducer,
};
