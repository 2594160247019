import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { CustomWidgetVariant } from '@kemu-io/kemu-types';
import { buildWidgetRequestBody, dbEntityToCollection } from './utils';
import widgetCache from './widgetCache';
import { WidgetCollectionState, WidgetCollectionItem } from '@src/app/reducers/widget/widgetSlice';
import * as widgetApi from '@src/api/widget/widgetApi';
import { AsyncRequestStatus } from '@src/types/core_t';
import { editCustomWidgetState } from '@src/app/recipe/utils';
import { replaceCurrentFolderPathAction } from '@src/features/Workspace/workspaceSlice';
import { uploadFile } from '@src/api/utils';
import { getGlobalIntl } from '@src/assets/i18n/globalIntl';

/**
 * Saves the given widget in the database and uploads the contents to S3.
 * It also adds the widget to the local cache.
*/
export const saveWidgetAsAction = createAsyncThunk('/widgets/saveWidgetAsAction', async (
	args: {
		widgetDbId: string;
		widgetIdInRecipe: string;
		recipeId: string;
		thingId: string;
		newName: string;
		variant: CustomWidgetVariant;
	},
	thunkAPI
): Promise<WidgetCollectionItem> => {

	const t = getGlobalIntl();
	const {
		requestObject,
		contentsBuf,
		contentsStr,
		widgetState
	} = buildWidgetRequestBody(args.widgetIdInRecipe, args.thingId, args.recipeId, t, args.variant, args.newName);

	const response = await widgetApi.saveWidgetAs(args.widgetDbId, requestObject);

	// Save the contents in the local cache
	await widgetCache.storeWidget(response.widget.id, response.widget.version, contentsStr);
	// Update the recipe reference
	editCustomWidgetState(args.recipeId, args.thingId, args.widgetIdInRecipe, {
		...widgetState,
		collectionInfo: {
			userId: response.widget.userId,
			version: response.widget.version,
			widgetId: response.widget.id
		}
	}, true);


	if (response.uploadUrl) {
		// Upload file to S3
		await uploadFile(response.uploadUrl, contentsBuf);
	}

	// Replace the current path
	thunkAPI.dispatch(replaceCurrentFolderPathAction({
		groupId: args.widgetIdInRecipe,
		name: widgetState.name,
		description: widgetState.description,
	}));

	return dbEntityToCollection(contentsStr, response.widget);
});


export const saveWidgetAsReducer = ((builder: ActionReducerMapBuilder<WidgetCollectionState>/*, thunk: any*/): void => {

	builder.addCase(saveWidgetAsAction.pending, (state) => {
		const t = getGlobalIntl();
		state.asyncIndicator = {
			title: t('Widget.AsyncIndicator.Progress', '{action} Widget...', { action: 'Saving' }),
			asyncStatus: {
				status: AsyncRequestStatus.loading,
				error: undefined,
			}
		};
	});

	builder.addCase(saveWidgetAsAction.rejected, (state, action) => {
		const t = getGlobalIntl();
		state.saveAsModal.visible = false;
		state.asyncIndicator = {
			title: t('Widget.AsyncIndicator.Failure', 'Failed to {action} widget: ', { action: 'save' }),
			asyncStatus: {
				status: AsyncRequestStatus.error,
				error: action.error,
			}
		};
	});

	builder.addCase(saveWidgetAsAction.fulfilled, (state, action: PayloadAction<WidgetCollectionItem>) => {
		const t = getGlobalIntl();
		state.selectedWidget = action.payload.dbId;
		state.saveAsModal.visible = false;
		// Add new widget to local collection
		state.customWidgets = [
			...state.customWidgets,
			action.payload,
		];

		state.asyncIndicator = {
			title: t('Widget.AsyncIndicator.Success', 'Widget {action}!', { action: 'saved' }),
			asyncStatus: {
				status: AsyncRequestStatus.completed,
				error: undefined,
			}
		};
	});
});

